<template>
  <div class="container onboarding-result">
    <h2>Bekræftelse</h2>
    <br />
    <div v-if="this.$route.query.state === 'Complete'">
      <div class="alert alert-success" role="alert">Gennemført</div>
    </div>
    <div v-else>
      <div class="alert alert-danger" role="alert">Fejl</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "OnboardingResult",
});
</script>
