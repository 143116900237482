<template>
  <section v-if="this.sessionRequest.workflow.metaDataFields" class="meta-data">
    <h3>Additional</h3>

    <!-- Loop MetaData properties -->
    <div
      v-for="metaData in this.sessionRequest.metaData"
      v-bind:key="metaData.name"
      class="form-group"
    >
      <!-- Select -->
      <template v-if="metaData.inputType === 'select'">
        <label class="control-label col-sm-3" v-bind:for="metaData.name">
          {{ metaData.label }}
        </label>
        <div class="control-input col-sm-9">
          <select
            class="uik-input__select"
            v-bind:id="metaData.name"
            v-bind:class="[
              metaData.readonly ? 'disabled' : '',
              metaData.value && metaData.value.length > 0
                ? 'not-empty'
                : 'empty',
            ]"
            v-model="metaData.value"
            v-bind:required="metaData.required ? true : false"
            v-bind:aria-label="metaData.label"
          >
            <option v-bind:value="''" aria-label="Intet valgt">
              Select from list
            </option>
            <option
              v-for="metaDataOption in metaData.options.split(',')"
              v-bind:value="metaDataOption"
              v-bind:key="metaDataOption"
              v-bind:disabled="metaData.readonly ? true : false"
              v-bind:aria-label="metaDataOption"
            >
              {{ metaDataOption }}
            </option>
          </select>
        </div>
      </template>

      <!-- Radio -->
      <template v-else-if="metaData.inputType === 'radio'">
        <label class="control-label col-sm-3">
          {{ metaData.label }}
        </label>
        <div class="control-input col-sm-9">
          <ul
            class="radio-group horizontal"
            role="radiogroup"
            v-bind:aria-label="metaData.label"
          >
            <li
              v-for="metaDataOption in metaData.options.split(',')"
              v-bind:key="metaDataOption"
            >
              <label
                v-bind:for="metaData.name + '_' + metaDataOption"
                v-bind:aria-label="metaDataOption"
              >
                <input
                  role="radio"
                  type="radio"
                  v-bind:id="metaData.name + '_' + metaDataOption"
                  class="uik-input__input"
                  v-bind:name="metaData.name"
                  v-bind:value="metaDataOption"
                  v-bind:disabled="metaData.readonly ? true : false"
                  v-bind:required="metaData.required ? true : false"
                  v-bind:aria-label="metaDataOption"
                  v-model="metaData.value"
                />
                {{ metaDataOption }}
                <div class="check"></div>
              </label>
            </li>
          </ul>
        </div>
      </template>

      <!-- Checkbox -->
      <template v-else-if="metaData.inputType === 'checkbox'">
        <label class="control-label col-sm-3"> </label>
        <div class="control-input col-sm-9">
          <ul class="checkbox-group horizontal">
            <li>
              <label
                v-bind:for="metaData.name"
                v-bind:aria-label="metaData.label"
              >
                <input
                  type="checkbox"
                  v-bind:id="metaData.name"
                  class="uik-input__input"
                  v-bind:name="metaData.name"
                  v-bind:value="true"
                  v-bind:disabled="metaData.readonly ? true : false"
                  v-bind:required="metaData.required ? true : false"
                  v-bind:aria-label="metaData.label"
                  v-model="metaData.value"
                />
                {{ metaData.label }}
                <div class="check"></div>
              </label>
            </li>
          </ul>
        </div>
      </template>

      <!-- Hidden -->
      <template
        v-else-if="metaData.inputType === 'hidden'"
        style="display: none"
      >
        <input
          type="hidden"
          autocomplete="nope"
          v-bind:required="metaData.required ? true : false"
          v-model.trim="metaData.value"
        />
      </template>

      <!-- Fallback: Text, Tel, Number, Email -->
      <template v-else>
        <label class="control-label col-sm-3" v-bind:for="metaData.name">
          {{ metaData.label }}
        </label>
        <div class="control-input col-sm-9">
          <input
            v-bind:type="metaData.inputType"
            v-bind:id="metaData.name"
            class="uik-input__input"
            autocomplete="nope"
            v-bind:readonly="metaData.readonly ? true : false"
            v-bind:disabled="metaData.readonly ? true : false"
            v-bind:required="metaData.required ? true : false"
            v-bind:aria-label="metaData.label"
            v-bind:class="[
              metaData.value && metaData.value.length > 0
                ? 'not-empty'
                : 'empty',
            ]"
            v-model.trim="metaData.value"
          />
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "OnboardingMetaData",
  props: {
    sessionRequest: {
      type: Object,
      required: true,
    },
  },
  created() {
    //  Initialise
    this.populateMetaData();
  },
  methods: {
    /**
     * Populate by default and query parameters
     */
    populateMetaData: function () {
      this.sessionRequest.metaData = [];

      //  Verify Workflow's MetaData fields
      if (this.sessionRequest.workflow.hasOwnProperty("metaDataFields")) {
        //  Loop MetaData Fields
        for (
          var i = 0;
          i < this.sessionRequest.workflow.metaDataFields.length;
          i++
        ) {
          //  Default radio value
          if (
            this.sessionRequest.workflow.metaDataFields[i].inputType === "radio"
          ) {
            this.sessionRequest.workflow.metaDataFields[i].value =
              this.sessionRequest.workflow.metaDataFields[i].options.split(
                ","
              )[0];
          }

          //  Get value by query-parameter
          let prefill =
            "pre_" + this.sessionRequest.workflow.metaDataFields[i].name;

          //  Trim and set value
          if (this.$route.query[prefill]) {
            this.sessionRequest.workflow.metaDataFields[i].value =
              this.$route.query[prefill].trim();
          }

          //  Set Meta Data
          this.sessionRequest.metaData.push(
            this.sessionRequest.workflow.metaDataFields[i]
          );
        }
      }

      //  Verify Campaign's MetaData fields
      if (this.sessionRequest.campaign.hasOwnProperty("metaDataFields")) {
        //  Loop MetaData Fields
        for (
          var o = 0;
          o < this.sessionRequest.campaign.metaDataFields.length;
          o++
        ) {
          //  Default radio value
          if (
            this.sessionRequest.campaign.metaDataFields[o].inputType === "radio"
          ) {
            this.sessionRequest.campaign.metaDataFields[o].value =
              this.sessionRequest.campaign.metaDataFields[o].options.split(
                ","
              )[0];
          }

          //  Get value by query-parameter
          let prefill =
            "pre_" + this.sessionRequest.campaign.metaDataFields[o].name;

          //  Trim and set value
          if (this.$route.query[prefill]) {
            this.sessionRequest.campaign.metaDataFields[o].value =
              this.$route.query[prefill].trim();
          }

          //  Set Meta Data
          this.sessionRequest.metaData.push(
            this.sessionRequest.campaign.metaDataFields[o]
          );
        }
      }

      //  Force update needed to update session - at least in Vue console
      this.$forceUpdate();
    },
  },
});
</script>
