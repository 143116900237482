<template>
  <div class="right-aligned popover-wrapper">
    <button type="button" v-on:click="toggleExpanded()" class="action">
      <i class="dashboard-icon icon-filter"></i>Filter
    </button>
    <div class="popover popover-filter" v-bind:class="{ expanded: expanded }">
      <h4 class="">State options</h4>
      <form
        method="post"
        action=""
        autocomplete="nope"
        novalidate="novalidate"
        v-on:submit.prevent
      >
        <!-- State -->
        <label for="filter_state">State</label>
        <select
          id="filter_state"
          class="uik-input__input"
          v-model="entityState"
        >
          <option v-bind:value="'all'">All</option>
          <option v-bind:value="'active'">Active</option>
          <option v-bind:value="'archived'">Archived</option>
        </select>

        <!-- Submit-->
        <div class="filter-footer">
          <ul class="unordered-list">
            <li>
              <button
                type="button"
                class="uik-btn__base uik-btn__secondary"
                v-on:click="clearFilter()"
              >
                Clear
              </button>
            </li>
            <li>
              <button
                type="button"
                class="uik-btn__base uik-btn__primary"
                v-on:click="applyFilter()"
              >
                Apply
              </button>
            </li>
          </ul>
        </div>
      </form>
    </div>
    <div
      class="popover-filter-bg"
      v-bind:class="{ expanded: expanded }"
      v-on:click="toggleExpanded()"
    ></div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "StateFilter",
  data: function () {
    return {
      entityState: "active",
      expanded: false,
    };
  },
  methods: {
    /**
     *  Toggle expand / collapse
     */
    toggleExpanded: function () {
      if (this.expanded) {
        this.expanded = false;
      } else {
        this.expanded = true;
      }
    },

    /**
     * Clear filter
     */
    clearFilter: function () {
      //  Reset
      this.entityState = "active";

      this.applyFilter();
    },

    /**
     * Apply filter
     */
    applyFilter: function () {
      //  Filter state
      if (this.entityState) {
        this.$root.store.campaignState = this.entityState;
      } else {
        this.$root.store.campaignState = null;
      }

      this.toggleExpanded();

      //  Emit
      this.$emit("appliedFilter");
    },
  },
});
</script>
