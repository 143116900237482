<template>
  <div class="start-date-selection">
    <div class="control-input">
      <label> Start date </label>
      <div class="row">
        <div class="col-sm-12">
          <div class="input-wrap">
            <date-range-picker
              ref="datePicker"
              v-bind:singleDatePicker="true"
              v-bind:timePicker="false"
              v-bind:timePicker24Hour="false"
              v-bind:showWeekNumbers="false"
              v-bind:showDropdowns="true"
              v-bind:autoApply="true"
              v-bind:ranges="false"
              v-bind:linkedCalendars="false"
              v-bind:always-show-calendars="true"
              v-bind:opens="'left'"
              v-bind:locale-data="{
                firstDay: 1,
                format: 'YYYY-MM-DD',
                separator: ' - ',
              }"
              v-model="datePicked"
              v-on:update="whenDatePicked()"
            >
              <template v-slot:input="datePicker">
                <span
                  v-bind:inner-html.prop="datePicker | formatDatePicker"
                ></span>
                <button type="button" class="btn btn-primary icon-btn">
                  <i class="dashboard-icon icon-calendar"></i>
                </button>
              </template>
            </date-range-picker>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { formats } from "../../mixins/formats.js";

export default Vue.extend({
  name: "OnboardingScheduleStartDate",
  mixins: [formats],
  components: {
    DateRangePicker,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    sessionRequest: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      datePicked: {
        startDate: null,
        endDate: null,
      },
    };
  },
  created() {
    //  Set startDate for date picker
    if (this.$route.query.pre_startDate) {
      this.datePicked = {
        startDate: this.$route.query.pre_startDate,
        endDate: null,
      };
    } else {
      this.datePicked = {
        startDate: new Date(),
        endDate: null,
      };
    }
  },
  methods: {
    /**
     * Define start date
     */
    whenDatePicked: function () {
      if (this.datePicked.startDate) {
        this.product.startDate = this.formatDateToYMD(
          this.datePicked.startDate
        );
      }
    },
  },
});
</script>
