<template>
  <div>
    <!-- Modal -->
    <div v-if="lead" class="modal slideIn">
      <!-- Header -->
      <div class="modal-header">
        <i class="dashboard-icon icon-user"></i>
        <h2>Lead</h2>
        <div class="right-aligned">
          <button
            type="button"
            class="go-back"
            v-on:click="$root.viewPrevious()"
          >
            <i class="dashboard-icon icon-triangle-left"></i>Go back
          </button>
          <button
            type="button"
            class="of-modal-close"
            v-on:click="closeLeadModal()"
          >
            <i class="dashboard-icon icon-close"></i>
          </button>
        </div>
      </div>
      <!-- Content -->
      <div class="modal-content">
        <div class="row">
          <div class="col-sm-12 modal-top">
            <h2>External lead ID: {{ this.lead.externalLeadId }}</h2>
          </div>
          <!-- Left -->
          <div class="col-sm-4 modal-left">
            <div
              class="uik-widget__wrapper uik-widget__wrapper-top lead-information"
            >
              <div class="uik-widget-title__wrapper">
                <h3>Review</h3>
              </div>
              <table class="uik-widget__table_info">
                <tbody>
                  <tr>
                    <td>Created</td>
                    <td
                      v-bind:inner-html.prop="this.lead.createdTs | formatTime"
                    ></td>
                  </tr>

                  <!-- Campaign with link -->
                  <tr v-if="this.campaign">
                    <td>Campaign</td>
                    <td v-if="this.$root.user.role === 'MerchantAdmin'">
                      <router-link
                        :to="{
                          name: 'campaigns',
                          query: {
                            page: 'onboarding_app',
                            view: 'campaigns',
                            guid: this.lead.campaignGuid,
                          },
                        }"
                      >
                        {{ this.campaign.name }}
                      </router-link>
                    </td>
                    <td v-else-if="this.$root.user.role === 'AgencyManager'">
                      <router-link
                        :to="{
                          name: 'agency-campaigns',
                          query: {
                            merchantId: this.lead.merchantId,
                            view: 'campaigns',
                            guid: this.lead.campaignGuid,
                          },
                        }"
                      >
                        {{ this.campaign.name }}
                      </router-link>
                    </td>
                    <td v-else>
                      {{ this.campaign.name }}
                    </td>
                  </tr>

                  <tr>
                    <td>Merchant</td>
                    <td>{{ this.lead.merchantId }}</td>
                  </tr>
                  <tr>
                    <td>Agency</td>
                    <td>{{ this.lead.agencyId }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- Right -->
          <div class="col-sm-8 modal-right">
            <!-- Sessions -->
            <div class="uik-widget__wrapper">
              <div class="uik-widget-title__wrapper"><h3>Sessions</h3></div>
              <div class="uik-entity-list session-list">
                <table>
                  <!-- Table header -->
                  <thead>
                    <tr>
                      <th class="manage-column column-status">Status</th>
                      <th class="manage-column column-created">Created</th>
                      <th class="manage-column column-service-type">
                        Intention
                      </th>
                      <th class="manage-column column-agency">Agency</th>
                    </tr>
                  </thead>

                  <!-- Error -->
                  <tbody v-if="this.sessionsListErrored === true">
                    <tr>
                      <td colspan="4" class="text-center">
                        We are sorry. We were not able to retrieve sessions.
                      </td>
                    </tr>
                  </tbody>

                  <!-- Loading -->
                  <tbody v-else-if="this.sessionsListLoading === true">
                    <tr>
                      <td colspan="4" class="text-center">
                        Loading sessions ...
                      </td>
                    </tr>
                  </tbody>

                  <!-- Table -->
                  <tbody v-else-if="this.sessions.length > 0">
                    <tr
                      tabindex="0"
                      v-for="session in this.sessions"
                      :key="session.sessionGuid"
                      v-on:keyup.up="
                        $event.target.previousElementSibling.focus()
                      "
                      v-on:keyup.down="$event.target.nextElementSibling.focus()"
                      v-on:keyup.enter="viewSession(session)"
                      v-on:click.exact="viewSession(session)"
                      v-on:click.ctrl="viewSessionNewTab(session)"
                      v-bind:class="{
                        blue: session.status === 'Processing',
                        green: session.status === 'Completed',
                        red: session.status === 'Failed',
                        grey: session.status === 'Expired',
                      }"
                    >
                      <td>{{ session.status }}</td>
                      <td>
                        <span
                          v-bind:inner-html.prop="
                            session.createdTs | formatTime
                          "
                        ></span>
                      </td>
                      <td>
                        {{ session.serviceType | humanReadableServiceType }}
                      </td>
                      <td>{{ session.agencyId }}</td>
                    </tr>
                  </tbody>

                  <!-- Not found -->
                  <tbody v-else>
                    <tr>
                      <td colspan="4" class="text-center">
                        No sessions yet ...
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      v-if="lead"
      v-on:click="closeLeadModal()"
      class="of-modal-bg fadeIn"
    ></div>
  </div>
</template>

<script>
import Vue from "vue";
import { leadHandler } from "../../mixins/leadHandler.js";
import { campaignHandler } from "../../mixins/campaignHandler.js";
import { formats } from "../../mixins/formats.js";

export default Vue.extend({
  name: "LeadModal",
  mixins: [leadHandler, campaignHandler, formats],
  props: {
    lead: {
      type: Object,
      required: false,
    },
  },
  data: function () {
    return {
      campaign: {},
      sessionsListLoading: false,
      sessionsListErrored: false,
      sessions: [],
    };
  },
  mounted() {
    //  Hotkey to close modal
    let self = this;
    window.addEventListener("keyup", function (e) {
      if (e.key === "Escape") {
        self.closeLeadModal();
      }
    });
  },
  created() {
    this.getLeadSessions();
    this.getLeadCampaign();
  },
  methods: {
    /**
     * Close modal
     */
    closeLeadModal: function () {
      this.$emit("closeLeadModal");
    },

    /**
     * Get campaign
     */
    getLeadCampaign: function () {
      //  Handle promise or storage
      let campaignHandler = this.getCampaign(this.lead.campaignGuid);

      //  Promise
      if (typeof campaignHandler.then !== "undefined") {
        campaignHandler.then((response) => {
          if (response.campaignErrored) {
            this.campaign = null;
          } else {
            this.campaign = response.campaign;
          }
        });
      }

      //  Storage
      else if (campaignHandler.campaign) {
        this.campaign = campaignHandler.campaign;
      }
    },

    /**
     * Get lead's sessions
     */
    getLeadSessions: function () {
      this.sessionsListLoading = true;

      this.getSessionsByLead(this.lead.leadGuid).then((response) => {
        this.sessions = response.sessions;

        if (response.sessionsErrored) {
          this.sessionsListErrored = true;
        }

        this.sessionsListLoading = false;
      });
    },

    /**
     * Open modal to view
     * @param {object} session
     */
    viewSession: function (session) {
      this.$root.viewEntity("session", session.sessionGuid);
    },

    /**
     * Open modal in new tab
     * @param {object} session
     */
    viewSessionNewTab: function (session) {
      this.$root.viewEntityNewTab("session", session.sessionGuid);
    },
  },
});
</script>
