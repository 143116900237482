<template>
  <div class="wrap agencies">
    <div class="content">
      <!-- Heading -->
      <div class="view-header-wrap uik-entity-list-header">
        <h2 class="title">Agencies</h2>

        <!-- Tooltip -->
        <tool-tip
          v-bind:tooltip="'Agencies can only engage the campaigns assigned to them and their resulting data. <a href=&quot;https://support.onlinefundraising.dk/hc/da/articles/360013209419&quot; target=&quot;_blank&quot;>See our guide.</a>'"
        />

        <div
          v-if="this.$root.user.role === 'MerchantAdmin'"
          class="right-aligned popover-wrapper"
        >
          <button
            type="button"
            class="uik-btn__base btn btn-primary"
            tabindex="-1"
            v-on:click="newAgency()"
          >
            Add agency
          </button>
        </div>
      </div>

      <!-- Table -->
      <div class="uik-entity-list agency-list">
        <table>
          <!-- Table header -->
          <thead>
            <tr>
              <th class="manage-column column-name">Name</th>
            </tr>
          </thead>

          <!-- Error -->
          <tbody v-if="this.listErrored === true">
            <tr>
              <td colspan="1" class="text-center">
                We are sorry. We were not able to retrieve agencies.
              </td>
            </tr>
          </tbody>

          <!-- Loading -->
          <tbody v-else-if="this.listLoading === true">
            <tr>
              <td colspan="1" class="text-center">Loading agencies ...</td>
            </tr>
          </tbody>

          <!-- Table -->
          <tbody v-else-if="this.$root.store.agencies.length > 0">
            <tr
              tabindex="0"
              v-for="agency in this.$root.store.agencies"
              v-bind:key="agency.agencyId"
              v-on:click="editAgency(agency)"
            >
              <td v-if="agency.name">{{ agency.name }}</td>
              <td v-else>{{ agency.agencyId }}</td>
            </tr>
          </tbody>

          <!-- Not found -->
          <tbody v-else>
            <tr>
              <td colspan="3" class="text-center">Ready for agencies ...</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Modal -->
    <div v-if="this.currentAgency">
      <agency-modal
        v-bind:agency="this.currentAgency"
        v-on:closeAgencyModal="closeAgencyModal()"
        v-on:removedAgency="whenRemovedAgency()"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import AgencyModal from "./AgencyModal";
import ToolTip from "../ToolTip";
import { agencyHandler } from "../../mixins/agencyHandler.js";

export default Vue.extend({
  name: "AgencyList",
  mixins: [agencyHandler],
  components: {
    AgencyModal,
    ToolTip,
  },
  data: function () {
    return {
      currentAgency: null,
      listErrored: false,
      listLoading: true,
    };
  },
  created() {
    this.getAgencies();
  },
  methods: {
    /**
     * Open modal to create
     */
    newAgency: function () {
      this.currentAgency = {};
    },

    /**
     * Open modal to edit
     */
    editAgency: function (agency) {
      this.currentAgency = agency;
    },

    /**
     * Close modal
     */
    closeAgencyModal: function () {
      this.currentAgency = null;
    },

    /**
     * Refresh agencies when removed
     */
    whenRemovedAgency: function () {
      this.$root.store.agencies = [];
      this.currentAgency = null;
      this.listLoading = true;
      this.getAgencies();
    },

    /**
     * Get agrencies from API
     */
    getAgencies: function () {
      let agencyHandler = this.loadAgencies();
      if (typeof agencyHandler.then !== "undefined") {
        agencyHandler.then((response) => {
          if (response.agenciesErrored) {
            this.listErrored = true;
          } else {
            this.listLoading = false;
          }
        });
      } else {
        this.listLoading = false;
      }
    },
  },
});
</script>
