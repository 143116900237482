<template>
  <div>
    <!-- Modal -->
    <div v-if="merchant" class="modal modal-narrow slideIn">
      <div class="modal-content">
        <!-- Header -->
        <div class="block">
          <div class="block-header">
            <h2 class="title">Insert access token</h2>
            <p class="desc">Please contact your client for an access token</p>
          </div>
          <div class="block-wrap token-input-wrap">
            <div class="input-wrap">
              <input
                type="text"
                class="uik-input__input"
                placeholder="Insert token"
                v-model="merchant.token"
              />
              <button
                type="button"
                class="uik-btn__base btn-primary btn"
                v-on:click="insertMerchant()"
                v-bind:disabled="merchant.token ? false : true"
              >
                Submit token
              </button>
            </div>
          </div>
          <div class="block info-next">
            <div class="row">
              <div class="col-sm-8">
                <h3>What happens next?</h3>
                <p class="desc">
                  Once confirmed you will gain access to the client's campaigns
                  and workflows.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      v-if="merchant"
      v-on:click="closeMerchantModal()"
      class="of-modal-bg fadeIn"
    ></div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import md5 from "js-md5";
import { merchantHandler } from "../../mixins/merchantHandler.js";

export default Vue.extend({
  name: "MerchantModal",
  mixins: [merchantHandler],
  props: {
    merchant: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    //  Hotkey to close modal
    let self = this;
    window.addEventListener("keyup", function (e) {
      if (e.key === "Escape") {
        self.closeMerchantModal();
      }
    });
  },
  methods: {
    /**
     * Close modal
     */
    closeMerchantModal: function () {
      this.$emit("closeMerchantModal");
    },

    /**
     * Register merchant
     */
    insertMerchant: function () {
      //  Body
      let body = JSON.stringify({
        token: this.merchant.token,
      });

      //  Request
      axios
        .post(this.$root.apiUrl + "/license/AddMerchantLicense", body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$root.user.authToken,
            requestMD5: md5(body),
          },
        })
        .then((response) => {
          //  Toast
          this.$root.successResponse("Access gained", response);

          //  Emit
          this.$emit("closeMerchantModal");

          //  Refresh merchants
          this.refreshMerchants();
        })
        .catch((error) => {
          this.$root.handleErrorResponse(error);
        });
    },
  },
});
</script>
