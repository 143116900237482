<template>
  <div>
    <!-- Modal -->
    <div v-if="user" class="modal modal-narrow slideIn">
      <div class="modal-content">
        <!-- Header -->
        <div class="view-header-wrap uik-entity-list-header">
          <h2 v-if="user.userGuid" class="title">Edit user</h2>
          <h2 v-else class="title">Create user</h2>
          <div
            v-if="this.$root.user.role === 'AgencyManager'"
            class="right-aligned popover-wrapper"
          >
            <button
              v-if="user.userGuid"
              type="button"
              class="uik-btn__link btn btn-archive"
              v-on:click="archiveUser()"
            >
              Archive
            </button>
            <button
              v-if="user.userGuid"
              type="button"
              class="uik-btn__base btn btn-primary"
              v-on:click="updateUser()"
            >
              Update
            </button>
            <button
              v-else
              type="button"
              class="uik-btn__base btn btn-primary"
              v-on:click="insertUser()"
            >
              Create
            </button>
          </div>
        </div>

        <div class="block">
          <div class="control-input">
            <label for="name">Name</label>
            <input
              type="text"
              id="name"
              class="uik-input__input"
              required
              v-model="user.name"
            />
          </div>
          <div class="control-input">
            <label for="role">Role</label>
            <select id="role" class="uik-input__select" v-model="user.role">
              <option
                v-for="role in roles"
                v-bind:value="role.value"
                v-bind:key="role.value"
                v-bind:disabled="role.value === undefined ? true : false"
              >
                {{ role.label }}
              </option>
            </select>
          </div>
          <div class="control-input">
            <label for="email">Email</label>
            <input
              type="email"
              id="email"
              class="uik-input__input"
              required
              v-model="user.email"
            />
          </div>
          <div class="control-input">
            <label for="externalId">External ID</label>
            <input
              type="text"
              id="externalId"
              class="uik-input__input"
              v-model="user.externalId"
            />
          </div>
        </div>
        <button
          v-if="user.userGuid && this.$root.user.role === 'AgencyManager'"
          type="button"
          class="uik-btn__link btn btn-reset-password"
          v-on:click="sendPasswordReset()"
        >
          Request password reset
        </button>
      </div>
    </div>
    <!-- Modal -->
    <div v-if="user" v-on:click="closeUser()" class="of-modal-bg fadeIn"></div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import md5 from "js-md5";

export default Vue.extend({
  name: "UserModal",
  props: {
    user: {
      type: Object,
      required: false,
    },
  },
  data: function () {
    return {
      roles: [
        { label: "Select role" },
        { label: "Junior agent", value: "JuniorAgent" },
        { label: "Senior agent", value: "SeniorAgent" },
        { label: "Manager", value: "AgencyManager" },
      ],
    };
  },
  mounted() {
    //  Hotkey to close modal
    let self = this;
    window.addEventListener("keyup", function (e) {
      if (e.key === "Escape") {
        self.closeUser();
      }
    });
  },
  methods: {
    /**
     * Close modal
     */
    closeUser: function () {
      this.$emit("closeUser");
    },

    /**
     * Archive user
     */
    archiveUser: function () {
      if (confirm("Are you sure?")) {
        //  Body
        let body = "";

        //  Request
        axios
          .post(
            this.$root.apiUrl + "/user/" + this.user.userGuid + "/Archive",
            body,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.$root.user.authToken,
                requestMD5: md5(body),
              },
            }
          )
          .then((response) => {
            this.$root.successResponse("Archived", response);
            this.$emit("archivedUser");
          })
          .catch((error) => {
            this.$root.handleErrorResponse(error);
          });
      }
    },

    /**
     * Validate user
     */
    validateUser: function () {
      //  Validation
      if (!this.user.name) {
        return "Missing name";
      } else if (!this.user.email) {
        return "Missing email";
      } else if (!this.user.role) {
        return "Missing role";
      }

      return null;
    },

    /**
     * Register user
     */
    insertUser: function () {
      //  Validate
      var error = this.validateUser();
      if (error) {
        this.$root.errorResponse(error);
        return;
      }

      //  Body
      let body = JSON.stringify(this.user);

      //  Request
      axios
        .post(this.$root.apiUrl + "/user", body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$root.user.authToken,
            requestMD5: md5(body),
          },
        })
        .then((response) => {
          this.user.userGuid = response.data.userGuid;
          this.$root.store.users.push(this.user);
          this.$root.successResponse("Created", response);

          //  Force update needed to switch UI from Insert to Update
          this.$forceUpdate();
        })
        .catch((error) => {
          this.$root.handleErrorResponse(error);
        });
    },

    /**
     * Update user
     */
    updateUser: function () {
      //  Validate
      var error = this.validateUser();
      if (error) {
        this.$root.errorResponse(error);
        return;
      }

      //  Body
      let body = JSON.stringify(this.user);

      //  Request
      axios
        .put(this.$root.apiUrl + "/user/" + this.user.userGuid, body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$root.user.authToken,
            requestMD5: md5(body),
          },
        })
        .then((response) => {
          this.$root.successResponse("Updated", response);
        })
        .catch((error) => {
          this.$root.handleErrorResponse(error);
        });
    },

    /**
     * Request password reset
     */
    sendPasswordReset: function () {
      //  Body
      let body = "";

      //  Request
      axios
        .post(
          this.$root.apiUrl +
            "/account/" +
            this.user.userGuid +
            "/ResetPassword",
          body,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
              requestMD5: md5(body),
            },
          }
        )
        .then((response) => {
          this.$root.successResponse("Email sent with reset link", response);
        })
        .catch((error) => {
          this.$root.handleErrorResponse(error);
        });
    },
  },
});
</script>
