var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.lead)?_c('div',{staticClass:"modal slideIn"},[_c('div',{staticClass:"modal-header"},[_c('i',{staticClass:"dashboard-icon icon-user"}),_c('h2',[_vm._v("Lead")]),_c('div',{staticClass:"right-aligned"},[_c('button',{staticClass:"go-back",attrs:{"type":"button"},on:{"click":function($event){return _vm.$root.viewPrevious()}}},[_c('i',{staticClass:"dashboard-icon icon-triangle-left"}),_vm._v("Go back ")]),_c('button',{staticClass:"of-modal-close",attrs:{"type":"button"},on:{"click":function($event){return _vm.closeLeadModal()}}},[_c('i',{staticClass:"dashboard-icon icon-close"})])])]),_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 modal-top"},[_c('h2',[_vm._v("External lead ID: "+_vm._s(this.lead.externalLeadId))])]),_c('div',{staticClass:"col-sm-4 modal-left"},[_c('div',{staticClass:"uik-widget__wrapper uik-widget__wrapper-top lead-information"},[_vm._m(0),_c('table',{staticClass:"uik-widget__table_info"},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Created")]),_c('td',{domProps:{"innerHTML":_vm._f("formatTime")(this.lead.createdTs)}})]),(this.campaign)?_c('tr',[_c('td',[_vm._v("Campaign")]),(this.$root.user.role === 'MerchantAdmin')?_c('td',[_c('router-link',{attrs:{"to":{
                        name: 'campaigns',
                        query: {
                          page: 'onboarding_app',
                          view: 'campaigns',
                          guid: this.lead.campaignGuid,
                        },
                      }}},[_vm._v(" "+_vm._s(this.campaign.name)+" ")])],1):(this.$root.user.role === 'AgencyManager')?_c('td',[_c('router-link',{attrs:{"to":{
                        name: 'agency-campaigns',
                        query: {
                          merchantId: this.lead.merchantId,
                          view: 'campaigns',
                          guid: this.lead.campaignGuid,
                        },
                      }}},[_vm._v(" "+_vm._s(this.campaign.name)+" ")])],1):_c('td',[_vm._v(" "+_vm._s(this.campaign.name)+" ")])]):_vm._e(),_c('tr',[_c('td',[_vm._v("Merchant")]),_c('td',[_vm._v(_vm._s(this.lead.merchantId))])]),_c('tr',[_c('td',[_vm._v("Agency")]),_c('td',[_vm._v(_vm._s(this.lead.agencyId))])])])])])]),_c('div',{staticClass:"col-sm-8 modal-right"},[_c('div',{staticClass:"uik-widget__wrapper"},[_vm._m(1),_c('div',{staticClass:"uik-entity-list session-list"},[_c('table',[_vm._m(2),(this.sessionsListErrored === true)?_c('tbody',[_vm._m(3)]):(this.sessionsListLoading === true)?_c('tbody',[_vm._m(4)]):(this.sessions.length > 0)?_c('tbody',_vm._l((this.sessions),function(session){return _c('tr',{key:session.sessionGuid,class:{
                      blue: session.status === 'Processing',
                      green: session.status === 'Completed',
                      red: session.status === 'Failed',
                      grey: session.status === 'Expired',
                    },attrs:{"tabindex":"0"},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return $event.target.previousElementSibling.focus()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return $event.target.nextElementSibling.focus()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.viewSession(session)}],"click":[function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.viewSession(session)},function($event){if(!$event.ctrlKey)return null;return _vm.viewSessionNewTab(session)}]}},[_c('td',[_vm._v(_vm._s(session.status))]),_c('td',[_c('span',{domProps:{"innerHTML":_vm._f("formatTime")(session.createdTs)}})]),_c('td',[_vm._v(" "+_vm._s(_vm._f("humanReadableServiceType")(session.serviceType))+" ")]),_c('td',[_vm._v(_vm._s(session.agencyId))])])}),0):_c('tbody',[_vm._m(5)])])])])])])])]):_vm._e(),(_vm.lead)?_c('div',{staticClass:"of-modal-bg fadeIn",on:{"click":function($event){return _vm.closeLeadModal()}}}):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uik-widget-title__wrapper"},[_c('h3',[_vm._v("Review")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uik-widget-title__wrapper"},[_c('h3',[_vm._v("Sessions")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('thead',[_c('tr',[_c('th',{staticClass:"manage-column column-status"},[_vm._v("Status")]),_c('th',{staticClass:"manage-column column-created"},[_vm._v("Created")]),_c('th',{staticClass:"manage-column column-service-type"},[_vm._v(" Intention ")]),_c('th',{staticClass:"manage-column column-agency"},[_vm._v("Agency")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"4"}},[_vm._v(" We are sorry. We were not able to retrieve sessions. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"4"}},[_vm._v(" Loading sessions ... ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"4"}},[_vm._v(" No sessions yet ... ")])])
}]

export { render, staticRenderFns }