<template>
  <div class="form-group-dawa">
    <label class="control-label col-sm-3" v-bind:for="contactData.name">
      {{ contactData.label }}
    </label>
    <div class="control-input col-sm-9">
      <input
        type="text"
        v-bind:id="contactData.name"
        class="uik-input__input"
        autocomplete="nope"
        v-bind:required="contactData.required ? true : false"
        v-bind:placeholder="
          contactData.attr && contactData.attr.placeholder
            ? contactData.attr.placeholder
            : false
        "
        v-bind:class="[
          contactData.value && contactData.value.length > 0
            ? 'not-empty'
            : 'empty',
          contactData.validity ? contactData.validity : '',
        ]"
        v-on:keydown="onKeyDown"
        v-on:keyup="onKeyUp"
        v-model.trim="contactData.value"
      />
      <span class="validator-icon"></span>
      <div v-if="acceptingInvalidAddress" class="dawa-suggestions">
        <button
          type="button"
          class="uik-btn__base btn btn-clear-address"
          v-on:click="clearAddress()"
        >
          Clear the address
        </button>
        <button
          type="button"
          class="uik-btn__base btn btn-accept-address"
          v-on:click="acceptInvalidAddress()"
        >
          Accept the address
        </button>
        <p v-if="countryCode == 'DK'">No suggestions found</p>
        <p v-else>Address not found</p>
      </div>
      <div v-else class="dawa-suggestions">
        <button
          type="button"
          class="uik-btn__base btn btn-clear-address"
          v-on:click="clearAddress()"
        >
          Clear the address
        </button>
        <button
          type="button"
          class="uik-btn__base btn btn-accept-address"
          v-if="dawaSuggestions.length > 0"
          v-on:click="acceptInvalidAddress()"
        >
          Accept the address
        </button>
        <p v-if="dawaSuggestions.length > 0">Select an address</p>
        <div
          class="inner"
          v-if="dawaSuggestions.length > 0"
          v-bind:style="{
            height:
              (dawaSuggestions.length * 30 + 10 > 280
                ? 280
                : dawaSuggestions.length * 30 + 10) + 'px',
          }"
        >
          <ul>
            <li
              v-for="dawaSuggestion in dawaSuggestions"
              v-bind:key="dawaSuggestion.id"
            >
              <button
                type="button"
                v-bind:class="
                  dawaSuggestion.type === 'adresse'
                    ? 'dawa-valid'
                    : 'dawa-fuzzy'
                "
                v-bind:title="
                  dawaSuggestion.type === 'adresse'
                    ? 'Valid unique address'
                    : 'Suggestion since address is not unique'
                "
                v-on:click="selectSuggestion(dawaSuggestion)"
              >
                {{ dawaSuggestion.fullAddress }}
                <span v-if="dawaSuggestion.type === 'adresse'">Valid</span>
                <span v-else>Suggestion</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { formats } from "../../mixins/formats.js";
import md5 from "js-md5";

export default Vue.extend({
  name: "OnboardingContactDataDawaAddress",
  mixins: [formats],
  props: {
    contactData: {
      type: Object,
      required: true,
    },
    sessionRequest: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      contactFieldTypes: this.$root.helpers.contactFieldTypes,
      countryCode: this.sessionRequest.contactData["countryCode"] ?? this.sessionRequest.workflow.countryCode ?? 'DK',
      dawaSuggestions: [],
      dawaTyping: null,
      dawaAddress2: null,
      dawaPostCode: null,
      dawaCity: null,
      dawaMunicipalityCode: null,
      busy: false,
      acceptingInvalidAddress: false,
      standaloneFields: []
    };
  },
  created() {
    //  Extract address info initially
    this.extractAddressInfo();

    //  Search DAWA by query-string
    if (this.$route.query.pre_address) {
      let address =
        this.$route.query.pre_address +
        (this.$route.query.pre_postCode
          ? " " + this.$route.query.pre_postCode
          : "") +
        (this.$route.query.pre_city ? " " + this.$route.query.pre_city : "");

      this.contactData.value = this.$route.query.pre_address.trim();

      //  Log
      window.console.log("Request when created");

      this.queryDataReciever(address);
    }
  },
  methods: {
    onKeyDown: function (e) {
      //  Tab
      if (e.key === "Tab") {
        //  Skip
      }

      //  Shift
      else if (e.key === "Shift") {
        //  Skip
      }

      //  Arrows
      else if (
        e.key === "ArrowLeft" ||
        e.key === "ArrowUp" ||
        e.key === "ArrowDown"
      ) {
        //  Skip
      }

      //  Everything else
      else {
        clearTimeout(this.dawaTyping);
      }
    },

    onKeyUp: function (e) {
      //  Tab
      if (e.key === "Tab") {
        //  Skip
      }

      //  Shift
      else if (e.key === "Shift") {
        //  Skip
      }

      //  Arrows
      else if (
        e.key === "ArrowLeft" ||
        e.key === "ArrowUp" ||
        e.key === "ArrowDown"
      ) {
        //  Skip
      }

      //  Everything else, queue DAWA
      else {
        clearTimeout(this.dawaTyping);

        if (this.contactData.value.length === 0) {
          this.clearAddress();
        } else {
          this.dawaTyping = setTimeout(
            function (scope) {
              //  Log
              window.console.log("Request when queued");

              scope.extractAddressInfo();
              scope.queryDataReciever(
                scope.contactData.value,
                scope.dawaPostCode,
                scope.dawaCity
              );
            },
            1000,
            this
          );
        }
      }
    },

    updateAddress: function (address) {
      this.contactData.value = address;

      if (this.contactData.value) {
        //  Log
        window.console.log("Request when address is updated");

        this.queryDataReciever(
          this.contactData.value,
          this.dawaPostCode,
          this.dawaCity
        );
      }
    },

    updatePostCode: function (postCode) {
      this.dawaPostCode = postCode;

      if (this.contactData.value) {
        //  Log
        window.console.log("Request when postCode is updated");

        this.queryDataReciever(
          this.contactData.value,
          this.dawaPostCode,
          this.dawaCity
        );
      }
    },

    updateCity: function (city) {
      this.dawaCity = city;

      if (this.contactData.value) {
        //  Log
        window.console.log("Request when city is updated");

        this.queryDataReciever(
          this.contactData.value,
          this.dawaPostCode,
          this.dawaCity
        );
      }
    },

    acceptInvalidAddress: function () {
      this.dawaSuggestions = [];
      this.acceptingInvalidAddress = false;
      this.contactData.validity = "valid";

      //  Emit to ContactData
      this.$emit(
        "dawaValidatedAddress",
        this.dawaAddress2,
        this.dawaPostCode,
        this.dawaCity
      );
    },

    clearAddress: function () {
      this.dawaSuggestions = [];
      this.acceptingInvalidAddress = false;
      this.contactData.value = null;
      this.dawaAddress2 = null;
      this.dawaPostCode = null;
      this.dawaCity = null;
      this.dawaMunicipalityCode = null;
      this.contactData.validity = "error";

      //  Emit to ContactData
      this.$emit("dawaClearedAddress");
    },

    selectSuggestion: function (dawaSuggestion) {
      this.dawaSuggestions = [];
      this.acceptingInvalidAddress = false;
      this.applyDawaAddress(dawaSuggestion);
    },

    applyDawaAddress: function (dawaSuggestion) {
      //  Combine address parts

      this.contactData.value = dawaSuggestion.address ?? dawaSuggestion.fullAddress;

      //  Temporary address
      if (!dawaSuggestion.success && dawaSuggestion.addressType != 'address') {
        //  Log
        window.console.log("Request when address is not unique");

        this.queryDataReciever(dawaSuggestion.address ?? dawaSuggestion.fullAddress, dawaSuggestion.postCode, dawaSuggestion.city);
      }

      //  Final address
      else {
        
        if (!this.standaloneFields.includes('postCode') && !this.standaloneFields.includes('city')) {
          this.contactData.value = dawaSuggestion.fullAddress;
        } else if (!this.standaloneFields.includes('postCode')) {
          this.contactData.value = dawaSuggestion.address + ', ' + dawaSuggestion.postCode;
        } else if (!this.standaloneFields.includes('city')) {
          this.contactData.value = dawaSuggestion.address + ', ' + dawaSuggestion.city;
        } else {
          this.contactData.value = dawaSuggestion.address ?? dawaSuggestion.fullAddress;
        }

        this.contactData.validity = "valid";
        this.contactData.dawaAddress = dawaSuggestion.address;
        this.contactData.dawaPostCode = dawaSuggestion.postCode;
        this.contactData.dawaCity = dawaSuggestion.city;
        this.contactData.dawaCountryCode = dawaSuggestion.countryCode;
        this.contactData.dawaId = dawaSuggestion.addressId;

        //  Emit to ContactData
        this.$emit(
          "dawaValidatedAddress",
          null,
          dawaSuggestion.postCode,
          dawaSuggestion.city,
          dawaSuggestion.countryCode
        );
      }
    },

    /**
     * Query Dataforsyningen's API ( https://dawadocs.dataforsyningen.dk/dok/api/autocomplete )
     */
    queryDataReciever: function (address, postCode, city) {
      if (this.busy) {
        //  Log
        window.console.log("Request blocked as busy");

        return;
      } else if (address.length < 5) {
        //  Log
        window.console.log("Request blocked as too short address");

        return;
      }

      this.dawaSuggestions = [];
      this.acceptingInvalidAddress = false;
      //  Query
      let query = address;

      if (postCode) {
        query = query + " " + postCode;
      }
      if (city) {
        query = query + " " + city;
      }

      const jsonData = JSON.stringify({
        merchantId: this.$root.merchant.name,
        addressType: 'address',
        operationType: 'validateAddress',
        countryCode: this.countryCode,
        address: query,
      })

      this.busy = true;

      //  Request
      axios.post(this.$root.dataRecieverUrl + '/form/Validate',
        jsonData,
        {
          headers: {
            'Content-Type': 'application/json',
            requestMD5: md5(jsonData),
          }
        }
        )
        .then((response) => {
          if (response.data) {
            //  Log
            window.console.log(response.data.addressValidator);

            this.busy = false;

            if (response.data.addressValidator.toUpperCase() == "DAWA") {                   
  
              this.contactData.validity = "error";      
               //  Handle Dawa
              if (response.data.success) {
                this.selectSuggestion(response.data);
              }
              else if (response.data.addressSuggestions.length == 0) {
                this.acceptingInvalidAddress = true;
              } 
              else {     
                this.dawaSuggestions = response.data.addressSuggestions;
              }
            } else {
              if (response.data.success) {
                  this.contactData.validity = "valid";
                  //  Emit to ContactData
                  this.contactData.dawaAddress = response.data.address;
                  this.contactData.dawaPostCode = response.data.postCode ? response.data.postCode.trim() : null;
                  this.contactData.dawaCity = response.data.city ?? null;
                  this.contactData.dawaCountryCode = response.data.countryCode ?? null;
                  this.contactData.dawaId = response.data.addressId ?? null;

                  this.$emit(
                    "dawaValidatedAddress",
                    null,
                    response.data.postCode ? response.data.postCode.replace(' ', '') : null,
                    response.data.city ?? null,
                    response.data.countryCode ?? null
                  );
              } else {
                this.contactData.validity = "error";
                this.acceptingInvalidAddress = true;

              }
            }

          }
        })
        .catch((error) => {
          this.busy = false;

          this.$root.handleErrorResponse(error);
        });
    },

    extractAddressInfo: function () {
      this.standaloneFields = [];
      //  Loop fields to locate postCode and city
      for (
        var i = 0;
        i < this.sessionRequest.workflow.contactDataFields.length;
        i++
      ) {
        //  Verify
        let key = this.sessionRequest.workflow.contactDataFields[i].name;
        let value = this.sessionRequest.workflow.contactDataFields[i].value;
        if (key === "postCode") {
          this.standaloneFields.push('postCode');
          if (value) {
            this.dawaPostCode = value;
          }
        } 
        if (key === "city") {
          this.standaloneFields.push('city');
          if (value) {
            this.dawaCity = value;
          }
        }
      }
    },
  },
});
</script>
