import axios from "axios";
import { campaignHandler } from "./campaignHandler.js";

export const leadHandler = {
    mixins: [campaignHandler],
    methods: {

        /**
         * Get lead by guid
         * @param {string} guid 
         */
        getLead: function(guid) {

            let lead = this.loadLead(guid);
            if (lead) {

                return {
                    leadErrored: false,
                    lead: lead
                };
            } else {

                // window.console.log("lead: get lead from API");

                //  Request
                return axios
                    .get(this.$root.apiUrl + this.returnLeadApiPath(guid), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken
                        }
                    })
                    .then(response => {

                        return {
                            leadErrored: false,
                            lead: response.data
                        };
                    })
                    .catch(error => {

                        this.$root.handleErrorResponse(error);

                        return {
                            leadErrored: true,
                            lead: null
                        };
                    });
            }
        },

        /**
         * Search leads by given externalLeadId
         * @param {string} externalLeadId
         */
        searchLeadsByExternalLeadId: function(externalLeadId) {

            //  Request
            return axios
                .get(this.$root.apiUrl + this.returnLeadsApiPath(null, externalLeadId), {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.$root.user.authToken
                    }
                })
                .then(response => {

                    return {
                        leadsErrored: false,
                        leads: response.data
                    };
                })
                .catch(error => {

                    this.$root.handleErrorResponse(error);

                    return {
                        leadsErrored: true,
                        leads: []
                    };
                });
        },

        /**
         * Get lead's sessions by guid
         * @param {string} guid 
         */
        getSessionsByLead: function(guid) {

            //  Request
            return axios
                .get(this.$root.apiUrl + this.returnLeadSessionsApiPath(guid), {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.$root.user.authToken
                    }
                })
                .then(response => {

                    return {
                        sessionsErrored: false,
                        sessions: response.data
                    };
                })
                .catch(error => {

                    this.$root.handleErrorResponse(error);

                    return {
                        sessionsErrored: true,
                        sessions: []
                    };
                });
        },

        /**
         * Get leads with campaigns and workflows
         */
        getLeads: function() {

            //  Get filtered campaignGuid
            let campaignGuid = (this.$root.store.leadsByCampaign) ? this.$root.store.leadsByCampaign : null;

            //  Prepare API request
            let request = [];

            //  Append lead request, if empty storage
            if (this.$root.store.leads.length === 0) {
                request.push(
                    axios.get(this.$root.apiUrl + this.returnLeadsApiPath(campaignGuid), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken
                        }
                    })
                );
            } else {
                request.push('');
            }

            //  Append campaign request, if empty storage
            if (this.$root.store.campaigns.length === 0) {
                request.push(
                    axios.get(this.$root.apiUrl + this.returnCampaignsApiPath('all'), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken
                        }
                    })
                );
            } else {
                request.push('');
            }

            //  Request
            return axios
                .all(request)
                .then(
                    axios.spread((leadResponse, campaignResponse) => {
                        if (this.$root.store.leads.length === 0) {

                            //  Set pageNumber
                            this.$root.store.leadPageNumber = leadResponse.data.pageNumber;

                            //  Detect last page by given pageSize
                            if (this.$root.store.leadPageSize !== leadResponse.data.pageSize) {
                                //  TODO: Account for pagination limit
                            }

                            //  Build storage for single retrival
                            for (var i = 0; i < leadResponse.data.list.length; i++) {
                                this.$root.store.leadPageStorage.push(leadResponse.data.list[i]);
                            }

                            //  Build storage
                            this.$root.store.leads = leadResponse.data.list;
                        }

                        if (this.$root.store.campaigns.length === 0) {

                            //  Build storage
                            this.$root.store.campaigns = campaignResponse.data;
                        }

                        //  Combine results
                        this.combineLeadsWithCampaigns();

                        return {
                            leadsErrored: false,
                            campaigns: this.$root.store.campaigns,
                            leads: this.$root.store.leads
                        };
                    })
                )
                .catch(error => {

                    this.$root.handleErrorResponse(error);

                    return {
                        leadsErrored: true,
                        campaigns: [],
                        leads: []
                    };
                });
        },

        /**
         * Combine leads with campaigns
         */
        combineLeadsWithCampaigns: function() {
            //  Loop leads
            for (var a = 0; a < this.$root.store.leads.length; a++) {
                //  Loop campaigns
                for (var b = 0; b < this.$root.store.campaigns.length; b++) {
                    if (
                        this.$root.store.campaigns[b].campaignGuid ===
                        this.$root.store.leads[a].campaignGuid
                    ) {
                        this.$root.store.leads[
                            a
                        ].campaignName = this.$root.store.campaigns[b].name;
                    }
                }
            }

            //  Force update needed to refresh UI with extended leads data
            this.$forceUpdate();
        },

        /**
         * Load lead from storage
         * @param {string} guid 
         */
        loadLead: function(guid) {

            if (this.$root.store.leads.length > 0) {
                for (var i = 0; i < this.$root.store.leads.length; i++) {
                    if (guid === this.$root.store.leads[i].leadGuid) {

                        // window.console.log("lead: get lead from storage");

                        return this.$root.store.leads[i];
                    }
                }
            }

            return null;
        },

        /**
         * Return API endpoint
         * @param {string} guid 
         * @param {string} externalLeadId 
         */
        returnLeadApiPath: function(guid = null, externalLeadId = null) {
            //  Merchant
            if (this.$root.user.role === "MerchantAdmin") {

                if (guid) {
                    return (
                        "/lead/" + guid + "?merchantId=" +
                        encodeURIComponent(this.$root.user.merchantId)
                    );
                } else if (externalLeadId) {
                    return (
                        "/lead?externalLeadId=" + externalLeadId + "&merchantId=" +
                        encodeURIComponent(this.$root.user.merchantId)
                    );
                } else {
                    return null;
                }
            }

            //  Agency
            else if (this.$root.merchant.merchantId && this.$root.user.agencyId) {

                if (guid) {
                    return (
                        "/lead/" + guid + "?merchantId=" +
                        encodeURIComponent(this.$root.merchant.merchantId) +
                        "&agencyId=" +
                        encodeURIComponent(this.$root.user.agencyId)
                    );
                } else if (externalLeadId) {
                    return (
                        "/lead?externalLeadId=" + externalLeadId + "&merchantId=" +
                        encodeURIComponent(this.$root.merchant.merchantId) +
                        "&agencyId=" +
                        encodeURIComponent(this.$root.user.agencyId)
                    );
                } else {
                    return null;
                }
            }

            //  Error
            else {
                return null;
            }
        },

        /**
         * Return API endpoint
         * @param {string} guid 
         */
        returnLeadSessionsApiPath: function(guid) {
            //  Merchant
            if (this.$root.user.role === "MerchantAdmin") {
                return (
                    "/lead/" + guid + "/sessions?merchantId=" +
                    encodeURIComponent(this.$root.user.merchantId)
                );
            }

            //  Agency
            else if (this.$root.merchant.merchantId && this.$root.user.agencyId) {
                return (
                    "/lead/" + guid + "/sessions?merchantId=" +
                    encodeURIComponent(this.$root.merchant.merchantId) +
                    "&agencyId=" +
                    encodeURIComponent(this.$root.user.agencyId)
                );
            }

            //  Error
            else {
                return null;
            }
        },

        /**
         * Return API endpoint
         * @param {string} campaignGuid
         * @param {string} externalLeadId
         */
        returnLeadsApiPath: function(campaignGuid = null, externalLeadId = null) {

            //  Merchant
            if (this.$root.user.role === "MerchantAdmin") {

                if (externalLeadId) {
                    return (
                        "/leads?externalLeadId=" + externalLeadId + (campaignGuid ? "&campaignGuid=" + campaignGuid : '') + "&merchantId=" + encodeURIComponent(this.$root.user.merchantId)
                    );
                } else {

                    return (
                        "/leads?pageNumber=" + this.$root.store.leadPageNumber + "&pageSize=" + this.$root.store.leadPageSize + (campaignGuid ? "&campaignGuid=" + campaignGuid : '') + "&merchantId=" +
                        encodeURIComponent(this.$root.user.merchantId)
                    );
                }
            }

            //  Agency
            else if (this.$root.merchant.merchantId && this.$root.user.agencyId) {

                if (externalLeadId) {
                    return (
                        "/leads?externalLeadId=" + externalLeadId + (campaignGuid ? "&campaignGuid=" + campaignGuid : '') + "&merchantId=" +
                        encodeURIComponent(this.$root.merchant.merchantId) +
                        "&agencyId=" +
                        encodeURIComponent(this.$root.user.agencyId)
                    );
                } else {
                    return (
                        "/leads?pageNumber=" + this.$root.store.leadPageNumber + "&pageSize=" + this.$root.store.leadPageSize + (campaignGuid ? "&campaignGuid=" + campaignGuid : '') + "&merchantId=" +
                        encodeURIComponent(this.$root.merchant.merchantId) +
                        "&agencyId=" +
                        encodeURIComponent(this.$root.user.agencyId)
                    );
                }

            }

            //  Error
            else {
                return null;
            }
        }
    }
};