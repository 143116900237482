<template>
  <div class="profile-wrap">
    <div class="profile-bar">
      <div v-if="this.$root.user.name" class="avatar-wrap">
        <router-link :to="{ name: 'agency-profile' }"
          ><i class="dashboard-icon icon-profile"></i
          >{{ this.$root.user.name }}</router-link
        >
      </div>
      <div v-else>
        <router-link :to="{ name: 'agency-profile' }">
          <i class="dashboard-icon icon-profile"></i> Profile
        </router-link>
      </div>

      <div
        v-if="this.$root.user.role === 'AgencyManager'"
        class="right-aligned popover-wrapper"
      >
        <button
          type="button"
          class="uik-btn__base btn btn-secondary"
          tabindex="-1"
          v-on:click="openExportModal()"
        >
          Export
        </button>
        <button
          type="button"
          class="uik-btn__base btn btn-primary"
          tabindex="-1"
          v-on:click="openMerchantModal()"
        >
          Add client
        </button>
      </div>
    </div>

    <!-- Merchant modal -->
    <merchant-modal
      v-bind:merchant="this.currentMerchant"
      v-on:closeMerchantModal="closeMerchantModal()"
    />

    <!-- Export modal -->
    <export-modal
      v-bind:exportRequest="this.currentExport"
      v-on:closeExportModal="closeExportModal()"
    />
  </div>
</template>

<script>
import Vue from "vue";
import MerchantModal from "../merchants/MerchantModal";
import ExportModal from "../ExportModal";

export default Vue.extend({
  name: "NavbarTop",
  components: {
    MerchantModal,
    ExportModal,
  },
  data: function () {
    return {
      currentMerchant: null,
      currentExport: null,
    };
  },
  methods: {
    /**
     * Open modal to create
     */
    openMerchantModal: function () {
      this.currentMerchant = {};
    },

    /**
     * Close modal
     */
    closeMerchantModal: function () {
      this.currentMerchant = null;
    },

    /**
     * Open modal to create
     */
    openExportModal: function () {
      this.currentExport = {};
    },

    /**
     * Close modal
     */
    closeExportModal: function () {
      this.currentExport = null;
    },
  },
});
</script>
