<template>
  <div>
    <!-- Modal -->
    <div v-if="agency" class="modal modal-narrow slideIn">
      <div class="modal-content">
        <!-- Header -->
        <div v-if="agency.agencyId" class="block">
          <div class="block-header">
            <h2 class="title">Revoke access from agency</h2>
            <p class="desc">
              This will not delete any data, but instantly prevent the agency
              from having access.
            </p>
          </div>
          <div class="block-wrap token-input-wrap">
            <button
              v-if="agency.name"
              type="button"
              class="uik-btn__base btn-cancel btn"
              v-on:click="removeLicense()"
            >
              Revoke access from "{{ agency.name }}" now
            </button>
            <button
              v-else
              type="button"
              class="uik-btn__base btn-cancel btn"
              v-on:click="removeLicense()"
            >
              Revoke access from "{{ agency.agencyId }}" now
            </button>
          </div>
        </div>

        <!-- Header -->
        <div v-else class="block">
          <div class="block-header">
            <h2 class="title">Create access token to agency</h2>
            <p class="desc">
              To add an agency, please create an access token and send it to
              your agency manager.
            </p>
          </div>
          <div class="block-wrap token-input-wrap">
            <div class="input-wrap">
              <input
                type="text"
                class="uik-input__input"
                readonly
                v-bind:disabled="agency.token ? false : true"
                v-model="agency.token"
              />
              <button
                type="button"
                class="uik-btn__base btn-primary btn"
                v-on:click="insertLicense()"
                v-bind:disabled="agency.token ? true : false"
              >
                Create token
              </button>
            </div>
            <small class="input-helper-text">Copy and paste into email</small>
          </div>
          <div class="block info-next">
            <div class="row">
              <div class="col-sm-8">
                <h3>What happens next?</h3>
                <p class="desc">
                  After the agency has recieved this token and entered in their
                  backend, they will automatically appear on your agency list.
                  The token can only be used once.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      v-if="agency"
      v-on:click="closeModal()"
      class="of-modal-bg fadeIn"
    ></div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  name: "AgencyModal",
  props: {
    agency: {
      type: Object,
      required: false,
    },
  },
  methods: {
    /**
     * Close modal
     */
    closeModal: function () {
      this.$emit("closeAgencyModal");
    },

    /**
     * Insert license
     */
    insertLicense: function () {
      //  Body
      let body = "";

      //  Request
      axios
        .post(this.$root.apiUrl + "/license/AddAgencyToken", body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$root.user.authToken,
          },
        })
        .then((response) => {
          //  Append token
          this.agency.token = response.data.token;

          //  Toast
          this.$root.successResponse("Created", response);

          //  Force update needed to switch UI from Insert to Update
          this.$forceUpdate();
        })
        .catch((error) => {
          this.$root.handleErrorResponse(error);
        });
    },

    /**
     * Remove license
     */
    removeLicense: function () {
      if (confirm("Are you sure?")) {
        //  Request
        axios
          .delete(
            this.$root.apiUrl + "/license?agencyId=" + this.agency.agencyId,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.$root.user.authToken,
              },
            }
          )
          .then((response) => {
            //  Toast
            this.$root.successResponse("Revoked", response);

            //  Emit
            this.$emit("removedAgency");
          })
          .catch((error) => {
            this.$root.handleErrorResponse(error);
          });
      }
    },
  },
});
</script>
