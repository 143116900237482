<template>
  <section
    v-if="this.sessionRequest.workflow.contactDataFields"
    class="contact-data"
  >
    <h3>Lead</h3>

    <!-- Loop ContactData properties -->
    <div
      v-for="contactData in this.sessionRequest.workflow.contactDataFields"
      v-bind:key="contactData.name"
      class="form-group"
    >
      <!-- Date input -->
      <onboarding-contact-data-date
        v-if="contactData.inputType === 'date'"
        v-bind:contactData="contactData"
        v-bind:sessionRequest="sessionRequest"
      />

      <!-- DAWA validated address -->
      <onboarding-contact-data-dawa-address
        v-else-if="contactData.inputType === 'dawaAddress'"
        ref="OnboardingContactDataDawaAddress"
        v-bind:contactData="contactData"
        v-bind:sessionRequest="sessionRequest"
        v-on:dawaValidatedAddress="dawaValidatedAddress"
        v-on:dawaClearedAddress="dawaClearedAddress()"
      />

      <!-- Text, Tel, Number, Email -->
      <template v-else>
        <label class="control-label col-sm-3" v-bind:for="contactData.name">
          {{ contactData.label }}
        </label>
        <div class="control-input col-sm-9">
          <input
            v-bind:type="contactData.inputType"
            v-bind:id="contactData.name"
            class="uik-input__input"
            autocomplete="nope"
            v-on:keydown="onKeyDown(contactData, $event)"
            v-on:keyup="onKeyUp(contactData)"
            v-bind:required="contactData.required ? true : false"
            v-bind:minlength="
              contactData.attr && contactData.attr.minlength
                ? contactData.attr.minlength
                : false
            "
            v-bind:maxlength="
              contactData.attr && contactData.attr.maxlength
                ? contactData.attr.maxlength
                : false
            "
            v-bind:pattern="
              contactData.attr && contactData.attr.pattern
                ? contactData.attr.pattern
                : false
            "
            v-bind:placeholder="
              contactData.attr && contactData.attr.placeholder
                ? contactData.attr.placeholder
                : false
            "
            v-bind:class="[
              contactData.value && contactData.value.length > 0
                ? 'not-empty'
                : 'empty',
              contactData.validity ? contactData.validity : '',
            ]"
            v-model.trim="contactData.value"
          />
          <span class="validator-icon"></span>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import OnboardingContactDataDate from "./OnboardingContactDataDate";
import OnboardingContactDataDawaAddress from "./OnboardingContactDataDawaAddress";
import { formats } from "../../mixins/formats.js";
import axios from "axios";
import md5 from "js-md5";

export default Vue.extend({
  name: "OnboardingContactData",
  mixins: [formats],
  components: {
    OnboardingContactDataDate,
    OnboardingContactDataDawaAddress,
  },
  props: {
    sessionRequest: {
      type: Object,
      required: true,
    },
    contactDataApplied: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      contactFieldTypes: this.$root.helpers.contactFieldTypes,
      typing: null,
      lookup: null,
      dateRange: {},
    };
  },
  watch: {
    //  Re-initialise now Contact's data have been applied
    contactDataApplied: function () {
      this.initialiseContactData();
    },
  },
  created() {
    this.initialiseContactData();
  },
  methods: {
    /**
     * Limit input on key down
     * @param {object} contactData
     * @param {object} e
     */
    onKeyDown: function (contactData, e) {
      clearTimeout(this.typing);

      //  Since keyCode is deprecated ( https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode )
      var keyCode = e.key
        ? e.key
        : e.code
        ? e.code
        : e.keyCode
        ? e.keyCode
        : e.which;

      //  Prevent values longer than maxlength
      if (
        contactData.attr &&
        contactData.attr.maxlength &&
        contactData.value &&
        contactData.value.length === contactData.attr.maxlength
      ) {
        //  Allow: backspace, delete, tab, escape, enter, home, end, left, right
        if (
          this.arrayContains(
            [
              "Backspace",
              "Delete",
              "Tab",
              "Escape",
              "Enter",
              "NumpadEnter",
              "Home",
              "End",
              "ArrowLeft",
              "ArrowRight",
            ],
            keyCode
          )
        ) {
          return;
        }

        e.preventDefault();
      }

      //  Prevent characters in tel
      else if (contactData.inputType === "number") {
        //  Allow ctrl / cmd + a/v/c
        if (
          (e.ctrlKey === true || e.metaKey === true) &&
          (keyCode === "a" || keyCode === "v" || keyCode === "c")
        ) {
          return;
        }

        //  Allow: backspace, delete, tab, escape, enter, home, end, left, right
        else if (
          this.arrayContains(
            [
              "Backspace",
              "Delete",
              "Tab",
              "Escape",
              "Enter",
              "NumpadEnter",
              "Home",
              "End",
              "ArrowLeft",
              "ArrowRight",
            ],
            keyCode
          )
        ) {
          return;
        }

        //  Allow numbers
        else if (
          keyCode === "1" ||
          keyCode === "Digit1" ||
          keyCode === "Numpad1" ||
          keyCode === "2" ||
          keyCode === "Digit2" ||
          keyCode === "Numpad2" ||
          keyCode === "3" ||
          keyCode === "Digit3" ||
          keyCode === "Numpad3" ||
          keyCode === "4" ||
          keyCode === "Digit4" ||
          keyCode === "Numpad4" ||
          keyCode === "5" ||
          keyCode === "Digit5" ||
          keyCode === "Numpad5" ||
          keyCode === "6" ||
          keyCode === "Digit6" ||
          keyCode === "Numpad6" ||
          keyCode === "7" ||
          keyCode === "Digit7" ||
          keyCode === "Numpad7" ||
          keyCode === "8" ||
          keyCode === "Digit8" ||
          keyCode === "Numpad8" ||
          keyCode === "9" ||
          keyCode === "Digit9" ||
          keyCode === "Numpad9" ||
          keyCode === "0" ||
          keyCode === "Digit0" ||
          keyCode === "Numpad0"
        ) {
          return;
        }

        //  Allow deprecated numbers
        else if (keyCode > 47 && keyCode < 58) {
          return;
        }

        //  Allow deprecated numpad
        else if (keyCode > 95 && keyCode < 106) {
          return;
        }

        e.preventDefault();
      }

      //  Prevent numbers in text
      else if (
        contactData.name === "name" ||
        contactData.name === "firstName" ||
        contactData.name === "lastName" ||
        contactData.name === "city" ||
        contactData.name === "countryCode"
      ) {
        if (
          keyCode === "1" ||
          keyCode === "Digit1" ||
          keyCode === "Numpad1" ||
          keyCode === "2" ||
          keyCode === "Digit2" ||
          keyCode === "Numpad2" ||
          keyCode === "3" ||
          keyCode === "Digit3" ||
          keyCode === "Numpad3" ||
          keyCode === "4" ||
          keyCode === "Digit4" ||
          keyCode === "Numpad4" ||
          keyCode === "5" ||
          keyCode === "Digit5" ||
          keyCode === "Numpad5" ||
          keyCode === "6" ||
          keyCode === "Digit6" ||
          keyCode === "Numpad6" ||
          keyCode === "7" ||
          keyCode === "Digit7" ||
          keyCode === "Numpad7" ||
          keyCode === "8" ||
          keyCode === "Digit8" ||
          keyCode === "Numpad8" ||
          keyCode === "9" ||
          keyCode === "Digit9" ||
          keyCode === "Numpad9" ||
          keyCode === "0" ||
          keyCode === "Digit0" ||
          keyCode === "Numpad0"
        ) {
          e.preventDefault();
        }

        //  Prevent deprecated numbers
        else if (keyCode > 47 && keyCode < 58) {
          e.preventDefault();
        }

        //  Prevent deprecated numpad
        else if (keyCode > 95 && keyCode < 106) {
          e.preventDefault();
        }
      }

      //  Prevent space in email
      else if (contactData.name === "email") {
        if (keyCode === " ") {
          e.preventDefault();
        }
      }
    },

    /**
     * On key up
     * @param {object} contactData
     */
    onKeyUp: function (contactData) {
      //  Validate

      //  Transfer postCode to DAWA
      if (
        contactData.name === "postCode" &&
        contactData.value &&
        contactData.value.length >= 4
      ) {
        clearTimeout(this.typing);
        this.typing = setTimeout(
          function (scope) {
            this.validateInput(contactData);
            if (scope.$refs.OnboardingContactDataDawaAddress) {
              scope.$refs.OnboardingContactDataDawaAddress[0].updatePostCode(
                contactData.value
              );
            }
          },
          1000,
          this
        );
      }
      //  Transfer city to DAWA
      else if (
        contactData.name === "city" &&
        contactData.value &&
        contactData.value.length >= 2
      ) {
        clearTimeout(this.typing);
        this.typing = setTimeout(
          function (scope) {
            this.validateInput(contactData);
            if (scope.$refs.OnboardingContactDataDawaAddress) {
              scope.$refs.OnboardingContactDataDawaAddress[0].updateCity(
                contactData.value
              );
            }
          },
          2000,
          this
        );
      } else {
        this.validateInput(contactData);
      }

      //  Force update needed to update input class
      this.$forceUpdate();
    },

    /**
     * Validate input
     * @param {object} contactData
     */
    validateInput: function (contactData) {
      if (contactData) {
        //  Empty
        if (!contactData.value || contactData.value === "") {
          //  Error on required
          if (contactData.required) {
            contactData.validity = "error";
          }

          //  Delete validity
          else {
            delete contactData.validity;
          }
        }

        //  Email
        else if (contactData.name === "email") {
          if (this.isEmailValid(contactData.value)) {
            contactData.validity = "valid";
          } else {
            contactData.validity = "error";
          }
        }
        else if (['msisdn', 'nationalId', 'businessCode', 'postCode'].includes(contactData.name)) {
    
          clearTimeout(this.lookup);

          this.lookup = setTimeout(()=>{
            
            const countryCode = this.sessionRequest.contactData["countryCode"] ?? this.sessionRequest.workflow.countryCode ?? 'DK';

            if (countryCode) {

              let jsonData;

              switch (contactData.name) {
                case 'msisdn':
                  jsonData = JSON.stringify({
                    merchantId: this.$root.merchant.name,
                    operationType: 'validateMsisdn',
                    countryCode: countryCode,
                    msisdn: contactData.value,
                  })
                  break;
                case 'nationalId':
                  jsonData = JSON.stringify({
                    merchantId: this.$root.merchant.name,
                    operationType: 'validateNationalId',
                    countryCode: countryCode,
                    nationalId: contactData.value,
                  })
                  break;
                case 'businessCode':
                  jsonData = JSON.stringify({
                    merchantId: this.$root.merchant.name,
                    operationType: 'validateBusinessCode',
                    countryCode: countryCode,
                    businessCode: contactData.value,
                  })
                  break;
                  case 'postCode':
                    jsonData = JSON.stringify({
                      merchantId: this.$root.merchant.name,
                      operationType: 'validatePostCode',
                      countryCode: countryCode,
                      postCode: contactData.value,
                    })
                  break;
                default:
                  break;
              }


              axios.post(
                this.$root.dataRecieverUrl + '/form/Validate',
                jsonData,
                {
                  headers: {
                    'Content-Type': 'application/json',
                    requestMD5: md5(jsonData),
                  }
                }
              ).then((response) => {
                if (response.data.success) {
                  contactData.validity = "valid";
                } else {
                  contactData.validity = "error";
                }
                this.$forceUpdate();
              }).catch(() => {
                contactData.validity = "error";
                this.$forceUpdate();
              })
              
            } else {
              contactData.validity = "valid";
            }
          }, 1000)
        }
        //  Validate minlength
        else if (
          contactData.attr &&
          contactData.attr.minlength &&
          contactData.value &&
          contactData.value.length < contactData.attr.minlength
        ) {
          contactData.validity = "error";
        }

        //  Validate maxlength
        else if (
          contactData.attr &&
          contactData.attr.maxlength &&
          contactData.value &&
          contactData.value.length > contactData.attr.maxlength
        ) {
          contactData.validity = "error";
        }

        //  Default
        else {
          contactData.validity = "valid";
        }
      }
    },

    /**
     * Receive emit from DawaAddress
     */
    dawaValidatedAddress: function (address2, postCode, city, countryCode) {
      //  Loop fields
      for (
        var i = 0;
        i < this.sessionRequest.workflow.contactDataFields.length;
        i++
      ) {
        //  Locate address fields
        let contactData = this.sessionRequest.workflow.contactDataFields[i];
        if (address2 && contactData.name === "address2") {
          contactData.value = address2;
          this.validateInput(contactData);
        } else if (postCode && contactData.name === "postCode") {
          contactData.value = postCode;
          this.validateInput(contactData);
        } else if (city && contactData.name === "city") {
          contactData.value = city;
          this.validateInput(contactData);
        } else if (countryCode && contactData.name === "countryCode") {
          contactData.value = countryCode;
          this.validateInput(contactData);
        }
      }

      //  Force update needed to update sessionRequest - at least in Vue console
      this.$forceUpdate();
    },

    /**
     * Receive emit from DawaAddress
     */
    dawaClearedAddress: function () {
      //  Loop fields
      for (
        var i = 0;
        i < this.sessionRequest.workflow.contactDataFields.length;
        i++
      ) {
        //  Locate address fields
        let contactData = this.sessionRequest.workflow.contactDataFields[i];
        if (contactData.name === "address2") {
          contactData.value = null;
          this.validateInput(contactData);
        } else if (contactData.name === "postCode") {
          contactData.value = null;
          this.validateInput(contactData);
        } else if (contactData.name === "city") {
          contactData.value = null;
          this.validateInput(contactData);
        } else if (contactData.name === "countryCode") {
          contactData.value = null;
          this.validateInput(contactData);
        }
      }

      //  Force update needed to update sessionRequest - at least in Vue console
      this.$forceUpdate();
    },

    /**
     * Populate Contact fields by query and existing Contact, in that order
     */
    initialiseContactData: function () {
      //  Verify ContactData fields and ContactData have been applied
      if (!this.sessionRequest.workflow.hasOwnProperty("contactDataFields")) {
        return;
      }

      //  Loop contactData fields
      for (
        var i = 0;
        i < this.sessionRequest.workflow.contactDataFields.length;
        i++
      ) {
        //  Get singular contactData field
        let contactData = this.sessionRequest.workflow.contactDataFields[i];

        //  Loop field configurations
        for (var o = 0; o < this.contactFieldTypes.length; o++) {
          //  Get field configuration
          var fieldConfig = this.contactFieldTypes[o];
          if (contactData.name === fieldConfig.name) {
            //  Set field input type
            contactData.inputType = fieldConfig.inputType;

            //  Set field attributes, if missing
            if (!contactData.attr) {
              contactData.attr = fieldConfig.attr;
            }

            //  Set field label, if missing
            if (!contactData.label) {
              contactData.label = fieldConfig.label;
            }

            //  Prefill value by Contact entity, only applicable to Upgrade
            if (Object.keys(this.sessionRequest.contactData).length !== 0) {
              //  Account for DAWA address
              if (
                contactData.name === "dawaAddress" &&
                this.sessionRequest.contactData["address"]
              ) {
                contactData.value = this.sessionRequest.contactData["address"];

                this.$refs.OnboardingContactDataDawaAddress[0].updateAddress(
                  contactData.value
                );

                //  Validate
                this.validateInput(contactData);
              }

              //  Check contact's data
              else if (this.sessionRequest.contactData[contactData.name]) {
                contactData.value =
                  this.sessionRequest.contactData[contactData.name];

                //  Validate
                this.validateInput(contactData);
              }
            }
          }
        }

        //  Trim and prefill field value by query-parameter
        if (this.$route.query["pre_" + contactData.name]) {
          contactData.value =
            this.$route.query["pre_" + contactData.name].trim();

          //  Validate
          this.validateInput(contactData);
        }

        //  Prefill msisdn by phone if not already given
        else if (
          contactData.name === "msisdn" &&
          this.$route.query["pre_phone"]
        ) {
          contactData.value = this.$route.query["pre_phone"].trim();

          //  Validate
          this.validateInput(contactData);
        }
      }

      //  Set contactGuid
      if (this.sessionRequest.contactGuid) {
        this.sessionRequest.contactData.contactGuid =
          this.sessionRequest.contactGuid;
      }

      //  Force update needed to update sessionRequest - at least in Vue console
      this.$forceUpdate();
    },
  },
});
</script>
