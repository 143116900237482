<template>
  <div class="upgrade-by-agreement-update">
    <template v-if="staticWorkflowProduct.agreementType === 'Shared'">
      <p>
        Updates to shared agreements are not allowed. Please use add-on instead.
      </p>
    </template>
    <template v-else>
      <!-- AgreementUpdate selection -->
      <div class="control-input">
        <label> Select how to update agreement </label>
        <ul class="radio-group horizontal product-select">
          <li v-for="upgradeProduct in agreementUpdateProducts" v-bind:key="upgradeProduct.uuid">
            <label v-bind:for="'product' + upgradeProduct.uuid">
              <input type="radio" v-bind:id="'product' + upgradeProduct.uuid" v-bind:value="upgradeProduct"
                v-model="staticWorkflowProduct"
                v-on:click="[onUpgradeProductChange(upgradeProduct), whenScheduleChanged()]" />
              {{ upgradeProduct.productName }}
              <div class="check"></div>
            </label>
          </li>
        </ul>
      </div>

      <div v-if="staticWorkflowProduct" class="row">
        <div class="col-sm-6">
          <!-- AgreementUpdate, amountTotal -->
          <div class="control-input">
            <label for="amountTotal">Amount, incl. VAT</label>
            <input type="number" id="amountTotal" class="uik-input__input" autocomplete="nope"
              v-model.trim="product.amountTotal" v-bind:disabled="staticWorkflowProduct.amountTotal ? true : false"
              required v-bind:class="[
      product.amountTotal && product.amountTotal.length > 0
        ? 'not-empty'
        : 'empty',
    ]" />
          </div>
        </div>
        <div class="col-sm-6">
          <!-- Schedule type selection -->
          <onboarding-schedule-type ref="OnboardingScheduleType" v-bind:product="product"
            v-bind:staticWorkflowProduct="staticWorkflowProduct" v-bind:sessionRequest="sessionRequest"
            v-on:whenScheduleChanged="whenScheduleChanged()" />

          <!-- Next due date selection -->
          <onboarding-schedule-next-due-date ref="OnboardingScheduleNextDueDate" v-bind:product="product"
            v-bind:staticWorkflowProduct="staticWorkflowProduct" v-bind:sessionRequest="sessionRequest" />

          <!-- Expires after date -->
          <onboarding-schedule-expiration ref="OnboardingScheduleExpiration" v-bind:product="product"
            v-bind:staticWorkflowProduct="staticWorkflowProduct" v-bind:sessionRequest="sessionRequest" />
        </div>
      </div>

      <!-- Submit -->
      <div class="footer-action center-aligned">
        <!-- Please beware of multiple sessions -->
        <div v-if="sessionAttempts > 0" v-bind:class="this.sessionStatus === 'Completed'
      ? 'alert alert-success'
      : this.sessionStatus === 'Rejected'
        ? 'alert alert-danger'
        : 'alert alert-info'
      ">
          <span v-bind:inner-html.prop="this.sessionStatus === 'Completed'
      ? 'The update was registered.'
      : this.sessionStatus === 'Rejected'
        ? 'An error occurred. Please review.'
        : 'Processing'
      " role="alert" v-bind:aria-label="this.sessionStatus === 'Completed'
      ? 'Opgraderingen blev gennemført.'
      : this.sessionStatus === 'Rejected'
        ? 'Opgraderingen blev afvist på grund af teknisk fejl.'
        : null
      "></span>
        </div>

        <button v-if="Object.keys(product).length > 0" type="button" class="uik-btn__base btn btn-primary"
          v-on:click="initialiseRequest(product, 'AgreementUpdate')"
          v-bind:disabled="this.sessionDelayCounter < 1 && !this.updateDisabled ? false : true">
          Update
          <template v-if="this.sessionDelayCounter > 0">({{ this.sessionDelayCounter }})</template>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import OnboardingScheduleType from "./OnboardingScheduleType";
import OnboardingScheduleNextDueDate from "./OnboardingScheduleNextDueDate";
import OnboardingScheduleExpiration from "./OnboardingScheduleExpiration";
import { changeEvents } from "../../mixins/changeEvents.js";
import { sessionRequestHandler } from "../../mixins/sessionRequestHandler.js";

export default Vue.extend({
  name: "OnboardingUpgradeAgreementUpdate",
  mixins: [changeEvents, sessionRequestHandler],
  components: {
    OnboardingScheduleType,
    OnboardingScheduleNextDueDate,
    OnboardingScheduleExpiration,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    staticWorkflowProduct: {
      type: Object,
      required: true,
    },
    sessionRequest: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      sessionStatus: null,
      sessionAttempts: 0,
      sessionDelayCounter: 0,
      updateDisabled: false
    };
  },
  computed: {
    agreementUpdateProducts: function () {
      return this.sessionRequest.workflow.upgradeProducts.filter(function (p) {
        return p.productName && p.upgradeType === "AgreementUpdate";
      });
    },
  },
  created() {
    //  Initialise session delay counter
    setInterval(
      function () {
        this.sessionDelayCounter = this.sessionDelayCounter - 1;
      }.bind(this),
      1000
    );
  },
  methods: {
    /**
     * When schedule is changed trigger refresh of displayed next due date
     */
    whenScheduleChanged: function () {
      this.updateDisabled = true;
      setTimeout(() => {
        if (this.$refs.OnboardingScheduleNextDueDate) {
          this.$refs.OnboardingScheduleNextDueDate.whenScheduleChanged();
        }
        this.updateDisabled = false;
      }, 1000)
    },
  },
});
</script>
