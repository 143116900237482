var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
    _vm.product.paymentType === 'Single' ||
    (_vm.product.paymentType === 'Recurring' &&
      _vm.product.agreementType === 'Personal')
  )?_c('div',{staticClass:"block"},[_vm._m(0),_c('ul',{staticClass:"checkbox-group vertical"},[_c('li',[_c('label',{attrs:{"for":'taxDeductable' + _vm.product.uuid}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.product.taxDeductable),expression:"product.taxDeductable"}],attrs:{"type":"checkbox","id":'taxDeductable' + _vm.product.uuid},domProps:{"checked":Array.isArray(_vm.product.taxDeductable)?_vm._i(_vm.product.taxDeductable,null)>-1:(_vm.product.taxDeductable)},on:{"change":function($event){var $$a=_vm.product.taxDeductable,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.product, "taxDeductable", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.product, "taxDeductable", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.product, "taxDeductable", $$c)}}}}),_vm._v("Payments are tax deductible "),_c('div',{staticClass:"check"})])]),(
        _vm.product.paymentType === 'Recurring' &&
        _vm.product.agreementType === 'Personal'
      )?_c('li',[_c('label',{attrs:{"for":'paymentRequired' + _vm.product.uuid}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.product.paymentRequired),expression:"product.paymentRequired"}],attrs:{"type":"checkbox","id":'paymentRequired' + _vm.product.uuid},domProps:{"checked":Array.isArray(_vm.product.paymentRequired)?_vm._i(_vm.product.paymentRequired,null)>-1:(_vm.product.paymentRequired)},on:{"change":function($event){var $$a=_vm.product.paymentRequired,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.product, "paymentRequired", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.product, "paymentRequired", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.product, "paymentRequired", $$c)}}}}),_vm._v("Payments are required in dunning "),_c('div',{staticClass:"check"})])]):_vm._e()])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"control-input"},[_c('label',[_vm._v("Payment settings")])])
}]

export { render, staticRenderFns }