var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"upgrade-by-add-on"},[_c('div',{staticClass:"control-input"},[_c('label',[_vm._v(" Select "+_vm._s(_vm._f("formatScheduleType")(_vm.sessionRequest.agreementData.scheduleType))+" add-on ")]),_c('ul',{staticClass:"radio-group horizontal product-select"},_vm._l((_vm.addOnProducts),function(upgradeProduct){return _c('li',{key:upgradeProduct.uuid},[_c('label',{attrs:{"for":'product' + upgradeProduct.uuid}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staticWorkflowProduct),expression:"staticWorkflowProduct"}],attrs:{"type":"radio","id":'product' + upgradeProduct.uuid},domProps:{"value":upgradeProduct,"checked":_vm._q(_vm.staticWorkflowProduct,upgradeProduct)},on:{"click":function($event){return _vm.onUpgradeProductChange(upgradeProduct)},"change":function($event){_vm.staticWorkflowProduct=upgradeProduct}}}),_vm._v(" "+_vm._s(upgradeProduct.productName)+" "),_c('div',{staticClass:"check"})])])}),0)]),(_vm.product)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[(Object.keys(_vm.product).length > 0)?_c('div',{staticClass:"control-input"},[(_vm.product.taxDeductable === true)?_c('label',{attrs:{"for":"amountTotal"}},[_vm._v("Amount in "+_vm._s(_vm.sessionRequest.agreementData.currencyCode)+", incl. VAT. "),_c('em',[_vm._v("Applicable to tax deduction.")])]):_c('label',{attrs:{"for":"amountTotal"}},[_vm._v("Amount in "+_vm._s(_vm.sessionRequest.agreementData.currencyCode)+", incl. VAT")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.product.amountTotal),expression:"product.amountTotal",modifiers:{"trim":true}}],staticClass:"uik-input__input",class:[
            _vm.product.amountTotal && _vm.product.amountTotal.length > 0
              ? 'not-empty'
              : 'empty',
          ],attrs:{"type":"number","id":"amountTotal","autocomplete":"nope","disabled":_vm.staticWorkflowProduct.amountTotal ? true : false,"required":""},domProps:{"value":(_vm.product.amountTotal)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.product, "amountTotal", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]):_vm._e(),_c('onboarding-schedule-start-date',{ref:"OnboardingScheduleStartDate",attrs:{"product":_vm.product,"staticWorkflowProduct":_vm.staticWorkflowProduct,"sessionRequest":_vm.sessionRequest}}),_c('onboarding-schedule-expiration',{ref:"OnboardingScheduleExpiration",attrs:{"product":_vm.product,"staticWorkflowProduct":_vm.staticWorkflowProduct,"sessionRequest":_vm.sessionRequest}})],1)]):_vm._e(),_c('div',{staticClass:"footer-action center-aligned"},[(_vm.sessionAttempts > 0)?_c('div',{class:this.sessionStatus === 'Completed'
          ? 'alert alert-success'
          : this.sessionStatus === 'Rejected'
          ? 'alert alert-danger'
          : 'alert alert-info'},[_c('span',{attrs:{"role":"alert","aria-label":this.sessionStatus === 'Completed'
            ? 'Opgraderingen blev gennemført.'
            : this.sessionStatus === 'Rejected'
            ? 'Opgraderingen blev afvist på grund af teknisk fejl.'
            : null},domProps:{"innerHTML":this.sessionStatus === 'Completed'
            ? 'The add-on was registered.'
            : this.sessionStatus === 'Rejected'
            ? 'An error occurred. Please review.'
            : 'Processing'}})]):_vm._e(),(Object.keys(_vm.product).length > 0)?_c('button',{staticClass:"uik-btn__base btn btn-primary",attrs:{"type":"button","disabled":this.sessionDelayCounter < 1 ? false : true},on:{"click":function($event){return _vm.initialiseRequest(_vm.product, 'CreateAddOn')}}},[_vm._v(" Create "),(this.sessionDelayCounter > 0)?[_vm._v("("+_vm._s(this.sessionDelayCounter)+")")]:_vm._e()],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }