<template>
  <div>
    <button type="button" v-on:click="sendLogout()">Logout</button>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Logout",
  methods: {
    sendLogout: function () {
      this.$root.logOut();
    },
  },
});
</script>
