<template>
  <div class="start-type-selection">
    <!-- scheduleStartType -->
    <div
      v-if="Object.keys(staticWorkflowProduct).length > 0"
      class="control-input"
      role="radiogroup"
      aria-label="Vælg opstart"
    >
      <label>Schedule starts</label>
      <ul class="radio-group horisontal">
        <!-- Defined fixed schedule -->
        <template v-if="product.scheduleFixedDay || product.scheduleBaseTier">
          <!-- Schedule starting instantly -->
          <li
            v-if="
              staticWorkflowProduct.scheduleStartTypeNow &&
              product.paymentMethodType !== 'Betalingsservice'
            "
          >
            <label for="scheduleStartTypeNow" aria-label="Start nu">
              <input
                role="radio"
                type="radio"
                id="scheduleStartTypeNow"
                v-model="product.scheduleStartType"
                v-bind:value="'now'"
                v-on:change="onScheduleStartTypeChange()"
                name="scheduleStartType"
                aria-label="Start nu"
              />With instant payment
              <div class="check"></div>
            </label>
          </li>

          <!-- Schedule starting on the first of next month -->
          <li v-if="staticWorkflowProduct.scheduleStartTypeFirst">
            <label
              for="scheduleStartTypeFirst"
              aria-label="Start den første i næste måned"
            >
              <input
                role="radio"
                type="radio"
                id="scheduleStartTypeFirst"
                v-model="product.scheduleStartType"
                v-bind:value="'first'"
                v-on:change="onScheduleStartTypeChange()"
                name="scheduleStartType"
                aria-label="Start den første i næste måned"
              />As determined by the frequency
              <div class="check"></div>
            </label>
          </li>
        </template>

        <!-- No defined fixed schedule -->
        <template v-else>
          <!-- Schedule starting instantly -->
          <li
            v-if="
              staticWorkflowProduct.scheduleStartTypeNow &&
              product.paymentMethodType !== 'Betalingsservice'
            "
          >
            <label for="scheduleStartTypeNow" aria-label="Start nu">
              <input
                role="radio"
                type="radio"
                id="scheduleStartTypeNow"
                v-model="product.scheduleStartType"
                v-bind:value="'now'"
                v-on:change="onScheduleStartTypeChange()"
                name="scheduleStartType"
                aria-label="Start nu"
              />Today (<span v-bind:inner-html.prop="getToday()"></span>)
              <div class="check"></div>
            </label>
          </li>

          <!-- Schedule starting on the first of next month -->
          <li v-if="staticWorkflowProduct.scheduleStartTypeFirst">
            <!-- first when scheduleSelectedSet -->
            <template v-if="staticWorkflowProduct.hasScheduleSelectedSet">
              <label
                for="scheduleStartTypeFirst"
                aria-label="Start den første i næste måned"
              >
                <input
                  role="radio"
                  type="radio"
                  id="scheduleStartTypeFirst"
                  v-model="product.scheduleStartType"
                  v-bind:value="'first'"
                  v-on:change="onScheduleStartTypeChange()"
                  name="scheduleStartType"
                  aria-label="Start den første i næste måned"
                />As determined by the selected months
                <div class="check"></div>
              </label>
            </template>

            <!-- first -->
            <template v-else>
              <label
                for="scheduleStartTypeFirst"
                aria-label="Start den første i næste måned"
              >
                <input
                  role="radio"
                  type="radio"
                  id="scheduleStartTypeFirst"
                  v-model="product.scheduleStartType"
                  v-bind:value="'first'"
                  v-on:change="onScheduleStartTypeChange()"
                  name="scheduleStartType"
                  aria-label="Start den første i næste måned"
                />First of the month (<span
                  v-bind:inner-html.prop="
                    getFirstOfMonthByPaymentMethodType(
                      product.paymentMethodType
                    )
                  "
                ></span
                >)
                <div class="check"></div>
              </label>
            </template>
          </li>

          <!-- Schedule starting on a custom date - see below -->
          <li v-if="staticWorkflowProduct.scheduleStartTypeCustom">
            <label
              for="scheduleStartTypeCustom"
              aria-label="Start på en valgfri dato"
            >
              <input
                role="radio"
                type="radio"
                id="scheduleStartTypeCustom"
                v-model="product.scheduleStartType"
                v-bind:value="'custom'"
                v-on:change="onScheduleStartTypeChange()"
                name="scheduleStartType"
                aria-label="Start på en valgfri dato"
              />Custom start date
              <div class="check"></div>
            </label>
          </li>
        </template>
      </ul>
    </div>

    <!-- scheduleStartCustomDate -->
    <div
      v-if="product.scheduleStartType === 'custom'"
      class="control-input schedule-start-custom-date-section"
    >
      <div class="row">
        <div class="col-sm-6">
          <label>Please define custom start date</label>
          <div class="input-wrap">
            <date-range-picker
              ref="datePicker"
              v-bind:singleDatePicker="true"
              v-bind:timePicker="false"
              v-bind:timePicker24Hour="false"
              v-bind:showWeekNumbers="false"
              v-bind:showDropdowns="true"
              v-bind:autoApply="true"
              v-bind:ranges="false"
              v-bind:minDate="
                getMinScheduleStartCustomDate(product.paymentMethodType)
              "
              v-bind:linkedCalendars="false"
              v-bind:always-show-calendars="true"
              v-bind:opens="'left'"
              v-bind:locale-data="{
                firstDay: 1,
                format: 'YYYY/MM/DD',
                separator: '-',
              }"
              v-model="product.scheduleStartCustomDate"
              v-on:update="whenDatePicked()"
            >
              <template v-slot:input="datePicker">
                <span
                  v-bind:inner-html.prop="datePicker | formatDatePicker"
                ></span>
                <button type="button" class="btn btn-primary icon-btn">
                  <i class="dashboard-icon icon-calendar"></i>
                </button>
              </template>
            </date-range-picker>
          </div>
        </div>
      </div>
    </div>

    <!-- startWithOneOff -->
    <!-- If Workflow allows OneOff, and not defined in advance -->
    <!-- If PaymentMethodType allows OneOff -->
    <!-- If scheduleStartType is other than "now" -->
    <div
      v-if="
        staticWorkflowProduct.scheduleStartWithOneOff &&
        !staticWorkflowProduct.oneOffAmount &&
        (product.paymentMethodType === 'Test' ||
          product.paymentMethodType === 'Card' ||
          product.paymentMethodType === 'MobilePay') &&
        (product.scheduleStartType === 'first' ||
          product.scheduleStartType === 'custom')
      "
      class="control-input one-off-section"
    >
      <ul class="checkbox-group vertical">
        <li>
          <label for="startWithOneOff">
            <input
              type="checkbox"
              id="startWithOneOff"
              v-model="product.startWithOneOff"
              v-bind:value="true"
              v-on:change="onStartWithOneOffChange()"
              aria-label="Tilføj en straksbetaling."
            />Add an instant payment to the sign-up
            <div class="check"></div>
          </label>
        </li>
      </ul>

      <div class="row">
        <div class="col-sm-6">
          <!-- oneOffAmount -->
          <div v-if="product.startWithOneOff === true" class="control-input">
            <label for="oneOffAmount"
              >Instant amount in {{ product.currencyCode }}, incl. VAT</label
            >
            <input
              type="number"
              id="oneOffAmount"
              class="uik-input__input"
              autocomplete="nope"
              v-model.trim="product.oneOffAmount"
              v-bind:placeholder="product.amountTotal"
              v-on:change="onOneOffAmountChange()"
              aria-label="Beløb for første betaling."
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { changeEvents } from "../../mixins/changeEvents.js";
import { formats } from "../../mixins/formats.js";
import { sessionRequestGatewayHandler } from "../../mixins/sessionRequestGatewayHandler.js";

export default Vue.extend({
  name: "OnboardingScheduleStartType",
  mixins: [changeEvents, formats, sessionRequestGatewayHandler],
  components: { DateRangePicker },
  props: {
    product: {
      type: Object,
      required: true,
    },
    staticWorkflowProduct: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.initialiseScheduleStartTypes();
  },
  updated() {
    this.initialiseScheduleStartTypes();
  },
  methods: {
    /**
     * Initialise
     */
    initialiseScheduleStartTypes: function () {
      if (!this.product.scheduleStartType) {
        //  Build array to check against
        let scheduleStartTypes = [];

        //  Make "now" available
        if (
          this.staticWorkflowProduct.scheduleStartTypeNow &&
          this.product.paymentMethodType !== "Betalingsservice"
        ) {
          scheduleStartTypes.push("now");
        }

        //  Make "first" available
        if (this.staticWorkflowProduct.scheduleStartTypeFirst) {
          scheduleStartTypes.push("first");
        }

        //  Make "custom" available
        if (this.staticWorkflowProduct.scheduleStartTypeCustom) {
          scheduleStartTypes.push("custom");
        }

        //  Just one available? Select it!
        if (scheduleStartTypes.length === 1) {
          this.product.scheduleStartType = scheduleStartTypes[0];

          this.onScheduleStartTypeChange();
        }
      }
    },

    /**
     * Update when start with one-off change
     */
    onStartWithOneOffChange: function () {
      window.console.log(
        "onStartWithOneOffChange triggered: " + this.product.startWithOneOff
      );

      //  Emit
      this.$emit("whenStartWithOneOffChanged");

      //  Force update needed
      this.$forceUpdate();
    },

    /**
     * Update when one-off amount change
     */
    onOneOffAmountChange: function () {
      window.console.log(
        "onOneOffChange triggered: " + this.product.oneOffAmount
      );

      //  Emit
      this.$emit("whenOneOffAmountChanged");
    },

    /**
     * Trigger mixin when start type change
     */
    onScheduleStartTypeChange: function () {
      window.console.log(
        "onScheduleStartTypeChange triggered: " + this.product.scheduleStartType
      );

      //  Trigger event - when scheduleStartType is changed reset custom start date
      this.onScheduleStartTypeChanged();

      //  Set OneOff, if applicable
      //  If Workflow allows OneOff
      //  If PaymentMethodType allows OneOff
      //  If scheduleStartType is other than "now"
      if (
        this.staticWorkflowProduct.scheduleStartWithOneOff &&
        (this.product.paymentMethodType === "Test" ||
          this.product.paymentMethodType === "Card" ||
          this.product.paymentMethodType === "MobilePay") &&
        (this.product.scheduleStartType === "first" ||
          this.product.scheduleStartType === "custom")
      ) {
        this.product.startWithOneOff = true;
      }

      //  Default to false
      else {
        this.product.startWithOneOff = false;
      }

      //  Emit
      this.$emit("whenScheduleStartTypeChanged");

      //  Force update needed
      this.$forceUpdate();
    },

    /**
     * Define custom start date
     */
    whenDatePicked: function () {
      if (this.product.scheduleStartCustomDate) {
        //  Ensure a custom start date later than on the 28th of any month is not selected
        if (
          parseInt(this.product.scheduleStartCustomDate.startDate.getDate()) >
          28
        ) {
          //  Warn the agent
          this.$root.errorResponse(
            "A custom start date later than on the 28th of any month is not permitted. Please review custom start date"
          );

          //  Force update needed
          this.$forceUpdate();
        }
      }
    },

    /**
     * Force update needed to update selection
     */
    whenBsAppVerificationChange: function () {
      this.$forceUpdate();
    },

    /**
     * Update OneOff amount
     */
    whenAmountTotalChange: function (amount) {
      this.product.oneOffAmount = amount;

      //  Force update needed
      this.$forceUpdate();
    },
  },
});
</script>
