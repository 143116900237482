<template>
  <div class="nav-line" role="menu">
    <ul>
      <li v-bind:class="[currentPage.includes('campaigns') ? activeClass : '']">
        <router-link
          :to="{
            name: 'campaigns',
            query: {
              page: 'onboarding_app',
              view: 'campaigns',
            },
          }"
        >
          <i class="dashboard-icon icon-campaign"></i>
          Campaigns
        </router-link>
      </li>
      <li v-bind:class="[currentPage.includes('leads') ? activeClass : '']">
        <router-link
          :to="{
            name: 'leads',
            query: {
              page: 'onboarding_app',
              view: 'leads',
            },
          }"
        >
          <i class="dashboard-icon icon-leads"></i>
          Leads
        </router-link>
      </li>
      <li v-bind:class="[currentPage.includes('sessions') ? activeClass : '']">
        <router-link
          :to="{
            name: 'sessions',
            query: {
              page: 'onboarding_app',
              view: 'sessions',
            },
          }"
        >
          <i class="dashboard-icon icon-sessions"></i>
          Sessions
        </router-link>
      </li>
      <li v-bind:class="[currentPage.includes('workflows') ? activeClass : '']">
        <router-link
          :to="{
            name: 'workflows',
            query: {
              page: 'onboarding_app',
              view: 'workflows',
            },
          }"
        >
          <i class="dashboard-icon icon-workflow"></i>
          Workflows
        </router-link>
      </li>
      <li
        v-if="this.$root.user.role === 'MerchantAdmin'"
        v-bind:class="[currentPage.includes('agencies') ? activeClass : '']"
      >
        <router-link
          :to="{
            name: 'agencies',
            query: {
              page: 'onboarding_app',
              view: 'agencies',
            },
          }"
        >
          <i class="dashboard-icon icon-agency"></i>
          Agencies
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Navline",
  data: function () {
    return {
      activeClass: "active",
    };
  },
  computed: {
    currentPage() {
      return this.$route.fullPath;
    },
  },
};
</script>
