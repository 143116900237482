import { formats } from "./formats.js";

export const sessionRequestTransactionalHandler = {
    mixins: [formats],
    methods: {

        /**
         * Define transactional data
         * @param {object} request 
         * @param {object} product 
         */
        filterTransactionalData: function (request, product) {

            if (request.serviceType === 'SignUpSingle') {

                request = this.filterPaymentData(request, product);
                request = this.filterPaymentMethodData(request, product);
            } else if (request.serviceType === 'SignUpRecurring') {

                request = this.filterOneOffData(request, product);
                request = this.filterAgreementData(request, product);
                request = this.filterSubscriptionData(request, product);
                request = this.filterPaymentMethodData(request, product);
            } else if (request.serviceType === 'AgreementUpdate') {

                request = this.filterAgreementData(request, product);
                request = this.filterSubscriptionData(request, product);
                request = this.filterPaymentMethodData(request, product);
            } else if (request.serviceType === 'CreateAddOn') {

                request = this.filterAgreementData(request, product);
                request = this.filterSubscriptionData(request, product);
                request = this.filterAddOnData(request, product);
            } else if (request.serviceType === 'ChangePaymentMethod') {

                request = this.filterAgreementData(request, product);
                request = this.filterSubscriptionData(request, product);
                request = this.filterPaymentMethodData(request, product);
            } else if (
                request.serviceType === 'SubscriptionRestart' ||
                request.serviceType === 'SubscriptionExpirationRestart' ||
                request.serviceType === 'SubscriptionHold' ||
                request.serviceType === 'SubscriptionExpirationHold' ||
                request.serviceType === 'SubscriptionCancel'
            ) {

                request = this.filterSubscriptionData(request, product);
            }

            return request;
        },

        /**
         * Validate data for gateway
         * @param {object} request
         */
        filterValidateGatewayRequirements: function (request) {

            //  Prioritise previous error
            if (request.error) {
                return request;
            }

            //  Validate only serviceTypes with gateway involved
            if (
                request.serviceType === 'SignUpSingle' ||
                request.serviceType === 'SignUpRecurring' ||
                request.serviceType === 'SignUpRecurringByBs3rd' ||
                request.serviceType === 'SignUpSingleByGiro3rd' ||
                request.serviceType === 'SignUpRecurringByGiro3rd' ||
                request.serviceType === 'SignUpSingleByInvoice3rd' ||
                request.serviceType === 'SignUpRecurringByInvoice3rd' ||
                request.serviceType === 'SignUpSingleByEAN3rd' ||
                request.serviceType === 'SignUpRecurringByEAN3rd' ||
                request.serviceType === 'ChangePaymentMethod'
            ) {

                //  Test or MobilePay
                if (
                    request.paymentMethodData.paymentMethodType === 'Test' ||
                    request.paymentMethodData.paymentMethodType === 'MobilePay'
                ) {

                    if (!request.contactData.msisdn) {
                        request.error = 'Missing required phone number for Test';
                        return request;
                    } else if (request.contactData.msisdn.length !== 10) {
                        request.error = 'The phone number does not seem Danish for Test. Please review';
                        return request;
                    }
                }

                //  Betalingsservice
                else if (request.paymentMethodData.paymentMethodType === 'Betalingsservice') {

                    //  Require address, if TotalSolution
                    if (this.$root.merchant.bsSolution === 'TOTAL') {

                        //  Require address
                        if (!request.contactData.address) {
                            request.error = 'Missing address for Betalingsservice';
                            return request;
                        } else if (!request.contactData.postCode) {
                            request.error = 'Missing postcode for Betalingsservice';
                            return request;
                        } else if (!request.contactData.city) {
                            request.error = 'Missing city for Betalingsservice';
                            return request;
                        }

                        //  Fallback, assuming DK
                        else if (!request.contactData.countryCode) {
                            request.contactData.countryCode = 'DK';
                        }
                    }

                    //  Validate Betalingsservice for third party
                    if (this.$root.merchant.bsByThirdParty) {

                        //  Allow SMS
                        if (request.completionChannel == "SMS") {
                            //  Allowed
                        }

                        //  Verify DataSet
                        else {

                            //  Require either National ID or Business Code
                            if (request.contactData.nationalId && request.contactData.businessCode) {
                                request.error = 'Both national ID (CPR) and business code (CVR) was given for Betalingsservice. Please remove one';
                                return request;
                            } else if (!request.contactData.nationalId && !request.contactData.businessCode) {
                                request.error = 'Missing either national ID (CPR) or business code (CVR) for Betalingsservice';
                                return request;
                            } else if (request.contactData.nationalId && !this.isNationalIdValid(request.contactData.nationalId)) {
                                request.error = 'National ID (CPR) is too long for Betalingsservice';
                                return request;
                            } else if (request.contactData.businessCode && request.contactData.businessCode.length < 6) {
                                request.error = 'Business code (CVR) is too short for Betalingsservice';
                                return request;
                            } else if (request.contactData.businessCode && request.contactData.businessCode.length > 10) {
                                request.error = 'Business code (CVR) is too long for Betalingsservice';
                                return request;
                            }

                            //  Require sort code
                            if (!request.paymentMethodData.sortCode) {
                                request.error = 'Missing sort code or completing with SMS for Betalingsservice';
                                return request;
                            } else if (request.paymentMethodData.sortCode.length < 3) {
                                request.error = 'Sort code is too short for Betalingsservice';
                                return request;
                            } else if (request.paymentMethodData.sortCode.length > 4) {
                                request.error = 'Sort code is too long for Betalingsservice';
                                return request;
                            }

                            //  Require account number
                            else if (!request.paymentMethodData.accountNumber) {
                                request.error = 'Missing account number or completing with SMS for Betalingsservice';
                                return request;
                            } else if (request.paymentMethodData.accountNumber.length < 6) {
                                request.error = 'Account number is too short for Betalingsservice';
                                return request;
                            } else if (request.paymentMethodData.accountNumber.length > 10) {
                                request.error = 'Account number is too long for Betalingsservice';
                                return request;
                            }
                        }
                    }

                    //  Validate Betalingsservice
                    else {

                        //  Allow SMS
                        if (request.completionChannel == "SMS") {
                            //  Allowed
                        }

                        //  Require either National ID or Business Code
                        else if (request.contactData.nationalId && request.contactData.businessCode) {
                            request.error = 'Both national ID (CPR) and business code (CVR) was given for Betalingsservice. Please remove one';
                            return request;
                        }
                    }
                }

                //  Giro
                else if (request.paymentMethodData.paymentMethodType === 'Giro') {

                    //  Require name or company name
                    if (!request.contactData.name && !request.contactData.companyName) {
                        request.error = 'Missing either name or business name for giro';
                        return request;
                    }

                    //  Require address
                    if (!request.contactData.address) {
                        request.error = 'Missing address for giro';
                        return request;
                    } else if (!request.contactData.postCode) {
                        request.error = 'Missing postcode for giro';
                        return request;
                    } else if (!request.contactData.city) {
                        request.error = 'Missing city for giro';
                        return request;
                    }

                    //  Fallback, assuming DK
                    else if (!request.contactData.countryCode) {
                        request.contactData.countryCode = 'DK';
                    }
                }

                //  Invoice
                else if (request.paymentMethodData.paymentMethodType === 'Invoice') {
                    //  Require att. name
                    if (!request.contactData.name) {
                        request.error = 'Missing att. name for invoice';
                        return request;
                    }

                    //  Require company name
                    if (!request.contactData.companyName) {
                        request.error = 'Missing business name for invoice';
                        return request;
                    }

                    //  Require business code
                    if (!request.contactData.businessCode) {
                        request.error = 'Missing business code for invoice';
                        return request;
                    }
                }

                //  EAN
                else if (request.paymentMethodData.paymentMethodType === 'EAN') {
                    //  Require company name
                    if (!request.contactData.companyName) {
                        request.error = 'Missing business name for EAN';
                        return request;
                    }

                    //  Require business code
                    if (!request.contactData.businessCode) {
                        request.error = 'Missing business code for EAN';
                        return request;
                    }
                }
            }

            return request;
        },

        /**
         * Define Agreement
         * @param {object} request 
         * @param {object} product 
         */
        filterAgreementData: function (request, product) {

            request.agreementData = {};

            //  Transfer agreementData
            if (Object.keys(this.sessionRequest.agreementData).length > 0) {
                request.agreementData = this.sessionRequest.agreementData;
            }

            //  Remove helpers
            this.$delete(request.agreementData, "paymentType");
            this.$delete(request.agreementData, "scheduleStartCustomDate");
            this.$delete(request.agreementData, "sendSms");

            //  Create AddOn
            if (request.serviceType === 'CreateAddOn') {

                //  Leave AgreementData as is
                return request;
            }

            //  Shared Agreement
            else if (request.serviceType === 'SignUpRecurring' && request.agreementData.agreementGuid) {

                //  Leave AgreementData as is
                return request;
            }

            //  Personal Agreement
            else {

                //  Essentials
                request = this.filterAgreementDataEssentials(request, product);
                if (request.error) {
                    return request;
                }

                //  Amount
                request = this.filterAgreementDataAmount(request, product);
                if (request.error) {
                    return request;
                }

                //  Schedule
                request = this.filterAgreementDataSchedule(request, product);
                if (request.error) {
                    return request;
                }
            }

            return request;
        },

        /**
         * Define Agreement essentials
         * @param {object} request 
         * @param {object} product 
         */
        filterAgreementDataEssentials: function (request, product) {

            //  Define agreementGuid and agreementType
            if (request.serviceType === 'SignUpRecurring') {
                if (product.agreementGuid) {
                    request.agreementData.agreementGuid = product.agreementGuid;
                    request.agreementData.agreementType = 'Shared';
                } else {
                    request.agreementData.agreementType = 'Personal';
                    request.agreementData.state = 'Available';
                }
            }

            //  Name by Workflow Product
            if (product.agreementName) {
                request.agreementData.name = product.agreementName.substring(0, 30);
            }

            //  Name by existing Agreement
            else if (request.agreementData.name) {
                request.agreementData.name = request.agreementData.name.substring(0, 30);
            }

            //  Fallback to name by product
            else if (product.name && product.agreementGuid) {
                request.agreementData.name = product.name.substring(0, 30);
            }

            //  Bail
            else if (!request.agreementData.name) {
                request.error = 'Missing Agreement name';
                return request;
            }

            //  Configuration by Workflow Product with fallback to existing Agreement
            request.agreementData.description = (product.agreementDescription ? product.agreementDescription.substring(0, 60) : (request.agreementData.description ? request.agreementData.description.substring(0, 60) : null));
            request.agreementData.currencyCode = (product.currencyCode ? product.currencyCode : (request.agreementData.currencyCode ? request.agreementData.currencyCode : 'DKK'));
            request.agreementData.paymentRequired = (product.paymentRequired ? product.paymentRequired : (request.agreementData.paymentRequired ? request.agreementData.paymentRequired : false));
            request.agreementData.taxDeductable = (product.taxDeductable ? product.taxDeductable : (request.agreementData.taxDeductable ? request.agreementData.taxDeductable : false));
            request.agreementData.purposeAccountingCode = (product.purposeAccountingCode ? product.purposeAccountingCode.substring(0, 32) : (request.agreementData.purposeAccountingCode ? request.agreementData.purposeAccountingCode.substring(0, 32) : null));
            request.agreementData.communicationCollectionGuid = (product.communicationCollectionGuid ? product.communicationCollectionGuid : (request.agreementData.communicationCollectionGuid ? request.agreementData.communicationCollectionGuid : null));

            //  Set Communication to Payment Session Handler during SignUp
            if (request.serviceType === 'AgreementUpdate') {
                //  Skip
            } else {
                request.communicationCollectionGuid = request.agreementData.communicationCollectionGuid;
            }

            return request;
        },

        /**
         * Define Agreement Amount
         * @param {object} request 
         * @param {object} product 
         */
        filterAgreementDataAmount: function (request, product) {

            //  Amount
            if (product.amountTotal) {

                //  Validate amount as number
                if (!this.isNumber(product.amountTotal)) {

                    request.error = 'Amount seems not numeric. Please review amount';
                    return request;
                } else {

                    request.agreementData.amountTotal = parseFloat(product.amountTotal);
                }

            } else if (!request.agreementData.amountTotal) {

                request.error = 'Missing Agreement amount. Please review amount';
                return request;
            }

            request.agreementData.vatPercentage = (product.vatPercentage ? parseFloat(product.vatPercentage) : 0);
            request.agreementData.amount = parseFloat(request.agreementData.amountTotal / (100 + request.agreementData.vatPercentage)) * 100;
            request.agreementData.amountVat = parseFloat(request.agreementData.amountTotal - request.agreementData.amount);

            //  Amount as double
            request.agreementData.amountTotal = this.formatDouble(request.agreementData.amountTotal);
            request.agreementData.vatPercentage = this.formatDouble(request.agreementData.vatPercentage);
            request.agreementData.amount = this.formatDouble(request.agreementData.amount);
            request.agreementData.amountVat = this.formatDouble(request.agreementData.amountVat);

            //  Unit
            request.agreementData.unit = "pcs";
            request.agreementData.unitPrice = request.agreementData.amount;
            request.agreementData.defaultQuantity = (product.defaultQuantity ? product.defaultQuantity : 1);

            return request;
        },

        /**
         * Define Agreement Schedule
         * @param {object} request 
         * @param {object} product 
         */
        filterAgreementDataSchedule: function (request, product) {

            //  Verify scheduleType
            if (product.scheduleType) {
                request.agreementData.scheduleType = product.scheduleType;
            } else if (!request.agreementData.scheduleType) {
                request.error = 'Missing Agreement scheduleType. Please review schedule';
                return request;
            }

            //  scheduleType: Manual
            if (product.scheduleType === 'Manual') {

                //  Do nothing
            } else {

                //  Deny weekly Betalingsservice
                if (product.scheduleCalendarUnit === 'Week' && product.paymentMethodType === 'Betalingsservice') {
                    request.error = 'Weekly charges with Betalingsservice are not allowed. Please review schedule';
                    return request;
                }

                let calculatedStartDate = new Date();

                //  scheduleStartType: Now
                if (product.scheduleStartType === 'now') {

                    if (product.scheduleCalendarUnit === 'Month') {

                        //  If later than the 28th, then push schedule forward according to scheduleType
                        if (parseInt(calculatedStartDate.getDate()) > 28) {

                            if (request.agreementData.scheduleType === "Monthly" || request.agreementData.scheduleType === "MonthlyFirst") {
                                calculatedStartDate.setMonth(calculatedStartDate.getMonth() + 1, 28);
                            } else if (request.agreementData.scheduleType === "Quarterly" || request.agreementData.scheduleType === "QuarterlyFirst") {
                                calculatedStartDate.setMonth(calculatedStartDate.getMonth() + 3, 28);
                            } else if (request.agreementData.scheduleType === "Halfyearly" || request.agreementData.scheduleType === "HalfyearlyFirst") {
                                calculatedStartDate.setMonth(calculatedStartDate.getMonth() + 6, 28);
                            } else if (request.agreementData.scheduleType === "Yearly" || request.agreementData.scheduleType === "YearlyFirst") {
                                calculatedStartDate.setMonth(calculatedStartDate.getMonth() + 12, 28);
                            } else if (request.agreementData.scheduleType === "Custom") {
                                calculatedStartDate.setMonth(calculatedStartDate.getMonth() + request.agreementData.scheduleEveryOther, 28);
                            }
                        }

                        request.agreementData.scheduleFixedDay = parseInt(calculatedStartDate.getDate());
                        request.agreementData.scheduleBaseTier = parseInt(calculatedStartDate.getMonth() + 1);

                    } else if (product.scheduleCalendarUnit === 'Week') {

                        request.agreementData.scheduleFixedDay = parseInt(calculatedStartDate.getDay() || 7);
                        request.agreementData.scheduleBaseTier = parseInt(calculatedStartDate.getWeekNumber());
                    }
                }

                //  scheduleStartType: First of next month
                else if (product.scheduleStartType === 'first') {

                    if (product.scheduleCalendarUnit === 'Month') {

                        //  Account for Betalingsservice's start dates
                        if (product.paymentMethodType === 'Betalingsservice') {
                            calculatedStartDate = new Date(this.getNextBetalingsserviceStartDate());
                        } else {
                            calculatedStartDate.setMonth(calculatedStartDate.getMonth() + 1, 1);
                        }

                        request.agreementData.scheduleFixedDay = parseInt(calculatedStartDate.getDate());
                        request.agreementData.scheduleBaseTier = parseInt(calculatedStartDate.getMonth() + 1);

                    } else if (product.scheduleCalendarUnit === 'Week') {

                        request.agreementData.scheduleFixedDay = parseInt(calculatedStartDate.getDay() || 7);
                        request.agreementData.scheduleBaseTier = parseInt(calculatedStartDate.getWeekNumber());
                    }
                }

                //  scheduleStartType: Custom
                else if (product.scheduleStartType === 'custom') {

                    if (product.scheduleStartCustomDate.startDate) {

                        let scheduleStartCustomDate = product.scheduleStartCustomDate.startDate;

                        //  Ensure object
                        if (typeof scheduleStartCustomDate === 'object') {
                            //  OK
                        } else if (typeof scheduleStartCustomDate === 'string') {

                            scheduleStartCustomDate = new Date(product.scheduleStartCustomDate.startDate);
                            if (typeof scheduleStartCustomDate !== 'object') {
                                request.error = 'Invalid start date. Please review';
                                return request;
                            }
                        }

                        if (product.scheduleCalendarUnit === 'Month') {

                            //  Account for Betalingsservice's start dates
                            if (product.paymentMethodType === 'Betalingsservice') {

                                let dateForFirstOfNextMonth = new Date(this.getNextBetalingsserviceStartDate());
                                if (scheduleStartCustomDate < dateForFirstOfNextMonth) {

                                    window.console.log('Correcting custom date for BS');
                                    scheduleStartCustomDate = dateForFirstOfNextMonth;
                                }
                            }

                            request.agreementData.scheduleFixedDay = parseInt(scheduleStartCustomDate.getDate());
                            request.agreementData.scheduleBaseTier = parseInt(scheduleStartCustomDate.getMonth() + 1);

                        } else if (product.scheduleCalendarUnit === 'Week') {

                            request.agreementData.scheduleFixedDay = parseInt(scheduleStartCustomDate.getDay() || 7);
                            request.agreementData.scheduleBaseTier = parseInt(scheduleStartCustomDate.getWeekNumber());

                        } else {

                            request.error = 'Missing calendar unit. Please review';
                            return request;
                        }

                    } else {

                        request.error = 'Missing start date. Please review';
                        return request;
                    }
                }

                //  Force schedule if scheduleSelectedSet is set
                if (product.hasScheduleSelectedSet) {

                    request.agreementData.scheduleType = "Monthly";
                    request.agreementData.scheduleEveryOther = 1;
                    request.agreementData.scheduleCalendarUnit = "Month";
                    request.agreementData.scheduleBaseTier = 1;
                    request.agreementData.scheduleFixedDay = 1;
                    request.agreementData.scheduleSelectedSet = this.convertScheduleSelectedSetDefinitionToString(product);
                }

                //  Otherwise set schedule
                else {

                    //  Delete scheduleSelectedSet
                    if (request.agreementData.scheduleSelectedSet) {
                        this.$delete(request.agreementData, "scheduleSelectedSet");
                    }

                    //  Set scheduleEveryOther
                    if (product.scheduleEveryOther) {
                        request.agreementData.scheduleEveryOther = parseInt(product.scheduleEveryOther);
                    }

                    //  Verify scheduleEveryOther
                    else if (!request.agreementData.scheduleEveryOther) {
                        request.error = 'Missing Agreement scheduleEveryOther. Please review schedule';
                        return request;
                    }

                    //  Set scheduleCalendarUnit
                    if (product.scheduleCalendarUnit) {
                        request.agreementData.scheduleCalendarUnit = product.scheduleCalendarUnit;
                    }

                    //  Verify scheduleCalendarUnit
                    else if (!request.agreementData.scheduleCalendarUnit) {
                        request.error = 'Missing Agreement scheduleCalendarUnit. Please review schedule';
                        return request;
                    }

                    //  Set scheduleBaseTier
                    if (product.scheduleBaseTier) {
                        request.agreementData.scheduleBaseTier = parseInt(product.scheduleBaseTier);
                    }

                    //  Verify scheduleBaseTier
                    else if (!request.agreementData.scheduleBaseTier) {
                        request.error = 'Missing Agreement scheduleBaseTier. Please review schedule';
                        return request;
                    }

                    //  Set scheduleFixedDay
                    if (product.scheduleFixedDay) {
                        request.agreementData.scheduleFixedDay = parseInt(product.scheduleFixedDay);
                    }

                    //  Verify scheduleFixedDay
                    else if (!request.agreementData.scheduleFixedDay) {
                        request.error = 'Missing Agreement scheduleFixedDay. Please review schedule';
                        return request;
                    }

                    //  Limit scheduleFixedDay to 28
                    if (request.agreementData.scheduleFixedDay > 28) {
                        //  request.agreementData.scheduleFixedDay = 28;
                        request.error = 'A custom start date later than on the 28th of any month is not permitted. Please review custom start date';
                        return request;
                    }
                }
            }

            return request;
        },

        /**
         * Define Subscription
         * @param {object} request 
         * @param {object} product 
         */
        filterSubscriptionData: function (request, product) {

            request.subscriptionData = {};

            //  Define subscriptionGuid
            if (this.sessionRequest.subscriptionGuid) {
                request.subscriptionData.subscriptionGuid = this.sessionRequest.subscriptionGuid;
            }

            //  Hold description
            if (request.serviceType === 'SubscriptionHold' && product.subscriptionActionDescription) {
                request.subscriptionData.holdDescription = product.subscriptionActionDescription;
            }

            //  Cancel description
            else if (request.serviceType === 'SubscriptionCancel' && product.subscriptionActionDescription) {
                request.subscriptionData.cancelDescription = product.subscriptionActionDescription;
            }

            //  Restart
            else if (request.serviceType === 'SubscriptionRestart' || request.serviceType === 'SubscriptionExpirationRestart') {

                //  Define new startDate
                let today = new Date();

                request.subscriptionData.startDate = this.formatDateToYMD(today);

                if (product.expiresAfterDate) {
                    request.subscriptionData.expiresAfterDate == product.expiresAfterDate.expiresAfterDate;
                }

                //  Define expiresAfterDate
                this.filterSubscriptionDataExpiresAfterDate(request, product);
                if (request.error) {
                    return request;
                }
            }

            //  Recurring
            else if (request.serviceType === 'SignUpRecurring') {

                //  Define startDate
                this.filterSubscriptionDataStartDate(request, product);
                if (request.error) {
                    return request;
                }

                //  Define expiresAfterDate
                this.filterSubscriptionDataExpiresAfterDate(request, product);
                if (request.error) {
                    return request;
                }
            }

            return request;
        },

        /**
         * Define Subscription startDate
         * @param {object} request 
         * @param {object} product 
         */
        filterSubscriptionDataStartDate: function (request, product) {

            let calculatedStartDate = new Date();

            //  scheduleType: Manual
            if (product.scheduleType === 'Manual') {
                request.subscriptionData.startDate = this.formatDateToYMD(calculatedStartDate);
            }

            //  scheduleType: Now
            else if (product.scheduleStartType === 'now') {
                request.subscriptionData.startDate = this.formatDateToYMD(calculatedStartDate);
            }

            //  scheduleType: First of next month
            else if (product.scheduleStartType === 'first') {

                //  Ensure startDate is next possible start date
                if (product.scheduleCalendarUnit === 'Month') {

                    //  Account for Betalingsservice's start dates
                    if (product.paymentMethodType === 'Betalingsservice') {
                        calculatedStartDate = new Date(this.getNextBetalingsserviceStartDate());
                    } else {
                        calculatedStartDate.setMonth(calculatedStartDate.getMonth() + 1, 1);
                    }
                }

                request.subscriptionData.startDate = this.formatDateToYMD(calculatedStartDate);
            }

            //  scheduleType: Custom
            else if (product.scheduleStartType === 'custom' && product.scheduleStartCustomDate.startDate) {

                //  Ensure object
                if (typeof product.scheduleStartCustomDate.startDate === 'object') {
                    //  OK
                } else if (typeof product.scheduleStartCustomDate.startDate === 'string') {

                    product.scheduleStartCustomDate.startDate = new Date(product.scheduleStartCustomDate.startDate);
                    if (typeof product.scheduleStartCustomDate.startDate !== 'object') {
                        request.error = 'Invalid start date. Please review start';
                        return request;
                    }
                }

                //  Account for Betalingsservice's start dates
                if (product.scheduleCalendarUnit === 'Month' && product.paymentMethodType === 'Betalingsservice') {

                    calculatedStartDate = new Date(this.getNextBetalingsserviceStartDate());

                    //  Ensure startDate is not before calculatedStartDate
                    if (product.scheduleStartCustomDate.startDate < calculatedStartDate) {

                        window.console.log('Correcting custom date for BS');
                        product.scheduleStartCustomDate.startDate = calculatedStartDate;
                    }
                }

                request.subscriptionData.startDate = this.formatDateToYMD(product.scheduleStartCustomDate.startDate);
            }

            //  startDate by nextDueDate
            else if (product.nextDueDate) {

                request.subscriptionData.startDate = product.nextDueDate;
            }

            //  Error
            else {

                request.error = 'Missing start date. Please review';
                return request;
            }

            return request;
        },

        /**
         * Define Subscription expiresAfterDate
         * @param {object} request 
         * @param {object} product 
         */
        filterSubscriptionDataExpiresAfterDate: function (request, product) {

            if (product.expiresAfterDate && product.expiresAfterDate.startDate) {

                //  Ensure object
                if (typeof product.expiresAfterDate.startDate === 'object') {
                    //  OK
                } else if (typeof product.expiresAfterDate.startDate === 'string') {

                    product.expiresAfterDate.startDate = new Date(product.expiresAfterDate.startDate);
                    if (typeof product.expiresAfterDate.startDate !== 'object') {
                        request.error = 'Invalid expiration date. Please review';
                        return request;
                    }
                }

                request.subscriptionData.expiresAfterDate = this.formatDateToYMD(product.expiresAfterDate.startDate);
            }

            return request;
        },

        /**
         * Define PaymentMethod
         * @param {object} request 
         * @param {object} product 
         */
        filterPaymentMethodData: function (request, product) {

            request.paymentMethodData = {};

            //  Define paymentMethodGuid
            if (this.sessionRequest.paymentMethodGuid) {
                request.paymentMethodData.paymentMethodGuid = this.sessionRequest.paymentMethodGuid;
            }

            //  Define PaymentMethodType
            if (product.paymentMethodType) {

                request.paymentMethodData.paymentMethodType = product.paymentMethodType;

                //  MobilePay
                if (request.paymentMethodData.paymentMethodType === 'MobilePay') {

                    //  Convert to Online or Subscription variant
                    if (request.serviceType === 'SignUpRecurring' || request.serviceType === 'ChangePaymentMethod') {
                        request.paymentMethodData.paymentMethodType = 'MobilePaySubscriptions';
                    } else {
                        request.paymentMethodData.paymentMethodType = 'MobilePayOnline';
                    }
                }

                //  Betalingsservice
                else if (request.paymentMethodData.paymentMethodType === 'Betalingsservice') {

                    //  Verify BS 3rd
                    if (this.$root.merchant.bsByThirdParty) {

                        //  Define serviceType and completionChannel after initial filter
                        request.serviceType = "SignUpRecurringByBs3rd";

                        //  Set completion channel
                        if (request.completionChannel === "SMS") {
                            //  Avoid sharing sortCode and accountNumber if SMS is sent
                        } else {
                            request.completionChannel = "DataSet";
                            request.paymentMethodData.sortCode = product.sortCode;
                            request.paymentMethodData.accountNumber = product.accountNumber;
                        }
                    }

                    //  Append sortCode and accountNumber if given by query-string
                    else if (product.sortCode && product.accountNumber) {
                        request.paymentMethodData.sortCode = product.sortCode;
                        request.paymentMethodData.accountNumber = product.accountNumber;
                    }
                }

                //  Giro
                else if (request.paymentMethodData.paymentMethodType === 'Giro') {

                    if (request.serviceType === 'SignUpSingle') {
                        request.serviceType = "SignUpSingleByGiro3rd";
                    } else if (request.serviceType === 'SignUpRecurring') {
                        request.serviceType = "SignUpRecurringByGiro3rd";
                    }
                    request.completionChannel = "DataSet";
                }

                //  Invoice
                else if (request.paymentMethodData.paymentMethodType === 'Invoice') {

                    if (request.serviceType === 'SignUpSingle') {
                        request.serviceType = "SignUpSingleByInvoice3rd";
                    } else if (request.serviceType === 'SignUpRecurring') {
                        request.serviceType = "SignUpRecurringByInvoice3rd";
                    }
                    request.completionChannel = "DataSet";
                }

                //  EAN
                else if (request.paymentMethodData.paymentMethodType === 'EAN') {

                    if (request.serviceType === 'SignUpSingle') {
                        request.serviceType = "SignUpSingleByEAN3rd";
                    } else if (request.serviceType === 'SignUpRecurring') {
                        request.serviceType = "SignUpRecurringByEAN3rd";
                    }
                    request.completionChannel = "DataSet";
                }
            }

            //  Error
            else if (
                request.serviceType === 'SignUpSingle' ||
                request.serviceType === 'SignUpRecurring' ||
                request.serviceType === 'ChangePaymentMethod'
            ) {
                request.error = 'Missing Payment Method Type. Please review payment method';
                return request;
            }

            return request;
        },

        /**
         * Define Payment
         * @param {object} request 
         * @param {object} product 
         */
        filterPaymentData: function (request, product) {

            request.paymentData = {};

            //  Essentials
            request.paymentData.paymentType = "Single";
            request.paymentData.currencyCode = (product.currencyCode ? product.currencyCode : 'DKK');
            request.paymentData.paymentRequired = (product.paymentRequired ? product.paymentRequired : false);
            request.paymentData.taxDeductable = (product.taxDeductable ? product.taxDeductable : false);
            request.paymentData.purposeAccountingCode = (product.purposeAccountingCode ? product.purposeAccountingCode.substring(0, 32) : null);
            request.paymentData.communicationCollectionGuid = (product.communicationCollectionGuid ? product.communicationCollectionGuid : null);

            //  Set Communication
            request.communicationCollectionGuid = request.paymentData.communicationCollectionGuid;

            //  Amount
            if (product.amountTotal) {

                //  Validate amount as number
                if (!this.isNumber(product.amountTotal)) {

                    request.error = 'Amount seems not numeric. Please review amount';
                    return request;
                } else {

                    request.paymentData.amountTotal = parseFloat(product.amountTotal);
                }
            } else {

                request.error = 'Missing Payment amount. Please review amount';
                return request;
            }

            request.paymentData.vatPercentage = (product.vatPercentage ? parseFloat(product.vatPercentage) : 0);
            request.paymentData.amount = parseFloat(request.paymentData.amountTotal / (100 + request.paymentData.vatPercentage)) * 100;
            request.paymentData.amountVat = parseFloat(request.paymentData.amountTotal - request.paymentData.amount);

            //  Amount as double
            request.paymentData.amountTotal = this.formatDouble(request.paymentData.amountTotal);
            request.paymentData.vatPercentage = this.formatDouble(request.paymentData.vatPercentage);
            request.paymentData.amount = this.formatDouble(request.paymentData.amount);
            request.paymentData.amountVat = this.formatDouble(request.paymentData.amountVat);

            return request;
        },

        /**
         * Define OneOff Payment
         * @param {object} request 
         * @param {object} product 
         */
        filterOneOffData: function (request, product) {

            //  Set OneOff if explicitly requested or start date is now, except if payment method type is Betalingsservice
            if (
                //  product.scheduleStartWithOneOff &&
                product.paymentMethodType !== 'Betalingsservice' && (

                    //  Defined explicitly
                    product.startWithOneOff == true ||

                    //  Derived from schedule starting now
                    product.scheduleStartType == 'now'
                )
            ) {

                //  Flag as OneOff
                product.paymentType = "OneOff";

                //  Essentials
                request.paymentData = {};
                request.paymentData.paymentType = "OneOff";

                //  Set OneOff amount from defined oneOffAmount
                if (product.oneOffAmount) {

                    //  Validate amount as number
                    if (!this.isNumber(product.oneOffAmount)) {

                        request.error = 'Amount seems not numeric. Please review amount';
                        return request;
                    } else {

                        request.paymentData.oneOffAmount = this.formatDouble(parseFloat(product.oneOffAmount));
                    }

                }

                //  Set OneOff amount from agreement
                else if (product.amountTotal) {

                    //  Validate amount as number
                    if (!this.isNumber(product.amountTotal)) {

                        request.error = 'Amount seems not numeric. Please review amount';
                        return request;
                    } else {

                        request.paymentData.oneOffAmount = this.formatDouble(parseFloat(product.amountTotal));
                    }
                }

                //  Set purposeAccountingCode
                request.paymentData.purposeAccountingCode = (product.purposeAccountingCode ? product.purposeAccountingCode.substring(0, 32) : null);

                //  Set taxDeductable
                request.paymentData.taxDeductable = (product.taxDeductable ? product.taxDeductable : false);
            }

            return request;
        },

        /**
         * Define AddOns
         * @param {object} request 
         * @param {object} product 
         */
        filterAddOnData: function (request, product) {

            request.addOnData = [];

            let addOnData = {};

            //  Essentials
            if (product.addOnName) {
                addOnData.name = product.addOnName;
            } else {
                request.error = 'Missing Add-On name';
                return request;
            }
            addOnData.addOnDescription = (product.addOnDescription ? product.addOnDescription.substring(0, 60) : null);
            addOnData.purposeAccountingCode = (product.purposeAccountingCode ? product.purposeAccountingCode.substring(0, 32) : null);
            addOnData.taxDeductable = (product.taxDeductable ? product.taxDeductable : false);

            //  Amount
            if (product.amountTotal) {

                //  Validate amount as number
                if (!this.isNumber(product.amountTotal)) {

                    request.error = 'Amount seems not numeric. Please review amount';
                    return request;
                } else {

                    addOnData.amountTotal = parseFloat(product.amountTotal);
                }
            } else {

                request.error = 'Missing Agreement amountTotal. Please review amount';
                return request;
            }

            addOnData.vatPercentage = (product.vatPercentage ? parseFloat(product.vatPercentage) : 0);
            addOnData.amount = parseFloat(addOnData.amountTotal / (100 + addOnData.vatPercentage)) * 100;
            addOnData.amountVat = parseFloat(addOnData.amountTotal - addOnData.amount);

            //  Amount as double
            addOnData.amountTotal = this.formatDouble(addOnData.amountTotal);
            addOnData.vatPercentage = this.formatDouble(addOnData.vatPercentage);
            addOnData.amount = this.formatDouble(addOnData.amount);
            addOnData.amountVat = this.formatDouble(addOnData.amountVat);

            //  Unit
            addOnData.unit = "pcs";
            addOnData.unitPrice = addOnData.amount;
            addOnData.defaultQuantity = (product.defaultQuantity ? product.defaultQuantity : 1);

            //  Start date
            if (product.startDate) {

                addOnData.startDate = product.startDate;
            } else {

                let today = new Date();

                addOnData.startDate = this.formatDateToYMD(today);
            }

            //  Expires after date
            if (product.expiresAfterDate && product.expiresAfterDate.startDate) {

                //  Ensure object
                if (typeof product.expiresAfterDate.startDate === 'object') {
                    //  OK
                } else if (typeof product.expiresAfterDate.startDate === 'string') {

                    product.expiresAfterDate.startDate = new Date(product.expiresAfterDate.startDate);
                    if (typeof product.expiresAfterDate.startDate !== 'object') {
                        request.error = 'Invalid expiration date. Please review';
                        return request;
                    }
                }

                addOnData.expiresAfterDate = this.formatDateToYMD(product.expiresAfterDate.startDate);
            }

            //  Append AddOn
            request.addOnData.push(addOnData);

            return request;
        },

        /**
         * Format double
         * @param {integer} number 
         */
        formatDouble: function (number) {

            var n = parseFloat(number);

            return n.toFixed(2);
        },

        /**
         * Validate number
         * @param {integer} number 
         * @returns 
         */
        isNumber: function (number) {

            return !isNaN(parseFloat(number)) && isFinite(number);
        }
    }
}