var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (this.sessionRequest.workflow.contactDataFields)?_c('section',{staticClass:"contact-data"},[_c('h3',[_vm._v("Lead")]),_vm._l((this.sessionRequest.workflow.contactDataFields),function(contactData){return _c('div',{key:contactData.name,staticClass:"form-group"},[(contactData.inputType === 'date')?_c('onboarding-contact-data-date',{attrs:{"contactData":contactData,"sessionRequest":_vm.sessionRequest}}):(contactData.inputType === 'dawaAddress')?_c('onboarding-contact-data-dawa-address',{ref:"OnboardingContactDataDawaAddress",refInFor:true,attrs:{"contactData":contactData,"sessionRequest":_vm.sessionRequest},on:{"dawaValidatedAddress":_vm.dawaValidatedAddress,"dawaClearedAddress":function($event){return _vm.dawaClearedAddress()}}}):[_c('label',{staticClass:"control-label col-sm-3",attrs:{"for":contactData.name}},[_vm._v(" "+_vm._s(contactData.label)+" ")]),_c('div',{staticClass:"control-input col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(contactData.value),expression:"contactData.value",modifiers:{"trim":true}}],staticClass:"uik-input__input",class:[
            contactData.value && contactData.value.length > 0
              ? 'not-empty'
              : 'empty',
            contactData.validity ? contactData.validity : '',
          ],attrs:{"type":contactData.inputType,"id":contactData.name,"autocomplete":"nope","required":contactData.required ? true : false,"minlength":contactData.attr && contactData.attr.minlength
              ? contactData.attr.minlength
              : false,"maxlength":contactData.attr && contactData.attr.maxlength
              ? contactData.attr.maxlength
              : false,"pattern":contactData.attr && contactData.attr.pattern
              ? contactData.attr.pattern
              : false,"placeholder":contactData.attr && contactData.attr.placeholder
              ? contactData.attr.placeholder
              : false},domProps:{"value":(contactData.value)},on:{"keydown":function($event){return _vm.onKeyDown(contactData, $event)},"keyup":function($event){return _vm.onKeyUp(contactData)},"input":function($event){if($event.target.composing)return;_vm.$set(contactData, "value", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"validator-icon"})])]],2)})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }