<template>
  <div class="wrap leads">
    <div class="content">
      <!-- Heading -->
      <div class="view-header-wrap uik-entity-list-header">
        <h2 class="title">Leads</h2>

        <!-- Tooltip -->
        <tool-tip
          v-bind:tooltip="'A lead represents an individual with possibly several sessions. <a href=&quot;https://support.onlinefundraising.dk/hc/da/articles/360013209339&quot; target=&quot;_blank&quot;>See our guide.</a>'"
        />

        <!-- Search -->
        <div class="search-wrapper">
          <form
            class="search-form"
            role="form"
            method="post"
            action=""
            autocomplete="nope"
            novalidate="novalidate"
            v-on:submit.prevent
          >
            <input
              class="uik-input__input search-input"
              type="search"
              placeholder="Search by external lead ID"
              tabindex="1"
              v-model="search"
            />
            <button type="button" tabindex="-1">
              <i class="dashboard-icon icon-search"></i>
            </button>
          </form>
        </div>

        <!-- List filter -->
        <list-filter
          v-bind:entityType="'lead'"
          v-on:appliedFilter="appliedFilter()"
        />
      </div>

      <!-- Table -->
      <div class="uik-entity-list lead-list">
        <table>
          <!-- Table header -->
          <thead>
            <tr>
              <th class="manage-column column-created">Created</th>
              <th class="manage-column column-external-lead-id">
                External lead ID
              </th>
              <th class="manage-column column-campaign">Campaign</th>
              <th class="manage-column column-agency">Agency</th>
            </tr>
          </thead>

          <!-- Error -->
          <tbody v-if="this.listErrored === true">
            <tr>
              <td colspan="4" class="text-center">
                We are sorry. We were not able to retrieve leads.
              </td>
            </tr>
          </tbody>

          <!-- Loading -->
          <tbody v-else-if="this.listLoading === true">
            <tr>
              <td colspan="4" class="text-center">Loading leads ...</td>
            </tr>
          </tbody>

          <!-- Table -->
          <tbody v-else-if="this.$root.store.leads.length > 0">
            <tr
              tabindex="0"
              v-for="lead in this.$root.store.leads"
              :key="lead.leadGuid"
              v-on:keyup.up="$event.target.previousElementSibling.focus()"
              v-on:keyup.down="$event.target.nextElementSibling.focus()"
              v-on:keyup.enter="viewLead(lead)"
              v-on:click.exact="viewLead(lead)"
              v-on:click.ctrl="viewLeadNewTab(lead)"
            >
              <td>
                <span
                  v-bind:inner-html.prop="lead.createdTs | formatTime"
                ></span>
              </td>
              <td>{{ lead.externalLeadId }}</td>
              <td>{{ lead.campaignName }}</td>
              <td>{{ lead.agencyId }}</td>
            </tr>
          </tbody>

          <!-- Not found -->
          <tbody v-else>
            <tr>
              <td colspan="4" class="text-center">No leads found ...</td>
            </tr>
          </tbody>
        </table>

        <!-- Pagination -->
        <pagination
          v-if="
            this.listErrored != true &&
            this.listLoading != true &&
            this.$root.store.leads.length > 0
          "
          v-bind:pageNumber="this.$root.store.leadPageNumber"
          v-bind:pageSize="this.$root.store.leadPageSize"
          v-bind:currentPageSize="this.$root.store.leads.length"
          v-on:setPage="setPage"
        />
      </div>
    </div>
    <!-- Modal -->
    <div v-if="this.currentLead">
      <lead-modal
        v-bind:lead="this.currentLead"
        v-on:closeLeadModal="closeLeadModal()"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import LeadModal from "./LeadModal";
import ListFilter from "../ListFilter";
import Pagination from "../Pagination";
import ToolTip from "../ToolTip";
import { leadHandler } from "../../mixins/leadHandler.js";
import { formats } from "../../mixins/formats.js";

export default Vue.extend({
  name: "LeadList",
  mixins: [leadHandler, formats],
  components: {
    LeadModal,
    ListFilter,
    Pagination,
    ToolTip,
  },
  data: function () {
    return {
      currentLead: null,
      search: null,
      listErrored: false,
      listLoading: true,
    };
  },
  created() {
    //  Reset list
    this.$root.store.leadPageNumber = 1;
    this.$root.store.leads = [];

    //  Get list
    this.getLeadsList();

    //  Listen to browser history navigation
    window.addEventListener("popstate", this.initialiseLeadModal, false);
  },
  watch: {
    search: function () {
      this.doSearchLeadsByExternalLeadId();
    },
  },
  methods: {
    /**
     * Open lead modal to view
     * @param {object} lead
     */
    viewLead: function (lead) {
      this.currentLead = lead;
      this.$root.viewEntity("lead", lead.leadGuid);
    },

    /**
     * Open modal in new tab
     * @param {object} lead
     */
    viewLeadNewTab: function (lead) {
      this.$root.viewEntityNewTab("lead", lead.leadGuid);
    },

    /**
     * Close lead modal
     */
    closeLeadModal: function () {
      this.currentLead = null;
      this.$root.viewEntity("lead");
    },

    /**
     * Set pagination page number
     * @param {int} pageNumber
     */
    setPage: function (pageNumber) {
      this.$root.store.leadPageNumber = pageNumber;
      this.$root.store.leads = [];
      this.getLeadsList();
    },

    /**
     * Apply filter
     */
    appliedFilter: function () {
      this.getLeadsList();
    },

    /**
     * Get leads
     */
    getLeadsList: function () {
      let leadHandler = this.getLeads();
      if (typeof leadHandler.then !== "undefined") {
        leadHandler.then((response) => {
          if (response.leadsErrored) {
            this.listErrored = true;
          }
          this.listLoading = false;

          this.initialiseLeadModal();
        });
      } else {
        this.listLoading = false;

        this.initialiseLeadModal();
      }
    },

    /**
     * Initialise lead modal by query parameter
     */
    initialiseLeadModal: function () {
      //  Detect search by query
      if (this.$route.query.view === "leads" && this.$route.query.s) {
        this.search = this.$route.query.s;
        this.doSearchLeadsByExternalLeadId();
      }

      //  Detect modal by query
      else if (this.$route.query.view === "leads" && this.$route.query.guid) {
        //  Handle promise or storage
        let leadHandler = this.getLead(this.$route.query.guid);

        //  Promise
        if (typeof leadHandler.then !== "undefined") {
          leadHandler.then((response) => {
            if (response.leadErrored) {
              this.currentLead = null;
            } else {
              this.currentLead = response.lead;
            }
          });
        }

        //  Storage
        else if (leadHandler.lead) {
          this.currentLead = leadHandler.lead;
        }
      }

      //  No query
      else {
        this.currentLead = null;
      }
    },

    /**
     * Search leads
     */
    doSearchLeadsByExternalLeadId: function () {
      //  Reset
      if (this.search.length == 0) {
        this.$root.store.leadPageNumber = 1;
        this.$root.store.leads = [];
        this.$root.viewEntity("lead");
        this.getLeadsList();
      }

      //  Initiate search
      else if (this.search.length > 1) {
        this.searchLeadsByExternalLeadId(this.search).then((response) => {
          if (response.leadsErrored) {
            this.currentLead = null;
          } else {
            this.$root.store.leadPageNumber = 1;
            this.$root.store.leads = response.leads;
            //  this.$root.viewEntity("lead", null, this.search);
          }
        });
      }
    },
  },
});
</script>
