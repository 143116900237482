import axios from "axios";
import { formats } from "./formats.js";

export const sessionRequestGatewayHandler = {
    mixins: [formats],
    methods: {

        /**
         * Get today
         */
        getToday() {

            return this.formatDateToYMD(new Date());
        },

        /**
         * Get date by PaymentMethodType
         * @param {string} paymentMethodType 
         */
        getFirstOfMonthByPaymentMethodType(paymentMethodType) {

            if (paymentMethodType === 'Betalingsservice') {

                return this.getNextBetalingsserviceStartDate();
            } else {

                let firstOfNextMonth = new Date();
                firstOfNextMonth.setMonth(firstOfNextMonth.getMonth() + 1, 1);

                return this.formatDateToYMD(firstOfNextMonth);
            }
        },

        /**
         * Get the minDate value of the first possible start date by chosen payment method type
         * @param {string} paymentMethodType 
         */
        getMinScheduleStartCustomDate(paymentMethodType) {

            if (paymentMethodType === 'Betalingsservice') {

                let minDateString = this.getNextBetalingsserviceStartDate();
                let minDateObject = new Date(minDateString);

                //  Subtract one day, as minDate is exclusive
                minDateObject.setDate(minDateObject.getDate() - 1);

                return this.formatDateToYMD(minDateObject);
            } else {

                let firstDate = new Date();

                return this.formatDateToYMD(firstDate);
            }
        },

        /**
         * Get Betalingsservice start date service
         */
        getBetalingsserviceStartDateService: function() {

            const currentYear = new Date().getFullYear()
            const currentMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);

            //  Request
            return axios
                .get('https://info.onlinefundraising.dk/bs/bs_' + currentYear + '_' + currentMonth + '.json')
                .then(response => {

                    //  window.console.log('Deadline');
                    //  window.console.log(response.data.subscriptionDeadline);

                    //  Before deadline
                    if (new Date() < new Date(response.data.subscriptionDeadline)) {
                        this.$root.helpers.gatewayDate.Betalingsservice = this.formatDateToYMD(response.data.beforeDeadline.nextPossibleAgreementDate);
                    }

                    //  After deadline
                    else {
                        this.$root.helpers.gatewayDate.Betalingsservice = this.formatDateToYMD(response.data.afterDeadline.nextPossibleAgreementDate);
                    }
                })
                .catch(error => {

                    //  Log error
                    window.console.log(error);

                    //  Fallback
                    this.$root.helpers.gatewayDate.Betalingsservice = this.getNextBetalingsserviceStartDateAsStaticFallback();
                });
        },

        /**
         * Get next possible start date by Betalingsservice
         */
        getNextBetalingsserviceStartDate() {

            //  API
            if (this.$root.helpers.gatewayDate.Betalingsservice) {
                window.console.log('BS date by API');
                return this.$root.helpers.gatewayDate.Betalingsservice;
            }

            //  Fallback
            else {
                window.console.log('BS date by fallback');
                return this.getNextBetalingsserviceStartDateAsStaticFallback();
            }
        },

        /**
         * Get next possible start date by Betalingsservice as static fallback
         */
        getNextBetalingsserviceStartDateAsStaticFallback() {

            let firstDate = new Date();

            let bsDates = this.$root.helpers.gatewayDates.Betalingsservice;

            for (var i = 0; i < bsDates.length; i++) {

                let bsDate = new Date(bsDates[i]);

                //  Same year and month
                if (firstDate.getFullYear() === bsDate.getFullYear() && firstDate.getMonth() === bsDate.getMonth()) {

                    //  Day before deadline
                    if (firstDate.getDate() + 1 === bsDate.getDate()) {

                        //  .. before 4pm, select next first
                        if (firstDate.getHours() < 16) {

                            firstDate.setMonth(firstDate.getMonth() + 1, 1);
                        }

                        //  .. after 4pm, select 2nd next first
                        else {

                            firstDate.setMonth(firstDate.getMonth() + 2, 1);
                        }
                    }

                    //  Before deadline, select next first
                    else if (firstDate.getDate() < bsDate.getDate()) {

                        firstDate.setMonth(firstDate.getMonth() + 1, 1);
                    }

                    //  After deadline, select 2nd next first
                    else {

                        firstDate.setMonth(firstDate.getMonth() + 2, 1);
                    }

                    return this.formatDateToYMD(firstDate);
                }
            }

            //  Default to first of next month, knowing first charge might be lost
            firstDate = new Date();
            firstDate.setMonth(firstDate.getMonth() + 1, 1);

            return this.formatDateToYMD(firstDate);
        }
    }
}