<template>
  <div class="wrap login">
    <div class="login-inner">
      <!-- Header -->
      <div v-if="action === 'ChangePassword'" class="block-header" aria-hidden="true">
        <h1 class="title">Change password</h1>
        <p class="desc">Please insert your new password twice</p>
      </div>
      <div v-else-if="action === 'ForgotPassword'" class="block-header" aria-hidden="true">
        <h1 class="title">Lost your password?</h1>
        <p class="desc">
          Please enter your username or email address. You will receive an email
          message with instructions on how to reset your password.
        </p>
      </div>
      <div v-else class="block-header" aria-hidden="true">
        <h1 class="title">Login</h1>
        <p class="desc">Please insert your email address and password</p>
      </div>

      <!-- Form -->
      <form method="post" action="" autocomplete="nope" novalidate="novalidate" v-on:submit.prevent>
        <!-- Email -->
        <div v-if="action === 'Login' || action === 'ForgotPassword'" class="control-input">
          <input type="email" id="email" class="uik-input__input" placeholder="Email address" v-model="email"
            autocomplete="email" aria-label="Email" required />
        </div>

        <!-- Password -->
        <div v-if="action === 'Login' || action === 'ChangePassword'" class="control-input">
          <input type="password" id="password" class="uik-input__input" placeholder="Password" v-model="password"
            autocomplete="nope" aria-label="Adgangskode" required />
        </div>

        <!-- Password -->
        <div v-if="action === 'ChangePassword'" class="control-input">
          <input type="password" id="password2" class="uik-input__input" placeholder="Password again" v-model="password2"
            autocomplete="nope" aria-label="Gentag adgangskode." required />
        </div>

        <!-- Buttons -->
        <div class="form-footer">
          <button v-if="action === 'ChangePassword'" type="button" v-on:click="sendChangePassword()"
            class="uik-btn__base btn btn-primary" aria-label="Skift adgangskode.">
            Change password
          </button>
          <button v-else-if="action === 'ForgotPassword'" type="button" v-on:click="sendForgotPassword()"
            class="uik-btn__base btn btn-primary" aria-label="Anskaf ny adgangskode.">
            Get new password
          </button>
          <button v-else-if="action === 'Login'" type="button" v-on:click="sendLogin()"
            class="uik-btn__base btn btn-primary" aria-label="Log ind.">
            Login
          </button>
        </div>
      </form>

      <!-- Footer -->
      <button v-if="action === 'ForgotPassword'" type="button" v-on:click="setActionLogin()" class="uik-btn__link btn"
        aria-label="Gå til log ind.">
        Go to login
      </button>
      <button v-else-if="action === 'Login'" type="button" v-on:click="setActionForgotPassword()"
        class="uik-btn__link btn" aria-label="Har du glemt din adgangskode?">
        Lost your password?
      </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import md5 from "js-md5";

export default Vue.extend({
  name: "Login",
  data: function () {
    return {
      email: null,
      password: null,
      password2: null,
      token: null,
      action: "Login",
    };
  },
  created() {
    this.setActionChangePassword();
  },
  methods: {
    /**
     * Set action
     */

    setActionForgotPassword: function () {
      this.action = "ForgotPassword";
    },

    /**
     * Set action
     */
    setActionChangePassword: function () {
      if (
        this.$route.query.action === "ChangePassword" &&
        this.$route.query.token
      ) {
        this.action = "ChangePassword";
        this.token = this.$route.query.token;
      }
    },

    /**
     * Set action
     */
    setActionLogin: function () {
      this.action = "Login";
    },

    /**
     * Send forgot password
     */
    sendForgotPassword: function () {
      //  Validation
      if (!this.email) {
        this.$root.errorResponse("Missing email");
        return;
      }

      //  Body
      let body = "";

      //  Request
      axios
        .post(
          this.$root.apiUrl +
          "/account/ForgotPassword?email=" +
          encodeURIComponent(this.email),
          body,
          {
            headers: {
              "Content-Type": "application/json",
              requestMD5: md5(body),
            },
          }
        )
        .then((response) => {
          this.$root.successResponse(
            "Email sent with reset link. Please check your inbox",
            response
          );
        })
        .catch((error) => {
          this.$root.handleErrorResponse(error);
        });
    },

    /**
     * Send change password
     */
    sendChangePassword: function () {
      //  Validation
      if (!this.password) {
        this.$root.errorResponse("Missing password");
        return;
      } else if (!this.password2) {
        this.$root.errorResponse("Missing password");
        return;
      } else if (this.password !== this.password2) {
        this.$root.errorResponse("Passwords are not the same");
        return;
      }

      //  Body
      let body = JSON.stringify({
        password: this.password,
      });

      //  Request
      axios
        .put(
          this.$root.apiUrl + "/account/ChangePassword?token=" + this.token,
          body,
          {
            headers: {
              "Content-Type": "application/json",
              requestMD5: md5(body),
            },
          }
        )
        .then((response) => {
          this.$root.successResponse("Password changed", response);

          //  Clean up
          this.password2 = null;

          //  Prepare for login
          this.setActionLogin();
        })
        .catch((error) => {
          this.$root.handleErrorResponse(error);
        });
    },

    /**
     * Send login
     */
    sendLogin: function () {
      //  Validation
      if (!this.email) {
        this.$root.errorResponse("Missing email");
        return;
      } else if (!this.password) {
        this.$root.errorResponse("Missing password");
        return;
      }

      //  Body
      let body = JSON.stringify({
        email: this.email,
        password: this.password,
      });

      //  Request
      axios
        .post(this.$root.apiUrl + "/account/Login", body, {
          headers: {
            "Content-Type": "application/json",
            requestMD5: md5(body),
          },
        })
        .then((response) => {
          //  Merchant
          this.$root.merchant = {};
          //  Storage
          this.$root.store.merchants = [];
          this.$root.store.agencies = [];
          this.$root.store.users = [];
          this.$root.store.campaigns = [];
          this.$root.store.workflows = [];
          this.$root.store.sessions = [];
          this.$root.store.sessionPageStorage = [];
          this.$root.store.sessionPageNumber = 1;
          this.$root.store.sessionPageSize = 10;
          this.$root.store.leads = [];
          this.$root.store.leadPageStorage = [];
          this.$root.store.leadPageNumber = 1;
          this.$root.store.leadPageSize = 10;
          this.$root.store.agreements = [];
          this.$root.store.communicationCollections = [];

          //  Register user
          this.$root.user.authToken = response.data.authToken.authToken;
          this.$root.user.agencyId = response.data.userReturnDTO.agencyId;
          this.$root.user.merchantId = response.data.userReturnDTO.merchantId;
          this.$root.user.guid = response.data.authToken.userGuid;
          this.$root.user.role = response.data.userReturnDTO.role;
          this.$root.user.name = response.data.userReturnDTO.name;
          this.$root.user.email = response.data.userReturnDTO.email;
          this.$root.user.externalId = response.data.userReturnDTO.externalId;
          this.$root.user.active = response.data.userReturnDTO.active;
          this.$root.user.scopes = response.data.userReturnDTO.active;

          //  Store authToken in browser session
          window.setMark("authToken", response.data.authToken.authToken);

          //  Set login expiration
          if (
            this.$root.user.role === "AgencyManager" ||
            this.$root.user.role === "SeniorAgent" ||
            this.$root.user.role === "JuniorAgent"
          ) {
            var nowInSeconds = Math.round(new Date().getTime() / 1000);
            var oneDay = 24 * 3600;
            window.setMark("authExp", nowInSeconds + oneDay);
          }

          //  Remember pageSize
          let leadPageSize = window.getMark("leadPageSize");
          if (leadPageSize) {
            this.$root.store.leadPageSize = leadPageSize;
          }
          let sessionPageSize = window.getMark("sessionPageSize");
          if (sessionPageSize) {
            this.$root.store.sessionPageSize = sessionPageSize;
          }

          //  Clean up
          this.password = null;

          //  Emit
          this.$emit("loggedIn");
        })
        .catch((error) => {
          this.$root.handleErrorResponse(error, "Invalid login. Please review");
        });
    },
  },
});
</script>
