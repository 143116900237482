<template>
  <div>
    <!-- Modal -->
    <div v-if="exportRequest" class="modal modal-narrow slideIn">
      <div class="modal-content">
        <!-- Header -->
        <div class="block">
          <div class="block-header">
            <h2 class="title">Export</h2>
            <p class="desc">
              Select a campaign to export the current results. It will be
              downloaded automatically once ready, so please keep the browser
              open until it's finished.
            </p>
          </div>
          <div
            v-if="
              this.$root.store.merchants.length > 0 &&
              Object.keys(this.$root.store.campaignsToExports).length > 0
            "
            class="block-wrap"
          >
            <div class="control-input">
              <select
                id="campaignExportId"
                class="uik-input__select"
                v-model="exportRequest.campaignGuid"
                required
              >
                <option v-bind:value="undefined" disabled>
                  Choose campaign to export
                </option>

                <!-- Loop merchants -->
                <optgroup
                  v-for="merchant in this.$root.store.merchants"
                  v-bind:key="merchant.merchantId"
                  v-bind:label="merchant.name"
                >
                  <!-- Loop merchants' campaigns -->
                  <option
                    v-for="campaignsToExport in $root.store.campaignsToExports[
                      merchant.merchantId
                    ]"
                    v-bind:key="campaignsToExport.campaignGuid"
                    v-bind:value="campaignsToExport.campaignGuid"
                  >
                    {{ campaignsToExport.name }}
                  </option>
                </optgroup>
              </select>
            </div>

            <div class="control-input">
              <button
                type="button"
                class="uik-btn__base btn-primary btn"
                v-on:click="requestExport()"
                v-bind:disabled="this.exportProcessing ? true : false"
              >
                Export
              </button>
            </div>

            <div v-if="this.exportProcessing" class="wave wave-loading">
              <div class="ver1"></div>
              <div class="ver2"></div>
              <div class="ver3"></div>
              <div class="ver4"></div>
              <div class="ver5"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      v-if="exportRequest"
      v-on:click="closeModal()"
      class="of-modal-bg fadeIn"
    ></div>
  </div>
</template>

<script>
import Vue from "vue";
import { merchantHandler } from "../mixins/merchantHandler.js";
import { exportHandler } from "../mixins/exportHandler.js";
import { formats } from "../mixins/formats.js";

export default Vue.extend({
  name: "ExportModal",
  mixins: [merchantHandler, exportHandler, formats],
  props: {
    exportRequest: {
      required: true,
    },
  },
  updated() {
    //  Get merchants
    this.loadMerchants();

    //  Get exports
    this.loadCampaignsToExports();
  },
  methods: {
    /**
     * Close modal
     */
    closeModal: function () {
      this.$emit("closeExportModal");
    },
  },
});
</script>
