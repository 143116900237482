<template>
  <div class="block-wrap sign-up-products">
    <div
      v-bind:class="[
        $root.user.role === 'MerchantAdmin'
          ? 'block'
          : 'block no-pointer-events',
      ]"
    >
      <!-- Header -->
      <div class="block-header">
        <h3>Sign-up products</h3>
      </div>

      <!-- Repeatable fields -->
      <draggable
        v-if="agreementsRetrieved && communicationCollectionsRetrieved"
        v-bind:list="this.signUpProducts"
        v-on:end="updateProducts()"
        ghost-class="being-dragged"
      >
        <div
          v-for="(product, index) in this.signUpProducts"
          v-bind:key="product.uuid"
        >
          <!-- Field open -->
          <div
            class="group-wrap"
            v-bind:class="{
              collapsed:
                $root.user.role === 'MerchantAdmin' ? product.collapsed : false,
            }"
          >
            <div class="group-header">
              <h3 v-if="product.productName">
                {{ getIndex(index) }}: {{ product.productName }}
              </h3>
              <h3 v-else>{{ getIndex(index) }}: Product</h3>
              <button
                v-if="$root.user.role === 'MerchantAdmin'"
                type="button"
                v-on:click="toggleCollapsed(product)"
              >
                <i class="expand-icon"></i>
              </button>
            </div>
            <div class="group-content clearfix">
              <!-- Product name / type -->
              <div class="block">
                <div class="row">
                  <div class="col-sm-7">
                    <!-- Name -->
                    <div class="control-input">
                      <label v-bind:for="'productName' + product.uuid"
                        >Product name</label
                      >
                      <input
                        type="text"
                        v-bind:id="'productName' + product.uuid"
                        class="uik-input__input"
                        placeholder="Please define a product name, visible to agents only"
                        required
                        v-model="product.productName"
                      />
                    </div>
                  </div>

                  <div class="col-sm-5">
                    <!-- Payment Type -->
                    <div class="control-input">
                      <label>Payment type</label>
                    </div>
                    <ul class="radio-group horizontal">
                      <li>
                        <label v-bind:for="'paymentTypeSingle' + product.uuid">
                          <input
                            type="radio"
                            v-bind:id="'paymentTypeSingle' + product.uuid"
                            v-model="product.paymentType"
                            v-bind:value="'Single'"
                          />Single
                          <div class="check"></div>
                        </label>
                      </li>
                      <li>
                        <label
                          v-bind:for="'paymentTypeReccuring' + product.uuid"
                        >
                          <input
                            type="radio"
                            v-bind:id="'paymentTypeReccuring' + product.uuid"
                            v-model="product.paymentType"
                            v-bind:value="'Recurring'"
                          />Recurring
                          <div class="check"></div>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Columns -->
              <div class="row">
                <div class="col-sm-7">
                  <!-- Agreement -->
                  <div v-if="product.paymentType === 'Recurring'" class="block">
                    <!-- Agreement Type -->
                    <div class="control-input">
                      <label v-bind:for="'agreementType' + product.uuid"
                        >Agreement type</label
                      >
                      <select
                        v-bind:id="'agreementType' + product.uuid"
                        class="uik-input__select"
                        required
                        v-model="product.agreementType"
                        v-on:change="onAgreementTypeChange(product)"
                      >
                        <option v-bind:value="null" disabled>
                          Define agreement
                        </option>
                        <optgroup label="Personal agreement">
                          <option v-bind:value="'Personal'">Create new</option>
                        </optgroup>
                        <optgroup
                          v-if="agreements.length > 0"
                          label="Existing shared agreements"
                        >
                          <option
                            v-for="agreement in agreements"
                            v-bind:value="agreement.agreementGuid"
                            v-bind:key="agreement.agreementGuid"
                          >
                            {{ agreement.name
                            }}{{
                              agreement.description
                                ? " - " + agreement.description
                                : ""
                            }}
                          </option>
                        </optgroup>
                      </select>
                    </div>

                    <!-- Agreement Name -->
                    <div
                      v-if="product.agreementType === 'Personal'"
                      class="control-input"
                    >
                      <label v-bind:for="'agreementName' + product.uuid"
                        >Personal agreement name</label
                      >
                      <input
                        type="text"
                        v-bind:id="'agreementName' + product.uuid"
                        class="uik-input__input"
                        placeholder="Please define a meaningful name, visible to the Lead"
                        maxlength="30"
                        required
                        v-model="product.agreementName"
                      />
                    </div>

                    <!-- Agreement Description -->
                    <div
                      v-if="product.agreementType === 'Personal'"
                      class="control-input"
                    >
                      <label v-bind:for="'agreementDescription' + product.uuid"
                        >Personal agreement description</label
                      >
                      <input
                        type="text"
                        v-bind:id="'agreementDescription' + product.uuid"
                        class="uik-input__input"
                        placeholder="Please define a meaningful description, visible to the Lead"
                        maxlength="60"
                        v-model="product.agreementDescription"
                      />
                    </div>
                  </div>

                  <!-- Agreement details -->
                  <div
                    v-if="
                      product.paymentType === 'Recurring' &&
                      product.agreementType !== 'Personal' &&
                      product.agreementType !== null
                    "
                    class="block"
                  >
                    <agreement-review-table v-bind:product="product" />
                  </div>

                  <!-- Amount / VAT percentage -->
                  <div
                    v-else-if="
                      product.paymentType === 'Single' ||
                      (product.paymentType === 'Recurring' &&
                        product.agreementType === 'Personal')
                    "
                    class="block"
                  >
                    <div class="row">
                      <div class="col-sm-3">
                        <!-- Amount -->
                        <div class="control-input">
                          <label
                            v-if="product.paymentType === 'Single'"
                            v-bind:for="'amount' + product.uuid"
                          >
                            Amount in {{ product.currencyCode }}
                          </label>
                          <label v-else v-bind:for="'amount' + product.uuid">
                            Recurring amount in {{ product.currencyCode }}
                          </label>
                          <input
                            type="number"
                            v-bind:id="'amount' + product.uuid"
                            class="uik-input__input"
                            placeholder="Total amount, incl. VAT"
                            v-model="product.amountTotal"
                          />
                          <small class="input-helper-text"
                            >Leave empty for agents to define</small
                          >
                        </div>
                      </div>
                      <div
                        v-if="
                          product.paymentType === 'Recurring' &&
                          product.scheduleStartWithOneOff
                        "
                        class="col-sm-3"
                      >
                        <!-- OneOff Amount -->
                        <div class="control-input">
                          <label v-bind:for="'oneOffAmount' + product.uuid"
                            >Instant amount in {{ product.currencyCode }}</label
                          >
                          <input
                            type="number"
                            v-bind:id="'oneOffAmount' + product.uuid"
                            class="uik-input__input"
                            placeholder="Amount, incl. VAT"
                            v-model="product.oneOffAmount"
                          />
                          <small class="input-helper-text"
                            >Leave empty for agents to define</small
                          >
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <!-- Currency Code -->
                        <div class="control-input">
                          <label v-bind:for="'currencyCode' + product.uuid"
                            >Currency</label
                          >
                          <select
                            class="uik-input__select"
                            v-bind:id="'currencyCode' + product.uuid"
                            required
                            v-model="product.currencyCode"
                          >
                            <option v-bind:value="'DKK'">DKK</option>
                            <option v-bind:value="'ISK'">ISK</option>
                            <option v-bind:value="'SEK'">SEK</option>
                            <option v-bind:value="'NOK'">NOK</option>
                            <option v-bind:value="'EUR'">EUR</option>
                            <option v-bind:value="'GBP'">GBP</option>
                            <option v-bind:value="'USD'">USD</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <!-- VAT Percentage -->
                        <div class="control-input">
                          <label v-bind:for="'vatPercentage' + product.uuid"
                            >VAT %</label
                          >
                          <input
                            type="number"
                            v-bind:id="'vatPercentage' + product.uuid"
                            class="uik-input__input"
                            placeholder="% in digits"
                            v-model="product.vatPercentage"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Communication / Purpose -->
                  <div
                    v-if="
                      product.paymentType === 'Single' ||
                      (product.paymentType === 'Recurring' &&
                        product.agreementType === 'Personal')
                    "
                    class="block"
                  >
                    <div class="row">
                      <div class="col-sm-6">
                        <!-- PurposeAccountingCode -->
                        <div class="control-input">
                          <label
                            v-bind:for="'purposeAccountingCode' + product.uuid"
                            >Purpose accounting code</label
                          >
                          <input
                            type="text"
                            class="uik-input__input"
                            v-bind:id="'purposeAccountingCode' + product.uuid"
                            placeholder="Optional"
                            maxlength="32"
                            v-model="product.purposeAccountingCode"
                          />
                        </div>
                      </div>

                      <div class="col-sm-6">
                        <!-- CommunicationCollectionGuid -->
                        <div class="control-input">
                          <label
                            v-bind:for="
                              'communicationCollectionGuid' + product.uuid
                            "
                            >Communication</label
                          >
                          <div v-if="communicationCollections.length > 0">
                            <select
                              class="uik-input__select"
                              v-bind:id="
                                'communicationCollectionGuid' + product.uuid
                              "
                              required
                              v-model="product.communicationCollectionGuid"
                            >
                              <option
                                v-if="
                                  product.communicationCollectionGuid ===
                                  undefined
                                "
                                v-bind:value="undefined"
                                disabled
                              >
                                Choose communication
                              </option>
                              <option v-else v-bind:value="null" disabled>
                                Choose communication
                              </option>
                              <option
                                v-for="communicationCollection in communicationCollections"
                                v-bind:value="
                                  communicationCollection.communicationCollectionGuid
                                "
                                v-bind:key="
                                  communicationCollection.communicationCollectionGuid
                                "
                                v-bind:disabled="
                                  communicationCollection.communicationCollectionGuid ===
                                  null
                                    ? true
                                    : false
                                "
                              >
                                {{ communicationCollection.collectionName }}
                              </option>
                            </select>
                          </div>
                          <div v-else>
                            <select
                              class="uik-input__select"
                              v-bind:id="
                                'communicationCollectionGuid' + product.uuid
                              "
                            >
                              <option value="" disabled selected>
                                Loading ...
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Schedule -->
                  <workflow-modal-schedule
                    v-bind:product="product"
                    v-bind:signUpProducts="signUpProducts"
                  />
                </div>
                <div class="vr-split"></div>
                <div class="col-sm-5">
                  <!-- ScheduleStartType -->
                  <workflow-modal-schedule-start-types
                    v-bind:product="product"
                  />

                  <!-- Payment settings -->
                  <workflow-modal-payment-settings v-bind:product="product" />

                  <!-- Payment Method Types -->
                  <workflow-modal-payment-method-types
                    v-bind:product="product"
                  />
                </div>
              </div>
            </div>

            <!-- Remove -->
            <div class="group-footer">
              <div class="right-aligned">
                <button
                  v-if="$root.user.role === 'MerchantAdmin'"
                  type="button"
                  class="uik-btn__base btn btn-cancel"
                  v-on:click="removeProduct(product.uuid)"
                >
                  Remove product
                </button>
              </div>
            </div>
          </div>
          <!-- Field end -->
        </div>
      </draggable>

      <!-- Footer -->
      <div v-if="$root.user.role === 'MerchantAdmin'" class="block-footer">
        <div class="center-aligned">
          <button
            type="button"
            class="uik-btn__base btn btn-primary"
            v-on:click="addProduct()"
          >
            Add product
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import WorkflowModalSchedule from "./WorkflowModalSchedule";
import WorkflowModalScheduleStartTypes from "./WorkflowModalScheduleStartTypes";
import WorkflowModalPaymentMethodTypes from "./WorkflowModalPaymentMethodTypes";
import WorkflowModalPaymentSettings from "./WorkflowModalPaymentSettings";
import AgreementReviewTable from "../AgreementReviewTable";
import Draggable from "vuedraggable";
import { relayHandler } from "../../mixins/relayHandler.js";
import { changeEvents } from "../../mixins/changeEvents.js";
import { formats } from "../../mixins/formats.js";

let uuid = 0;

export default Vue.extend({
  name: "SignUpProducts",
  mixins: [relayHandler, changeEvents, formats],
  components: {
    WorkflowModalSchedule,
    WorkflowModalScheduleStartTypes,
    WorkflowModalPaymentMethodTypes,
    WorkflowModalPaymentSettings,
    AgreementReviewTable,
    Draggable,
  },
  props: {
    workflow: {
      type: Object,
      required: false,
    },
  },
  data: function () {
    return {
      signUpProducts: [],
      agreementsRetrieved: false,
      agreements: [],
      communicationCollectionsRetrieved: false,
      communicationCollections: [],
    };
  },
  created() {
    //  Populate signUpProducts
    this.signUpProducts = this.workflow.signUpProducts;

    //  Update uuid
    if (this.signUpProducts) {
      var i;
      for (i = uuid; i < this.signUpProducts.length; i++) {
        //  Bump by greater uuid
        if (this.signUpProducts[i].uuid > uuid) {
          uuid = this.signUpProducts[i].uuid;
        }
        uuid++;
      }
    }

    //  Get entities
    this.extractAgreements();
    this.extractCommunications();
  },
  methods: {
    /**
     * Get index
     * @param {int} index
     */
    getIndex: function (index) {
      return index + 1;
    },

    /**
     * Get agreements
     */
    extractAgreements: function () {
      let agreementHandler = this.getAgreements();
      if (typeof agreementHandler.then !== "undefined") {
        agreementHandler.then((response) => {
          if (!response.agreementsErrored) {
            this.agreements = this.$root.store.agreements;
          }
          this.agreementsRetrieved = true;
        });
      } else {
        this.agreements = this.$root.store.agreements;
        this.agreementsRetrieved = true;
      }
    },

    /**
     * Get communicationCollections
     */
    extractCommunications: function () {
      let communicationHandler = this.getCommunications();
      if (typeof communicationHandler.then !== "undefined") {
        communicationHandler.then((response) => {
          if (!response.communicationsErrored) {
            this.communicationCollections =
              this.$root.store.communicationCollections;
          }
          this.communicationCollectionsRetrieved = true;
        });
      } else {
        this.communicationCollections =
          this.$root.store.communicationCollections;
        this.communicationCollectionsRetrieved = true;
      }
    },

    /**
     * Append product
     */
    addProduct: function () {
      var product = {
        uuid: uuid++,
        collapsed: false,
        productName: null,
        paymentType: null,
        paymentMethodTypeTest: false,
        paymentMethodTypeCard: false,
        paymentMethodTypeMobilePay: false,
        paymentMethodTypeBetalingsservice: false,
        paymentMethodTypeGiro: false,
        paymentMethodTypeInvoice: false,
        paymentMethodTypeEAN: false,
        paymentMethodTypeDirectDebit: false,
        agreementType: null,
        agreementGuid: null,
        agreementName: null,
        agreementDescription: null,
        amountTotal: null,
        vatPercentage: null,
        currencyCode: "DKK", // initial
        purposeAccountingCode: null,
        taxDeductable: false,
        paymentRequired: false,
        communicationCollectionGuid: null,
        scheduleStartTypeNow: null,
        scheduleStartTypeFirst: null,
        scheduleStartTypeCustom: null,
        scheduleStartCustomDate: null,
        scheduleEveryOther: null,
        scheduleCalendarUnit: null,
        scheduleFixedDay: null,
        scheduleBaseTier: null,
        scheduleType: null,
        scheduleStartWithOneOff: null,
        oneOffAmount: null,
        scheduleExpires: null,
        expiresAfterDate: {
          startDate: null,
          endDate: null,
        },
      };

      if (!this.signUpProducts) {
        this.signUpProducts = [product];
      } else {
        this.signUpProducts.push(product);
      }

      this.updateProducts();
    },

    /**
     * Update list of products
     */
    updateProducts: function () {
      this.workflow.signUpProducts = this.signUpProducts;
    },

    /**
     * Remove product
     * @param {int} uuid
     */
    removeProduct: function (uuid) {
      if (this.signUpProducts) {
        var i;
        for (i = 0; i < this.signUpProducts.length; i++) {
          if (this.signUpProducts[i].uuid === uuid) {
            this.signUpProducts.splice(i, 1);
          }
        }
      }

      this.updateProducts();
    },

    /**
     * Toggle collapsed / expand
     * @param {object} product
     */
    toggleCollapsed: function (product) {
      if (product.collapsed) {
        product.collapsed = false;
      } else {
        product.collapsed = true;
      }
    },
  },
});
</script>
