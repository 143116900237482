<template>
  <div
    v-if="product.agreementType !== 'Shared'"
    class="next-due-date-selection"
  >
    <div class="control-input">
      <label> Next due date </label>
      <div class="row">
        <div class="col-sm-12">
          <div class="input-wrap">
            <date-range-picker
              ref="datePicker"
              v-bind:singleDatePicker="true"
              v-bind:timePicker="false"
              v-bind:timePicker24Hour="false"
              v-bind:showWeekNumbers="false"
              v-bind:showDropdowns="true"
              v-bind:autoApply="true"
              v-bind:ranges="false"
              v-bind:linkedCalendars="false"
              v-bind:always-show-calendars="true"
              v-bind:opens="'left'"
              v-bind:locale-data="{
                firstDay: 1,
                format: 'YYYY-MM-DD',
                separator: ' - ',
              }"
              v-model="datePicked"
              v-on:update="whenDatePicked()"
            >
              <template v-slot:input="datePicker">
                <span
                  v-bind:inner-html.prop="datePicker | formatDatePicker"
                ></span>
                <button type="button" class="btn btn-primary icon-btn">
                  <i class="dashboard-icon icon-calendar"></i>
                </button>
              </template>
            </date-range-picker>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { formats } from "../../mixins/formats.js";
import { sessionRequestGatewayHandler } from "../../mixins/sessionRequestGatewayHandler.js";

export default Vue.extend({
  name: "OnboardingScheduleNextDueDate",
  mixins: [formats, sessionRequestGatewayHandler],
  components: {
    DateRangePicker,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    sessionRequest: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      datePicked: {
        startDate: null,
        endDate: null,
      },
    };
  },
  created() {
    //  Set nextDueDate for date picker
    if (this.$route.query.pre_nextDueDate) {
      this.datePicked = {
        startDate: this.$route.query.pre_nextDueDate,
        endDate: null,
      };
    } else if (this.sessionRequest.subscriptionData.nextDueDate) {
      this.datePicked = {
        startDate: this.sessionRequest.subscriptionData.nextDueDate.substring(
          0,
          10
        ),
        endDate: null,
      };
    }

    this.calculateNextDueDate();
  },
  methods: {
    /**
     * Refresh display of next due date
     */
    whenScheduleChanged: function () {
      this.calculateNextDueDate();
    },

    /**
     * Transfer selected date to next due date
     */
    whenDatePicked: function () {
      this.calculateNextDueDate();
    },

    calculateNextDueDate: function () {
      let updateDatePicked = false;

      if (this.datePicked.startDate) {
        let today = new Date();
        let nextDueDateTime = new Date(this.datePicked.startDate);

        //  Detect invalid scheduleFixedDay
        if (
          this.product.scheduleCalendarUnit === "Month" &&
          nextDueDateTime.getDate() > 28
        ) {
          nextDueDateTime.setDate(28);
          updateDatePicked = true;
          this.$root.infoResponse(
            "Only dates 1-28 are allowed. Next due date have been adjusted."
          );
        }

        //  Detect invalid past date
        if (today > nextDueDateTime) {
          while (today > nextDueDateTime) {
            nextDueDateTime.setMonth(nextDueDateTime.getMonth() + 1, 1);
          }
          updateDatePicked = true;
          this.$root.infoResponse(
            "Past dates are not allowed. Next due date have been adjusted."
          );
        }

        //  Detect invalid date by gateway limitations
        if (this.sessionRequest.subscriptionData.paymentMethodType) {
          let nextPossibleDueDateTimeByGateway = new Date(
            this.getMinScheduleStartCustomDate(
              this.sessionRequest.subscriptionData.paymentMethodType
            )
          );
          if (nextPossibleDueDateTimeByGateway > nextDueDateTime) {
            while (nextPossibleDueDateTimeByGateway > nextDueDateTime) {
              nextDueDateTime.setMonth(nextDueDateTime.getMonth() + 1, 1);
            }
            updateDatePicked = true;
            this.$root.infoResponse(
              "It is not possible to charge on the chosen date. Next due date have been adjusted."
            );
          }
        }

        this.product.scheduleBaseTier = parseInt(
          nextDueDateTime.getMonth() + 1
        );
        this.product.scheduleFixedDay = parseInt(nextDueDateTime.getDate());

        this.product.nextDueDate = this.formatDateToYMD(nextDueDateTime);

        //  Adjust date picked
        if (updateDatePicked) {
          this.datePicked = {
            startDate: new Date(nextDueDateTime),
            endDate: new Date(nextDueDateTime),
          };
        }

        //  Update display of "Next planned charge"
        this.sessionRequest.subscriptionData.nextDueDate =
          this.product.nextDueDate;
      }
    },
  },
});
</script>
