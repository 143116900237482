import axios from "axios";
import FileSaver from "file-saver";

export const exportHandler = {
    data: function() {
        return {
            exportProcessing: false,
        };
    },
    methods: {

        /**
         * Load nested merchants and campaigns from storage
         */
        loadCampaignsToExports: function() {

            //  Check storage
            if (Object.keys(this.$root.store.campaignsToExports).length > 0) {
                // window.console.log("exports get list from storage");

                return {
                    exportsErrored: false,
                    exports: this.$root.store.campaignsToExports
                };
            }

            //  Fetch to storage
            else {
                return this.fetchCampaignsToExports();
            }
        },

        /**
         * Get nested merchants and campaigns from storage from API
         */
        fetchCampaignsToExports: function() {

            if (Object.keys(this.$root.store.campaignsToExports).length === 0) {

                //  Request
                axios
                    .get(this.$root.apiUrl + "/agency/campaigns", {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken,
                        }
                    })
                    .then(response => {

                        //  Build storage
                        this.$root.store.campaignsToExports = response.data;

                        return {
                            exportsErrored: false,
                            exports: this.$root.store.campaignsToExports
                        };
                    })
                    .catch(error => {

                        this.$root.handleErrorResponse(error);

                        return {
                            exportsErrored: true,
                            exports: {}
                        };
                    });
            }
        },

        /**
         * Request export
         */
        requestExport: function() {

            //  Verify
            if (this.exportProcessing) {
                this.$root.errorResponse(
                    "Please wait while export is being processed"
                );
                return;
            }

            //  Validation
            else if (!this.exportRequest.campaignGuid) {
                this.$root.errorResponse(
                    "Please select a campaign from the list"
                );
                return;
            }

            //  Request
            axios
                .post(
                    this.$root.apiUrl +
                    "/export/" +
                    this.exportRequest.campaignGuid,
                    null, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken,
                        },
                    }
                )
                .then((response) => {

                    this.$root.store.campaignExportToDownload = response.data;

                    this.checkExportRequestContinuously();

                    //  Set processing
                    this.exportProcessing = true;
                })
                .catch((error) => {
                    this.$root.handleErrorResponse(error);
                });
        },

        /**
         * Check export request every 2.5 sec.
         */
        checkExportRequestContinuously: function() {

            let checkingExportRequest = setInterval(
                function() {

                    //  Request
                    axios
                        .get(this.$root.apiUrl + "/export/exportrequest/" + this.$root.store.campaignExportToDownload, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: this.$root.user.authToken,
                            }
                        })
                        .then(response => {

                            if (response.data.state === 'PROCESSED') {

                                this.downloadExportRequest(response.data.exportGuid);

                                clearInterval(checkingExportRequest);
                            }
                        })
                        .catch(error => {

                            this.$root.handleErrorResponse(error);
                        });

                }.bind(this),
                2500
            );
        },

        /**
         * Download export
         */
        downloadExportRequest: function(exportGuid) {

            //  Request
            axios
                .get(this.$root.apiUrl + "/exportdownload/" + exportGuid, {
                    headers: {
                        Authorization: this.$root.user.authToken,
                    },
                    responseType: 'blob',
                    timeout: 30000
                })
                .then(response => {

                    var file = new Blob([response.data], {
                        type: response.headers['content-type']
                    });
                    FileSaver.saveAs(file, this.getFileNameFromDisposition(response.headers['content-disposition']));

                    //  Reset processing
                    this.exportProcessing = false;
                })
                .catch(error => {

                    this.$root.handleErrorResponse(error);
                });
        },

        /**
         * Get filename from content-disposition
         * @param {string} disposition 
         */
        getFileNameFromDisposition: function(disposition) {

            if (disposition && disposition.indexOf('inline') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    return matches[1].replace(/['"]/g, '');
                }
            }

            return null;
        }
    }
};