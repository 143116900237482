<template>
  <div class="block-wrap contact-data-fields">   
    <div
      v-bind:class="[
        $root.user.role === 'MerchantAdmin'
          ? 'block'
          : 'block no-pointer-events',
      ]"
    >
      <!-- Header -->
      <div class="block-header">
        <h3>Contact information</h3>
        <p>Create fields for contact information gathered in onboarding</p>
      </div>
      <div class="field-group-wrapper">
        <!-- Repeatable heading -->
        <div class="row field-group-header">
          <div class="col-sm-4">Field type</div>
          <div class="col-sm-4">Label</div>
          <div class="col-sm-2">Configuration</div>
        </div>

        <!-- Repeatable fields -->
        <draggable
          v-bind:list="this.contactDataFields"
          v-on:end="updateContactFields()"
          ghost-class="being-dragged"
        >
          <div
            v-for="contactField in this.contactDataFields"
            v-bind:key="contactField.uuid"
          >
            <!-- Field open -->
            <div class="row field-group inline-add-remove">
              <i class="icon-draggable"></i>
              <div class="col-sm-4 control-input">
                <select
                  class="uik-input__select"
                  v-model="contactField.name"
                  required
                >
                  <option
                    v-for="contactFieldType in $root.helpers.contactFieldTypes"
                    v-bind:value="contactFieldType.name"
                    v-bind:key="contactFieldType.name"
                    v-bind:disabled="
                      contactFieldType.name === '' ? true : false
                    "
                  >
                    {{ contactFieldType.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-4 control-input">
                <input
                  type="text"
                  class="uik-input__input"
                  placeholder="Label"
                  v-model="contactField.label"
                />
              </div>
              <div class="col-sm-3 control-input">
                <ul class="checkbox-group horizontal">
                  <li>
                    <div class="control-input">
                      <label>
                        <input
                          type="checkbox"
                          v-model="contactField.required"
                        />
                        Require
                        <div class="check"></div>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>

              <!-- Remove field -->
              <div class="col-sm-1 control-input">
                <button
                  v-if="$root.user.role === 'MerchantAdmin'"
                  type="button"
                  class="uik-btn__base btn btn-remove"
                  v-on:click="removeContactField(contactField.uuid)"
                >
                  Remove
                </button>
              </div>
            </div>
            <!-- Field end -->
          </div>
        </draggable>

        <!-- Add field open -->
        <div class="row field-group inline-add-remove disabled">
          <i class="icon-draggable"></i>
          <div class="col-sm-4 control-input">
            <input type="text" class="uik-input__input" disabled />
          </div>
          <div class="col-sm-4 control-input">
            <input type="text" class="uik-input__input" disabled />
          </div>
          <div class="col-sm-3 control-input">
            <ul class="checkbox-group horizontal">
              <li>
                <div class="control-input">
                  <label>
                    <input type="checkbox" disabled />
                    Require
                    <div class="check"></div>
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-sm-1 control-input">
            <button
              v-if="$root.user.role === 'MerchantAdmin'"
              type="button"
              class="uik-btn__base btn-primary btn btn-add"
              v-on:click="addcontactField()"
            >
              Add
            </button>
          </div>
        </div>
      </div>
      <!-- Add field end -->
    </div>
    <div v-bind:class="[
              this.$root.user.role === 'MerchantAdmin'
                ? 'block' 
                : 'block no-pointer-events',
            ]">
      <div class="row">
        <div class="col-sm-6">
          <div class="control-input">
            <label for="countryCode">Validate contact details by country</label>

            <tool-tip v-if="this.workflow.serviceType == 'SignUp'"
              v-bind:tooltip="'The fields phone, national ID, business number, postcode and validated address will be validated based on the selected country if possible. If none is selected, Denmark will be applied by default'"
            />

            <tool-tip v-else-if="this.workflow.serviceType == 'Upgrade'"
              v-bind:tooltip="'The fields phone, national ID, business number, postcode and validated address will be validated based on the selected country if possible. If none is selected, Denmark will be applied by default. If the incoming contact has a country assigned. That country will take priority over any configuration here when validating the fields'"
            />

            <select
              class="uik-input__select"
              id="countryCode"
              v-model="workflow.countryCode"
              required
            >
              <option
                v-if="workflow.countryCode === undefined"
                v-bind:value="undefined"
              >
                None selected
              </option>
              <option v-else v-bind:value="null">
                None selected
              </option>
              <option
                v-for="countryCode in $root.helpers
                  .countryCodes"
                v-bind:value="countryCode.value"
                v-bind:key="countryCode.value"
              >
                {{ countryCode.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
import Vue from "vue";
import Draggable from "vuedraggable";
import ToolTip from "../ToolTip";

let uuid = 0;

export default Vue.extend({
  name: "ContactFields",
  components: {
    Draggable,
    ToolTip,
  },
  props: {
    workflow: {
      type: Object,
      required: false,
    },
  },
  data: function () {
    return {
      contactDataFields: null,
    };
  },
  created() {
    //  Populate signUpProducts
    this.contactDataFields = this.workflow.contactDataFields;

    //  Update uuid
    if (this.contactDataFields) {
      var i;
      for (i = uuid; i < this.contactDataFields.length; i++) {
        //  Bump by greater uuid
        if (this.contactDataFields[i].uuid > uuid) {
          uuid = this.contactDataFields[i].uuid;
        }
        uuid++;
      }
    }
  },
  methods: {
    /**
     * Append field
     */
    addcontactField: function () {
      var contactDataField = {
        uuid: uuid++,
        name: "",
        label: "",
        required: false,
        hiddenToEndUser: false,
      };

      if (!this.contactDataFields) {
        this.contactDataFields = [contactDataField];
      } else {
        this.contactDataFields.push(contactDataField);
      }

      this.updateContactFields();
    },

    /**
     * Update list of fields
     */
    updateContactFields: function () {
      this.workflow.contactDataFields = this.contactDataFields;
    },

    /**
     * Remove field
     * @param {int} uuid
     */
    removeContactField: function (uuid) {
      if (this.contactDataFields) {
        var i;
        for (i = 0; i < this.contactDataFields.length; i++) {
          if (this.contactDataFields[i].uuid === uuid) {
            this.contactDataFields.splice(i, 1);
          }
        }
      }

      this.updateContactFields();
    },
  },
});
</script>
