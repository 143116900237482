import axios from "axios";

export const agencyHandler = {
    methods: {

        /**
         * Get agencies from storage
         */
        loadAgencies: function() {

            //  Check storage
            if (this.$root.store.agencies.length > 0) {
                // window.console.log("agencies get list from storage");

                return {
                    agenciesErrored: false,
                    agencies: this.$root.store.agencies,
                };
            }

            //  Fetch to storage
            else {
                return this.fetchAgencies();
            }
        },

        /**
         * Get agencies from API
         */
        fetchAgencies: function() {

            // window.console.log("agencies get list from API");

            //  Request
            return axios
                .get(this.$root.apiUrl +
                    "/agencies?merchantId=" +
                    encodeURIComponent(this.$root.user.merchantId), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken
                        }
                    })
                .then(response => {
                    //  Build storage
                    this.$root.store.agencies = response.data;

                    return {
                        agenciesErrored: false,
                        agencies: this.$root.store.agencies
                    };
                })
                .catch(error => {

                    this.$root.handleErrorResponse(error);

                    return {
                        agenciesErrored: true,
                        agencies: []
                    };
                });
        }
    }
};