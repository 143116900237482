<template>
  <div>
    <!-- Schedule -->
    <div
      v-if="
        product.paymentType === 'Recurring' &&
        product.agreementType === 'Personal'
      "
      class="block"
    >
      <div class="row">
        <div class="col-sm-6">
          <!-- ScheduleType -->
          <div
            class="control-input"
            v-bind:class="{
              'no-pointer-events': product.hasScheduleSelectedSet,
            }"
          >
            <label v-bind:for="'scheduleType' + product.uuid">Schedule</label>
            <select
              class="uik-input__select"
              v-bind:id="'scheduleType' + product.uuid"
              v-model="product.scheduleType"
              v-on:change="onScheduleTypeChange(product)"
            >
              <option
                v-if="product.scheduleType === undefined"
                v-bind:value="undefined"
              >
                Schedule
              </option>
              <option v-else v-bind:value="null">Schedule</option>
              <option
                v-for="scheduleType in $root.helpers.scheduleTypes"
                v-bind:value="scheduleType.value"
                v-bind:key="scheduleType.value"
                v-bind:disabled="scheduleType.value === null ? true : false"
              >
                {{ scheduleType.label }}
              </option>
            </select>
            <small class="input-helper-text"
              >Leave empty for agents to define</small
            >
          </div>

          <!-- ScheduleType - scheduleSelectSet -->
          <div v-if="product.scheduleType === 'Monthly'" class="control-input">
            <ul class="checkbox-group vertical">
              <!-- hasScheduleSelectedSet -->
              <li>
                <label v-bind:for="'hasScheduleSelectedSet' + product.uuid">
                  <input
                    type="checkbox"
                    v-bind:id="'hasScheduleSelectedSet' + product.uuid"
                    v-model="product.hasScheduleSelectedSet"
                  />Only charge on specific months of the year
                  <div class="check"></div>
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-sm-6">
          <!-- Schedule customisation by scheduleSelectSet -->
          <schedule-selected-set
            v-if="
              product.scheduleType === 'Monthly' &&
              product.hasScheduleSelectedSet
            "
            v-bind:product="this.product"
          />

          <!-- Schedule customisation by schedule elements -->
          <div v-else class="control-input">
            <label>Repeat every</label>
            <div class="input-wrap">
              <select
                class="uik-input__select"
                v-bind:id="'scheduleEveryOther' + product.uuid"
                v-model="product.scheduleEveryOther"
                v-on:change="onScheduleEveryOtherChange(product)"
              >
                <option
                  v-if="product.scheduleEveryOther === undefined"
                  v-bind:value="undefined"
                >
                  Repeat
                </option>
                <option v-else v-bind:value="null">Repeat</option>
                <option
                  v-for="scheduleEveryOther in $root.helpers
                    .scheduleEveryOthers"
                  v-bind:value="scheduleEveryOther.value"
                  v-bind:key="scheduleEveryOther.value"
                  v-bind:disabled="
                    scheduleEveryOther.value === null ? true : false
                  "
                >
                  {{ scheduleEveryOther.label }}
                </option>
              </select>
              <select
                class="uik-input__select"
                v-bind:id="'scheduleCalendarUnit' + product.uuid"
                v-model="product.scheduleCalendarUnit"
                v-on:change="onScheduleCalendarUnitChange(product)"
              >
                <option
                  v-if="product.scheduleCalendarUnit === undefined"
                  v-bind:value="undefined"
                >
                  Unit
                </option>
                <option v-else v-bind:value="null">Unit</option>
                <option
                  v-for="scheduleCalendarUnit in $root.helpers
                    .scheduleCalendarUnits"
                  v-bind:value="scheduleCalendarUnit.value"
                  v-bind:key="scheduleCalendarUnit.value"
                  v-bind:disabled="
                    scheduleCalendarUnit.value === null ? true : false
                  "
                >
                  {{ scheduleCalendarUnit.label }}
                </option>
              </select>
            </div>
          </div>

          <!-- Schedule customisation by schedule elements -->
          <div
            v-if="
              !product.hasScheduleSelectedSet &&
              product.scheduleType !== 'Weekly' &&
              product.scheduleCalendarUnit !== 'Week'
            "
            class="control-input"
          >
            <label>Repeat based on</label>
            <div class="input-wrap">
              <select
                class="uik-input__select"
                v-bind:id="'scheduleFixedDay' + product.uuid"
                v-model="product.scheduleFixedDay"
              >
                <option
                  v-if="product.scheduleFixedDay === undefined"
                  v-bind:value="undefined"
                >
                  Day of month
                </option>
                <option v-else v-bind:value="null">Day of month</option>
                <option
                  v-for="scheduleFixedDay in $root.helpers.scheduleFixedDays"
                  v-bind:value="scheduleFixedDay.value"
                  v-bind:key="scheduleFixedDay.value"
                >
                  {{ scheduleFixedDay.label }}
                </option>
              </select>
              <select
                class="uik-input__select"
                v-bind:id="'scheduleBaseTier' + product.uuid"
                v-model="product.scheduleBaseTier"
              >
                <option
                  v-if="product.scheduleBaseTier === undefined"
                  v-bind:value="undefined"
                >
                  Month
                </option>
                <option v-else v-bind:value="null">Month</option>
                <option
                  v-for="scheduleBaseTier in $root.helpers.scheduleBaseTiers"
                  v-bind:value="scheduleBaseTier.value"
                  v-bind:key="scheduleBaseTier.value"
                >
                  {{ scheduleBaseTier.label }}
                </option>
              </select>
            </div>
            <small class="input-helper-text"
              >Leave empty for agents to define</small
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Expires after date -->
    <div
      v-if="product.paymentType === 'Recurring' && product.scheduleExpires"
      class="block"
    >
      <div class="control-input">
        <label v-bind:for="'scheduleType' + product.uuid"
          >Expiration date</label
        >
        <div class="row">
          <div class="col-sm-6">
            <div class="input-wrap">
              <date-range-picker
                ref="datePicker"
                v-bind:singleDatePicker="true"
                v-bind:timePicker="false"
                v-bind:timePicker24Hour="false"
                v-bind:showWeekNumbers="false"
                v-bind:showDropdowns="true"
                v-bind:autoApply="true"
                v-bind:ranges="false"
                v-bind:linkedCalendars="false"
                v-bind:always-show-calendars="true"
                v-bind:opens="'left'"
                v-bind:locale-data="{
                  firstDay: 1,
                  format: 'YYYY-MM-DD',
                  separator: ' - ',
                }"
                v-model="product.expiresAfterDate"
              >
                <template v-slot:input="datePicker">
                  <span
                    v-bind:inner-html.prop="datePicker | formatDatePicker"
                  ></span>
                  <button type="button" class="btn btn-primary icon-btn">
                    <i class="dashboard-icon icon-calendar"></i>
                  </button>
                </template>
              </date-range-picker>
            </div>
            <small class="input-helper-text"
              >Leave empty for agents to define</small
            >
          </div>
          <div class="col-sm-6">
            <div class="control-input">
              <button
                type="button"
                class="uik-btn__base btn"
                v-on:click="clearDateRange(product)"
              >
                Clear date
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ScheduleSelectedSet from "./WorkflowModalScheduleSelectedSet.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { changeEvents } from "../../mixins/changeEvents.js";
import { formats } from "../../mixins/formats.js";

export default Vue.extend({
  name: "WorkflowModalSchedule",
  mixins: [changeEvents, formats],
  components: {
    ScheduleSelectedSet,
    DateRangePicker,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    signUpProducts: {
      type: Array,
      required: true,
    },
  },
  created() {
    /**
     * Initialise date range picker by transfering and formatting dates
     */
    if (this.signUpProducts) {
      var o;
      for (o = 0; o < this.signUpProducts.length; o++) {
        if (this.signUpProducts[o].expiresAfterDate) {
          //  Keep as defined
        } else {
          this.signUpProducts[o].expiresAfterDate = {
            startDate: null,
            endDate: null,
          };
        }
      }
    }
  },
  methods: {
    /**
     * Clear date definition
     * @param {object} product
     */
    clearDateRange: function (product) {
      product.expiresAfterDate = {
        startDate: null,
        endDate: null,
      };
    },
  },
});
</script>
