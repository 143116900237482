var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-line",attrs:{"role":"menu"}},[_c('ul',[_c('li',{class:[_vm.currentPage.includes('campaigns') ? _vm.activeClass : '']},[_c('router-link',{attrs:{"to":{
          name: 'campaigns',
          query: {
            page: 'onboarding_app',
            view: 'campaigns',
          },
        }}},[_c('i',{staticClass:"dashboard-icon icon-campaign"}),_vm._v(" Campaigns ")])],1),_c('li',{class:[_vm.currentPage.includes('leads') ? _vm.activeClass : '']},[_c('router-link',{attrs:{"to":{
          name: 'leads',
          query: {
            page: 'onboarding_app',
            view: 'leads',
          },
        }}},[_c('i',{staticClass:"dashboard-icon icon-leads"}),_vm._v(" Leads ")])],1),_c('li',{class:[_vm.currentPage.includes('sessions') ? _vm.activeClass : '']},[_c('router-link',{attrs:{"to":{
          name: 'sessions',
          query: {
            page: 'onboarding_app',
            view: 'sessions',
          },
        }}},[_c('i',{staticClass:"dashboard-icon icon-sessions"}),_vm._v(" Sessions ")])],1),_c('li',{class:[_vm.currentPage.includes('workflows') ? _vm.activeClass : '']},[_c('router-link',{attrs:{"to":{
          name: 'workflows',
          query: {
            page: 'onboarding_app',
            view: 'workflows',
          },
        }}},[_c('i',{staticClass:"dashboard-icon icon-workflow"}),_vm._v(" Workflows ")])],1),(this.$root.user.role === 'MerchantAdmin')?_c('li',{class:[_vm.currentPage.includes('agencies') ? _vm.activeClass : '']},[_c('router-link',{attrs:{"to":{
          name: 'agencies',
          query: {
            page: 'onboarding_app',
            view: 'agencies',
          },
        }}},[_c('i',{staticClass:"dashboard-icon icon-agency"}),_vm._v(" Agencies ")])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }