<template>
  <div>
    <table class="agreement-info">
      <tbody>
        <tr>
          <td class="table-header">Communication</td>
          <td>
            {{
              this.getCommunicationCollectionNameByGuid(
                product.communicationCollectionGuid
              )
            }}
          </td>
        </tr>
        <tr>
          <td class="table-header">Purpose accounting code</td>
          <td>
            {{ product.purposeAccountingCode }}
          </td>
        </tr>
        <tr>
          <td class="table-header">Amount, incl. VAT</td>
          <td>
            {{ product.amountTotal | formatAmount }}
            <span class="cur">{{ product.currencyCode }}</span>
          </td>
        </tr>
        <tr>
          <td class="table-header">VAT</td>
          {{
            product.vatPercentage
          }}
          %
        </tr>
        <tr>
          <td class="table-header">Tax-deductible</td>
          <td>
            {{ product.taxDeductable | formatBoolean }}
          </td>
        </tr>
        <tr>
          <td class="table-header">Payment required</td>
          <td>
            {{ product.paymentRequired | formatBoolean }}
          </td>
        </tr>
        <tr>
          <td class="table-header">Frequency</td>
          <td>
            <span v-html="this.humanReadableSchedule(product)"></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from "vue";
import { relayHandler } from "../mixins/relayHandler.js";
import { formats } from "../mixins/formats.js";

export default Vue.extend({
  name: "AgreementReviewTable",
  mixins: [relayHandler, formats],
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    /**
     * Get Communication name by guid
     * @param {string} guid
     */
    getCommunicationCollectionNameByGuid: function (guid) {
      //  Handle promise or storage
      let communicationHandler = this.getCommunication(guid);

      //  Promise
      if (typeof communicationHandler.then !== "undefined") {
        communicationHandler.then((response) => {
          if (!response.communicationErrored) {
            return response.communicationCollection.collectionName;
          } else {
            return "";
          }
        });
      }

      //  Storage
      else if (communicationHandler.communicationCollection) {
        return communicationHandler.communicationCollection.collectionName;
      }
    },
  },
});
</script>
