<template>
  <div
    class="schedule-selection"
    v-bind:class="{
      hidden: this.staticWorkflowProduct.hasScheduleSelectedSet,
    }"
  >
    <!-- Schedule customisation by scheduleSelectedSet -->
    <div v-if="this.staticWorkflowProduct.hasScheduleSelectedSet" class="row">
      <!--
      <div class="col-sm-6">
        <p>
          <strong>Selected months</strong>
          <span v-bind:inner-html.prop="this.scheduleSelectedSetMonths"></span>
        </p>
      </div>
      -->
    </div>
    <!-- Schedule customisation by schedule elements -->
    <div v-else class="row">
      <!-- scheduleType -->
      <div class="col-sm-6">
        <div class="control-input">
          <label for="scheduleType">Schedule</label>
          <select
            id="scheduleType"
            class="uik-input__select"
            v-model="product.scheduleType"
            v-bind:class="[
              staticWorkflowProduct.scheduleType ||
              product.agreementType === 'Shared'
                ? 'disabled'
                : null,
              product.scheduleType && product.scheduleType.length > 0
                ? 'not-empty'
                : 'empty',
            ]"
            v-bind:disabled="
              staticWorkflowProduct.scheduleType ||
              product.agreementType === 'Shared'
                ? true
                : false
            "
            required
            aria-label="Vælg frekvens"
            v-on:change="whenScheduleTypeChange()"
          >
            <option
              v-if="product.scheduleType === undefined"
              v-bind:value="undefined"
              v-bind:disabled="
                staticWorkflowProduct.scheduleType ? true : false
              "
            >
              Select schedule
            </option>
            <option
              v-else
              v-bind:value="null"
              v-bind:disabled="
                staticWorkflowProduct.scheduleType ? true : false
              "
            >
              Select schedule
            </option>
            <option
              v-for="scheduleType in this.$root.helpers.scheduleTypes"
              v-bind:value="scheduleType.value"
              v-bind:key="scheduleType.value"
              v-bind:disabled="
                staticWorkflowProduct.scheduleType || scheduleType.disabled
                  ? true
                  : false
              "
            >
              {{ scheduleType.label }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-sm-6" aria-hidden="true">
        <!-- scheduleEveryOther + scheduleCalendarUnit - custom -->
        <div class="control-input">
          <label>Repeat every</label>
          <div class="input-wrap">
            <select
              class="uik-input__select"
              v-bind:class="[
                staticWorkflowProduct.scheduleType === 'Manual' ||
                staticWorkflowProduct.scheduleEveryOther ||
                product.agreementType === 'Shared'
                  ? 'disabled'
                  : null,
              ]"
              id="scheduleEveryOther"
              required
              v-model="product.scheduleEveryOther"
              v-on:change="whenScheduleEveryOtherChange()"
              tabindex="-1"
            >
              <option
                v-if="product.scheduleEveryOther === undefined"
                v-bind:value="undefined"
                v-bind:disabled="
                  staticWorkflowProduct.scheduleEveryOther ? true : false
                "
              >
                Repeat
              </option>
              <option
                v-else
                v-bind:value="null"
                v-bind:disabled="
                  staticWorkflowProduct.scheduleEveryOther ? true : false
                "
              >
                Repeat
              </option>
              <option
                v-for="scheduleEveryOther in this.$root.helpers
                  .scheduleEveryOthers"
                v-bind:value="scheduleEveryOther.value"
                v-bind:key="scheduleEveryOther.value"
                v-bind:disabled="
                  staticWorkflowProduct.scheduleEveryOther ? true : false
                "
              >
                {{ scheduleEveryOther.label }}
              </option>
            </select>
            <select
              class="uik-input__select"
              v-bind:class="[
                staticWorkflowProduct.scheduleType === 'Manual' ||
                staticWorkflowProduct.scheduleCalendarUnit ||
                product.agreementType === 'Shared'
                  ? 'disabled'
                  : null,
              ]"
              id="scheduleCalendarUnit"
              required
              v-model="product.scheduleCalendarUnit"
              v-on:change="whenScheduleCalendarUnitChange()"
              tabindex="-1"
            >
              <option
                v-if="product.scheduleCalendarUnit === undefined"
                v-bind:value="undefined"
                v-bind:disabled="
                  staticWorkflowProduct.scheduleCalendarUnit ? true : false
                "
              >
                Unit
              </option>
              <option
                v-else
                v-bind:value="null"
                v-bind:disabled="
                  staticWorkflowProduct.scheduleCalendarUnit ? true : false
                "
              >
                Unit
              </option>
              <option
                v-for="scheduleCalendarUnit in this.$root.helpers
                  .scheduleCalendarUnits"
                v-bind:value="scheduleCalendarUnit.value"
                v-bind:key="scheduleCalendarUnit.value"
                v-bind:disabled="
                  staticWorkflowProduct.scheduleCalendarUnit ? true : false
                "
              >
                {{ scheduleCalendarUnit.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { changeEvents } from "../../mixins/changeEvents.js";

export default Vue.extend({
  name: "OnboardingScheduleType",
  mixins: [changeEvents],
  props: {
    product: {
      type: Object,
      required: true,
    },
    staticWorkflowProduct: {
      type: Object,
      required: true,
    },
  },
  computed: {
    scheduleSelectedSetMonths: function () {
      var html = '<ul class="list">';

      if (this.staticWorkflowProduct.scheduleSelectSet_1) {
        html = html + "<li>January</li>";
      }
      if (this.staticWorkflowProduct.scheduleSelectSet_2) {
        html = html + "<li>February</li>";
      }
      if (this.staticWorkflowProduct.scheduleSelectSet_3) {
        html = html + "<li>March</li>";
      }
      if (this.staticWorkflowProduct.scheduleSelectSet_4) {
        html = html + "<li>April</li>";
      }
      if (this.staticWorkflowProduct.scheduleSelectSet_5) {
        html = html + "<li>May</li>";
      }
      if (this.staticWorkflowProduct.scheduleSelectSet_6) {
        html = html + "<li>June</li>";
      }
      if (this.staticWorkflowProduct.scheduleSelectSet_7) {
        html = html + "<li>July</li>";
      }
      if (this.staticWorkflowProduct.scheduleSelectSet_8) {
        html = html + "<li>August</li>";
      }
      if (this.staticWorkflowProduct.scheduleSelectSet_9) {
        html = html + "<li>September</li>";
      }
      if (this.staticWorkflowProduct.scheduleSelectSet_10) {
        html = html + "<li>October</li>";
      }
      if (this.staticWorkflowProduct.scheduleSelectSet_11) {
        html = html + "<li>November</li>";
      }
      if (this.staticWorkflowProduct.scheduleSelectSet_12) {
        html = html + "<li>December</li>";
      }
      return html + "</ul>";
    },
  },
  methods: {
    /**
     * Apply mixin and emit change
     */
    whenScheduleTypeChange: function () {
      this.onScheduleTypeChange(this.product);

      //  Emit
      this.$emit("whenScheduleChanged");
      this.$forceUpdate();
    },

    /**
     * Apply mixin and emit change
     */
    whenScheduleEveryOtherChange: function () {
      this.onScheduleEveryOtherChange(this.product);

      //  Emit
      this.$emit("whenScheduleChanged");
      this.$forceUpdate();
    },

    /**
     * Apply mixin and emit change
     */
    whenScheduleCalendarUnitChange: function () {
      this.onScheduleCalendarUnitChange(this.product);

      //  Emit
      this.$emit("whenScheduleChanged");
      this.$forceUpdate();
    },
  },
});
</script>
