<template>
  <span class="tooltip-wrapper">
    <i class="dashboard-icon icon-info"></i>
    <span v-html="tooltip"></span>
  </span>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "ToolTip",
  props: {
    tooltip: {
      required: true,
    },
  },
});
</script>
