<template>
  <div class="block-wrap add-on-products">
    <div
      v-bind:class="[
        $root.user.role === 'MerchantAdmin'
          ? 'block'
          : 'block no-pointer-events',
      ]"
    >
      <!-- Header -->
      <div class="block-header">
        <h3>Upgrade products</h3>
      </div>

      <!-- Repeatable fields -->
      <draggable
        v-bind:list="this.upgradeProducts"
        v-on:end="updateProducts()"
        ghost-class="being-dragged"
      >
        <div
          v-for="(product, index) in this.upgradeProducts"
          v-bind:key="product.uuid"
        >
          <!-- Field open -->
          <div
            class="group-wrap"
            v-bind:class="{
              collapsed:
                $root.user.role === 'MerchantAdmin' ? product.collapsed : false,
            }"
          >
            <div class="group-header">
              <h3 v-if="product.productName">
                {{ getIndex(index) }}: {{ product.productName }}
              </h3>
              <h3 v-else>{{ getIndex(index) }}: Product</h3>
              <button
                v-if="$root.user.role === 'MerchantAdmin'"
                type="button"
                v-on:click="toggleCollapsed(product)"
              >
                <i class="expand-icon"></i>
              </button>
            </div>
            <div class="group-content clearfix">
              <!-- Product name / type -->
              <div class="block">
                <div class="row">
                  <div class="col-sm-7">
                    <!-- Name -->
                    <div class="control-input">
                      <label v-bind:for="'productName' + product.uuid"
                        >Product name</label
                      >
                      <input
                        type="text"
                        v-bind:id="'productName' + product.uuid"
                        class="uik-input__input"
                        placeholder="Please define a product name, visible to agency"
                        v-model="product.productName"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-sm-5">
                    <!-- Type -->
                    <div class="control-input">
                      <label>Upgrade type</label>
                    </div>
                    <ul class="radio-group horizontal">
                      <li>
                        <label v-bind:for="'upgradeTypeAddOn' + product.uuid">
                          <input
                            type="radio"
                            v-bind:id="'upgradeTypeAddOn' + product.uuid"
                            v-model="product.upgradeType"
                            v-bind:value="'AddOn'"
                          />Add-On
                          <div class="check"></div>
                        </label>
                      </li>
                      <li>
                        <label
                          v-bind:for="
                            'upgradeTypeAgreementUpdate' + product.uuid
                          "
                        >
                          <input
                            type="radio"
                            v-bind:id="
                              'upgradeTypeAgreementUpdate' + product.uuid
                            "
                            v-model="product.upgradeType"
                            v-bind:value="'AgreementUpdate'"
                          />Adjustment of agreement
                          <div class="check"></div>

                          <!-- Tooltip -->
                          <tool-tip
                            v-bind:tooltip="'This product allows changing of amount and frequency of the Lead\'s existing Agreement.'"
                          />
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- AgreementUpdate -->
              <div v-if="product.upgradeType === 'AgreementUpdate'" class="row">
                <div class="col-sm-7">
                  <div class="block">
                    <!-- Agreement Name -->
                    <div class="control-input">
                      <label v-bind:for="'agreementName' + product.uuid"
                        >Agreement name</label
                      >
                      <input
                        type="text"
                        v-bind:id="'agreementName' + product.uuid"
                        class="uik-input__input"
                        placeholder="Leave empty to maintain agreement name"
                        maxlength="30"
                        v-model="product.agreementName"
                      />
                    </div>

                    <!-- Agreement Description -->
                    <div class="control-input">
                      <label v-bind:for="'agreementDescription' + product.uuid"
                        >Agreement description</label
                      >
                      <input
                        type="text"
                        v-bind:id="'agreementDescription' + product.uuid"
                        class="uik-input__input"
                        placeholder="Leave empty to maintain agreement description"
                        maxlength="60"
                        v-model="product.agreementDescription"
                      />
                    </div>
                  </div>

                  <!-- Amount / VAT percentage -->
                  <div class="block">
                    <div class="row">
                      <div class="col-sm-6">
                        <!-- Amount -->
                        <div class="control-input">
                          <label v-bind:for="'amount' + product.uuid"
                            >Amount in {{ product.currencyCode }}</label
                          >
                          <input
                            type="number"
                            v-bind:id="'amount' + product.uuid"
                            class="uik-input__input"
                            placeholder="Total amount, incl. VAT"
                            v-model="product.amountTotal"
                          />
                          <small class="input-helper-text"
                            >Leave empty for agents to define</small
                          >
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <!-- Currency Code -->
                        <div class="control-input">
                          <label v-bind:for="'currencyCode' + product.uuid"
                            >Currency</label
                          >
                          <select
                            class="uik-input__select"
                            v-bind:id="'currencyCode' + product.uuid"
                            required
                            v-model="product.currencyCode"
                          >
                            <option v-bind:value="undefined">Maintain</option>
                            <option v-bind:value="'DKK'">DKK</option>
                            <option v-bind:value="'ISK'">ISK</option>
                            <option v-bind:value="'SEK'">SEK</option>
                            <option v-bind:value="'NOK'">NOK</option>
                            <option v-bind:value="'EUR'">EUR</option>
                            <option v-bind:value="'GBP'">GBP</option>
                            <option v-bind:value="'USD'">USD</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <!-- VAT Percentage -->
                        <div class="control-input">
                          <label v-bind:for="'vatPercentage' + product.uuid"
                            >VAT percentage</label
                          >
                          <input
                            type="number"
                            v-bind:id="'vatPercentage' + product.uuid"
                            class="uik-input__input"
                            placeholder="% in digits"
                            v-model="product.vatPercentage"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Communication / Purpose -->
                  <div class="block">
                    <div class="row">
                      <div class="col-sm-6">
                        <!-- PurposeAccountingCode -->
                        <div class="control-input">
                          <label
                            v-bind:for="'purposeAccountingCode' + product.uuid"
                            >Purpose accounting code</label
                          >
                          <input
                            type="text"
                            class="uik-input__input"
                            v-bind:id="'purposeAccountingCode' + product.uuid"
                            placeholder="Maintain existing purpose accounting code"
                            maxlength="32"
                            v-model="product.purposeAccountingCode"
                          />
                        </div>
                      </div>

                      <div class="col-sm-6">
                        <!-- CommunicationCollectionGuid -->
                        <div class="control-input">
                          <label
                            v-bind:for="
                              'communicationCollectionGuid' + product.uuid
                            "
                            >Communication</label
                          >
                          <div v-if="communicationCollections.length > 0">
                            <select
                              class="uik-input__select"
                              v-bind:id="
                                'communicationCollectionGuid' + product.uuid
                              "
                              v-model="product.communicationCollectionGuid"
                            >
                              <option
                                v-if="
                                  product.communicationCollectionGuid ===
                                  undefined
                                "
                                v-bind:value="undefined"
                              >
                                Maintain existing communication
                              </option>
                              <option v-else v-bind:value="null">
                                Maintain existing communication
                              </option>
                              <option
                                v-for="communicationCollection in communicationCollections"
                                v-bind:value="
                                  communicationCollection.communicationCollectionGuid
                                "
                                v-bind:key="
                                  communicationCollection.communicationCollectionGuid
                                "
                                v-bind:disabled="
                                  communicationCollection.communicationCollectionGuid ===
                                  null
                                    ? true
                                    : false
                                "
                              >
                                {{ communicationCollection.collectionName }}
                              </option>
                            </select>
                          </div>
                          <div v-else>
                            <select
                              class="uik-input__select"
                              v-bind:id="
                                'communicationCollectionGuid' + product.uuid
                              "
                            >
                              <option value="" disabled selected>
                                Loading ...
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Schedule -->
                  <div class="block">
                    <div class="row">
                      <div class="col-sm-6">
                        <!-- ScheduleType -->
                        <div class="control-input">
                          <label v-bind:for="'scheduleType' + product.uuid"
                            >Schedule</label
                          >
                          <select
                            class="uik-input__select"
                            v-bind:id="'scheduleType' + product.uuid"
                            v-model="product.scheduleType"
                            v-on:change="onScheduleTypeChange(product)"
                          >
                            <option
                              v-if="product.scheduleType === undefined"
                              v-bind:value="undefined"
                            >
                              Maintain existing schedule
                            </option>
                            <option v-else v-bind:value="null">
                              Maintain existing schedule
                            </option>
                            <option
                              v-for="scheduleType in $root.helpers
                                .scheduleTypes"
                              v-bind:value="scheduleType.value"
                              v-bind:key="scheduleType.value"
                            >
                              {{ scheduleType.label }}
                            </option>
                          </select>
                          <small class="input-helper-text"
                            >Leave empty for agents to define</small
                          >
                        </div>

                        <!-- ScheduleType - scheduleSelectSet -->
                        <div
                          v-if="product.scheduleType === 'Monthly'"
                          class="control-input"
                        >
                          <ul class="checkbox-group vertical">
                            <!-- hasScheduleSelectedSet -->
                            <li>
                              <label
                                v-bind:for="
                                  'hasScheduleSelectedSet' + product.uuid
                                "
                              >
                                <input
                                  type="checkbox"
                                  v-bind:id="
                                    'hasScheduleSelectedSet' + product.uuid
                                  "
                                  v-model="product.hasScheduleSelectedSet"
                                />Only charge on specific months of the year
                                <div class="check"></div>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="col-sm-6">
                        <!-- Schedule customisation by scheduleSelectSet -->
                        <schedule-selected-set
                          v-if="
                            product.scheduleType === 'Monthly' &&
                            product.hasScheduleSelectedSet
                          "
                          v-bind:product="product"
                        />

                        <!-- Schedule customisation by schedule elements -->
                        <div v-else class="control-input">
                          <label>Repeat every</label>
                          <div class="input-wrap">
                            <select
                              class="uik-input__select"
                              v-bind:id="'scheduleEveryOther' + product.uuid"
                              v-model="product.scheduleEveryOther"
                              v-on:change="onScheduleEveryOtherChange(product)"
                            >
                              <option
                                v-if="product.scheduleEveryOther === undefined"
                                v-bind:value="undefined"
                              >
                                Maintain
                              </option>
                              <option v-else v-bind:value="null">
                                Maintain
                              </option>
                              <option
                                v-for="scheduleEveryOther in $root.helpers
                                  .scheduleEveryOthers"
                                v-bind:value="scheduleEveryOther.value"
                                v-bind:key="scheduleEveryOther.value"
                              >
                                {{ scheduleEveryOther.label }}
                              </option>
                            </select>
                            <select
                              class="uik-input__select"
                              v-bind:id="'scheduleCalendarUnit' + product.uuid"
                              v-model="product.scheduleCalendarUnit"
                            >
                              <option
                                v-if="
                                  product.scheduleCalendarUnit === undefined
                                "
                                v-bind:value="undefined"
                              >
                                Maintain
                              </option>
                              <option v-else v-bind:value="null">
                                Maintain
                              </option>
                              <option
                                v-for="scheduleCalendarUnit in $root.helpers
                                  .scheduleCalendarUnits"
                                v-bind:value="scheduleCalendarUnit.value"
                                v-bind:key="scheduleCalendarUnit.value"
                              >
                                {{ scheduleCalendarUnit.label }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <!-- Schedule customisation by schedule elements -->
                        <div
                          v-if="
                            !product.hasScheduleSelectedSet &&
                            product.scheduleType !== 'Weekly' &&
                            product.scheduleCalendarUnit !== 'Week'
                          "
                          class="control-input"
                        >
                          <label>Repeat based on</label>
                          <div class="input-wrap">
                            <select
                              class="uik-input__select"
                              v-bind:id="'scheduleFixedDay' + product.uuid"
                              v-model="product.scheduleFixedDay"
                            >
                              <option
                                v-if="product.scheduleFixedDay === undefined"
                                v-bind:value="undefined"
                              >
                                Maintain
                              </option>
                              <option v-else v-bind:value="null">
                                Maintain
                              </option>
                              <option
                                v-for="scheduleFixedDay in $root.helpers
                                  .scheduleFixedDays"
                                v-bind:value="scheduleFixedDay.value"
                                v-bind:key="scheduleFixedDay.value"
                              >
                                {{ scheduleFixedDay.label }}
                              </option>
                            </select>
                            <select
                              class="uik-input__select"
                              v-bind:id="'scheduleBaseTier' + product.uuid"
                              v-model="product.scheduleBaseTier"
                            >
                              <option
                                v-if="product.scheduleBaseTier === undefined"
                                v-bind:value="undefined"
                              >
                                Maintain
                              </option>
                              <option v-else v-bind:value="null">
                                Maintain
                              </option>
                              <option
                                v-for="scheduleBaseTier in $root.helpers
                                  .scheduleBaseTiers"
                                v-bind:value="scheduleBaseTier.value"
                                v-bind:key="scheduleBaseTier.value"
                              >
                                {{ scheduleBaseTier.label }}
                              </option>
                            </select>
                          </div>
                          <small class="input-helper-text"
                            >Leave empty for agents to define</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vr-split"></div>
                <div class="col-sm-5">
                  <div v-if="product.upgradeType === 'AddOn'" class="block">
                    <div class="control-input">
                      <label>Scheduling</label>
                    </div>

                    <!-- scheduleExpires -->
                    <ul class="checkbox-group vertical">
                      <li>
                        <label v-bind:for="'scheduleExpires' + product.uuid">
                          <input
                            type="checkbox"
                            v-bind:id="'scheduleExpires' + product.uuid"
                            v-model="product.scheduleExpires"
                            v-bind:value="'oneoff'"
                          />Allow expiration date
                          <div class="check"></div>
                        </label>
                      </li>
                    </ul>
                  </div>

                  <div class="block">
                    <div class="control-input">
                      <label>Payment settings</label>
                    </div>

                    <ul class="checkbox-group vertical">
                      <!-- taxDeductable -->
                      <li>
                        <label v-bind:for="'taxDeductable' + product.uuid">
                          <input
                            type="checkbox"
                            v-bind:id="'taxDeductable' + product.uuid"
                            v-model="product.taxDeductable"
                          />Payments are tax deductible
                          <div class="check"></div>
                        </label>
                      </li>

                      <!-- paymentRequired -->
                      <li>
                        <label v-bind:for="'paymentRequired' + product.uuid">
                          <input
                            type="checkbox"
                            v-bind:id="'paymentRequired' + product.uuid"
                            v-model="product.paymentRequired"
                          />Payments are required in dunning
                          <div class="check"></div>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- AddOn -->
              <div v-else-if="product.upgradeType === 'AddOn'" class="row">
                <div class="col-sm-7">
                  <div class="block">
                    <!-- AddOn Name -->
                    <div class="control-input">
                      <label v-bind:for="'addOnName' + product.uuid"
                        >Add-On name</label
                      >
                      <input
                        type="text"
                        v-bind:id="'addOnName' + product.uuid"
                        class="uik-input__input"
                        placeholder="Please define a meaningful name, visible to the Lead"
                        maxlength="30"
                        required
                        v-model="product.addOnName"
                      />
                    </div>

                    <!-- AddOn Description -->
                    <div class="control-input">
                      <label v-bind:for="'addOnDescription' + product.uuid"
                        >Add-On description</label
                      >
                      <input
                        type="text"
                        v-bind:id="'addOnDescription' + product.uuid"
                        class="uik-input__input"
                        placeholder="Please define a meaningful description, visible to the Lead"
                        maxlength="60"
                        v-model="product.addOnDescription"
                      />
                    </div>
                  </div>
                  <div class="block">
                    <div class="row">
                      <div class="col-sm-6">
                        <!-- amountTotal -->
                        <div class="control-input">
                          <label v-bind:for="'amountTotal' + product.uuid"
                            >Amount in existing currency</label
                          >
                          <input
                            type="number"
                            v-bind:id="'amountTotal' + product.uuid"
                            class="uik-input__input"
                            placeholder="Total amount, including VAT"
                            v-model="product.amountTotal"
                          />
                          <small class="input-helper-text"
                            >Leave empty for agents to define</small
                          >
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <!-- vatPercentage -->
                        <div class="control-input">
                          <label v-bind:for="'vatPercentage' + product.uuid"
                            >VAT percentage</label
                          >
                          <input
                            type="number"
                            v-bind:id="'vatPercentage' + product.uuid"
                            class="uik-input__input"
                            placeholder="% in digits"
                            v-model="product.vatPercentage"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="block">
                    <div class="row">
                      <div class="col-sm-6">
                        <!-- PurposeAccountingCode -->
                        <div class="control-input">
                          <label
                            v-bind:for="'purposeAccountingCode' + product.uuid"
                            >Purpose accounting code</label
                          >
                          <input
                            type="text"
                            class="uik-input__input"
                            v-bind:id="'purposeAccountingCode' + product.uuid"
                            placeholder="Optional"
                            maxlength="32"
                            v-model="product.purposeAccountingCode"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Expires after date -->
                  <div
                    v-if="
                      product.upgradeType === 'AddOn' && product.scheduleExpires
                    "
                    class="block"
                  >
                    <div class="control-input">
                      <label v-bind:for="'scheduleType' + product.uuid"
                        >Expiration date</label
                      >

                      <div class="row">
                        <div class="col-sm-6">
                          <div class="input-wrap">
                            <date-range-picker
                              ref="datePicker"
                              v-bind:singleDatePicker="true"
                              v-bind:timePicker="false"
                              v-bind:timePicker24Hour="false"
                              v-bind:showWeekNumbers="false"
                              v-bind:showDropdowns="true"
                              v-bind:autoApply="true"
                              v-bind:ranges="false"
                              v-bind:linkedCalendars="false"
                              v-bind:always-show-calendars="true"
                              v-bind:opens="'left'"
                              v-bind:locale-data="{
                                firstDay: 1,
                                format: 'YYYY/MM/DD',
                                separator: '-',
                              }"
                              v-model="product.expiresAfterDate"
                            >
                              <template v-slot:input="datePicker">
                                <span
                                  v-bind:inner-html.prop="
                                    datePicker | formatDatePicker
                                  "
                                ></span>
                                <button
                                  type="button"
                                  class="btn btn-primary icon-btn"
                                >
                                  <i class="dashboard-icon icon-calendar"></i>
                                </button>
                              </template>
                            </date-range-picker>
                          </div>
                          <small class="input-helper-text"
                            >Leave empty for agents to define</small
                          >
                        </div>
                        <div class="col-sm-6">
                          <div class="control-input">
                            <button
                              type="button"
                              class="uik-btn__base btn"
                              v-on:click="clearDateRange(product)"
                            >
                              Clear date
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vr-split"></div>
                <div class="col-sm-5">
                  <div class="block">
                    <div class="control-input">
                      <label>Scheduling</label>
                    </div>

                    <!-- scheduleExpires -->
                    <ul class="checkbox-group vertical">
                      <li>
                        <label v-bind:for="'scheduleExpires' + product.uuid">
                          <input
                            type="checkbox"
                            v-bind:id="'scheduleExpires' + product.uuid"
                            v-model="product.scheduleExpires"
                            v-bind:value="'oneoff'"
                          />Allow expiration date
                          <div class="check"></div>
                        </label>
                      </li>
                    </ul>
                  </div>

                  <div class="block">
                    <div class="control-input">
                      <label>Payment settings</label>
                    </div>

                    <!-- taxDeductable -->
                    <ul class="checkbox-group vertical">
                      <li>
                        <label v-bind:for="'taxDeductable' + product.uuid">
                          <input
                            type="checkbox"
                            v-bind:id="'taxDeductable' + product.uuid"
                            v-model="product.taxDeductable"
                          />Payments are tax deductible
                          <div class="check"></div>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!-- Remove -->
            <div class="group-footer">
              <div class="right-aligned">
                <button
                  v-if="$root.user.role === 'MerchantAdmin'"
                  type="button"
                  class="uik-btn__base btn btn-cancel"
                  v-on:click="removeProduct(product.uuid)"
                >
                  Remove product
                </button>
              </div>
            </div>
          </div>
          <!-- Field end -->
        </div>
      </draggable>

      <!-- Footer -->
      <div v-if="$root.user.role === 'MerchantAdmin'" class="block-footer">
        <div class="center-aligned">
          <button
            type="button"
            class="uik-btn__base btn btn-primary"
            v-on:click="addProduct()"
          >
            Add product
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ScheduleSelectedSet from "./WorkflowModalScheduleSelectedSet.vue";
import Draggable from "vuedraggable";
import ToolTip from "../ToolTip";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { relayHandler } from "../../mixins/relayHandler.js";
import { changeEvents } from "../../mixins/changeEvents.js";
import { formats } from "../../mixins/formats.js";

let uuid = 0;

export default Vue.extend({
  name: "UpgradeProducts",
  mixins: [relayHandler, changeEvents, formats],
  components: {
    ScheduleSelectedSet,
    Draggable,
    ToolTip,
    DateRangePicker,
  },
  props: {
    workflow: {
      type: Object,
      required: false,
    },
  },
  data: function () {
    return {
      upgradeProducts: null,
      communicationCollectionsRetrieved: false,
      communicationCollections: [],
    };
  },
  created() {
    //  Populate upgradeProducts
    this.upgradeProducts = this.workflow.upgradeProducts;

    //  Update uuid
    if (this.upgradeProducts) {
      var i;
      for (i = uuid; i < this.upgradeProducts.length; i++) {
        //  Bump by greater uuid
        if (this.upgradeProducts[i].uuid > uuid) {
          uuid = this.upgradeProducts[i].uuid;
        }
        uuid++;
      }
    }

    //  Initialise date range picker
    this.initialiseDateRange();

    //  Get entities
    this.extractCommunications();
  },
  methods: {
    /**
     * Get index
     * @param {int} uuid
     */
    getIndex: function (index) {
      return index + 1;
    },

    /**
     * Get communicationCollections
     */
    extractCommunications: function () {
      let communicationHandler = this.getCommunications();
      if (typeof communicationHandler.then !== "undefined") {
        communicationHandler.then((response) => {
          if (!response.communicationsErrored) {
            this.communicationCollections =
              this.$root.store.communicationCollections;
          }
          this.communicationCollectionsRetrieved = true;
        });
      } else {
        this.communicationCollections =
          this.$root.store.communicationCollections;
        this.communicationCollectionsRetrieved = true;
      }
    },

    /**
     * Append product
     */
    addProduct: function () {
      var product = {
        uuid: uuid++,
        collapsed: false,
        productName: null,
        upgradeType: null,
        addOnName: null,
        addOnDescription: null,
        agreementName: null,
        agreementDescription: null,
        amountTotal: null,
        vatPercentage: null,
        currencyCode: null,
        purposeAccountingCode: null,
        taxDeductable: false,
        paymentRequired: false,
        communicationCollectionGuid: null,
        scheduleEveryOther: null,
        scheduleCalendarUnit: null,
        scheduleFixedDay: null,
        scheduleBaseTier: null,
        scheduleType: null,
        scheduleExpires: null,
        expiresAfterDate: {
          startDate: null,
          endDate: null,
        },
      };

      if (!this.upgradeProducts) {
        this.upgradeProducts = [product];
      } else {
        this.upgradeProducts.push(product);
      }

      this.updateProducts();
    },

    /**
     * Update list of products
     */
    updateProducts: function () {
      this.workflow.upgradeProducts = this.upgradeProducts;
    },

    /**
     * Remove product
     * @param {int} uuid
     */
    removeProduct: function (uuid) {
      if (this.upgradeProducts) {
        var i;
        for (i = 0; i < this.upgradeProducts.length; i++) {
          if (this.upgradeProducts[i].uuid === uuid) {
            this.upgradeProducts.splice(i, 1);
          }
        }
      }

      this.updateProducts();
    },

    /**
     * Toggle collapsed / expand
     * @param {object} product
     */
    toggleCollapsed: function (product) {
      if (product.collapsed) {
        product.collapsed = false;
      } else {
        product.collapsed = true;
      }
    },

    /**
     * Initialise date range picker by transfering and formatting dates
     */
    initialiseDateRange: function () {
      if (this.upgradeProducts) {
        var o;
        for (o = 0; o < this.upgradeProducts.length; o++) {
          if (this.upgradeProducts[o].expiresAfterDate) {
            //  Keep as defined
          } else {
            this.upgradeProducts[o].expiresAfterDate = {
              startDate: null,
              endDate: null,
            };
          }
        }
      }
    },

    /**
     * Clear date definition
     * @param {object} product
     */
    clearDateRange: function (product) {
      product.expiresAfterDate = {
        startDate: null,
        endDate: null,
      };
    },
  },
});
</script>
