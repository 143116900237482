<template>
  <!-- Betalingsservice third party specific and api fallback -->
  <div
    v-if="this.$root.merchant.bsByThirdParty"
    class="row betalingsservice-details"
  >
    <!-- nationalId for third party -->
    <div
      v-if="!getNationalIdField && !getBusinessCodeField"
      class="col-sm-6 form-group"
    >
      <label class="control-label" for="nationalId">National ID</label>
      <div class="control-input">
        <input
          type="number"
          id="nationalId"
          class="uik-input__input"
          minlength="10"
          maxlength="10"
          autocomplete="nope"
          placeholder="(CPR-nr.)"
          v-model.trim="product.nationalId"
          v-on:keyup="validateNationalIdInput(product.nationalId)"
          v-bind:class="[
            product.nationalIdValidity ? product.nationalIdValidity : '',
          ]"
        />
        <span class="validator-icon"></span>
      </div>
    </div>

    <!-- businessCode for third party -->
    <div
      v-if="!getNationalIdField && !getBusinessCodeField"
      class="col-sm-6 form-group"
    >
      <label class="control-label" for="businessCode">Business Code</label>
      <div class="control-input">
        <input
          type="number"
          id="businessCode"
          class="uik-input__input"
          autocomplete="nope"
          placeholder="(CVR-nr.)"
          v-model.trim="product.businessCode"
        />
      </div>
    </div>

    <!-- sortCode for third party -->
    <div class="col-sm-6 form-group">
      <label class="control-label" for="sortCode">Sort code</label>
      <div class="control-input">
        <input
          type="number"
          id="sortCode"
          class="uik-input__input"
          minlength="3"
          maxlength="4"
          autocomplete="nope"
          placeholder="(reg.nr.)"
          v-model.trim="product.sortCode"
        />
      </div>
    </div>

    <!-- accountNumber for third party -->
    <div class="col-sm-6 form-group">
      <label class="control-label" for="accountNumber">Account number</label>
      <div class="control-input">
        <input
          type="number"
          id="accountNumber"
          class="uik-input__input"
          minlength="6"
          maxlength="10"
          autocomplete="nope"
          placeholder="(kontonr.)"
          v-model.trim="product.accountNumber"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { relayHandler } from "../../mixins/relayHandler.js";
import { sessionRequestGatewayHandler } from "../../mixins/sessionRequestGatewayHandler.js";

export default Vue.extend({
  name: "OnboardingPaymentMethodTypeSelectionBetalingsservice",
  mixins: [relayHandler, sessionRequestGatewayHandler],
  props: {
    sessionRequest: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      bsAppVerifying: false,
    };
  },
  computed: {
    getNationalIdField: function () {
      let nationalIdContactField =
        this.sessionRequest.workflow.contactDataFields.filter(function (field) {
          if (field.name === "nationalId" && field.value) {
            return field;
          }
        });

      if (nationalIdContactField.length) {
        return nationalIdContactField[0];
      }

      return null;
    },
    getBusinessCodeField: function () {
      let businessCodeContactField =
        this.sessionRequest.workflow.contactDataFields.filter(function (field) {
          if (field.name === "businessCode" && field.value) {
            return field;
          }
        });

      if (businessCodeContactField.length) {
        return businessCodeContactField[0];
      }

      return null;
    },
  },
  methods: {
    /**
     * Validte National Id
     * @param {string} nationalId
     */
    validateNationalIdInput: function (nationalId) {
      //  Reset
      if (nationalId.length < 1) {
        this.product.nationalIdValidity = "";
      }

      //  Validate
      else if (this.isNationalIdValid(nationalId)) {
        this.product.nationalIdValidity = "valid";
      }

      //  Default
      else {
        this.product.nationalIdValidity = "error";
      }

      //  Force update needed to update input class
      this.$forceUpdate();
    },
  },
});
</script>
