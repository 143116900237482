<template>
  <div class="right-aligned popover-wrapper">
    <button type="button" v-on:click="toggleExpanded()" class="action">
      <i class="dashboard-icon icon-filter"></i>Filter
    </button>
    <div class="popover popover-filter" v-bind:class="{ expanded: expanded }">
      <h4>Filter options</h4>
      <form
        method="post"
        action=""
        autocomplete="nope"
        novalidate="novalidate"
        v-on:submit.prevent
      >
        <!-- Campaign -->
        <label
          v-if="this.$root.store.campaigns.length > 0"
          for="filter_campaign"
          >Campaign</label
        >
        <select
          v-if="this.$root.store.campaigns.length > 0"
          id="filter_campaign"
          class="uik-input__input"
          v-model="campaignGuid"
        >
          <option v-bind:value="null">All</option>
          <option
            v-for="campaign in this.$root.store.campaigns"
            v-bind:key="campaign.campaignGuid"
            v-bind:value="campaign.campaignGuid"
          >
            {{ campaign.name }}
          </option>
        </select>

        <!-- PageSize -->
        <label for="filter_page_size">Size</label>
        <input
          type="number"
          class="uik-input__input"
          min="10"
          max="100"
          v-model="pageSize"
        />

        <!-- Submit-->
        <div class="filter-footer">
          <ul class="unordered-list">
            <li>
              <button
                type="button"
                class="uik-btn__base uik-btn__secondary"
                v-on:click="clearFilter()"
              >
                Clear
              </button>
            </li>
            <li>
              <button
                type="button"
                class="uik-btn__base uik-btn__primary"
                v-on:click="applyFilter()"
              >
                Apply
              </button>
            </li>
          </ul>
        </div>
      </form>
    </div>
    <div
      class="popover-filter-bg"
      v-bind:class="{ expanded: expanded }"
      v-on:click="toggleExpanded()"
    ></div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "ListFilter",
  props: {
    entityType: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      campaignGuid: null,
      pageSize:
        this.entityType === "lead"
          ? this.$root.store.leadPageSize
          : this.$root.store.sessionPageSize,
      expanded: false,
    };
  },
  methods: {
    /**
     *  Toggle expand / collapse
     */
    toggleExpanded: function () {
      if (this.expanded) {
        this.expanded = false;
      } else {
        this.expanded = true;
      }
    },

    /**
     * Clear filter
     */
    clearFilter: function () {
      //  Reset
      this.campaignGuid = null;
      this.pageSize = 10;

      this.applyFilter();
    },

    /**
     * Apply filter
     */
    applyFilter: function () {
      //  Filter lead size
      if (this.entityType === "lead") {
        this.$root.store.leads = [];
        this.$root.store.leadPageNumber = 1;
        this.$root.store.leadPageSize = parseInt(this.pageSize);
        window.setMark("leadPageSize", this.$root.store.leadPageSize);
      }

      //  Filter session size
      else {
        this.$root.store.sessions = [];
        this.$root.store.sessionPageNumber = 1;
        this.$root.store.sessionPageSize = parseInt(this.pageSize);
        window.setMark("sessionPageSize", this.$root.store.sessionPageSize);
      }

      //  Filter campaign
      if (this.campaignGuid && this.entityType === "lead") {
        this.$root.store.leadsByCampaign = this.campaignGuid;
      } else if (this.campaignGuid) {
        this.$root.store.sessionsByCampaign = this.campaignGuid;
      } else if (this.entityType === "lead") {
        this.$root.store.leadsByCampaign = null;
      } else {
        this.$root.store.sessionsByCampaign = null;
      }

      this.toggleExpanded();

      //  Emit
      this.$emit("appliedFilter");
    },
  },
});
</script>
