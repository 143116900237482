<template>
  <div
    v-if="
      product.paymentType === 'Single' ||
      (product.paymentType === 'Recurring' &&
        product.agreementType === 'Personal')
    "
    class="block"
  >
    <div class="control-input">
      <label>Payment settings</label>
    </div>
    <ul class="checkbox-group vertical">
      <!-- taxDeductable -->
      <li>
        <label v-bind:for="'taxDeductable' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'taxDeductable' + product.uuid"
            v-model="product.taxDeductable"
          />Payments are tax deductible
          <div class="check"></div>
        </label>
      </li>

      <!-- paymentRequired -->
      <li
        v-if="
          product.paymentType === 'Recurring' &&
          product.agreementType === 'Personal'
        "
      >
        <label v-bind:for="'paymentRequired' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'paymentRequired' + product.uuid"
            v-model="product.paymentRequired"
          />Payments are required in dunning
          <div class="check"></div>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "WorkflowModalPaymentSettings",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
});
</script>
