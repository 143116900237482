var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"review-product"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('h2',[_vm._v("Review existing agreement")]),_c('table',{staticClass:"product-info"},[_c('tbody',[(this.sessionRequest.agreementData.createdTs)?_c('tr',[_c('td',{staticClass:"table-header"},[_vm._v("Created")]),_c('td',[_c('span',{domProps:{"innerHTML":_vm._f("formatDate")(this.sessionRequest.agreementData.createdTs)}})])]):_vm._e(),(this.sessionRequest.agreementData.agreementType)?_c('tr',[_c('td',{staticClass:"table-header"},[_vm._v("Agreement type")]),_c('td',[_vm._v(_vm._s(this.sessionRequest.agreementData.agreementType))])]):_vm._e(),(this.sessionRequest.agreementData.name)?_c('tr',[_c('td',{staticClass:"table-header"},[_vm._v("Agreement name")]),_c('td',[_vm._v(_vm._s(this.sessionRequest.agreementData.name))])]):_vm._e(),(this.sessionRequest.agreementData.amountTotal)?_c('tr',[_c('td',{staticClass:"table-header"},[_vm._v("Amount, incl. VAT")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatAmount")(this.sessionRequest.agreementData.amountTotal))+" "+_vm._s(this.sessionRequest.agreementData.currencyCode)+" ")])]):_vm._e(),_c('tr',[_c('td',{staticClass:"table-header"},[_vm._v("Tax-deductible")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatBoolean")(this.sessionRequest.agreementData.taxDeductable))+" ")])]),_c('tr',[_c('td',{staticClass:"table-header"},[_vm._v("Frequency")]),_c('td',[_c('span',{domProps:{"innerHTML":_vm._s(
                  this.humanReadableSchedule(
                    this.sessionRequest.agreementData
                  )
                )}})])]),(
              this.sessionRequest.subscriptionData.state === 'Active' &&
              this.sessionRequest.subscriptionData.nextDueDate
            )?_c('tr',[_c('td',{staticClass:"table-header"},[_vm._v("Next planned charge")]),_c('td',[_c('span',{domProps:{"innerHTML":_vm._f("formatDateWithColor")(this.sessionRequest.subscriptionData.nextDueDate)}})])]):_vm._e(),(
              this.sessionRequest.subscriptionData.state === 'Active' &&
              this.sessionRequest.subscriptionData.expiresAfterDate
            )?_c('tr',[_c('td',{staticClass:"table-header"},[_vm._v("Expiration")]),_c('td',[_c('span',{domProps:{"innerHTML":_vm._f("formatDate")(this.sessionRequest.subscriptionData.expiresAfterDate)}})])]):_vm._e(),(this.sessionRequest.subscriptionData)?_c('tr',[_c('td',{staticClass:"table-header"},[_vm._v("Subscription")]),(this.sessionRequest.subscriptionData.state === 'Active')?_c('td',[_c('span',{staticClass:"green"},[_vm._v(" Active ")])]):(this.sessionRequest.subscriptionData.holdDescription)?_c('td',[_c('span',{staticClass:"yellow"},[_vm._v(" On hold: "+_vm._s(this.sessionRequest.subscriptionData.holdDescription)+" ")])]):(
                this.sessionRequest.subscriptionData.cancelDescription
              )?_c('td',[_c('span',{staticClass:"red"},[_vm._v(" "+_vm._s(this.sessionRequest.subscriptionData.cancelDescription)+" ")])]):_c('td',[_c('span',{staticClass:"red"},[_vm._v(" Inactive ")])])]):_vm._e(),(this.sessionRequest.paymentMethodData.paymentMethodType)?_c('tr',[_c('td',{staticClass:"table-header"},[_vm._v("Payment method")]),(this.sessionRequest.paymentMethodData.state === 'Active')?_c('td',[_c('span',{staticClass:"green"},[_vm._v(" "+_vm._s(_vm._f("humanReadablePaymentMethodType")(this.sessionRequest.paymentMethodData.paymentMethodType))+" is active ")])]):_c('td',[_c('span',{staticClass:"red"},[_vm._v(" "+_vm._s(_vm._f("humanReadablePaymentMethodType")(this.sessionRequest.paymentMethodData.paymentMethodType))+" is inactive ")])])]):_vm._e()])])]),_c('div',{staticClass:"col-sm-6"},[(this.sessionRequest.addOnsData.length > 0)?[_c('h2',[_vm._v("Review add-ons")]),_c('table',{staticClass:"product-info"},[_c('tbody',[_vm._l((this.sessionRequest.addOnsData),function(addOnData){return _c('tr',{key:addOnData.addOnGuid},[_c('td',{staticClass:"table-header",class:addOnData.state === 'Inactive' ? 'strike-through' : ''},[_c('span',{attrs:{"title":'Created ' + addOnData.createdTs}},[_vm._v(_vm._s(addOnData.name))])]),_c('td',{staticClass:"text-align-right",class:addOnData.state === 'Inactive' ? 'strike-through' : ''},[_vm._v(" "+_vm._s(_vm._f("formatAmount")(addOnData.amountTotal))+" "+_vm._s(_vm.sessionRequest.agreementData.currencyCode)+" ")])])}),_c('tr',[_c('td',[_vm._v("Total add-ons")]),_c('td',{staticClass:"text-align-right"},[_c('u',[_vm._v(_vm._s(_vm._f("formatAmount")(_vm.addOnTotal))+" "+_vm._s(_vm.sessionRequest.agreementData.currencyCode))])])]),_c('tr',[_vm._m(0),_c('td',{staticClass:"text-align-right"},[_c('strong',[_c('u',[_vm._v(_vm._s(_vm._f("formatAmount")(_vm.subTotal))+" "+_vm._s(_vm.sessionRequest.agreementData.currencyCode))])])])])],2)])]:_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('strong',[_vm._v("Total charge")])])
}]

export { render, staticRenderFns }