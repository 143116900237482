<template>
  <div id="app">
    <!-- Onboarding Result by paymentSessionGuid -->
    <template v-if="this.$route.query.paymentSessionGuid">
      <router-view />
    </template>
    <!-- Login -->
    <template v-else-if="this.$root.user.authToken === null">
      <login v-on:loggedIn="whenloggedIn()" />
    </template>
    <template v-else>
      <!-- Onboarding frontend -->
      <template v-if="this.$route.path.includes('onboard')">
        <router-view />
      </template>

      <!-- Onboarding frontend review -->
      <template v-else-if="this.$route.query.page === 'onboarding_review'">
        <onboarding />
      </template>

      <!-- Merchant backend -->
      <div
        v-else-if="this.$root.user.role === 'MerchantAdmin'"
        class="merchant-backend"
      >
        <!-- Navigation -->
        <navline />

        <!-- Router Hack for Merchant -->
        <template v-if="this.$route.query.view === 'workflows'">
          <workflow-list />
        </template>
        <template v-else-if="this.$route.query.view === 'campaigns'">
          <campaign-list />
        </template>
        <template v-else-if="this.$route.query.view === 'sessions'">
          <session-list />
        </template>
        <template v-else-if="this.$route.query.view === 'leads'">
          <lead-list />
        </template>
        <template v-else-if="this.$route.query.view === 'merchants'">
          <merchant-list />
        </template>
        <template v-else-if="this.$route.query.view === 'agencies'">
          <agency-list />
        </template>
        <template v-else-if="this.$route.query.view === 'users'">
          <user-list />
        </template>
        <template v-else-if="this.$route.query.view === 'profile'">
          <profile />
        </template>
        <template v-else>
          <router-view />
        </template>
      </div>

      <!-- Agency backend -->
      <div
        v-else-if="
          this.$root.user.role === 'AgencyManager' ||
          this.$root.user.role === 'SeniorAgent' ||
          this.$root.user.role === 'JuniorAgent'
        "
        class="agency-backend"
      >
        <!-- Navigation -->
        <navbar-top />
        <navbar-left />

        <!-- Router - Agency -->
        <template v-if="this.$route.query.merchantId">
          <!-- Wait for merchant to initialise -->
          <template v-if="this.$root.merchant.merchantId">
            <router-view />
          </template>
          <template v-else>
            <p>Loading merchant ...</p>
          </template>
        </template>
        <template v-else>
          <router-view />
        </template>
      </div>

      <!-- <router-view /> -->
    </template>
  </div>
</template>

<script>
//  Nav
import Navline from "./components/nav/Navline";
import NavbarTop from "./components/nav/NavbarTop";
import NavbarLeft from "./components/nav/NavbarLeft";
import Login from "./components/account/Login";
//  Components
import CampaignList from "./components/campaigns/CampaignList";
import WorkflowList from "./components/workflows/WorkflowList";
import SessionList from "./components/sessions/SessionList";
import LeadList from "./components/leads/LeadList";
import AgencyList from "./components/agencies/AgencyList";
import UserList from "./components/users/UserList";
import Profile from "./components/account/Profile";
import Onboarding from "./components/frontend/Onboarding";

export default {
  name: "app",
  components: {
    Navline,
    NavbarTop,
    NavbarLeft,
    Login,
    CampaignList,
    WorkflowList,
    SessionList,
    LeadList,
    AgencyList,
    UserList,
    Profile,
    Onboarding,
  },
  methods: {
    whenloggedIn: function () {
      this.$forceUpdate();
    },
  },
};
</script>
