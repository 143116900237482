<template>
  <div class="wrap sessions">
    <div class="content">
      <!-- Heading -->
      <div class="view-header-wrap uik-entity-list-header">
        <h2 class="title">Sessions</h2>

        <!-- Tooltip -->
        <tool-tip
          v-bind:tooltip="'A session represents a single submission of a workflow. <a href=&quot;https://support.onlinefundraising.dk/hc/da/articles/360013134140&quot; target=&quot;_blank&quot;>See our guide.</a>'"
        />

        <!-- Search -->
        <div class="search-wrapper">
          <form
            class="search-form"
            role="form"
            method="post"
            action=""
            autocomplete="nope"
            novalidate="novalidate"
            v-on:submit.prevent
          >
            <input
              class="uik-input__input search-input"
              type="search"
              placeholder="Search by external lead ID"
              tabindex="1"
              v-model="search"
            />
            <button type="button" tabindex="-1">
              <i class="dashboard-icon icon-search"></i>
            </button>
          </form>
        </div>

        <!-- List filter -->
        <list-filter
          v-bind:entityType="'session'"
          v-on:appliedFilter="appliedFilter()"
        />
      </div>

      <!-- Table -->
      <div class="uik-entity-list session-list">
        <table>
          <!-- Table header -->
          <thead>
            <tr>
              <th class="manage-column column-status">Status</th>
              <th class="manage-column column-created">Created</th>
              <th class="manage-column column-service-type">Intention</th>
              <th class="manage-column column-campaign">Campaign</th>
              <th class="manage-column column-workflow">Workflow</th>
              <th class="manage-column column-agency">Agency</th>
            </tr>
          </thead>

          <!-- Error -->
          <tbody v-if="this.listErrored === true">
            <tr>
              <td colspan="6" class="text-center">
                We are sorry. We were not able to retrieve sessions.
              </td>
            </tr>
          </tbody>

          <!-- Loading -->
          <tbody v-else-if="this.listLoading === true">
            <tr>
              <td colspan="6" class="text-center">Loading sessions ...</td>
            </tr>
          </tbody>

          <!-- Table -->
          <tbody v-else-if="this.$root.store.sessions.length > 0">
            <tr
              tabindex="0"
              v-for="session in this.$root.store.sessions"
              :key="session.sessionGuid"
              v-on:keyup.up="$event.target.previousElementSibling.focus()"
              v-on:keyup.down="$event.target.nextElementSibling.focus()"
              v-on:keyup.enter="viewSession(session)"
              v-on:click.exact="viewSession(session)"
              v-on:click.ctrl="viewSessionNewTab(session)"
              v-bind:class="{
                blue: session.status === 'Processing',
                green: session.status === 'Completed',
                red: session.status === 'Failed',
                grey: session.status === 'Expired',
              }"
            >
              <td>{{ session.status }}</td>
              <td>
                <span
                  v-bind:inner-html.prop="session.createdTs | formatTime"
                ></span>
              </td>
              <td>{{ session.serviceType | humanReadableServiceType }}</td>
              <td>{{ session.campaignName }}</td>
              <td>{{ session.workflowName }}</td>
              <td>{{ session.agencyId }}</td>
            </tr>
          </tbody>

          <!-- Not found -->
          <tbody v-else>
            <tr>
              <td colspan="6" class="text-center">No sessions found ...</td>
            </tr>
          </tbody>
        </table>

        <!-- Pagination -->
        <pagination
          v-if="
            this.listErrored != true &&
            this.listLoading != true &&
            this.$root.store.sessions.length > 0
          "
          v-bind:pageNumber="this.$root.store.sessionPageNumber"
          v-bind:pageSize="this.$root.store.sessionPageSize"
          v-bind:currentPageSize="this.$root.store.sessions.length"
          v-on:setPage="setPage"
        />
      </div>
    </div>
    <!-- Modal -->
    <div v-if="this.currentSession">
      <session-modal
        v-bind:session="this.currentSession"
        v-on:closeSessionModal="closeSessionModal()"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import SessionModal from "./SessionModal";
import ListFilter from "../ListFilter";
import Pagination from "../Pagination";
import ToolTip from "../ToolTip";
import { sessionHandler } from "../../mixins/sessionHandler.js";
import { formats } from "../../mixins/formats.js";

export default Vue.extend({
  name: "SessionList",
  mixins: [sessionHandler, formats],
  components: {
    SessionModal,
    ListFilter,
    Pagination,
    ToolTip,
  },
  data: function () {
    return {
      currentSession: null,
      search: null,
      listErrored: false,
      listLoading: true,
    };
  },
  created() {
    //  Reset list
    this.$root.store.sessionPageNumber = 1;
    this.$root.store.sessions = [];

    //  Get list
    this.getSessionsList();

    //  Listen to browser history navigation
    window.addEventListener("popstate", this.initialiseSessionModal, false);
  },
  watch: {
    search: function () {
      this.doSearchSessionsByExternalLeadId();
    },
  },
  methods: {
    /**
     * Open modal to view
     * @param {object} session
     */
    viewSession: function (session) {
      this.currentSession = session;
      this.$root.viewEntity("session", session.sessionGuid);
    },

    /**
     * Open modal in new tab
     * @param {object} session
     */
    viewSessionNewTab: function (session) {
      this.$root.viewEntityNewTab("session", session.sessionGuid);
    },

    /**
     * Close modal
     */
    closeSessionModal: function () {
      this.currentSession = null;
      this.$root.viewEntity("session");
    },

    /**
     * Set pagination page number
     * @param {int} pageNumber
     */
    setPage: function (pageNumber) {
      this.$root.store.sessionPageNumber = pageNumber;
      this.$root.store.sessions = [];
      this.getSessionsList();
    },

    /**
     * Apply filter
     */
    appliedFilter: function () {
      this.getSessionsList();
    },

    /**
     * Get list of sessions combined with campaigns and workflows
     */
    getSessionsList: function () {
      let sessionHandler = this.getSessions();
      if (typeof sessionHandler.then !== "undefined") {
        sessionHandler.then((response) => {
          if (response.sessionsErrored) {
            this.listErrored = true;
          }
          this.listLoading = false;

          this.initialiseSessionModal();
        });
      } else {
        this.listLoading = false;

        this.initialiseSessionModal();
      }
    },

    /**
     * Initialise session modal by query parameter
     */
    initialiseSessionModal: function () {
      //  Detect search by query
      if (this.$route.query.view === "sessions" && this.$route.query.s) {
        this.search = this.$route.query.s;
        this.doSearchSessionsByExternalLeadId();
      }

      //  Detect query
      else if (
        this.$route.query.view === "sessions" &&
        this.$route.query.guid
      ) {
        //  Handle promise or storage
        let sessionHandler = this.getSession(this.$route.query.guid);

        //  Promise
        if (typeof sessionHandler.then !== "undefined") {
          sessionHandler.then((response) => {
            if (response.sessionErrored) {
              this.currentSession = null;
            } else {
              this.currentSession = response.session;
            }
          });
        }

        //  Storage
        else if (sessionHandler.session) {
          this.currentSession = sessionHandler.session;
        }
      }

      //  No query
      else {
        this.currentSession = null;
      }
    },

    /**
     * Search
     */
    doSearchSessionsByExternalLeadId: function () {
      //  Reset
      if (this.search.length == 0) {
        this.$root.store.sessionPageNumber = 1;
        this.$root.store.sessions = [];
        this.$root.viewEntity("session");
        this.getSessionsList();
      }

      //  Initiate search
      else if (this.search.length > 1) {
        this.searchSessionsByExternalLeadId(this.search).then((response) => {
          if (response.sessionsErrored) {
            this.currentSession = null;
          } else {
            this.$root.store.sessionPageNumber = 1;
            this.$root.store.sessions = response.sessions;
            //  this.$root.viewEntity("session", null, this.search);
          }
        });
      }
    },
  },
});
</script>
