import axios from "axios";

export const merchantHandler = {
    methods: {

        /**
         * Refresh merchants
         */
        refreshMerchants: function() {

            //  Reset storage
            this.$root.store.merchants = [];

            //  Load
            this.loadMerchants();
        },

        /**
         * Load merchants from storage
         */
        loadMerchants: function() {

            //  Check storage
            if (this.$root.store.merchants.length > 0) {
                // window.console.log("merchants get list from storage");

                return {
                    merchantsErrored: false,
                    merchants: this.$root.store.merchants,
                };
            }

            //  Fetch to storage
            else {
                return this.fetchMerchants();
            }
        },

        /**
         * Get merchants from API
         */
        fetchMerchants: function() {

            // window.console.log("merchants get list from API");

            //  Request
            return axios
                .get(this.$root.apiUrl + "/merchants", {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.$root.user.authToken
                    }
                })
                .then(response => {
                    //  Build storage
                    this.$root.store.merchants = response.data;

                    return {
                        merchantsErrored: false,
                        merchants: this.$root.store.merchants
                    };
                })
                .catch(error => {

                    this.$root.handleErrorResponse(error);

                    return {
                        merchantsErrored: true,
                        merchant: []
                    };
                });
        },

        /**
         * Get merchant by merchantId
         * @param {string} merchantId
         */
        getMerchant: function(merchantId) {

            let merchant = this.loadMerchant(merchantId);
            if (merchant) {

                return {
                    merchantErrored: false,
                    merchant: merchant
                };
            } else {

                // window.console.log("merchant: get merchant from API");

                //  Request
                return axios
                    .get(this.$root.apiUrl + this.returnMerchantApiPath(merchantId), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken
                        }
                    })
                    .then(response => {

                        return {
                            merchantErrored: false,
                            merchant: response.data
                        };
                    })
                    .catch(error => {

                        this.$root.handleErrorResponse(error);

                        return {
                            merchantErrored: true,
                            merchant: {}
                        };
                    });
            }
        },

        /**
         * Load merchant from storage
         * @param {string} merchantId
         */
        loadMerchant: function(merchantId) {

            if (this.$root.merchant.merchantId === merchantId && this.$root.merchant.name) {

                // window.console.log("merchant: get merchant from storage");

                return this.$root.merchant;

            } else if (this.$root.store.merchants.length > 0) {
                for (var i = 0; i < this.$root.store.merchants.length; i++) {
                    if (merchantId === this.$root.store.merchants[i].merchantId) {

                        // window.console.log("merchant: get merchant from storage");

                        return this.$root.store.merchants[i];
                    }
                }
            }

            return null;
        },

        /**
         * Return API endpoint
         * @param {string} merchantId
         */
        returnMerchantApiPath: function(merchantId) {
            //  Merchant
            if (this.$root.user.role === "MerchantAdmin") {
                return (
                    "/merchant/" +
                    encodeURIComponent(merchantId)
                );
            }

            //  Agency
            else if (merchantId && this.$root.user.agencyId) {
                return (
                    "/merchant/" +
                    encodeURIComponent(merchantId) +
                    "?agencyId=" +
                    encodeURIComponent(this.$root.user.agencyId)
                );
            }

            //  Error
            else {
                return null;
            }
        }
    }
};