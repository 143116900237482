<template>
  <div class="wrap dashboard">
    <div class="content">
      <div v-if="this.$root.user.role === 'MerchantAdmin'">
        <h2 v-if="this.$root.user.name">Welcome {{ this.$root.user.name }}</h2>
        <h2 v-else>Welcome merchant admin</h2>
      </div>
      <div v-else-if="this.$root.user.role === 'AgencyManager'">
        <h2 v-if="this.$root.user.name">Welcome {{ this.$root.user.name }}</h2>
        <h2 v-else>Welcome agency manager</h2>
        <p>Please select a client on the left</p>
      </div>
      <div v-else-if="this.$root.user.role === 'SeniorAgent'">
        <h2 v-if="this.$root.user.name">Welcome {{ this.$root.user.name }}</h2>
        <h2 v-else>Welcome senior agent</h2>
        <p>Please select a client on the left</p>
      </div>
      <div v-else-if="this.$root.user.role === 'JuniorAgent'">
        <h2 v-if="this.$root.user.name">Welcome {{ this.$root.user.name }}</h2>
        <h2 v-else>Welcome junior agent</h2>
        <p>Please select a client on the left</p>
      </div>
      <div v-else>Loading ...</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Dashboard",
});
</script>
