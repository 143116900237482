<template>
  <div class="row">
    <div class="col-sm-6 control-input">
      <label for="eanNumber">Enter EAN number</label>
      <input
        type="number"
        id="eanNumber"
        class="uik-input__input"
        minlength="13"
        maxlength="13"
        autocomplete="nope"
        placeholder="Insert required EAN number"
        required
        v-bind:class="[
          product.eanNumber && product.eanNumber.length > 0
            ? 'not-empty'
            : 'empty',
        ]"
        v-model.trim="product.eanNumber"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "OnboardingPaymentMethodTypeSelectionEAN",
  props: {
    sessionRequest: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
});
</script>
