<template>
  <div class="wrap users">
    <div class="content">
      <!-- Heading -->
      <div class="view-header-wrap uik-entity-list-header">
        <h2 class="title">Users</h2>
        <div
          v-if="this.$root.user.role === 'AgencyManager'"
          class="right-aligned popover-wrapper"
        >
          <button
            type="button"
            class="uik-btn__base btn btn-primary"
            tabindex="-1"
            v-on:click="newUser()"
          >
            Create new
          </button>
        </div>
      </div>

      <!-- Table -->
      <div class="uik-entity-list user-list">
        <table>
          <!-- Table header -->
          <thead>
            <tr>
              <th class="manage-column column-name">Name</th>
              <th class="manage-column column-email">Email</th>
              <th class="manage-column column-role">User role</th>
            </tr>
          </thead>

          <!-- Error -->
          <tbody v-if="this.usersErrored === true">
            <tr>
              <td colspan="3" class="text-center">
                We are sorry. We were not able to retrieve users.
              </td>
            </tr>
          </tbody>

          <!-- Loadng -->
          <tbody v-else-if="this.usersLoading === true">
            <tr>
              <td colspan="3" class="text-center">Loading users ...</td>
            </tr>
          </tbody>

          <!-- Table body -->
          <tbody v-else>
            <tr
              tabindex="0"
              v-for="user in this.$root.store.users"
              v-bind:key="user.userGuid"
              v-on:click="editUser(user)"
            >
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.role }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Modal -->
    <div v-if="this.currenctUser">
      <user-modal
        v-bind:user="this.currenctUser"
        v-on:closeUser="closeUser()"
        v-on:archivedUser="refreshUsers()"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import UserModal from "./UserModal";

export default Vue.extend({
  name: "UserList",
  components: {
    UserModal,
  },
  data: function () {
    return {
      currenctUser: null,
      usersErrored: false,
      usersLoading: true,
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    /**
     * Open modal to create
     */
    newUser: function () {
      this.currenctUser = {};
    },

    /**
     * Open modal to edit
     * @param {object} user
     */
    editUser: function (user) {
      if (user.userGuid === this.$root.user.guid) {
        this.$router.push({
          name: "agency-profile",
        });
      } else if (this.$root.user.role === "AgencyManager") {
        this.currenctUser = user;
      }
    },

    /**
     * Close modal
     */
    closeUser: function () {
      this.currenctUser = null;
    },

    /**
     * Refresh list of users
     */
    refreshUsers: function () {
      this.$root.store.users = [];
      this.currenctUser = null;
      this.usersLoading = true;
      this.getUsers();
    },

    /**
     * Get users from API
     */
    getUsers: function () {
      //  Check storage
      if (this.$root.store.users.length > 0) {
        this.usersLoading = false;
        return;
      }

      //  Request
      axios
        .get(this.$root.apiUrl + "/users", {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$root.user.authToken,
          },
        })
        .then((response) => {
          //  Build storage
          this.$root.store.users = response.data;
        })
        .catch((error) => {
          this.usersErrored = true;
          this.$root.handleErrorResponse(error);
        })
        .finally(() => {
          this.usersLoading = false;
        });
    },
  },
});
</script>
