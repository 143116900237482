<template>
  <div>
    <nav class="pagination">
      <button
        v-if="pageNumber > 1"
        type="button"
        class="uik-btn__base paginate-prev"
        v-on:click="prevPage()"
      >
        <i class="dashboard-icon icon-triangle-left"></i>
      </button>

      <button
        v-if="pageNumber > 2"
        type="button"
        class="uik-btn__base page-link"
        v-on:click="setPage(pageNumber - 2)"
      >
        {{ pageNumber - 2 }}
      </button>
      <button
        v-if="pageNumber > 1"
        type="button"
        class="uik-btn__base page-link"
        v-on:click="setPage(pageNumber - 1)"
      >
        {{ pageNumber - 1 }}
      </button>
      <button type="button" class="uik-btn__base page-link active">
        {{ pageNumber }}
      </button>
      <button
        v-if="currentPageSize === pageSize"
        type="button"
        class="uik-btn__base page-link"
        v-on:click="setPage(pageNumber + 1)"
      >
        {{ pageNumber + 1 }}
      </button>
      <button
        v-if="currentPageSize === pageSize"
        type="button"
        class="uik-btn__base page-link"
        v-on:click="setPage(pageNumber + 2)"
      >
        {{ pageNumber + 2 }}
      </button>

      <button
        v-if="currentPageSize === pageSize"
        type="button"
        class="uik-btn__base paginate-next"
        v-on:click="nextPage()"
      >
        <i class="dashboard-icon icon-triangle-right"></i>
      </button>
    </nav>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Pagination",
  props: {
    pageNumber: {
      type: Number,
    },
    pageSize: {
      type: Number,
    },
    currentPageSize: {
      type: Number,
    },
  },
  mounted() {
    //  Hotkey to navigate
    let self = this;
    window.addEventListener("keyup", function (e) {
      if (e.code === "ArrowLeft") {
        self.prevPage();
      } else if (e.code === "ArrowRight") {
        self.nextPage();
      }
    });
  },
  methods: {
    /**
     * Previous
     */
    prevPage: function () {
      if (this.pageNumber > 1) {
        this.setPage(this.pageNumber - 1);
      }
    },

    /**
     * Next
     */
    nextPage: function () {
      this.setPage(this.pageNumber + 1);
    },

    /**
     * Go to page
     * @param {int} pageNumber
     */
    setPage: function (pageNumber) {
      this.$emit("setPage", pageNumber);
    },
  },
});
</script>
