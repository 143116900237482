<template>
  <div class="review-product">
    <table class="product-info">
      <tbody>
        <!-- paymentType -->
        <tr>
          <td class="table-header">Type</td>
          <td v-if="product.paymentType === 'Recurring'">Recurring</td>
          <td v-else>Single</td>
        </tr>

        <!-- Agreement -->
        <tr
          v-if="
            product.paymentType === 'Recurring' &&
            product.agreementType !== 'Personal'
          "
        >
          <td class="table-header">Agreement type</td>
          <td>Shared</td>
        </tr>
        <tr v-if="product.paymentType === 'Recurring'">
          <td class="table-header">Agreement name</td>
          <td>{{ product.agreementName }}</td>
        </tr>

        <!-- Amount -->
        <tr>
          <td class="table-header">Amount, incl. VAT</td>
          <td>
            {{ product.amountTotal | formatAmount }}
            {{ product.currencyCode }}
          </td>
        </tr>

        <!-- OneOff Amount -->
        <tr
          v-if="
            //  staticWorkflowProduct.scheduleStartWithOneOff &&
            product.oneOffAmount &&
            product.paymentMethodType !== 'Betalingsservice' &&
            //  Defined explicitly or derived from schedule starting now
            (product.startWithOneOff == true ||
              product.scheduleStartType == 'now')
          "
        >
          <td class="table-header">Amount on instant payment</td>
          <td>
            {{ product.oneOffAmount | formatAmount }}
            {{ product.currencyCode }}
          </td>
        </tr>

        <!-- Tax -->
        <tr>
          <td class="table-header">Tax-deductible</td>
          <td>{{ product.taxDeductable | formatBoolean }}</td>
        </tr>

        <!-- Frequency -->
        <tr v-if="product.paymentType === 'Recurring'">
          <td class="table-header">Frequency</td>
          <td>
            <span
              v-html="this.humanReadableSchedule(product, 'Please define')"
            ></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from "vue";
import { formats } from "../../mixins/formats.js";

export default Vue.extend({
  name: "OnboardingSignUpReviewTable",
  mixins: [formats],
  props: {
    product: {
      type: Object,
      required: true,
    },
    staticWorkflowProduct: {
      type: Object,
      required: true,
    },
  },
  methods: {
    triggerForceUpdate: function () {
      this.$forceUpdate();
    },
  },
});
</script>
