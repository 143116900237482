<template>
  <div class="wrap campaigns">
    <div class="content">
      <!-- Heading -->
      <div class="view-header-wrap uik-entity-list-header">
        <h2 class="title">Campaigns</h2>

        <!-- Tooltip -->
        <tool-tip
          v-bind:tooltip="'A configuration of when and who is pitching what on your behalf. <a href=&quot;https://support.onlinefundraising.dk/hc/da/articles/360013133660&quot; target=&quot;_blank&quot;>See our guide.</a>'"
        />

        <div class="right-aligned popover-wrapper">
          <!-- State filter -->
          <state-filter v-on:appliedFilter="appliedFilter()" />

          <!-- Button -->
          <button
            v-if="this.$root.user.role === 'MerchantAdmin'"
            type="button"
            class="uik-btn__base btn btn-primary"
            tabindex="-1"
            v-on:click="newCampaign()"
          >
            Create new
          </button>
        </div>
      </div>

      <!-- Table -->
      <div class="uik-entity-list campaign-list">
        <table>
          <!-- Table header -->
          <thead>
            <tr class="uik-entity-list campaign-list">
              <th class="manage-column column-created">Created</th>
              <th class="manage-column column-campaignName">Name</th>
              <th class="manage-column column-workflow">Workflow</th>
              <th
                v-if="this.$root.user.role === 'MerchantAdmin'"
                class="manage-column column-agency"
              >
                Agency
              </th>
              <th class="manage-column column-campaginDates">Running period</th>
            </tr>
          </thead>

          <!-- Error -->
          <tbody v-if="this.listErrored">
            <tr>
              <td
                v-bind:colspan="
                  this.$root.user.role === 'MerchantAdmin' ? '5' : '4'
                "
                class="text-center"
              >
                We are sorry. We were not able to retrieve your campaigns.
              </td>
            </tr>
          </tbody>

          <!-- Loading -->
          <tbody v-else-if="this.listLoading">
            <tr>
              <td
                v-bind:colspan="
                  this.$root.user.role === 'MerchantAdmin' ? '5' : '4'
                "
                class="text-center"
              >
                Loading campaigns ...
              </td>
            </tr>
          </tbody>

          <!-- Table body -->
          <tbody v-else-if="this.$root.store.campaigns.length > 0">
            <tr
              tabindex="0"
              v-for="campaign in this.$root.store.campaigns"
              v-bind:key="campaign.campaignGuid"
              v-on:keyup.up="$event.target.previousElementSibling.focus()"
              v-on:keyup.down="$event.target.nextElementSibling.focus()"
              v-on:keyup.enter="editCampaign(campaign)"
              v-on:click.exact="editCampaign(campaign)"
              v-on:click.ctrl="editCampaignNewTab(campaign)"
              v-bind:class="{
                green: campaign.active === true,
                grey: campaign.active === false,
              }"
            >
              <td>
                <span
                  v-bind:inner-html.prop="campaign.createdTs | formatTime"
                ></span>
              </td>
              <td>
                {{ campaign.name }}
              </td>
              <td>
                {{ campaign.workflowName }}
              </td>
              <td v-if="$root.user.role == 'MerchantAdmin'">
                {{ campaign.agencyId }}
              </td>
              <td>
                <span
                  v-bind:inner-html.prop="campaign.firstDate | formatDate"
                ></span>
                -
                <span
                  v-bind:inner-html.prop="
                    campaign.lastDate | formatDateWithColor
                  "
                ></span>
              </td>
            </tr>
          </tbody>

          <!-- Not found -->
          <tbody v-else>
            <tr>
              <td
                v-bind:colspan="
                  this.$root.user.role === 'MerchantAdmin' ? '5' : '4'
                "
                class="text-center"
              >
                Ready for campaigns ...
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Modal -->
    <div v-if="this.currentCampaign">
      <campaign-modal
        v-bind:campaign="this.currentCampaign"
        v-on:insertedCampaign="initialiseCampaignModal()"
        v-on:updatedCampaign="getCampaignsList()"
        v-on:archivedCampaign="refreshCampaigns()"
        v-on:closeCampaignModal="closeCampaignModal()"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import CampaignModal from "./CampaignModal";
import StateFilter from "../StateFilter";
import ToolTip from "../ToolTip";
import { campaignHandler } from "../../mixins/campaignHandler.js";
import { formats } from "../../mixins/formats.js";

export default Vue.extend({
  name: "CampaignList",
  mixins: [campaignHandler, formats],
  components: {
    CampaignModal,
    StateFilter,
    ToolTip,
  },
  data: function () {
    return {
      currentCampaign: null,
      listErrored: false,
      listLoading: true,
    };
  },
  created() {
    //  Reset list
    this.$root.store.campaigns = [];

    //  Get list
    this.getCampaignsList();

    //  Listen to browser history navigation
    window.addEventListener("popstate", this.initialiseCampaignModal, false);
  },
  methods: {
    /**
     * Open campaign modal for new
     */
    newCampaign: function () {
      this.currentCampaign = {};
    },

    /**
     * Open campaign modal to edit
     */
    editCampaign: function (campaign) {
      this.currentCampaign = campaign;
      this.$root.viewEntity("campaign", campaign.campaignGuid);
    },

    /**
     * Open campaign modal in new tab
     */
    editCampaignNewTab: function (campaign) {
      this.$root.viewEntityNewTab("campaign", campaign.campaignGuid);
    },

    /**
     * Close campaign modal
     */
    closeCampaignModal: function () {
      this.currentCampaign = null;
      this.$root.viewEntity("campaign");
    },

    /**
     * Get list of campaigns combined with workflows
     */
    getCampaignsList: function () {
      let campaignHandler = this.getCampaigns();
      if (typeof campaignHandler.then !== "undefined") {
        campaignHandler.then((response) => {
          if (response.campaignsErrored) {
            this.listErrored = true;
          }
          this.listLoading = false;

          this.initialiseCampaignModal();
        });
      } else {
        this.listLoading = false;

        this.initialiseCampaignModal();
      }
    },

    /**
     * Initialise campaign modal by query parameter
     */
    initialiseCampaignModal: function () {
      //  Detect query
      if (this.$route.query.view === "campaigns" && this.$route.query.guid) {
        //  Handle promise or storage
        let campaignHandler = this.getCampaign(this.$route.query.guid);

        //  Promise
        if (typeof campaignHandler.then !== "undefined") {
          campaignHandler.then((response) => {
            if (response.campaignErrored) {
              this.currentCampaign = null;
            } else {
              this.currentCampaign = response.campaign;
            }
          });
        }

        //  Storage
        else if (campaignHandler.campaign) {
          this.currentCampaign = campaignHandler.campaign;
        }
      }

      //  No query
      else {
        this.currentCampaign = null;
      }
    },

    /**
     * Apply filter
     */
    appliedFilter: function () {
      this.refreshCampaigns();
    },

    /**
     * Refresh list of campaigns
     */
    refreshCampaigns: function () {
      this.$root.store.campaigns = [];
      this.currentCampaign = null;
      this.listLoading = true;
      this.getCampaignsList();
    },
  },
});
</script>
