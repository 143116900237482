import axios from "axios";
import { campaignHandler } from "./campaignHandler.js";
import { workflowHandler } from "./workflowHandler.js";

export const sessionHandler = {
    mixins: [campaignHandler, workflowHandler],
    methods: {

        /**
         * Get session by guid
         * @param {string} guid 
         */
        getSession: function(guid) {

            let session = this.loadSession(guid);
            if (session) {

                return {
                    sessionErrored: false,
                    session: session
                };
            } else {

                // window.console.log("session: get session from API");

                //  Request
                return axios
                    .get(this.$root.apiUrl + this.returnSessionApiPath(guid), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken
                        }
                    })
                    .then(response => {

                        return {
                            sessionErrored: false,
                            session: response.data
                        };
                    })
                    .catch(error => {

                        this.$root.handleErrorResponse(error);

                        return {
                            sessionErrored: true,
                            session: null
                        };
                    });
            }
        },

        /**
         * Get sessions with campaigns and workflows
         */
        getSessions: function() {

            //  Get filtered campaignGuid
            let campaignGuid = (this.$root.store.sessionsByCampaign) ? this.$root.store.sessionsByCampaign : null;

            //  Prepare API request
            let request = [];

            //  Append session request, if empty storage
            if (this.$root.store.sessions.length === 0) {
                request.push(
                    axios.get(this.$root.apiUrl + this.returnSessionsApiPath(campaignGuid), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken
                        }
                    })
                );
            } else {
                request.push('');
            }

            //  Append campaign request, if empty storage
            if (this.$root.store.campaigns.length === 0) {
                request.push(
                    axios.get(this.$root.apiUrl + this.returnCampaignsApiPath('all'), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken
                        }
                    })
                );
            } else {
                request.push('');
            }

            //  Append workflow request, if empty storage
            if (this.$root.store.workflows.length === 0) {
                request.push(
                    axios.get(this.$root.apiUrl + this.returnWorkflowsApiPath(), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken
                        }
                    })
                );
            } else {
                request.push('');
            }

            //  Request
            return axios
                .all(request)
                .then(
                    axios.spread((sessionResponse, campaignResponse, workflowResponse) => {
                        if (this.$root.store.sessions.length === 0) {

                            //  Set pageNumber
                            this.$root.store.sessionPageNumber = sessionResponse.data.pageNumber;

                            //  Detect last page by given pageSize
                            if (this.$root.store.sessionPageSize !== sessionResponse.data.pageSize) {
                                //  TODO: Account for pagination limit
                            }

                            //  Build storage for single retrival
                            for (var i = 0; i < sessionResponse.data.list.length; i++) {
                                this.$root.store.sessionPageStorage.push(sessionResponse.data.list[i]);
                            }

                            //  Build storage
                            this.$root.store.sessions = sessionResponse.data.list;
                        }

                        if (this.$root.store.campaigns.length === 0) {

                            //  Build storage
                            this.$root.store.campaigns = campaignResponse.data;
                        }

                        if (this.$root.store.workflows.length === 0) {

                            //  Build storage
                            this.$root.store.workflows = workflowResponse.data;
                        }

                        //  Combine results
                        this.combineSessionsWithCampaignsAndWorkflows();

                        return {
                            sessionsErrored: false,
                            campaigns: this.$root.store.campaigns,
                            workflows: this.$root.store.workflows,
                            sessions: this.$root.store.sessions
                        };
                    })
                )
                .catch(error => {

                    this.$root.handleErrorResponse(error);

                    return {
                        sessionsErrored: true,
                        campaigns: [],
                        workflows: [],
                        sessions: []
                    };
                });
        },

        /**
         * Search sessions by given externalLeadId
         * @param {string} externalLeadId
         */
        searchSessionsByExternalLeadId: function(externalLeadId) {

            //  Request
            return axios
                .get(this.$root.apiUrl + this.returnSessionsApiPath(null, externalLeadId), {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.$root.user.authToken
                    }
                })
                .then(response => {

                    return {
                        sessionsErrored: false,
                        sessions: response.data
                    };
                })
                .catch(error => {

                    this.$root.handleErrorResponse(error);

                    return {
                        sessionsErrored: true,
                        sessions: []
                    };
                });
        },

        /**
         * Combine sessions with campaigns and workflows
         */
        combineSessionsWithCampaignsAndWorkflows: function() {
            //  Loop sessions
            for (var a = 0; a < this.$root.store.sessions.length; a++) {
                //  Loop campaigns
                for (var b = 0; b < this.$root.store.campaigns.length; b++) {
                    if (
                        this.$root.store.campaigns[b].campaignGuid ===
                        this.$root.store.sessions[a].campaignGuid
                    ) {
                        this.$root.store.sessions[
                            a
                        ].campaignName = this.$root.store.campaigns[b].name;
                    }
                }

                //  Loop workflows
                for (var c = 0; c < this.$root.store.workflows.length; c++) {
                    if (
                        this.$root.store.workflows[c].workflowGuid ===
                        this.$root.store.sessions[a].workflowGuid
                    ) {
                        this.$root.store.sessions[
                            a
                        ].workflowName = this.$root.store.workflows[c].name;
                    }
                }
            }

            //  Force update needed to refresh UI with extended sessions data
            this.$forceUpdate();
        },

        /**
         * Load session from storage
         * @param {string} guid 
         */
        loadSession: function(guid) {

            if (this.$root.store.sessions.length > 0) {
                for (var i = 0; i < this.$root.store.sessions.length; i++) {
                    if (guid === this.$root.store.sessions[i].sessionGuid) {

                        // window.console.log("session: get session from storage");

                        return this.$root.store.sessions[i];
                    }
                }
            }

            return null;
        },

        /**
         * Return API endpoint
         * @param {string} guid 
         */
        returnSessionApiPath: function(guid) {
            //  Merchant
            if (this.$root.user.role === "MerchantAdmin") {
                return (
                    "/session/" + guid + "?merchantId=" +
                    encodeURIComponent(this.$root.user.merchantId)
                );
            }

            //  Agency
            else if (this.$root.merchant.merchantId && this.$root.user.agencyId) {
                return (
                    "/session/" + guid + "?merchantId=" +
                    encodeURIComponent(this.$root.merchant.merchantId) +
                    "&agencyId=" +
                    encodeURIComponent(this.$root.user.agencyId)
                );
            }

            //  Error
            else {
                return null;
            }
        },

        /**
         * Return API endpoint
         * @param {string} campaignGuid
         * @param {string} externalLeadId
         */
        returnSessionsApiPath: function(campaignGuid = null, externalLeadId = null) {
            //  Merchant
            if (this.$root.user.role === "MerchantAdmin") {

                if (externalLeadId) {
                    return (
                        "/sessions?externalLeadId=" + externalLeadId + (campaignGuid ? "&campaignGuid=" + campaignGuid : '') + "&merchantId=" + encodeURIComponent(this.$root.user.merchantId)
                    );
                } else {

                    return (
                        "/sessions?pageNumber=" + this.$root.store.sessionPageNumber + "&pageSize=" + this.$root.store.sessionPageSize + (campaignGuid ? "&campaignGuid=" + campaignGuid : '') + "&merchantId=" +
                        encodeURIComponent(this.$root.user.merchantId)
                    );
                }
            }

            //  Agency
            else if (this.$root.merchant.merchantId && this.$root.user.agencyId) {

                if (externalLeadId) {
                    return (
                        "/sessions?externalLeadId=" + externalLeadId + (campaignGuid ? "&campaignGuid=" + campaignGuid : '') + "&merchantId=" +
                        encodeURIComponent(this.$root.merchant.merchantId) +
                        "&agencyId=" +
                        encodeURIComponent(this.$root.user.agencyId)
                    );
                } else {
                    return (
                        "/sessions?pageNumber=" + this.$root.store.sessionPageNumber + "&pageSize=" + this.$root.store.sessionPageSize + (campaignGuid ? "&campaignGuid=" + campaignGuid : '') + "&merchantId=" +
                        encodeURIComponent(this.$root.merchant.merchantId) +
                        "&agencyId=" +
                        encodeURIComponent(this.$root.user.agencyId)
                    );
                }

            }

            //  Error
            else {
                return null;
            }
        }
    }
};