<template>
  <div class="upgrade-by-change-payment-method">
    <!-- Confirmation -->
    <template v-if="sessionStatus === 'Completed'">
      <!-- Success message -->
      <div
        class="alert alert-success center-aligned"
        tabindex="0"
        ref="successMessage"
        role="alert"
        aria-label="Sessionen er nu gennemført."
      >
        Session complete, please proceed to the next lead
      </div>
      <hr />

      <!-- Refresh instance -->
      <button
        type="button"
        class="uik-btn__base btn btn-secondary"
        style="display: block; margin: 0 auto"
        v-on:click="refreshPage()"
        aria-hidden="true"
      >
        Start again
      </button>
    </template>

    <!-- Change PaymentMethod -->
    <template v-else>
      <!-- PaymentMethodType selection -->
      <onboarding-payment-method-type-selection
        v-if="!iframeGatewayUrl && Object.keys(product).length > 0"
        ref="OnboardingPaymentMethodTypeSelection"
        v-bind:product="this.product"
        v-bind:staticWorkflowProduct="this.staticWorkflowProduct"
        v-bind:sessionRequest="this.sessionRequest"
        v-on:whenPaymentMethodTypeChanged="updateUpgrade()"
        v-on:whenBsAppVerificationChange="whenBsAppVerificationChange()"
      />

      <!-- Complete by SMS -->
      <onboarding-send-sms
        v-if="!iframeGatewayUrl && Object.keys(product).length > 0"
        ref="OnboardingSendSms"
        v-bind:product="this.product"
        v-bind:sessionRequest="this.sessionRequest"
        v-bind:getContactPhone="this.getContactPhone"
        v-on:whenBsAppVerificationChange="whenBsAppVerificationChange()"
      />

      <!-- iframe -->
      <div v-if="iframeGatewayUrl" class="iframe-holder">
        <h3>Enter gateway details</h3>
        <p>Abort session to change i.e. payment method</p>
        <iframe
          type="text/html"
          v-bind:src="iframeGatewayUrl"
          width="100%"
          v-bind:height="iframeGatewayHeight + 'px'"
          allowtransparency="true"
          scrolling="yes"
          seamless
          webkitAllowFullScreen
          mozallowfullscreen
          allowFullScreen
        ></iframe>
      </div>

      <!-- Submit -->
      <div
        v-if="product.paymentMethodType"
        class="footer-action center-aligned"
      >
        <!-- Screen reader info -->
        <div
          v-if="sessionStatus === 'Committed'"
          role="status"
          aria-label="En SMS er nu afsendt til vedkommende. Vent venligst på deres bekræftelse."
          class="sr-only"
        ></div>

        <!-- Please wait -->
        <p v-if="sessionStatus === 'Committed'">
          Please wait while we connect to the gateway ...
        </p>
        <div v-if="sessionStatus === 'Committed'" class="wave wave-loading">
          <div class="ver1"></div>
          <div class="ver2"></div>
          <div class="ver3"></div>
          <div class="ver4"></div>
          <div class="ver5"></div>
        </div>

        <button
          type="button"
          class="uik-btn__base btn btn-primary"
          v-on:click="initialiseRequest(product, 'ChangePaymentMethod')"
          v-bind:disabled="
            this.sessionDelayCounter < 1 &&
            (sessionStatus === null ||
              sessionStatus === 'Failed' ||
              sessionStatus === 'Aborted')
              ? false
              : true
          "
          aria-label="Start session igen."
        >
          <span v-if="sessionStatus === 'Failed' || sessionStatus === 'Aborted'"
            >Restart session
            <template v-if="this.sessionDelayCounter > 0"
              >({{ this.sessionDelayCounter }})</template
            ></span
          >
          <span v-else
            >Start session
            <template v-if="this.sessionDelayCounter > 0"
              >({{ this.sessionDelayCounter }})</template
            ></span
          >
        </button>
        <button
          type="button"
          class="uik-btn__base btn btn-secondary"
          v-on:click="abortSession()"
          v-bind:class="[
            sessionStatus !== null &&
            sessionStatus !== 'Failed' &&
            sessionStatus !== 'Aborted' &&
            sessionStatus !== 'Completed'
              ? 'uik-btn__base btn btn-secondary'
              : 'uik-btn__base btn btn-secondary no-visibility',
          ]"
          aria-label="Afbryd sessionen."
        >
          Abort session
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import OnboardingPaymentMethodTypeSelection from "./OnboardingPaymentMethodTypeSelection";
import OnboardingSendSms from "./OnboardingSendSms";
import { changeEvents } from "../../mixins/changeEvents.js";
import { sessionRequestHandler } from "../../mixins/sessionRequestHandler.js";

export default Vue.extend({
  name: "OnboardingUpgradeChangePaymentMethod",
  mixins: [changeEvents, sessionRequestHandler],
  components: {
    OnboardingPaymentMethodTypeSelection,
    OnboardingSendSms,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    staticWorkflowProduct: {
      type: Object,
      required: true,
    },
    sessionRequest: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      sessionStatus: null,
      iframeGatewayUrl: null,
      iframeGatewayHeight: 650,
      sessionDelayCounter: 0,
    };
  },
  computed: {
    /**
     * Get contact phone
     */
    getContactPhone: function () {
      if (this.sessionRequest.workflow.hasOwnProperty("contactDataFields")) {
        let contactPhoneField =
          this.sessionRequest.workflow.contactDataFields.filter(function (
            field
          ) {
            if (field.name === "msisdn" && field.value) {
              return field;
            }
          });

        if (contactPhoneField.length > 0) {
          return contactPhoneField[0].value;
        }
      }

      return null;
    },
  },
  created() {
    //  Initialise session delay counter
    setInterval(
      function () {
        this.sessionDelayCounter = this.sessionDelayCounter - 1;
      }.bind(this),
      1000
    );
  },
  methods: {
    /**
     * Force update
     */
    updateUpgrade: function () {
      window.console.log("updateUpgrade triggered");

      this.$forceUpdate();
    },

    /**
     * Force update needed to update selection
     */
    whenBsAppVerificationChange: function () {
      this.$forceUpdate();
    },

    /**
     * Refresh the page
     */
    refreshPage: function () {
      window.location.reload();
    },
  },
});
</script>
