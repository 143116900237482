var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"review-product"},[_c('table',{staticClass:"product-info"},[_c('tbody',[_c('tr',[_c('td',{staticClass:"table-header"},[_vm._v("Type")]),(_vm.product.paymentType === 'Recurring')?_c('td',[_vm._v("Recurring")]):_c('td',[_vm._v("Single")])]),(
          _vm.product.paymentType === 'Recurring' &&
          _vm.product.agreementType !== 'Personal'
        )?_c('tr',[_c('td',{staticClass:"table-header"},[_vm._v("Agreement type")]),_c('td',[_vm._v("Shared")])]):_vm._e(),(_vm.product.paymentType === 'Recurring')?_c('tr',[_c('td',{staticClass:"table-header"},[_vm._v("Agreement name")]),_c('td',[_vm._v(_vm._s(_vm.product.agreementName))])]):_vm._e(),_c('tr',[_c('td',{staticClass:"table-header"},[_vm._v("Amount, incl. VAT")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatAmount")(_vm.product.amountTotal))+" "+_vm._s(_vm.product.currencyCode)+" ")])]),(
          //  staticWorkflowProduct.scheduleStartWithOneOff &&
          _vm.product.oneOffAmount &&
          _vm.product.paymentMethodType !== 'Betalingsservice' &&
          //  Defined explicitly or derived from schedule starting now
          (_vm.product.startWithOneOff == true ||
            _vm.product.scheduleStartType == 'now')
        )?_c('tr',[_c('td',{staticClass:"table-header"},[_vm._v("Amount on instant payment")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatAmount")(_vm.product.oneOffAmount))+" "+_vm._s(_vm.product.currencyCode)+" ")])]):_vm._e(),_c('tr',[_c('td',{staticClass:"table-header"},[_vm._v("Tax-deductible")]),_c('td',[_vm._v(_vm._s(_vm._f("formatBoolean")(_vm.product.taxDeductable)))])]),(_vm.product.paymentType === 'Recurring')?_c('tr',[_c('td',{staticClass:"table-header"},[_vm._v("Frequency")]),_c('td',[_c('span',{domProps:{"innerHTML":_vm._s(this.humanReadableSchedule(_vm.product, 'Please define'))}})])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }