<template>
  <div>
    <!-- Modal -->
    <div v-if="campaign" class="modal slideIn">
      <div class="modal-content">
        <!-- Header -->
        <div class="view-header-wrap uik-entity-list-header">
          <h2
            v-if="
              this.$root.user.role === 'MerchantAdmin' && campaign.campaignGuid
            "
            class="title"
          >
            Edit campaign: {{ campaign.name }}
          </h2>
          <h2 v-else-if="campaign.campaignGuid" class="title">
            Campaign: {{ campaign.name }}
          </h2>
          <h2 v-else class="title">Create new campaign</h2>

          <!-- Buttons -->
          <div
            v-if="this.$root.user.role === 'MerchantAdmin'"
            class="right-aligned popover-wrapper"
          >
            <button
              v-if="campaign.campaignGuid"
              type="button"
              class="uik-btn__link btn"
              v-on:click="closeCampaignModal()"
            >
              Close
            </button>
            <button
              v-else
              type="button"
              class="uik-btn__base btn"
              v-on:click="closeCampaignModal()"
            >
              Cancel
            </button>
            <button
              v-if="campaign.campaignGuid"
              type="button"
              class="uik-btn__link btn btn-archive"
              v-on:click="archiveCampaign()"
            >
              Archive
            </button>
            <button
              v-if="campaign.campaignGuid"
              type="button"
              class="uik-btn__base btn btn-primary"
              tabindex="-1"
              v-on:click="updateCampaign()"
            >
              Update
            </button>
            <button
              v-else
              type="button"
              class="uik-btn__base btn btn-primary"
              tabindex="-1"
              v-on:click="insertCampaign()"
            >
              Create
            </button>
          </div>
          <div v-else class="right-aligned popover-wrapper">
            <button
              v-if="campaign.campaignGuid"
              type="button"
              class="uik-btn__link btn"
              v-on:click="closeCampaignModal()"
            >
              Close
            </button>
          </div>
        </div>

        <!-- Columns -->
        <div class="campaign-settings-wrap">
          <div class="row">
            <!-- Column left open -->
            <div class="col-sm-8">
              <div class="block-wrap">
                <div class="block">
                  <!-- Name -->
                  <div class="block-header">
                    <h4 class="header">Campaign name</h4>
                    <small class="input-helper-text"
                      >Appears only to the agent</small
                    >
                  </div>
                  <div class="control-input">
                    <input
                      type="text"
                      id="campaignName"
                      class="uik-input__input"
                      placeholder="Give your campaign a name"
                      required
                      v-bind:disabled="
                        this.$root.user.role === 'MerchantAdmin' ? false : true
                      "
                      v-model="campaign.name"
                    />
                  </div>
                  <!-- Description -->
                  <div class="block-header">
                    <h4 class="header">Description</h4>
                    <small class="input-helper-text"
                      >Appears only to the agent</small
                    >
                  </div>
                  <div class="control-input">
                    <textarea
                      id="campaignDescription"
                      class="uik-input__input"
                      placeholder="Describe your campaign"
                      required
                      v-bind:disabled="
                        this.$root.user.role === 'MerchantAdmin' ? false : true
                      "
                      v-model="campaign.description"
                    >
                    </textarea>
                  </div>
                </div>
                <div class="block">
                  <div class="row">
                    <!-- Workflow -->
                    <div class="col-sm-6">
                      <div class="block-header">
                        <h4 class="header">Workflow</h4>
                        <small class="input-helper-text"
                          >Select a workflow for this campaign</small
                        >
                      </div>
                      <div
                        v-if="this.$root.store.workflows.length === 0"
                        class="control-input"
                      >
                        <select class="uik-input__select" disabled>
                          <option disabled selected>
                            Please create a workflow first ...
                          </option>
                        </select>
                      </div>
                      <div v-else class="control-input">
                        <select
                          id="workflowGuid"
                          class="uik-input__select"
                          required
                          v-bind:disabled="
                            this.$root.user.role === 'MerchantAdmin'
                              ? false
                              : true
                          "
                          v-model="campaign.workflowGuid"
                        >
                          <option v-bind:value="undefined" disabled>
                            Choose workflow
                          </option>
                          <option
                            v-for="workflow in this.$root.store.workflows"
                            v-bind:value="workflow.workflowGuid"
                            v-bind:key="workflow.workflowGuid"
                            v-bind:disabled="
                              workflow.active !== true ||
                              workflow.active !== true
                                ? true
                                : false
                            "
                          >
                            {{ workflow.name }}
                          </option>
                        </select>
                      </div>
                      <!-- Link to selected Workflow -->
                      <div v-if="campaign.workflowGuid">
                        <p
                          v-on:click.exact="
                            editWorkflowNewTab(campaign.workflowGuid)
                          "
                          style="
                            color: rgba(15, 91, 194, 0.8);
                            font-weight: 500;
                            cursor: pointer;
                          "
                        >
                          Open workflow in new tab
                        </p>
                      </div>
                    </div>
                    <!-- Agency -->
                    <div
                      v-if="this.$root.user.role === 'MerchantAdmin'"
                      class="col-sm-6"
                    >
                      <!-- Hide Agency selection from other than Merchant -->
                      <div class="block-header">
                        <h3 class="header">Assign to agency</h3>
                        <small class="input-helper-text"
                          >Select an agency for this campaign</small
                        >
                      </div>
                      <div
                        v-if="this.$root.store.agencies.length === 0"
                        class="control-input"
                      >
                        <select class="uik-input__select" disabled>
                          <option disabled selected>
                            Please connect to an agency first ...
                          </option>
                        </select>
                      </div>
                      <div v-else class="control-input">
                        <select
                          id="agencyId"
                          class="uik-input__select"
                          required
                          v-bind:disabled="
                            this.$root.user.role === 'MerchantAdmin'
                              ? false
                              : true
                          "
                          v-model="campaign.agencyId"
                        >
                          <option v-bind:value="undefined" disabled>
                            Choose workflow
                          </option>
                          <option
                            v-for="agency in this.$root.store.agencies"
                            v-bind:value="agency.agencyId"
                            v-bind:key="agency.agencyId"
                            v-bind:disabled="!agency.agencyId ? true : false"
                          >
                            {{ agency | humanReadableAgency }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Column left close -->

            <!-- Column right open -->
            <div class="col-sm-4">
              <div class="block-wrap">
                <!-- Date range -->
                <div
                  v-bind:class="[
                    this.$root.user.role === 'MerchantAdmin'
                      ? 'block'
                      : 'block no-pointer-events',
                  ]"
                >
                  <div class="block-header">
                    <h4 class="header">Running period</h4>
                    <small class="input-helper-text"
                      >Set the dates, both days included</small
                    >
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="control-input">
                        <div class="input-wrap">
                          <date-range-picker
                            ref="datePicker"
                            v-bind:singleDatePicker="false"
                            v-bind:timePicker="false"
                            v-bind:timePicker24Hour="false"
                            v-bind:showWeekNumbers="true"
                            v-bind:showDropdowns="true"
                            v-bind:autoApply="true"
                            v-bind:ranges="false"
                            v-bind:linkedCalendars="false"
                            v-bind:always-show-calendars="true"
                            v-bind:opens="'left'"
                            v-bind:locale-data="{
                              firstDay: 1,
                              format: 'yyyy-mm-dd',
                              separator: ' - ',
                            }"
                            v-model="dateRange"
                            v-on:update="dateRangeUpdated()"
                          >
                            <template v-slot:input="datePicker">
                              <span
                                v-bind:inner-html.prop="
                                  datePicker | formatDateRangePicker
                                "
                              ></span>
                              <button
                                type="button"
                                class="btn btn-primary icon-btn"
                              >
                                <i class="dashboard-icon icon-calendar"></i>
                              </button>
                            </template>
                          </date-range-picker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Guid -->
                <div v-if="this.campaign.campaignGuid" class="block">
                  <div class="block-header">
                    <h4 class="header">Campaign guid</h4>
                    <small class="input-helper-text"
                      >Unique reference for this campaign</small
                    >
                  </div>
                  <div class="control-input">
                    <input
                      type="text"
                      class="uik-input__input"
                      v-bind:value="this.campaign.campaignGuid"
                      readonly
                    />
                  </div>

                  <!-- Review link -->
                  <br />
                  <p v-if="this.$root.user.role === 'MerchantAdmin'">
                    <a
                      v-bind:href="getFullLink()"
                      target="_blank"
                      rel="noopener noreferrer"
                      >Review campaign in new tab</a
                    >
                  </p>

                  <!-- Review link -->
                  <p v-else>
                    <a
                      v-bind:href="getFullLink()"
                      target="_blank"
                      rel="noopener noreferrer"
                      >Engage campaign in new tab</a
                    >
                  </p>
                </div>

                <!-- Link -->
                <div v-if="this.campaign.campaignGuid" class="block">
                  <div class="block-header">
                    <h4
                      v-if="
                        this.workflow && this.workflow.serviceType === 'Upgrade'
                      "
                      class="header"
                    >
                      Deep link to upgrade
                    </h4>
                    <h4 v-else class="header">Deep link to sign-up</h4>
                    <small class="input-helper-text"
                      >Download the configuration and apply variables in your
                      dialer.</small
                    >
                  </div>
                  <div class="control-input">
                    <!--
                    <input
                      type="text"
                      class="uik-input__input"
                      v-bind:value="getDeepLink('string')"
                      readonly
                      onclick="this.select()"
                    />
                    -->

                    <!-- Download -->
                    <a
                      v-if="
                        this.workflow && this.workflow.serviceType === 'Upgrade'
                      "
                      v-bind:href="
                        'data:application/txt;charset=utf-8,' +
                        encodeURIComponent(
                          'Full link to upgrade:\r\n' +
                            getDeepLink('string') +
                            '\r\n\r\nSplit with examples:\r\n' +
                            getDeepLink('file') +
                            '\r\n\r\n\r\nFull link to change payment method:\r\n' +
                            getDeepLinkAlt('string') +
                            '\r\n\r\nSplit with examples:\r\n' +
                            getDeepLinkAlt('file')
                        )
                      "
                      download="Onboarding upgrade configuration.txt"
                      class="uik-btn__base btn btn-secondary"
                    >
                      Download configuration
                    </a>

                    <!-- Download -->
                    <a
                      v-else
                      v-bind:href="
                        'data:application/txt;charset=utf-8,' +
                        encodeURIComponent(
                          'Full link to sign-up:\r\n' +
                            getDeepLink('string') +
                            '\r\n\r\nSplit with examples:\r\n' +
                            getDeepLink('file')
                        )
                      "
                      download="Onboarding sign-up configuration.txt"
                      class="uik-btn__base btn btn-secondary"
                    >
                      Download configuration
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- Column right close -->

            <!-- Column wide open -->
            <div class="col-sm-12">
              <meta-data-fields
                ref="metaDataFields"
                v-bind:element="this.campaign"
              />
            </div>
            <!-- Column wide close -->
          </div>
        </div>
        <!-- Columns close -->
      </div>
    </div>
    <div
      v-if="campaign"
      v-on:click="closeCampaignModal()"
      class="of-modal-bg fadeIn"
    ></div>
  </div>
</template>

<script>
import Vue from "vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import MetaDataFields from "../shared/MetaDataFields";
import { campaignHandler } from "../../mixins/campaignHandler.js";
import { workflowHandler } from "../../mixins/workflowHandler.js";
import { agencyHandler } from "../../mixins/agencyHandler.js";
import { formats } from "../../mixins/formats.js";

export default Vue.extend({
  name: "CampaignModal",
  mixins: [campaignHandler, workflowHandler, agencyHandler, formats],
  components: {
    DateRangePicker,
    MetaDataFields,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      workflow: null,
      dateRange: {},
    };
  },
  mounted() {
    //  Hotkey to close modal by Esc
    let self = this;
    window.addEventListener("keyup", function (e) {
      if (e.code === "Escape") {
        self.closeCampaignModal();
      }
    });
  },
  created() {
    //  Get workflows
    this.getWorkflows();

    //  Get agencies
    if (this.$root.user.role === "MerchantAdmin") {
      this.loadAgencies();
    }

    //  Parse range date picker
    if (this.campaign.firstDate && this.campaign.lastDate) {
      this.dateRangeInitialise();
    }
  },
  methods: {
    /**
     * Get full link
     */
    getFullLink: function () {
      if (this.$root.user.role === "MerchantAdmin") {
        return (
          this.$root.backendPath +
          "?page=onboarding_review&campaignGuid=" +
          this.campaign.campaignGuid
        );
      } else {
        if (this.$root.apiUrl.includes("development")) {
          return (
            "https://development.onboarding.onlinefundraising.dk/onboard?campaignGuid=" +
            this.campaign.campaignGuid
          );
        } else if (this.$root.apiUrl.includes("sandbox")) {
          return (
            "https://sandbox.onboarding.onlinefundraising.dk/onboard?campaignGuid=" +
            this.campaign.campaignGuid
          );
        } else {
          return (
            //  "https://allears.onboarding.onlinefundraising.dk/onboard?campaignGuid=" + this.campaign.campaignGuid
            "https://onboarding.onlinefundraising.dk/onboard?campaignGuid=" +
            this.campaign.campaignGuid
          );
        }
      }
    },

    /**
     * Get deep link as string or file
     */
    getDeepLink: function (format) {
      //  Define linebreak or not
      let linebreak = "";
      if (format === "file") {
        linebreak = "\r\n";
      }

      //  Prepare link
      let uri = "";

      //  Set domain
      if (this.$root.apiUrl.includes("development")) {
        uri = "https://development.onboarding.onlinefundraising.dk/onboard?";
      } else if (this.$root.apiUrl.includes("sandbox")) {
        uri = "https://sandbox.onboarding.onlinefundraising.dk/onboard?";
      } else {
        //  uri = "https://allears.onboarding.onlinefundraising.dk/onboard?";
        uri = "https://onboarding.onlinefundraising.dk/onboard?";
      }

      //  Query param by campaign
      uri += "campaignGuid=" + this.campaign.campaignGuid + linebreak;

      //  Query params by workflow
      if (this.campaign.workflowGuid) {
        //  Handle promise or storage
        let workflowHandler = this.getWorkflow(this.campaign.workflowGuid);

        //  Promise
        if (typeof workflowHandler.then !== "undefined") {
          workflowHandler.then((response) => {
            if (response.workflowErrored) {
              this.workflow = null;
            } else {
              this.workflow = response.workflow;
            }
          });
        }

        //  Storage
        else if (workflowHandler.workflow) {
          this.workflow = workflowHandler.workflow;
        }

        if (this.workflow) {
          //  SignUp
          if (this.workflow.serviceType === "SignUp") {
            uri += "&externalLeadId=" + linebreak;
            uri += "&product=" + linebreak;
            uri += "&pre_amount=" + linebreak;
            uri += "&pre_purposeAccountingCode=" + linebreak;
            uri +=
              "&pre_paymentMethodType=" +
              (format === "file" ? "MobilePay|Betalingsservice|Card" : "") +
              linebreak;
            uri +=
              "&pre_scheduleType=" +
              (format === "file"
                ? "Yearly|Halfyearly|Quarterly|Monthly|Weekly"
                : "") +
              linebreak;
            uri +=
              "&pre_scheduleStartType=" +
              (format === "file" ? "now|first|custom" : "") +
              linebreak;
            uri +=
              "&pre_scheduleStartCustomDate=" +
              (format === "file" ? "YYYY-MM-DD" : "") +
              linebreak;
            uri +=
              "&pre_scheduleStartWithOneOff=" +
              (format === "file" ? "true|false" : "") +
              linebreak;
            uri +=
              "&pre_expiresAfterDate=" +
              (format === "file" ? "YYYY-MM-DD" : "") +
              linebreak;
            uri += "&pre_oneOffAmount=" + linebreak;
          }

          //  Upgrade
          else if (this.workflow.serviceType === "Upgrade") {
            uri += "&subscriptionGuid=" + linebreak;
            uri += "&product=" + linebreak;
            uri += "&pre_purposeAccountingCode=" + linebreak;
            uri +=
              "&pre_nextDueDate=" +
              (format === "file" ? "YYYY-MM-DD" : "") +
              linebreak;
            uri +=
              "&pre_expiresAfterDate=" +
              (format === "file" ? "YYYY-MM-DD" : "") +
              linebreak;
          }

          //  contactDataFields
          if (this.workflow.contactDataFields) {
            for (var i = 0; i < this.workflow.contactDataFields.length; i++) {
              if (this.workflow.contactDataFields[i].name === "dawaAddress") {
                uri += "&pre_address=" + linebreak;
              } else {
                uri +=
                  "&pre_" +
                  this.workflow.contactDataFields[i].name +
                  "=" +
                  linebreak;
              }
            }
          }

          //  metaDataFields
          if (this.workflow.metaDataFields) {
            for (var o = 0; o < this.workflow.metaDataFields.length; o++) {
              uri +=
                "&pre_" +
                this.workflow.metaDataFields[o].name +
                "=" +
                linebreak;
            }
          }

          //  Only file
          if (
            format === "file" &&
            this.workflow &&
            this.workflow.serviceType === "SignUp"
          ) {
            //  Send sortCode and accountNumber
            uri += "&pre_sortCode=" + linebreak;
            uri += "&pre_accountNumber=" + linebreak;

            //  Complete with SMS
            uri += "&sendSms=true|false" + linebreak;

            //  Complete with a single button useful for screen reading
            uri += "&onlyButton=true|false";
          }
        }
      }

      return uri;
    },

    /**
     * Get alternate deep link
     */
    getDeepLinkAlt: function (format) {
      //  Define linebreak or not
      let linebreak = "";
      if (format === "file") {
        linebreak = "\r\n";
      }

      //  Prepare link
      let uri = "";

      //  Set domain
      if (this.$root.apiUrl.includes("development")) {
        uri = "https://development.onboarding.onlinefundraising.dk/onboard?";
      } else if (this.$root.apiUrl.includes("sandbox")) {
        uri = "https://sandbox.onboarding.onlinefundraising.dk/onboard?";
      } else {
        //  uri = "https://allears.onboarding.onlinefundraising.dk/onboard?";
        uri = "https://onboarding.onlinefundraising.dk/onboard?";
      }

      //  Query param by campaign
      uri += "campaignGuid=" + this.campaign.campaignGuid + linebreak;

      //  Query params by workflow
      if (this.campaign.workflowGuid) {
        //  Handle promise or storage
        let workflowHandler = this.getWorkflow(this.campaign.workflowGuid);

        //  Promise
        if (typeof workflowHandler.then !== "undefined") {
          workflowHandler.then((response) => {
            if (response.workflowErrored) {
              this.workflow = null;
            } else {
              this.workflow = response.workflow;
            }
          });
        }

        //  Storage
        else if (workflowHandler.workflow) {
          this.workflow = workflowHandler.workflow;
        }

        if (this.workflow) {
          uri += "&subscriptionGuid=" + linebreak;
          uri += "&action=ChangePaymentMethod" + linebreak;
          uri +=
            "&pre_paymentMethodType=" +
            (format === "file" ? "MobilePay|Betalingsservice|Card" : "") +
            linebreak;

          //  contactDataFields
          if (this.workflow.contactDataFields) {
            for (var i = 0; i < this.workflow.contactDataFields.length; i++) {
              uri +=
                "&pre_" +
                this.workflow.contactDataFields[i].name +
                "=" +
                linebreak;
            }
          }

          //  metaDataFields
          if (this.workflow.metaDataFields) {
            for (var o = 0; o < this.workflow.metaDataFields.length; o++) {
              uri +=
                "&pre_" +
                this.workflow.metaDataFields[o].name +
                "=" +
                linebreak;
            }
          }

          //  Only file
          if (format === "file") {
            //  Send sortCode and accountNumber
            uri += "&pre_sortCode=" + linebreak;
            uri += "&pre_accountNumber=" + linebreak;

            //  Complete with SMS
            uri += "&sendSms=true|false" + linebreak;

            //  Complete with a single button useful for screen reading
            uri += "&onlyButton=true|false";
          }
        }
      }

      return uri;
    },

    /**
     * Open workflow modal in new tab
     * @param {string} workflowGuid
     */
    editWorkflowNewTab: function (workflowGuid) {
      this.$root.viewEntityNewTab("workflow", workflowGuid);
    },

    /**
     * Close campaign modal
     */
    closeCampaignModal: function () {
      this.dateRange = {};
      this.$emit("closeCampaignModal");
    },

    /**
     * Initialise date range picker by transfering and formatting dates
     */
    dateRangeInitialise: function () {
      this.dateRange = {
        startDate: this.campaign.firstDate.substring(0, 10),
        endDate: this.campaign.lastDate.substring(0, 10),
      };
    },

    /**
     * Handle updated date
     */
    dateRangeUpdated: function () {
      if (this.dateRange) {
        //  Set first date
        this.campaign.firstDate =
          this.dateRange.startDate.getFullYear() +
          "-" +
          ("0" + (this.dateRange.startDate.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + this.dateRange.startDate.getDate()).slice(-2);

        //  Set last date
        this.campaign.lastDate =
          this.dateRange.endDate.getFullYear() +
          "-" +
          ("0" + (this.dateRange.endDate.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + this.dateRange.endDate.getDate()).slice(-2);
      }
    },
  },
});
</script>
