<template>
  <div class="wrap profile">
    <div class="content">
      <div class="uik-entity-list-header">
        <h2 class="title">Profile</h2>
      </div>

      <form
        method="post"
        action=""
        autocomplete="nope"
        novalidate="novalidate"
        v-on:submit.prevent
      >
        <div class="control-input">
          <label for="name">Name</label>
          <input
            type="text"
            id="name"
            class="uik-input__input"
            autocomplete="name"
            placeholder="Visible to clients"
            required
            v-model="name"
          />
        </div>
        <div class="control-input">
          <label for="email">Email</label>
          <input
            type="email"
            id="email"
            class="uik-input__input"
            autocomplete="email"
            placeholder="Only for resetting passwords"
            required
            v-model="email"
          />
        </div>
        <div class="control-input">
          <label for="externalId">External ID</label>
          <input
            type="text"
            id="externalId"
            class="uik-input__input"
            placeholder="Optional"
            required
            v-model="externalId"
          />
        </div>
        <div class="form-footer">
          <button
            type="button"
            v-on:click="updateProfile()"
            class="uik-btn__base btn btn-primary"
          >
            Update
          </button>
        </div>
      </form>
      <button
        type="button"
        v-on:click="resetPassword()"
        class="uik-btn__link btn"
      >
        Request password reset
      </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import md5 from "js-md5";

export default Vue.extend({
  name: "Profile",
  data: function () {
    return {
      email: this.$root.user.email,
      name: this.$root.user.name,
      externalId: this.$root.user.externalId,
    };
  },
  methods: {
    /**
     * Reset password
     */
    resetPassword: function () {
      //  Validation
      if (!this.$root.user.guid) {
        this.$root.errorResponse(
          "Something is wrong. Please logout and in again"
        );
        return;
      }

      //  Request
      axios
        .post(
          this.$root.apiUrl +
            "/account/" +
            this.$root.user.guid +
            "/ResetPassword",
          null,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        )
        .then((response) => {
          this.$root.successResponse("Email sent with reset link", response);
        })
        .catch((error) => {
          this.$root.handleErrorResponse(error);
        });
    },

    /**
     * Update profile
     */
    updateProfile: function () {
      //  Validation
      if (!this.$root.user.guid) {
        this.$root.errorResponse(
          "Something is wrong. Please logout and in again"
        );
        return;
      } else if (!this.email) {
        this.$root.errorResponse("Missing email");
        return;
      } else if (!this.name) {
        this.$root.errorResponse("Missing name");
        return;
      }

      //  Body
      let body = JSON.stringify({
        userGuid: this.$root.user.guid,
        agencyId: this.$root.user.agencyId,
        role: this.$root.user.role,
        active: this.$root.user.active,
        externalId: this.externalId, // update
        name: this.name, // update
        email: this.email, // update
      });

      //  Request
      axios
        .put(this.$root.apiUrl + "/user/" + this.$root.user.guid, body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$root.user.authToken,
            requestMD5: md5(body),
          },
        })
        .then((response) => {
          this.$root.user.name = response.data.name;
          this.$root.user.email = response.data.email;

          this.$root.successResponse("Updated");
        })
        .catch((error) => {
          this.$root.handleErrorResponse(error);
        });
    },
  },
});
</script>
