<template>
  <div class="signup">
    <!-- Product selection -->
    <h2
      v-bind:class="{
        hidden: product.onlyButton,
      }"
      v-bind:aria-hidden="product.onlyButton"
    >
      Select product
    </h2>

    <!-- Tab triggers -->
    <div
      class="nav"
      v-bind:aria-hidden="
        this.$route.query.product || product.onlyButton ? true : false
      "
      v-bind:class="{
        'no-pointer-events':
          $route.query.product || sessionStatus === 'Completed',
        hidden: product.onlyButton,
      }"
    >
      <ul
        class="nav-tabs"
        role="tablist"
        aria-label="Vælg produkt med piletast."
      >
        <li
          v-for="signUpProduct in signUpProducts"
          v-bind:key="signUpProduct.uuid"
          class="nav-item"
        >
          <button
            type="button"
            class="nav-link"
            v-bind:class="product.uuid === signUpProduct.uuid ? 'active' : null"
            v-bind:tabindex="
              $route.query.product || sessionStatus === 'Completed' ? '-1' : '0'
            "
            v-on:keyup.left="prevProduct($event)"
            v-on:keyup.up="prevProduct($event)"
            v-on:keyup.right="nextProduct($event)"
            v-on:keyup.down="nextProduct($event)"
            v-on:keyup.enter="selectProduct(signUpProduct)"
            v-on:click="onSignUpProductChange(signUpProduct)"
            role="tab"
          >
            {{ signUpProduct.productName }}
          </button>
        </li>
      </ul>
    </div>

    <!-- Tab Content -->
    <div class="tab-content">
      <div class="tab-pane active" role="tabpanel">
        <div
          class="row"
          v-bind:class="{
            hidden: product.onlyButton,
          }"
          v-bind:aria-hidden="product.onlyButton"
        >
          <div class="col-sm-6 tab-right">
            <!-- Review product table -->
            <onboarding-sign-up-review-table
              v-if="Object.keys(product).length > 0"
              ref="OnboardingSignUpReviewTable"
              v-bind:product="this.product"
              v-bind:staticWorkflowProduct="this.staticWorkflowProduct"
            />
          </div>
          <div class="col-sm-6 tab-left">
            <!-- AmountTotal -->
            <div v-if="Object.keys(product).length > 0" class="control-input">
              <label for="amountTotal"
                >Amount in {{ product.currencyCode }}, incl. VAT</label
              >
              <input
                type="number"
                id="amountTotal"
                class="uik-input__input"
                autocomplete="nope"
                v-model.trim="product.amountTotal"
                v-bind:disabled="
                  staticWorkflowProduct.amountTotal ||
                  product.agreementType === 'Shared'
                    ? true
                    : false
                "
                v-bind:class="[
                  (product.amountTotal && product.amountTotal.length > 0) ||
                  product.agreementType
                    ? 'not-empty'
                    : 'empty',
                ]"
                v-on:change="onAmountTotalChange()"
                aria-label="Beløb"
                required
              />
            </div>

            <!-- Schedule type selection -->
            <onboarding-schedule-type
              v-if="
                Object.keys(product).length > 0 &&
                product.paymentType === 'Recurring'
              "
              ref="OnboardingScheduleType"
              v-bind:product="this.product"
              v-bind:staticWorkflowProduct="this.staticWorkflowProduct"
            />

            <!-- Expires after date -->
            <onboarding-schedule-expiration
              v-if="
                Object.keys(product).length > 0 &&
                product.paymentType === 'Recurring'
              "
              ref="OnboardingScheduleExpiration"
              v-bind:product="this.product"
              v-bind:staticWorkflowProduct="this.staticWorkflowProduct"
            />
          </div>
        </div>

        <!-- Confirmation -->
        <template v-if="sessionStatus === 'Completed'">
          <!-- Success message -->
          <div
            class="alert alert-success center-aligned"
            tabindex="0"
            ref="successMessage"
            role="alert"
            aria-label="Sessionen er nu gennemført."
          >
            Session complete, please proceed to the next lead
          </div>
          <hr
            v-bind:class="{
              hidden: product.onlyButton,
            }"
            v-bind:aria-hidden="product.onlyButton"
          />

          <!-- Refresh instance -->
          <button
            type="button"
            class="uik-btn__base btn btn-secondary"
            style="display: block; margin: 0 auto"
            tabindex="-1"
            v-on:click="refreshPage()"
            v-bind:class="{
              hidden: product.onlyButton,
            }"
            v-bind:aria-hidden="product.onlyButton"
          >
            Start again
          </button>
        </template>

        <!-- Rejection -->
        <template v-else-if="sessionStatus === 'Rejected'">
          <div
            class="alert alert-danger center-aligned"
            tabindex="0"
            ref="errorMessage"
            role="alert"
            aria-label="Afvist"
          >
            Session rejected by lead
          </div>

          <!-- Restart -->
          <div class="footer-action center-aligned">
            <button
              type="button"
              class="uik-btn__base btn btn-primary"
              tabindex="1"
              v-bind:autofocus="product.onlyButton"
              v-on:click="initialiseRequest(product, 'SignUp')"
              aria-label="Start session igen."
            >
              <span>Restart session</span>
            </button>
          </div>
        </template>

        <!-- Failure -->
        <template v-else-if="sessionStatus === 'Failed'">
          <div
            class="alert alert-danger center-aligned"
            tabindex="0"
            ref="errorMessage"
            role="alert"
            aria-label="En fejl opstod"
          >
            Session failed by gateway
          </div>

          <!-- Restart -->
          <div class="footer-action center-aligned">
            <button
              type="button"
              class="uik-btn__base btn btn-primary"
              tabindex="1"
              v-on:click="initialiseRequest(product, 'SignUp')"
              aria-label="Start session igen."
            >
              <span>Restart session</span>
            </button>
          </div>
        </template>

        <!-- SignUp -->
        <template v-else>
          <!-- Payment method type selection -->
          <onboarding-payment-method-type-selection
            v-if="!iframeGatewayUrl && Object.keys(product).length > 0"
            ref="OnboardingPaymentMethodTypeSelection"
            v-bind:product="this.product"
            v-bind:staticWorkflowProduct="this.staticWorkflowProduct"
            v-bind:sessionRequest="this.sessionRequest"
            v-on:whenPaymentMethodTypeChanged="updateSignUp()"
            v-on:whenBsAppVerificationChange="whenBsAppVerificationChange()"
            v-bind:class="{
              hidden: product.onlyButton,
            }"
            v-bind:aria-hidden="product.onlyButton"
          />

          <!-- Start type selection -->
          <onboarding-schedule-start-type
            v-if="
              !iframeGatewayUrl &&
              Object.keys(product).length > 0 &&
              product.paymentType === 'Recurring'
            "
            ref="OnboardingScheduleStartType"
            v-bind:product="this.product"
            v-bind:staticWorkflowProduct="this.staticWorkflowProduct"
            v-bind:sessionRequest="this.sessionRequest"
            v-on:whenScheduleStartTypeChanged="updateSignUp()"
            v-on:whenStartWithOneOffChanged="updateSignUp()"
            v-on:whenOneOffAmountChanged="updateSignUp()"
            v-on:whenBsAppVerificationChange="whenBsAppVerificationChange()"
            v-bind:class="{
              hidden: product.onlyButton,
            }"
            v-bind:aria-hidden="product.onlyButton"
          />

          <!-- Complete by SMS -->
          <onboarding-send-sms
            v-if="!iframeGatewayUrl && Object.keys(product).length > 0"
            ref="OnboardingSendSms"
            v-bind:product="this.product"
            v-bind:sessionRequest="this.sessionRequest"
            v-bind:getContactPhone="this.getContactPhone"
            v-on:whenBsAppVerificationChange="whenBsAppVerificationChange()"
            v-bind:class="{
              hidden: product.onlyButton,
            }"
            v-bind:aria-hidden="product.onlyButton"
          />

          <!-- iframe -->
          <div v-if="iframeGatewayUrl" class="iframe-holder" aria-live="polite">
            <h3>Enter gateway details</h3>
            <p>Abort session to change i.e. payment method</p>

            <!-- Link to new tab in MobilePay Online (Single) -->
            <a v-if="product.paymentMethodType == 'MobilePay' && product.paymentType == 'Single'" v-bind:href="iframeGatewayUrl" class="uik-btn__base btn-small" target="_blank">Open in new tab, if window fails</a>

            <iframe
              type="text/html"
              v-bind:src="iframeGatewayUrl"
              width="100%"
              v-bind:height="iframeGatewayHeight + 'px'"
              allowtransparency="true"
              scrolling="yes"
              seamless
              webkitAllowFullScreen
              mozallowfullscreen
              allowFullScreen
            ></iframe>
          </div>

          <!-- Submit session -->
          <div
            v-if="Object.keys(product).length > 0"
            class="footer-action center-aligned"
          >
            <!-- Please beware of multiple sessions -->
            <div v-if="sessionAttempts > 1" class="alert alert-info">
              Beware! You have now started more than one session. Previous
              sessions may still be active, so please ensure that the lead only
              completes once.
            </div>

            <!-- Please wait for gateway -->
            <template v-if="sessionStatus === 'Committed'">
              <!-- Screen reader info -->
              <div
                v-if="!product.sendSms"
                role="status"
                aria-label="Vent venligst."
                class="sr-only"
              ></div>

              <p>Please wait while we connect to the gateway ...</p>
              <div class="wave wave-loading">
                <div class="ver1"></div>
                <div class="ver2"></div>
                <div class="ver3"></div>
                <div class="ver4"></div>
                <div class="ver5"></div>
              </div>
            </template>

            <!-- Please wait for SMS -->
            <template
              v-else-if="sessionStatus === 'SentToGateway' && product.sendSms"
            >
              <!-- Screen reader info -->
              <div
                role="status"
                aria-label="En SMS er nu afsendt til vedkommende. Vent venligst på deres bekræftelse."
                class="sr-only"
              ></div>

              <p>An SMS has been sent. Please ask for confirmation ...</p>
              <div class="wave wave-loading">
                <div class="ver1"></div>
                <div class="ver2"></div>
                <div class="ver3"></div>
                <div class="ver4"></div>
                <div class="ver5"></div>
              </div>
            </template>

            <!-- Start session with delay -->
            <button
              type="button"
              class="uik-btn__base btn btn-primary"
              v-on:click="initialiseRequest(product, 'SignUp')"
              v-bind:disabled="
                this.sessionDelayCounter < 1 &&
                (sessionStatus === null || sessionStatus === 'Aborted')
                  ? false
                  : true
              "
              v-bind:tabindex="
                this.sessionDelayCounter < 1 &&
                (sessionStatus === null || sessionStatus === 'Aborted')
                  ? '1'
                  : '-1'
              "
              v-bind:autofocus="product.onlyButton"
              v-bind:aria-hidden="
                this.sessionDelayCounter < 1 &&
                (sessionStatus === null || sessionStatus === 'Aborted')
                  ? false
                  : true
              "
              v-bind:aria-label="
                sessionStatus === 'Aborted'
                  ? 'Start session igen.'
                  : 'Start session.'
              "
            >
              <span v-if="sessionStatus === 'Aborted'"
                >Restart session
                <template v-if="this.sessionDelayCounter > 0"
                  >({{ this.sessionDelayCounter }})</template
                ></span
              >
              <span v-else
                >Start session
                <template v-if="this.sessionDelayCounter > 0"
                  >({{ this.sessionDelayCounter }})</template
                ></span
              >
            </button>

            <!-- Abort session -->
            <button
              type="button"
              class="uik-btn__base btn btn-secondary"
              v-on:click="abortSession()"
              v-bind:class="[
                sessionStatus !== null && sessionStatus !== 'Aborted'
                  ? 'uik-btn__base btn btn-secondary'
                  : 'uik-btn__base btn btn-secondary no-visibility',
              ]"
              aria-label="Afbryd sessionen."
            >
              Abort session
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import OnboardingSignUpReviewTable from "./OnboardingSignUpReviewTable";
import OnboardingScheduleStartType from "./OnboardingScheduleStartType";
import OnboardingScheduleType from "./OnboardingScheduleType";
import OnboardingScheduleExpiration from "./OnboardingScheduleExpiration";
import OnboardingPaymentMethodTypeSelection from "./OnboardingPaymentMethodTypeSelection";
import OnboardingSendSms from "./OnboardingSendSms";
import { changeEvents } from "../../mixins/changeEvents.js";
import { sessionRequestHandler } from "../../mixins/sessionRequestHandler.js";
import { sessionRequestGatewayHandler } from "../../mixins/sessionRequestGatewayHandler.js";

export default Vue.extend({
  name: "OnboardingSignUp",
  mixins: [changeEvents, sessionRequestHandler, sessionRequestGatewayHandler],
  components: {
    OnboardingSignUpReviewTable,
    OnboardingPaymentMethodTypeSelection,
    OnboardingScheduleType,
    OnboardingScheduleStartType,
    OnboardingScheduleExpiration,
    OnboardingSendSms,
  },
  props: {
    sessionRequest: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      product: {},
      staticWorkflowProduct: {},
      iframeGatewayUrl: null,
      iframeGatewayHeight: 650,
      sessionStatus: null,
      sessionAttempts: 0,
      sessionDelayCounter: 0,
    };
  },
  computed: {
    /**
     * Get sign-up products
     */
    signUpProducts: function () {
      return this.sessionRequest.workflow.signUpProducts.filter(function (p) {
        return p.productName;
      });
    },

    /**
     * Get contact phone
     */
    getContactPhone: function () {
      if (this.sessionRequest.workflow.hasOwnProperty("contactDataFields")) {
        let contactPhoneField =
          this.sessionRequest.workflow.contactDataFields.filter(function (
            field
          ) {
            if (field.name === "msisdn" && field.value) {
              return field;
            }
          });

        if (contactPhoneField.length > 0) {
          return contactPhoneField[0].value;
        }
      }

      return null;
    },
  },
  created() {
    //  Select product by query
    if (this.$route.query.product || this.$route.query.pre_product) {
      let index = 0;
      if (this.$route.query.product) {
        index = parseInt(this.$route.query.product) - 1;
      } else if (this.$route.query.pre_product) {
        index = parseInt(this.$route.query.pre_product) - 1;
      }

      this.onSignUpProductChange(
        this.sessionRequest.workflow.signUpProducts[index]
      );
    }

    //  Select first product
    else {
      this.onSignUpProductChange(
        this.sessionRequest.workflow.signUpProducts[0]
      );
    }

    //  Initialise session delay counter
    setInterval(
      function () {
        this.sessionDelayCounter = this.sessionDelayCounter - 1;
      }.bind(this),
      1000
    );

    this.getBetalingsserviceStartDateService();
  },
  methods: {
    prevProduct: function (e) {
      if (e.target.parentElement.previousElementSibling) {
        e.target.parentElement.previousElementSibling.firstElementChild.focus();
      }
    },
    nextProduct: function (e) {
      if (e.target.parentElement.nextElementSibling) {
        e.target.parentElement.nextElementSibling.firstElementChild.focus();
      }
    },
    selectProduct: function (signUpProduct) {
      this.onSignUpProductChange(signUpProduct);
      document.getElementById("amountTotal").focus();
    },

    /**
     * Force update
     */
    updateSignUp: function () {
      window.console.log("updateSignUp triggered");
      this.$refs.OnboardingSignUpReviewTable.triggerForceUpdate();

      this.$forceUpdate();
    },

    /**
     * Force update needed to update selection
     */
    whenBsAppVerificationChange: function () {
      this.updateSignUp();

      //  Trigger update in start selection
      this.$refs.OnboardingScheduleStartType.whenBsAppVerificationChange();
    },

    /**
     * Listen for amount change specifically
     */
    onAmountTotalChange: function () {
      //  Trigger update in start selection as it holds the OneOffAmount
      this.$refs.OnboardingScheduleStartType.whenAmountTotalChange(
        this.product.amountTotal
      );
    },

    /**
     * Refresh the page
     */
    refreshPage: function () {
      window.location.reload();
    },
  },
});
</script>
