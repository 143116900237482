import axios from "axios";
import { formats } from "./formats.js";

export const relayHandler = {
    mixins: [formats],
    methods: {

        /**
         * Get agreeement
         * @param {string} guid 
         */
        getAgreement: function(guid) {

            let agreementLocated = this.loadAgreement(guid);
            if (agreementLocated) {

                return {
                    agreementErrored: false,
                    agreement: agreementLocated
                };
            } else {

                //  Handle promise or storage
                let relayAgreementsHandler = this.getAgreements();

                //  Promise
                if (typeof relayAgreementsHandler.then !== "undefined") {
                    relayAgreementsHandler.then(response => {
                        if (!response.agreementsErrored) {

                            return this.handleAgreementResponse(guid);
                        } else {

                            return {
                                agreementErrored: true,
                                agreement: null
                            };
                        }
                    });
                }

                //  Storage
                else if (relayAgreementsHandler.agreements) {

                    return this.handleAgreementResponse(guid);
                }
            }
        },

        /**
         * Load agreement from storage
         * @param {string} guid 
         */
        loadAgreement: function(guid) {

            if (this.$root.store.agreements.length > 0) {
                for (var i = 0; i < this.$root.store.agreements.length; i++) {
                    if (guid === this.$root.store.agreements[i].agreementGuid) {
                        return this.$root.store.agreements[i];
                    }
                }
            }

            return null;
        },

        /**
         * Handle agreement response
         * @param {string} guid 
         */
        handleAgreementResponse: function(guid) {

            let agreement = this.loadAgreement(guid);
            if (agreement) {

                return {
                    agreementErrored: false,
                    agreement: agreement
                };
            } else {

                return {
                    agreementErrored: true,
                    agreement: null
                };
            }
        },

        /**
         * Get agreements
         */
        getAgreements: function() {

            if (this.$root.store.agreements.length > 0) {
                return {
                    agreementsErrored: false,
                    agreements: this.$root.store.agreements
                };
            } else {

                //  Request
                return axios
                    .get(this.getAgreementsApiPath(), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken
                        }
                    })
                    .then(response => {

                        this.$root.store.agreements = this.sortSharedAgreements(response.data.list);

                        return {
                            agreementsErrored: false,
                            agreements: this.$root.store.agreements
                        };
                    })
                    .catch(error => {

                        this.$root.handleErrorResponse(error);

                        return {
                            agreementsErrored: true,
                            agreements: []
                        };

                    });
            }
        },

        /**
         * Get communication
         * @param {string} guid 
         */
        getCommunication: function(guid) {

            let communicationLocated = this.loadCommunication(guid);
            if (communicationLocated) {

                return {
                    communicationErrored: false,
                    communicationCollection: communicationLocated
                };
            } else {

                //  Handle promise or storage
                let relayCommunicationsHandler = this.getCommunications();

                //  Promise
                if (typeof relayCommunicationsHandler.then !== "undefined") {
                    relayCommunicationsHandler.then(response => {
                        if (!response.communicationsErrored) {

                            return this.handleCommunicationResponse(guid);
                        } else {

                            return {
                                communicationErrored: true,
                                communicationCollection: null
                            };
                        }
                    });
                }

                //  Storage
                else if (relayCommunicationsHandler.communicationCollections) {

                    return this.handleCommunicationResponse(guid);
                }
            }
        },

        /**
         * Load communication from storage
         * @param {string} guid 
         */
        loadCommunication: function(guid) {

            if (this.$root.store.communicationCollections.length > 0) {
                for (var i = 0; i < this.$root.store.communicationCollections.length; i++) {
                    if (guid === this.$root.store.communicationCollections[i].communicationCollectionGuid) {
                        return this.$root.store.communicationCollections[i];
                    }
                }
            }

            return null;
        },

        /**
         * Handle communication response
         * @param {string} guid 
         */
        handleCommunicationResponse: function(guid) {

            let communication = this.loadCommunication(guid);
            if (communication) {

                return {
                    communicationErrored: false,
                    communicationCollection: communication
                };
            } else {

                return {
                    communicationErrored: true,
                    communicationCollection: null
                };
            }
        },

        /**
         * Get communications
         */
        getCommunications: function() {

            if (this.$root.store.communicationCollections.length > 0) {
                return {
                    communicationsErrored: false,
                    communicationCollections: this.$root.store.communicationCollections
                };
            } else {

                //  Request
                return axios
                    .get(this.getCommunicationsApiPath(), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken
                        }
                    })
                    .then(response => {

                        var nestedCommunicationCollections = this.checkCommunicationForInheritance(response.data);
                        var flattenedCommunicationCollections = this.flattenCommunicationInheritance(nestedCommunicationCollections, '');

                        this.$root.store.communicationCollections = flattenedCommunicationCollections;

                        return {
                            communicationsErrored: false,
                            communicationCollections: this.$root.store.communicationCollections
                        };
                    })
                    .catch(error => {

                        this.$root.handleErrorResponse(error);

                        return {
                            communicationsErrored: true,
                            communicationCollections: []
                        };

                    });
            }
        },

        /**
         * Get payment session by sessionGuid
         * @param {object} sessionGuid
         */
        getPaymentSession: function(sessionGuid) {

            //  Request
            return axios
                .get(this.getPaymentSessionApiPath(sessionGuid), {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.$root.user.authToken
                    }
                })
                .then(response => {

                    return {
                        paymentSessionErrored: false,
                        paymentSession: response.data
                    };
                })
                .catch(error => {

                    this.$root.handleErrorResponse(error);

                    return {
                        paymentSessionErrored: true,
                        paymentSession: {}
                    };
                });
        },

        /**
         * Apply Shared Agreement to product by guid
         * @param {string} guid
         */
        applySharedAgreementToProduct: function(guid) {

            if (!guid || !this.sessionRequest.merchantId) {
                return;
            }

            //  Request
            axios
                .get(
                    this.$root.apiUrl +
                    "/relay/agreement/" +
                    guid +
                    "?merchantId=" +
                    encodeURIComponent(this.sessionRequest.merchantId), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken
                        }
                    }
                )
                .then(response => {

                    if (response.status === 200) {

                        //  Set agreement data to session request
                        this.sessionRequest.agreementData = response.data;
                        this.sessionRequest.originalAgreementData = Object.assign({}, response.data);

                        //  Merge with product
                        for (var prop in this.sessionRequest.agreementData) {
                            if (this.sessionRequest.agreementData.hasOwnProperty(prop)) {
                                this.product[prop] = this.sessionRequest.agreementData[prop];
                            }
                        }
                    }
                })
                .catch(error => {
                    this.$root.handleErrorResponse(error, error.response.data);
                });
        },

        /**
         * Apply Contact to session
         */
        applyContactToSession: function() {

            if (!this.sessionRequest.contactGuid || !this.sessionRequest.merchantId) {
                return;
            }

            //  Request
            axios
                .get(
                    this.$root.apiUrl +
                    "/relay/contact/" +
                    this.sessionRequest.contactGuid +
                    "?merchantId=" +
                    encodeURIComponent(this.sessionRequest.merchantId), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken
                        }
                    }
                )
                .then(response => {
                    if (response.status === 200) {
                        this.sessionRequest.contactData = response.data;
                        this.contactApplied();
                    }
                })
                .catch(error => {
                    this.$root.handleErrorResponse(error, error.response.data);
                });
        },

        /**
         * Apply Subscription to session
         */
        applySubscriptionToSession: function() {

            if (!this.sessionRequest.subscriptionGuid || !this.sessionRequest.merchantId) {
                return;
            }

            //  Request
            axios
                .get(
                    this.$root.apiUrl +
                    "/relay/subscription/" +
                    this.sessionRequest.subscriptionGuid +
                    "?merchantId=" +
                    encodeURIComponent(this.sessionRequest.merchantId), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken
                        }
                    }
                )
                .then(response => {

                    if (response.status === 200) {

                        //  Set data
                        this.sessionRequest.subscriptionData = response.data;

                        //  Set guids
                        this.sessionRequest.agreementGuid = this.sessionRequest.subscriptionData.agreementGuid;
                        this.sessionRequest.paymentMethodGuid = this.sessionRequest.subscriptionData.paymentMethodGuid;
                        this.sessionRequest.contactGuid = this.sessionRequest.subscriptionData.contactGuid;

                        this.applyRelatedEntitiesToSession();
                    }
                })
                .catch(error => {
                    this.$root.handleErrorResponse(error, error.response.data);
                });
        },

        /**
         * Apply Agreement, PaymentMethod and Contact to session in relation to Subscription
         */
        applyRelatedEntitiesToSession: function() {

            //  Prepare API request
            let request = [];

            if (this.sessionRequest.agreementGuid && this.sessionRequest.merchantId) {
                request.push(
                    axios.get(
                        this.$root.apiUrl +
                        "/relay/agreement/" +
                        this.sessionRequest.agreementGuid +
                        "?merchantId=" +
                        encodeURIComponent(this.sessionRequest.merchantId), {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: this.$root.user.authToken
                            }
                        }
                    )
                );
            } else {
                request.push('');
            }

            if (this.sessionRequest.subscriptionGuid && this.sessionRequest.merchantId) {
                request.push(
                    axios.get(
                        this.$root.apiUrl +
                        "/relay/subscription/" +
                        this.sessionRequest.subscriptionGuid +
                        "/addOns?merchantId=" +
                        encodeURIComponent(this.sessionRequest.merchantId), {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: this.$root.user.authToken
                            }
                        }
                    )
                );
            } else {
                request.push('');
            }

            if (this.sessionRequest.paymentMethodGuid && this.sessionRequest.merchantId) {
                request.push(
                    axios.get(
                        this.$root.apiUrl +
                        "/relay/paymentMethod/" +
                        this.sessionRequest.paymentMethodGuid +
                        "?merchantId=" +
                        encodeURIComponent(this.sessionRequest.merchantId), {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: this.$root.user.authToken
                            }
                        }
                    )
                );
            } else {
                request.push('');
            }

            if (this.sessionRequest.contactGuid && this.sessionRequest.merchantId) {
                request.push(
                    axios.get(
                        this.$root.apiUrl +
                        "/relay/contact/" +
                        this.sessionRequest.contactGuid +
                        "?merchantId=" +
                        encodeURIComponent(this.sessionRequest.merchantId), {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: this.$root.user.authToken
                            }
                        }
                    )
                );
            } else {
                request.push('');
            }

            //  Request
            return axios
                .all(request)
                .then(
                    axios.spread((agreementResponse, addOnResponse, paymentMethodResponse, contactResponse) => {

                        if (agreementResponse.status === 200) {
                            this.sessionRequest.agreementData = agreementResponse.data;
                            this.sessionRequest.originalAgreementData = Object.assign({}, agreementResponse.data);
                        }
                        if (addOnResponse.status === 200) {
                            this.sessionRequest.addOnsData = addOnResponse.data;
                        }
                        if (paymentMethodResponse.status === 200) {
                            this.sessionRequest.paymentMethodData = paymentMethodResponse.data;
                        }
                        if (contactResponse.status === 200) {
                            this.sessionRequest.contactData = contactResponse.data;
                            this.contactApplied();
                        }

                    })
                )
                .catch(error => {
                    this.$root.handleErrorResponse(error, error.response.data);
                });
        },

        /**
         * Relay API path
         */
        getAgreementsApiPath() {
            //  Merchant
            if (this.$root.user.role === "MerchantAdmin") {
                return (
                    this.$root.apiUrl +
                    "/relay/agreements?pageNumber=1&pageSize=100&state=Available"
                );
            }

            //  Agency
            else if (this.$root.merchant.merchantId && this.$root.user.agencyId) {
                return (
                    this.$root.apiUrl +
                    "/relay/agreements?pageNumber=1&pageSize=100&state=Available&merchantId=" +
                    encodeURIComponent(this.$root.merchant.merchantId)
                );
            }

            //  Error
            else {
                return null;
            }
        },

        /**
         * Relay API path
         */
        getCommunicationsApiPath() {
            //  Merchant
            if (this.$root.user.role === "MerchantAdmin") {
                return this.$root.apiUrl + "/relay/communicationCollections";
            }

            //  Agency
            else if (this.$root.merchant.merchantId && this.$root.user.agencyId) {
                return (
                    this.$root.apiUrl +
                    "/relay/communicationCollections?merchantId=" +
                    encodeURIComponent(this.$root.merchant.merchantId)
                );
            }

            //  Error
            else {
                return null;
            }
        },

        /**
         * Relay API path
         * @param {string} sessionGuid
         */
        getPaymentSessionApiPath(sessionGuid) {
            //  Merchant
            if (this.$root.user.role === "MerchantAdmin") {
                return this.$root.apiUrl + "/relay/paymentSession/" + sessionGuid;
            }

            //  Agency
            else if (this.$root.merchant.merchantId && this.$root.user.agencyId) {
                return (
                    this.$root.apiUrl +
                    "/relay/paymentSession/" + sessionGuid + "?merchantId=" +
                    encodeURIComponent(this.$root.merchant.merchantId)
                );
            }

            //  Error
            else {
                return null;
            }
        },

        /**
         * Check for inheritance
         * 
         * @param {Array} communicationCollections
         * 
         * @returns {Array}
         */
        checkCommunicationForInheritance: function(communicationCollections) {

            var output = [];

            for (var i in communicationCollections) {

                //  Default
                if (communicationCollections[i].hasOwnProperty("defaultCollection") && communicationCollections[i].defaultCollection === true) {

                    var childrenOfDefault = this.checkCommunicationForChildren(communicationCollections, communicationCollections[i].communicationCollectionGuid);
                    if (childrenOfDefault.length) {
                        communicationCollections[i].children = childrenOfDefault;
                    }
                    output.push(communicationCollections[i]);
                }
            }

            for (var o in communicationCollections) {

                //  Default
                if (communicationCollections[o].hasOwnProperty("defaultCollection") && communicationCollections[o].defaultCollection === true) {
                    //  Skip
                }

                //  No parent
                else if (!communicationCollections[o].hasOwnProperty("parentGuid")) {

                    var childrenOfCommunication = this.checkCommunicationForChildren(communicationCollections, communicationCollections[o].communicationCollectionGuid);
                    if (childrenOfCommunication.length) {
                        communicationCollections[o].children = childrenOfCommunication;
                    }
                    output.push(communicationCollections[o]);
                }
            }

            return output;
        },

        /**
         * Build tree of communication collections
         * 
         * @param {Array} communicationCollections
         * @param {Object} parent
         * 
         * @returns {Array}
         */
        checkCommunicationForChildren: function(communicationCollections, parent) {

            var output = [];

            for (var i in communicationCollections) {

                //  Has parent
                if (communicationCollections[i].hasOwnProperty("parentGuid") && communicationCollections[i].parentGuid === parent) {

                    var children = this.checkCommunicationForChildren(communicationCollections, communicationCollections[i].communicationCollectionGuid);
                    if (children.length) {
                        communicationCollections[i].children = children;
                    }
                    output.push(communicationCollections[i]);
                }
            }

            return output;
        },

        /**
         * Sort shared agreements
         * 
         * @param {Array} agreements
         * 
         * @returns {Array}
         */
        sortSharedAgreements: function(agreements) {

            //  Sorting by frequency numerically (scheduleEveryOther)
            agreements.sort(function(a, b) {
                if (a.scheduleEveryOther < b.scheduleEveryOther) {
                    return -1;
                } else if (a.scheduleEveryOther > b.scheduleEveryOther) {
                    return 1;
                }
                return 0;
            });

            //  Sorting by starting month numerically (scheduleBaseTier)
            agreements.sort(function(a, b) {
                if (a.scheduleBaseTier < b.scheduleBaseTier) {
                    return -1;
                } else if (a.scheduleBaseTier > b.scheduleBaseTier) {
                    return 1;
                }
                return 0;
            });

            //  Sorting by name
            agreements.sort(function(a, b) {
                if (a.name < b.name) {
                    return -1;
                } else if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });

            return agreements;
        },

        /**
         * Flatten nested communication collections
         * 
         * @param {Array} communicationCollections
         * @param {String} prefix
         * 
         * @returns {Array}
         */
        flattenCommunicationInheritance: function(communicationCollections, prefix = '') {

            var output = [];

            for (var i in communicationCollections) {
                if (communicationCollections[i].hasOwnProperty("children")) {

                    //  Get children and remove
                    var children = communicationCollections[i].children;
                    delete communicationCollections[i].children;

                    //  Rename
                    communicationCollections[i].collectionName = prefix + communicationCollections[i].collectionName;

                    //  Push parent
                    output.push(communicationCollections[i]);

                    //  Flatten nested communication collections
                    var nestedCommunicationCollections = this.flattenCommunicationInheritance(children, prefix);
                    for (var o in nestedCommunicationCollections) {

                        var storePrefix = prefix;
                        prefix += '- ';

                        //  Rename
                        nestedCommunicationCollections[o].collectionName = prefix + nestedCommunicationCollections[o].collectionName;

                        //  Push children
                        output.push(nestedCommunicationCollections[o]);

                        prefix = storePrefix;
                    }

                } else {

                    //  Rename
                    communicationCollections[i].collectionName = prefix + communicationCollections[i].collectionName;

                    //  Push sibling
                    output.push(communicationCollections[i]);
                }
            }

            return output;
        }
    }
}