import axios from "axios";
import md5 from "js-md5";
import { workflowHandler } from "./workflowHandler.js";

export const campaignHandler = {
    mixins: [workflowHandler],
    methods: {

        /**
         * Get campaign by guid
         * @param {string} guid 
         */
        getCampaign: function(guid) {

            let campaign = this.loadCampaign(guid);
            if (campaign) {

                return {
                    campaignErrored: false,
                    campaignErrorDescription: null,
                    campaign: campaign
                };
            } else {

                // window.console.log("campaign: get campaign from API");

                //  Request
                return axios
                    .get(this.$root.apiUrl + this.returnCampaignApiPath(guid), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken
                        }
                    })
                    .then(response => {

                        return {
                            campaignErrored: false,
                            campaignErrorDescription: null,
                            campaign: response.data
                        };
                    })
                    .catch(error => {

                        this.$root.handleErrorResponse(error);

                        return {
                            campaignErrored: true,
                            campaignErrorDescription: (error.response && error.response.data.errorDescription) ? error.response.data.errorDescription : null,
                            campaign: null
                        };
                    });
            }
        },

        /**
         * Insert campaign
         */
        insertCampaign: function() {

            //  Validate
            var error = this.validateCampaign();
            if (error) {
                this.$root.errorResponse(error);
                return;
            }

            //  Body
            let body = JSON.stringify(this.campaign);

            //  Request
            axios
                .post(this.$root.apiUrl + "/campaign", body, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.$root.user.authToken,
                        requestMD5: md5(body)
                    }
                })
                .then(response => {

                    //  Append guid and createdTs
                    //  TODO: timezone is off
                    this.campaign.campaignGuid = response.data.campaignGuid;
                    this.campaign.createdTs = new Date()
                        .toJSON()
                        .slice(0, 19)
                        .replace("T", " ");

                    //  Push to list
                    this.$root.store.campaigns.push(this.campaign);

                    //  Router
                    this.$root.viewEntity("campaign", response.data.campaignGuid);

                    //  Emit
                    this.$emit("insertedCampaign", this.campaign);

                    //  Toast
                    this.$root.successResponse("Created", response);

                    //  Force update needed to switch UI from Insert to Update
                    this.$forceUpdate();
                })
                .catch(error => {
                    this.$root.handleErrorResponse(error);
                });
        },

        /**
         * Update campaign
         */
        updateCampaign: function() {

            //  Validate
            var error = this.validateCampaign();
            if (error) {
                this.$root.errorResponse(error);
                return;
            }

            //  Body
            let body = JSON.stringify(this.campaign);

            //  Request
            axios
                .put(
                    this.$root.apiUrl + "/campaign/" + this.campaign.campaignGuid,
                    body, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$root.user.authToken,
                            requestMD5: md5(body)
                        }
                    }
                )
                .then(response => {

                    //  Emit
                    this.$emit("updatedCampaign", response);

                    //  Toast
                    this.$root.successResponse("Updated", response);
                })
                .catch(error => {
                    this.$root.handleErrorResponse(error);
                });
        },

        /**
         * Archive campaign
         */
        archiveCampaign: function() {

            //  Verify role
            if (this.$root.user.role !== 'MerchantAdmin') {
                this.$root.errorResponse("You are not allowed to edit campaigns");
                return;
            }

            //  Body
            let body = "";

            //  Confirmation
            if (confirm("Are you sure?")) {

                //  Request
                axios
                    .post(
                        this.$root.apiUrl +
                        "/campaign/" +
                        this.campaign.campaignGuid +
                        "/Archive",
                        body, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: this.$root.user.authToken,
                                requestMD5: md5(body)
                            }
                        }
                    )
                    .then(response => {

                        //  Emit
                        this.$emit("archivedCampaign", response);

                        //  Toast
                        this.$root.successResponse("Archived", response);

                        //  Router
                        this.$root.viewEntity("campaign");
                    })
                    .catch(error => {
                        this.$root.handleErrorResponse(error);
                    });
            }
        },

        /**
         * Get campaigns with workflows
         */
        getCampaigns: function() {

            //  Get filtered campaign state
            let state = (this.$root.store.campaignState) ? this.$root.store.campaignState : null;

            //  Check full storage
            if (this.$root.store.campaigns.length > 0 && this.$root.store.workflows.length > 0) {

                //  Combine results
                this.combineCampaignsWithWorkflows();

                return {
                    campaignsErrored: false,
                    campaigns: this.$root.store.campaigns,
                    workflows: this.$root.store.workflows
                };
            }

            //  Retrieve from API
            else {

                //  Prepare API request
                let request = [];

                //  Append campaign request, if empty storage
                if (this.$root.store.campaigns.length === 0) {
                    request.push(
                        axios.get(this.$root.apiUrl + this.returnCampaignsApiPath(state), {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: this.$root.user.authToken
                            }
                        })
                    );
                } else {
                    request.push('');
                }

                //  Append workflow request, if empty storage
                if (this.$root.store.workflows.length === 0) {
                    request.push(
                        axios.get(this.$root.apiUrl + this.returnWorkflowsApiPath(), {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: this.$root.user.authToken
                            }
                        })
                    );
                } else {
                    request.push('');
                }

                //  Request
                return axios
                    .all(request)
                    .then(
                        axios.spread((campaignResponse, workflowResponse) => {

                            if (this.$root.store.campaigns.length === 0) {

                                //  Build storage
                                this.$root.store.campaigns = campaignResponse.data;
                            }

                            if (this.$root.store.workflows.length === 0) {

                                //  Build storage
                                this.$root.store.workflows = workflowResponse.data;
                            }

                            //  Combine results
                            this.combineCampaignsWithWorkflows();

                            return {
                                campaignsErrored: false,
                                campaigns: this.$root.store.campaigns,
                                workflows: this.$root.store.workflows
                            };
                        })
                    )
                    .catch(error => {

                        this.$root.handleErrorResponse(error);

                        return {
                            campaignsErrored: true,
                            campaigns: [],
                            workflows: []
                        };
                    });
            }
        },

        /**
         * Combine list of campaigns with workflows
         */
        combineCampaignsWithWorkflows: function() {
            if (this.$root.store.campaigns.length > 0 && this.$root.store.workflows.length > 0) {
                //  Loop campaigns
                for (var i = 0; i < this.$root.store.campaigns.length; i++) {
                    //  Loop workflows
                    for (var o = 0; o < this.$root.store.workflows.length; o++) {
                        //  Attach workflowName to Campaign by workflowGuid
                        if (
                            this.$root.store.campaigns[i].workflowGuid ===
                            this.$root.store.workflows[o].workflowGuid
                        ) {
                            this.$root.store.campaigns[
                                i
                            ].workflowName = this.$root.store.workflows[o].name;
                        }
                    }
                }
            }

            //  Force update needed to refresh UI with extended campaigns data
            this.$forceUpdate();
        },

        /**
         * Load campaign from storage
         * @param {string} guid 
         */
        loadCampaign: function(guid) {

            if (this.$root.store.campaigns.length > 0) {
                for (var i = 0; i < this.$root.store.campaigns.length; i++) {
                    if (guid === this.$root.store.campaigns[i].campaignGuid) {

                        // window.console.log("campaign: get campaign from storage");

                        return this.$root.store.campaigns[i];
                    }
                }
            }

            return null;
        },

        /**
         * Validate campaign
         */
        validateCampaign: function() {

            //  Verify role
            if (this.$root.user.role !== 'MerchantAdmin') {
                return "You are not allowed to edit campaigns";
            }

            //  Remove workflow name
            this.$delete(this.campaign, "workflowName");

            //  Set merchantId
            this.campaign.merchantId = this.$root.user.merchantId;

            //  Validation
            if (!this.campaign.name) {
                return "Missing name";
            } else if (!this.campaign.firstDate || !this.campaign.lastDate) {
                return "Missing running period";
            } else if (!this.campaign.workflowGuid) {
                return "Missing Workflow";
            } else if (!this.campaign.agencyId) {
                return "Missing Agency";
            }

            return null;
        },

        /**
         * Return API endpoint
         * @param {string} guid
         */
        returnCampaignApiPath: function(guid) {
            //  Merchant
            if (this.$root.user.role === "MerchantAdmin") {
                return (
                    "/campaign/" + guid + "?merchantId=" +
                    encodeURIComponent(this.$root.user.merchantId)
                );
            }

            //  Agency
            else if (this.$root.user.agencyId) {
                return (
                    "/campaign/" + guid + "?agencyId=" +
                    encodeURIComponent(this.$root.user.agencyId)
                );
            }

            //  Error
            else {
                return null;
            }
        },

        /**
         * Return API endpoint
         * @param {string} state
         */
        returnCampaignsApiPath: function(state = null) {

            //  Defaults to all (all|active|archived)
            if (state === null) {
                state = 'active';
            }

            //  Merchant
            if (this.$root.user.role === "MerchantAdmin") {
                return (
                    "/campaigns?state=" + state + "&merchantId=" +
                    encodeURIComponent(this.$root.user.merchantId)
                );
            }

            //  Agency
            else if (this.$root.merchant.merchantId && this.$root.user.agencyId) {
                return (
                    "/campaigns?state=" + state + "&merchantId=" +
                    encodeURIComponent(this.$root.merchant.merchantId) +
                    "&agencyId=" +
                    encodeURIComponent(this.$root.user.agencyId)
                );
            }

            //  Error
            else {
                return null;
            }
        }
    }
};