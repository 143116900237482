<template>
  <div class="nav-left" role="menu">
    <div class="nav-wrap">
      <div class="brand-wrap"></div>
      <nav class="primary-menu">
        <ul>
          <li v-bind:class="[currentPage === '/' ? 'active' : '']">
            <router-link :to="{ name: 'agency-dashboard' }">
              Dashboard
            </router-link>
          </li>
          <li>
            <!-- Error -->
            <span v-if="this.listErrored === true"> Clients - error </span>

            <!-- Loading -->
            <span v-else-if="this.listLoading === true">
              Clients - loading
            </span>

            <!-- Ready -->
            <span
              v-else-if="this.$root.store.merchants.length > 0"
              v-on:click="resetMerchant()"
            >
              Clients
            </span>

            <!-- Merchant list -->
            <ul
              class="merchants-list sub-menu"
              v-if="this.$root.store.merchants.length > 0"
            >
              <li
                v-for="merchant in this.$root.store.merchants"
                v-bind:key="merchant.merchantId"
              >
                <!-- Engage merchant -->
                <button
                  type="button"
                  v-bind:class="[
                    Object.keys($root.merchant).length > 0 &&
                    $root.merchant.merchantId === merchant.merchantId
                      ? 'active'
                      : '',
                  ]"
                  v-on:click="openMerchantModal(merchant)"
                >
                  {{ merchant.name }}
                </button>

                <!-- Merchant data -->
                <ul
                  class="merchants-entities sub-menu"
                >
                  <!-- Leads -->
                  <li
                    v-bind:class="[
                      currentPage.includes('leads') ? 'active' : '',
                    ]"
                  >
                    <router-link
                      :to="{
                        name: 'agency-leads',
                        query: { merchantId: merchant.merchantId },
                      }"
                    >
                      Leads
                    </router-link>
                  </li>

                  <!-- Sessions -->
                  <li
                    v-bind:class="[
                      currentPage.includes('sessions') ? 'active' : '',
                    ]"
                  >
                    <router-link
                      :to="{
                        name: 'agency-sessions',
                        query: { merchantId: merchant.merchantId },
                      }"
                    >
                      Sessions
                    </router-link>
                  </li>

                  <!-- Campaigns -->
                  <li
                    v-bind:class="[
                      currentPage.includes('campaigns') ? 'active' : '',
                    ]"
                  >
                    <router-link
                      :to="{
                        name: 'agency-campaigns',
                        query: { merchantId: merchant.merchantId },
                      }"
                    >
                      Campaigns
                    </router-link>
                  </li>

                  <!-- Workflows -->
                  <li
                    v-if="$root.user.role === 'AgencyManager'"
                    v-bind:class="[
                      currentPage.includes('workflows') ? 'active' : '',
                    ]"
                  >
                    <router-link
                      :to="{
                        name: 'agency-workflows',
                        query: { merchantId: merchant.merchantId },
                      }"
                    >
                      Workflows
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li
            v-if="this.$root.user.role === 'AgencyManager'"
            v-bind:class="[currentPage.includes('users') ? 'active' : '']"
          >
            <router-link :to="{ name: 'agency-users' }"> Users </router-link>
          </li>
        </ul>
      </nav>
      <nav class="support-menu">
        <ul>
          <li>
            <a
              href="https://support.onlinefundraising.dk"
              target="_blank"
              rel="noopener noreferrer"
              >Support</a
            >
          </li>
        </ul>
        <ul class="logout">
          <li>
            <logout />
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import Logout from "../account/Logout";
import { merchantHandler } from "../../mixins/merchantHandler.js";

export default {
  name: "NavbarLeft",
  mixins: [merchantHandler],
  components: {
    Logout,
  },
  data: function () {
    return {
      listErrored: false,
      listLoading: true,
    };
  },
  computed: {
    currentPage() {
      return this.$route.path;
    },
  },
  created() {
    // window.console.log("created");
    this.getMerchants();
  },
  mounted() {
    // window.console.log("mounted");
  },
  methods: {
    /**
     * Reset merchant and stored data
     */
    resetMerchant() {
      //  Merchant
      this.$root.merchant = {};
      //  Merchant data
      this.$root.store.agreements = [];
      this.$root.store.campaigns = [];
      this.$root.store.communicationCollections = [];
      this.$root.store.leads = [];
      this.$root.store.leadPageStorage = [];
      this.$root.store.leadPageNumber = 1;
      this.$root.store.sessions = [];
      this.$root.store.sessionPageStorage = [];
      this.$root.store.sessionPageNumber = 1;
      this.$root.store.workflows = [];

      //  Remember pageSize
      let leadPageSize = window.getMark("leadPageSize");
      if (leadPageSize) {
        this.$root.store.leadPageSize = leadPageSize;
      } else {
        this.$root.store.leadPageSize = 10;
      }
      let sessionPageSize = window.getMark("sessionPageSize");
      if (sessionPageSize) {
        this.$root.store.sessionPageSize = sessionPageSize;
      } else {
        this.$root.store.sessionPageSize = 10;
      }

      //  Reset filters
      this.$root.store.leadsByCampaign = null;
      this.$root.store.sessionsByCampaign = null;

      //  Reset route
      this.$router.push({
        name: "agency-dashboard",
      });
    },

    /**
     * Engange merchant
     * @param {object} merchant
     */
    openMerchantModal: function (merchant) {
      this.resetMerchant();
      this.$root.merchant = merchant;

      this.$router.push({
        name: "agency-dashboard",
        query: {
          merchantId: merchant.merchantId,
        },
      });
    },

    /**
     * Get merchants
     */
    getMerchants: function () {
      let merchantHandler = this.loadMerchants();

      //  Promise
      if (typeof merchantHandler.then !== "undefined") {
        merchantHandler.then((response) => {
          if (response.agenciesErrored) {
            this.listErrored = true;
          } else {
            this.listLoading = false;
          }
        });
      }

      //  Storage
      else {
        this.listLoading = false;
      }
    },
  },
};
</script>
