//  Modules
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueToast from "vue-toast-notification";
import axios from "axios";
import App from './App.vue';

//  Router
import Onboarding from './components/frontend/Onboarding.vue';
import OnboardingResult from './components/frontend/OnboardingResult.vue';
import Dashboard from './components/dashboard/Dashboard.vue';
import Workflows from './components/workflows/WorkflowList.vue';
import Campaigns from './components/campaigns/CampaignList.vue';
import Sessions from './components/sessions/SessionList.vue';
import Leads from './components/leads/LeadList.vue';
import Agencies from './components/agencies/AgencyList.vue';
import Users from './components/users/UserList.vue';
import Profile from './components/account/Profile.vue';

//  Mixins
import { merchantHandler } from "./mixins/merchantHandler.js";

//  Assets
import "vue-toast-notification/dist/index.css";
require('./assets/css/reset.min.css');
require('./assets/css/bootstrap-grid.min.css')
require('./assets/css/uik-core.css');
require('./assets/css/uik-lists.css');
require('./assets/css/uik-singles.css');
require('./assets/css/onboarding-app.css');
require('./assets/js/onboarding-app.js');

Vue.use(VueRouter);
Vue.use(VueToast, { position: "top-right", duration: 6000 });

//	MERCHANT
//	userType:   MerchantAdmin
//	merchantId: Red Christian
//	Name:       Christian
//	AuthToken:  7784b257ed5a45ff9c8aa85d2a19a46008d9b71fe17e4110b724d231a3ccd6be
//	
//	AGENCY
//	userType:   AgencyManager
//	Email:      laugesen+manager1@fundraisingbureauet.dk
//	Password:   2M6nRKRAl3fuAIOgNwp7
//	
//	userType:   SeniorAgent
//	Email:      laugesen+senior3@fundraisingbureauet.dk
//	Password:   t5Agnauw5RY2Ie9Z8KN2
//	
//	userType:   JuniorAgent
//	Email:      laugesen+junior1@fundraisingbureauet.dk
//	Password:   iW3UT71LwfbiJGTqa1ru

// eslint-disable-next-line
const config_production = false;
// eslint-disable-next-line
const config_client_path = (typeof onboardingConfig === 'object' ? onboardingConfig.client_path : '/wp-admin');
// eslint-disable-next-line
const config_api_url = (typeof onboardingConfig === 'object' ? onboardingConfig.api_url : (config_production ? 'https://onboarding.api.v1.onlinefundraising.dk' : 'https://sandbox.onboarding.api.onlinefundraising.dk')); // https://sandbox.onboarding.api.onlinefundraising.dk // https://development.onboarding.api.onlinefundraising.dk
// eslint-disable-next-line
const config_api_token = (typeof onboardingConfig === 'object' ? onboardingConfig.api_token : ''); // be7be837b099aeeb377b3b85587b163ad20d2a5ad52b12aa0ee326e55518fff3
// eslint-disable-next-line
const config_data_receiver_url = 'https://development.datareceiver.api.onlinefundraising.dk';

//  Vue production tips
Vue.config.productionTip = false;

//  Router configuration
const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: [
        { name: 'onboarding', path: '/onboard', component: Onboarding },
        { name: 'onboarding-result', path: '/result', component: OnboardingResult },
        //  Agency
        { name: 'agency-dashboard', path: '/admin', component: Dashboard },
        { name: 'agency-workflows', path: '/admin/workflows', component: Workflows },
        { name: 'agency-campaigns', path: '/admin/campaigns', component: Campaigns },
        { name: 'agency-sessions', path: '/admin/sessions', component: Sessions },
        { name: 'agency-leads', path: '/admin/leads', component: Leads },
        { name: 'agency-agencies', path: '/admin/agencies', component: Agencies },
        { name: 'agency-users', path: '/admin/users', component: Users },
        { name: 'agency-profile', path: '/admin/profile', component: Profile },
        //  Default (merchant)
        { name: 'dashboard', path: config_client_path, component: Dashboard },
        { name: 'workflows', path: config_client_path, component: Workflows },
        { name: 'campaigns', path: config_client_path, component: Campaigns },
        { name: 'sessions', path: config_client_path, component: Sessions },
        { name: 'leads', path: config_client_path, component: Leads },
        { name: 'agencies', path: config_client_path, component: Agencies },
        { name: 'users', path: config_client_path, component: Users },
        { name: 'profile', path: config_client_path, component: Profile }
    ]
});

new Vue({
    router,
    mixins: [
        merchantHandler
    ],
    data: {
        backendPath: config_client_path,
        apiUrl: config_api_url,
        dataRecieverUrl: config_data_receiver_url,
        user: {
            merchantId: null,
            agencyId: null,
            authToken: config_api_token,
            guid: null,
            role: null,
            name: null,
            email: null,
            externalId: null,
            active: false,
            scopes: null,
        },
        merchant: {},
        store: {
            merchants: [],
            agencies: [],
            users: [],
            campaigns: [],
            workflows: [],
            sessions: [],
            sessionPageStorage: [],
            sessionPageNumber: 1,
            sessionPageSize: 10,
            leads: [],
            leadPageStorage: [],
            leadPageNumber: 1,
            leadPageSize: 10,
            agreements: [],
            communicationCollections: [],
            campaignsToExports: {},
            campaignExportToDownload: '',
        },
        helpers: {
            gatewayDate: {
                Betalingsservice: ''
            },
            gatewayDates: {
                Betalingsservice: [
                    '2023-01-24',
                    '2023-02-21',
                    '2023-03-24',
                    '2023-04-21',
                    '2023-05-23',
                    '2023-06-23',
                    '2023-07-24',
                    '2023-08-24',
                    '2023-09-22',
                    '2023-10-24',
                    '2023-11-23',
                    '2023-12-20',
                    '2024-01-24',
                    '2024-02-22',
                    '2024-03-20',
                    '2024-04-23',
                    '2024-05-24',
                    '2024-06-21',
                    '2024-07-24',
                    '2024-08-23',
                    '2024-09-23',
                    '2024-10-24',
                    '2024-11-22',
                    '2024-12-18'
                ]
            },
            contactFieldTypes: [{
                    label: "Field type",
                    name: ""
                },
                {
                    label: "Name",
                    name: "name",
                    inputType: "text",
                    attr: {
                        pattern: "[a-zA-Z]+"
                    }
                },
                {
                    label: "First name",
                    name: "firstName",
                    inputType: "text",
                    attr: {
                        pattern: "[a-zA-Z]+"
                    }
                },
                {
                    label: "Last name",
                    name: "lastName",
                    inputType: "text",
                    attr: {
                        pattern: "[a-zA-Z]+"
                    }
                },
                {
                    label: "Email",
                    name: "email",
                    inputType: "email",
                    attr: {
                        minlength: 6
                    }
                },
                {
                    label: "Phone",
                    name: "msisdn",
                    inputType: "number",
                    attr: {
                        minlength: 8,
                        maxlength: 10
                    }
                },
                {
                    label: "National ID",
                    name: "nationalId",
                    inputType: "number",
                    attr: {
                        minlength: 10,
                        maxlength: 10
                    }
                },
                {
                    label: "Business number",
                    name: "businessCode",
                    inputType: "number",
                    attr: {
                        minlength: 8
                    }
                },
                {
                    label: "Business name",
                    name: "companyName",
                    inputType: "text"
                },
                {
                    label: "Validated address",
                    name: "dawaAddress",
                    inputType: "dawaAddress"
                },
                {
                    label: "Address",
                    name: "address",
                    inputType: "text"
                },
                {
                    label: "Address line 2",
                    name: "address2",
                    inputType: "text"
                },
                {
                    label: "Postcode",
                    name: "postCode",
                    inputType: "number"
                },
                {
                    label: "City",
                    name: "city",
                    inputType: "text"
                },
                {
                    label: "Country",
                    name: "countryCode",
                    inputType: "text",
                    attr: {
                        pattern: "[a-zA-Z]{2}",
                        placeholder: "Two letters (DK, SE, NO, FI, DE etc.)"
                    }
                },
                {
                    label: "Birthdate",
                    name: "birthDate",
                    inputType: "date",
                    attr: {
                        pattern: "[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])",
                        placeholder: "YYYY-MM-DD"
                    }
                },
                {
                    label: "External ID",
                    name: "externalId",
                    inputType: "text"
                }
            ],
            urlShorteners: [
                { label: "giiv.dk", value: "GIIV" },
                { label: "kortl.ink", value: "KORTL_INK" }
            ],
            scheduleTypes: [
                { label: "Annually", value: "Yearly" },
                { label: "Biannually", value: "Halfyearly" },
                { label: "Quarterly", value: "Quarterly" },
                { label: "Monthly", value: "Monthly" },
                { label: "Weekly", value: "Weekly" },
                { label: "Custom schedule", value: "Custom" },
                { label: "Manual schedule", value: "Manual" },
            ],
            scheduleEveryOthers: [
                { label: "1.", value: 1 },
                { label: "2.", value: 2 },
                { label: "3.", value: 3 },
                { label: "4.", value: 4 },
                { label: "5.", value: 5 },
                { label: "6.", value: 6 },
                { label: "7.", value: 7 },
                { label: "8.", value: 8 },
                { label: "9.", value: 9 },
                { label: "10.", value: 10 },
                { label: "11.", value: 11 },
                { label: "12.", value: 12 }
            ],
            scheduleCalendarUnits: [
                { label: "Week", value: "Week" },
                { label: "Month", value: "Month" }
            ],
            scheduleFixedDays: [
                { label: "1st", value: 1 },
                { label: "2nd", value: 2 },
                { label: "3rd", value: 3 },
                { label: "4th", value: 4 },
                { label: "5th", value: 5 },
                { label: "6th", value: 6 },
                { label: "7th", value: 7 },
                { label: "8th", value: 8 },
                { label: "9th", value: 9 },
                { label: "10th", value: 10 },
                { label: "11th", value: 11 },
                { label: "12th", value: 12 },
                { label: "13th", value: 13 },
                { label: "14th", value: 14 },
                { label: "15th", value: 15 },
                { label: "16th", value: 16 },
                { label: "17th", value: 17 },
                { label: "18th", value: 18 },
                { label: "19th", value: 19 },
                { label: "20th", value: 20 },
                { label: "21st", value: 21 },
                { label: "22nd", value: 22 },
                { label: "23rd", value: 23 },
                { label: "24th", value: 24 },
                { label: "25th", value: 25 },
                { label: "26th", value: 26 },
                { label: "27th", value: 27 },
                { label: "28th", value: 28 }
            ],
            scheduleBaseTiers: [
                { label: "Jan.", value: 1 },
                { label: "Feb.", value: 2 },
                { label: "Mar.", value: 3 },
                { label: "Apr.", value: 4 },
                { label: "May", value: 5 },
                { label: "Jun.", value: 6 },
                { label: "Jul.", value: 7 },
                { label: "Aug.", value: 8 },
                { label: "Sep.", value: 9 },
                { label: "Oct.", value: 10 },
                { label: "Nov.", value: 11 },
                { label: "Dec.", value: 12 }
            ],
            countryCodes: [
                { label: "Denmark", value: 'DK' },     
                { label: "Faroe Islands", value: 'FO' },
                { label: "Greenland", value: 'GL' },
                { label: "Iceland", value: 'IS' },
                { label: "Norway", value: 'NO' },
                { label: "Sweden", value: 'SE' },
                { label: "Germany", value: 'DE' },
                { label: "United Kingdom", value: 'GB' },
                { label: "Finland", value: 'FI' },
            ]
        },
        strings: {
            "_OnThe": "on the",
            "_In": "in",
            "_Of": "of",
            "_Every": "every",
            "_OfEvery": "of every",
            "_BasedOn": "based on",
            "_StartingFrom": "starting from",
            "_CalendarUnit_Week": "Week",
            "_CalendarUnit_Month": "Month",
            "_Frequency_SelectedMonths": "Selected months",
            "_Frequency_Manual": "Manual",
            "_Frequency_Custom": "Custom",
            "_Frequency_Daily": "Daily",
            "_Frequency_Weekly": "Weekly",
            "_Frequency_Monthly": "Monthly",
            "_Frequency_Hexannually": "Hexannually",
            "_Frequency_Quarterly": "Quarterly",
            "_Frequency_Triannually": "Triannually",
            "_Frequency_Halfyearly": "Biannually",
            "_Frequency_Yearly": "Annually",
            "_Frequency_MonthlyFirst": "Monthly on the 1st",
            "_Frequency_QuarterlyFirst": "Quarterly on the 1st of Jan, Apr, Jul, and Oct",
            "_Frequency_HalfyearlyFirst": "Biannually on the 1st of Jan and Jul",
            "_Frequency_YearlyFirst": "Annually on the 1st of Jan",
            "_Sunday": "Sunday",
            "_Monday": "Monday",
            "_Tuesday": "Tuesday",
            "_Wednesday": "Wednesday",
            "_Thursday": "Thursday",
            "_Friday": "Friday",
            "_Saturday": "Saturday",
            "_Sun": "Sun",
            "_Mon": "Mon",
            "_Tue": "Tue",
            "_Wed": "Wed",
            "_Thu": "Thu",
            "_Fri": "Fri",
            "_Sat": "Sat",
            "_January": "January",
            "_February": "February",
            "_March": "March",
            "_April": "April",
            "_May": "May",
            "_June": "June",
            "_July": "July",
            "_August": "August",
            "_September": "September",
            "_October": "October",
            "_November": "November",
            "_December": "December",
            "_Jan": "Jan",
            "_Feb": "Feb",
            "_Mar": "Mar",
            "_Apr": "Apr",
            "_May_abbr": "May",
            "_Jun": "Jun",
            "_Jul": "Jul",
            "_Aug": "Aug",
            "_Sep": "Sep",
            "_Oct": "Oct",
            "_Nov": "Nov",
            "_Dec": "Dec"
        }
    },
    created() {

        this.getAccount();
    },
    methods: {

        /**
         * Get account as verification
         */
        getAccount: function() {

            this.ensureToken();

            if (this.user.authToken) {

                window.console.log("account verifying");

                //  Request
                axios
                    .get(this.apiUrl + "/account", {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.user.authToken
                        }
                    })
                    .then(response => {
                        this.handleAccountResponse(response);
                    })
                    .catch(error => {
                        this.handleAccountError(error);
                    });
            }
        },

        /**
         * Get authToken from browser session
         */
        ensureToken: function() {

            var nowInSeconds = Math.round(new Date().getTime() / 1000);

            let authExp = window.getMark("authExp");
            if (authExp && nowInSeconds > authExp) {

                this.logOut();
                window.console.log("login expired");

                this.$root.errorResponse("Login has expired. Please login again");
            } else if (!this.user.authToken) {
                this.user.authToken = window.getMark("authToken");
            }
        },

        /**
         * Handle response from account
         * @param {object} response 
         */
        handleAccountResponse: function(response) {

            window.console.log("account verified");

            //  Register user
            this.user.guid = response.data.userGuid;
            this.user.agencyId = response.data.agencyId;
            this.user.merchantId = response.data.merchantId;
            this.user.role = response.data.role;
            this.user.name = response.data.name;
            this.user.email = response.data.email;
            this.user.externalId = response.data.externalId;
            this.user.scopes = response.data.scopes;

            //  Update expiration
            if (
                this.$root.user.role === 'AgencyManager' ||
                this.$root.user.role === 'SeniorAgent' ||
                this.$root.user.role === 'JuniorAgent'
            ) {
                var nowInSeconds = Math.round(new Date().getTime() / 1000);
                var oneDay = 24 * 3600;
                window.setMark("authExp", nowInSeconds + oneDay);
            }

            //  Get merchant
            if (this.$root.user.role === 'MerchantAdmin') {

                let merchantHandler = this.getMerchant(response.data.merchantId);

                //  Promise
                if (typeof merchantHandler.then !== "undefined") {
                    merchantHandler.then(response => {
                        if (response.merchantErrored) {
                            this.$root.merchant = {};
                        } else {
                            this.$root.merchant = response.merchant;
                        }
                    });
                }

                //  Storage
                else if (merchantHandler.merchant) {
                    this.$root.merchant = merchantHandler.merchant;
                }
            }

            //  Agency
            else if (this.$route.query.merchantId) {

                let merchantHandler = this.getMerchant(this.$route.query.merchantId);

                //  Promise
                if (typeof merchantHandler.then !== "undefined") {
                    merchantHandler.then(response => {
                        if (response.merchantErrored) {
                            this.$root.merchant = {};
                        } else {
                            this.$root.merchant = response.merchant;
                        }
                    });
                }

                //  Storage
                else if (merchantHandler.merchant) {
                    this.$root.merchant = merchantHandler.merchant;
                }
            }

            this.$forceUpdate();
        },

        /**
         * Handle error from account
         * @param {object} error 
         */
        handleAccountError: function(error) {

            this.logOut();
            window.console.log("login not verified");

            this.$root.handleErrorResponse(error, "Unverified access. Please login");
        },

        /**
         * Handle error response
         * @param {object} error 
         * @param {string} fallbackErrorMessage 
         */
        handleErrorResponse: function(error, fallbackErrorMessage = null) {

            var errorMessage;

            if (error.response && error.response.status === 503) {
                errorMessage =
                    "Service is temporarily unavailable. Please try again later";
            } else if (error.response && error.response.data.errorDescription) {
                errorMessage = error.response.data.errorDescription;
            } else if (fallbackErrorMessage !== null) {
                errorMessage = fallbackErrorMessage;
            } else if (error.response && error.response.status === 400 && error.response.data) {
                errorMessage = error.response.data;
            } else {
                errorMessage = "Something went wrong. Please try again later";
            }

            this.errorResponse(errorMessage);

            return errorMessage;
        },

        /**
         * Toast error message
         * @param {string} errorMessage 
         */
        errorResponse: function(errorMessage) {
            Vue.$toast.open({
                message: errorMessage,
                type: "error"
            });
        },

        /**
         * Toast info message
         * @param {string} infoMessage 
         */
        infoResponse: function(infoMessage) {
            Vue.$toast.open({
                message: infoMessage,
                type: "info",
                duration: 10000
            });
        },

        /**
         * Toast success message
         * @param {string} successMessage
         */
        successResponse: function(successMessage) {
            Vue.$toast.open({
                message: successMessage,
                type: "success"
            });
        },

        /**
         * Go back in browser history
         */
        viewPrevious: function() {
            this.$router.go(-1);
        },

        /**
         * Open modal to view
         * @param {string} entityType 
         * @param {string} guid 
         * @param {string} search
         */
        viewEntity: function(entityType, guid = null, search = null) {

            let routerPrefix = "";
            let query = {};

            //  Merchant page
            if (this.$root.user.role === 'MerchantAdmin') {
                query['page'] = "onboarding_app";
            }

            //  MerchantId and agency route prefix
            else {
                routerPrefix = "agency-";
                query["merchantId"] = this.$root.merchant.merchantId;
            }

            //  Extend query
            if (guid) {
                query['view'] = entityType + "s";
                query['guid'] = guid;
            } else if (search) {
                query['view'] = entityType + "s";
                query['s'] = search;
            } else {
                query['view'] = entityType + "s";
            }

            this.$router.push({
                name: routerPrefix + entityType + "s",
                query: query
            });
        },

        /**
         * Open modal in new tab
         * @param {string} entityType
         * @param {string} guid
         */
        viewEntityNewTab: function(entityType, guid = null) {

            let routerPrefix = "";
            let query = {};

            //  Merchant page
            if (this.$root.user.role === 'MerchantAdmin') {
                query['page'] = "onboarding_app";
            }

            //  MerchantId and agency route prefix
            else {
                routerPrefix = "agency-";
                query["merchantId"] = this.$root.merchant.merchantId;
            }

            //  View and entity guid
            if (guid) {
                query['view'] = entityType + "s";
                query['guid'] = guid;
            } else {
                query['view'] = entityType + "s";
            }

            let routeData = this.$router.resolve({
                name: routerPrefix + entityType + "s",
                query: query
            });
            window.open(routeData.href, "_blank");
        },

        /**
         * Log out
         */
        logOut: function() {

            //  User
            this.$root.user = {};
            this.$root.user.authToken = null;
            //  Merchant
            this.$root.merchant = {};
            //  Storage
            this.$root.store.merchants = [];
            this.$root.store.agencies = [];
            this.$root.store.users = [];
            this.$root.store.campaigns = [];
            this.$root.store.workflows = [];
            this.$root.store.sessions = [];
            this.$root.store.sessionPageStorage = [];
            this.$root.store.sessionPageNumber = 1;
            this.$root.store.sessionPageSize = 10;
            this.$root.store.leads = [];
            this.$root.store.leadPageStorage = [];
            this.$root.store.leadPageNumber = 1;
            this.$root.store.leadPageSize = 10;
            this.$root.store.agreements = [];
            this.$root.store.communicationCollections = [];

            //  Empty authToken in browser session
            window.setMark("authToken", null);
            window.setMark("authExp", null);
        }
    },
    render: h => h(App),
}).$mount('#onboarding_app')