<template>
  <div class="wrap workflows">
    <div class="content">
      <!-- Heading -->
      <div class="view-header-wrap uik-entity-list-header">
        <h2 class="title">Workflows</h2>

        <!-- Tooltip -->
        <tool-tip
          v-bind:tooltip="'A configuration of what is pitched in onboarding and how data is collected. <a href=&quot;https://support.onlinefundraising.dk/hc/da/articles/360013209399&quot; target=&quot;_blank&quot;>See our guide.</a>'"
        />

        <!-- Buttons -->
        <div
          v-if="this.$root.user.role === 'MerchantAdmin'"
          class="right-aligned popover-wrapper"
        >
          <button
            type="button"
            class="uik-btn__base btn btn-primary"
            tabindex="-1"
            v-on:click="newWorkflow()"
          >
            Create new
          </button>
        </div>
      </div>

      <!-- Table -->
      <div class="uik-entity-list workflow-list">
        <table>
          <!-- Table header -->
          <thead>
            <tr>
              <th class="manage-column column-created">Created</th>
              <th class="manage-column column-name">Name</th>
              <th class="manage-column column-intention">Intention</th>
            </tr>
          </thead>

          <!-- Error -->
          <tbody v-if="this.listErrored">
            <tr>
              <td colspan="3" class="text-center">
                We are sorry. We were not able to retrieve your workflows.
              </td>
            </tr>
          </tbody>

          <!-- Loading -->
          <tbody v-else-if="this.listLoading">
            <tr>
              <td colspan="3" class="text-center">Loading workflows ...</td>
            </tr>
          </tbody>

          <!-- Table body -->
          <tbody v-else-if="this.$root.store.workflows.length > 0">
            <tr
              tabindex="0"
              v-for="workflow in this.$root.store.workflows"
              v-bind:key="workflow.workflowGuid"
              v-on:keyup.up="$event.target.previousElementSibling.focus()"
              v-on:keyup.down="$event.target.nextElementSibling.focus()"
              v-on:keyup.enter="editWorkflow(workflow)"
              v-on:click.exact="editWorkflow(workflow)"
              v-on:click.ctrl="editWorkflowNewTab(workflow)"
            >
              <td>
                <span
                  v-bind:inner-html.prop="workflow.createdTs | formatTime"
                ></span>
              </td>
              <td>{{ workflow.name }}</td>
              <td v-if="workflow.serviceType === 'SignUp'">Sign-up</td>
              <td v-else-if="workflow.serviceType === 'Upgrade'">Upgrade</td>
              <td v-else></td>
            </tr>
          </tbody>

          <!-- Not found -->
          <tbody v-else>
            <tr>
              <td colspan="3" class="text-center">Ready for workflows ...</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Modal -->
    <div v-if="this.currenctWorkflow">
      <workflow-modal
        v-bind:workflow="this.currenctWorkflow"
        v-on:insertedWorkflow="initialiseWorkflowModal()"
        v-on:updatedWorkflow="getWorkflowsList()"
        v-on:archivedWorkflow="refreshWorkflows()"
        v-on:closeWorkflowModal="closeWorkflowModal()"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import WorkflowModal from "./WorkflowModal";
import ToolTip from "../ToolTip";
import { workflowHandler } from "../../mixins/workflowHandler.js";
import { formats } from "../../mixins/formats.js";

export default Vue.extend({
  name: "WorkflowList",
  mixins: [workflowHandler, formats],
  components: {
    WorkflowModal,
    ToolTip,
  },
  data: function () {
    return {
      currenctWorkflow: null,
      listErrored: false,
      listLoading: true,
    };
  },
  created() {
    this.getWorkflowsList();

    //  Listen to browser history navigation
    window.addEventListener("popstate", this.initialiseWorkflowModal, false);
  },
  methods: {
    /**
     * Open workflow modal for new
     */
    newWorkflow: function () {
      this.currenctWorkflow = {};
    },

    /**
     * Open workflow modal to edit
     * @param {object} workflow
     */
    editWorkflow: function (workflow) {
      this.currenctWorkflow = workflow;
      this.$root.viewEntity("workflow", workflow.workflowGuid);
    },

    /**
     * Open workflow modal in new tab
     * @param {object} workflow
     */
    editWorkflowNewTab: function (workflow) {
      this.$root.viewEntityNewTab("workflow", workflow.workflowGuid);
    },

    /**
     * Close workflow modal
     */
    closeWorkflowModal: function () {
      this.currenctWorkflow = null;
      this.$root.viewEntity("workflow");
    },

    /**
     * Get list of workflows
     */
    getWorkflowsList: function () {
      let workflowHandler = this.getWorkflows();
      if (typeof workflowHandler.then !== "undefined") {
        workflowHandler.then((response) => {
          if (response.workflowsErrored) {
            this.listErrored = true;
          }
          this.listLoading = false;

          this.initialiseWorkflowModal();
        });
      } else {
        this.listLoading = false;

        this.initialiseWorkflowModal();
      }
    },

    /**
     * Initialise workflow modal by query parameter
     */
    initialiseWorkflowModal: function () {
      //  Detect query
      if (this.$route.query.view === "workflows" && this.$route.query.guid) {
        //  Handle promise or storage
        let workflowHandler = this.getWorkflow(this.$route.query.guid);

        //  Promise
        if (typeof workflowHandler.then !== "undefined") {
          workflowHandler.then((response) => {
            if (response.workflowErrored) {
              this.currenctWorkflow = null;
            } else {
              this.currenctWorkflow = response.workflow;
            }
          });
        }

        //  Storage
        else if (workflowHandler.workflow) {
          this.currenctWorkflow = workflowHandler.workflow;
        }
      }

      //  No query
      else {
        this.currenctWorkflow = null;
      }
    },

    /**
     * Refresh list of workflows
     */
    refreshWorkflows: function () {
      this.$root.store.workflows = [];
      this.currenctWorkflow = null;
      this.listLoading = true;
      this.getWorkflowsList();
    },
  },
});
</script>
