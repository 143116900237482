<template>
  <div class="form-group-date">
    <label class="control-label col-sm-3" v-bind:for="contactData.name">
      {{ contactData.label }}
    </label>
    <div class="control-input col-sm-9">
      <div class="input-wrap">
        <date-range-picker
          ref="datePicker"
          v-bind:required="contactData.required ? true : false"
          v-bind:class="[
            contactData.value && contactData.value.length > 0
              ? 'not-empty'
              : 'empty',
            contactData.validity ? contactData.validity : '',
          ]"
          v-bind:singleDatePicker="true"
          v-bind:timePicker="false"
          v-bind:timePicker24Hour="false"
          v-bind:showWeekNumbers="false"
          v-bind:showDropdowns="true"
          v-bind:autoApply="true"
          v-bind:ranges="false"
          v-bind:linkedCalendars="false"
          v-bind:always-show-calendars="true"
          v-bind:opens="'left'"
          v-bind:locale-data="{
            firstDay: 1,
            format: 'YYYY/MM/DD',
            separator: '-',
          }"
          v-model="datePicked"
          v-on:update="whenDatePicked()"
        >
          <template v-slot:input="datePicker">
            <span
              v-bind:inner-html.prop="datePicker | formatBirthDatePicker"
            ></span>
            <button type="button" class="btn btn-primary icon-btn">
              <i class="dashboard-icon icon-calendar"></i>
            </button>
          </template>
        </date-range-picker>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { formats } from "../../mixins/formats.js";

export default Vue.extend({
  name: "OnboardingContactDataDate",
  mixins: [formats],
  components: {
    DateRangePicker,
  },
  props: {
    contactData: {
      type: Object,
      required: true,
    },
    sessionRequest: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      contactFieldTypes: this.$root.helpers.contactFieldTypes,
      datePicked: {},
      validDate: true,
    };
  },
  created() {
    //  Initialise
    if (this.$route.query["pre_birthDate"]) {
      let date = this.$route.query["pre_birthDate"];
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      this.validDate = dateRegex.test(date);
      if (this.validDate) {
        this.datePicked = {
          startDate: date,
          endDate: "",
        };
      } else {
        this.$root.errorResponse("Attention! Your pre_birthDate-variable seems invalid. Please review!");
      }
    }
  },
  methods: {
    /**
     * Define birth date
     */
    whenDatePicked: function () {
      if (this.datePicked.startDate) {
        //  Loop fields to locate and update birthDate
        for (
          var i = 0;
          i < this.sessionRequest.workflow.contactDataFields.length;
          i++
        ) {
          let name = this.sessionRequest.workflow.contactDataFields[i].name;
          let field = this.sessionRequest.workflow.contactDataFields[i];
          if (name === "birthDate") {
            field.value = this.formatDateToYMD(this.datePicked.startDate);
          }
        }
      }
    },
  },
});
</script>
