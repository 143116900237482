<template>
  <div class="review-product">
    <div class="row">
      <div class="col-sm-6">
        <!-- Review Agreement -->
        <h2>Review existing agreement</h2>
        <table class="product-info">
          <tbody>
            <tr v-if="this.sessionRequest.agreementData.createdTs">
              <td class="table-header">Created</td>
              <td>
                <span
                  v-bind:inner-html.prop="
                    this.sessionRequest.agreementData.createdTs | formatDate
                  "
                ></span>
              </td>
            </tr>
            <tr v-if="this.sessionRequest.agreementData.agreementType">
              <td class="table-header">Agreement type</td>
              <td>{{ this.sessionRequest.agreementData.agreementType }}</td>
            </tr>
            <tr v-if="this.sessionRequest.agreementData.name">
              <td class="table-header">Agreement name</td>
              <td>{{ this.sessionRequest.agreementData.name }}</td>
            </tr>
            <tr v-if="this.sessionRequest.agreementData.amountTotal">
              <td class="table-header">Amount, incl. VAT</td>
              <td>
                {{
                  this.sessionRequest.agreementData.amountTotal | formatAmount
                }}
                {{ this.sessionRequest.agreementData.currencyCode }}
              </td>
            </tr>
            <tr>
              <td class="table-header">Tax-deductible</td>
              <td>
                {{
                  this.sessionRequest.agreementData.taxDeductable
                    | formatBoolean
                }}
              </td>
            </tr>
            <tr>
              <td class="table-header">Frequency</td>
              <td>
                <span
                  v-html="
                    this.humanReadableSchedule(
                      this.sessionRequest.agreementData
                    )
                  "
                ></span>
              </td>
            </tr>

            <!-- Subscription nextDueDate -->
            <tr
              v-if="
                this.sessionRequest.subscriptionData.state === 'Active' &&
                this.sessionRequest.subscriptionData.nextDueDate
              "
            >
              <td class="table-header">Next planned charge</td>
              <td>
                <span
                  v-bind:inner-html.prop="
                    this.sessionRequest.subscriptionData.nextDueDate
                      | formatDateWithColor
                  "
                ></span>
              </td>
            </tr>

            <!-- Subscription expiresAfterDate -->
            <tr
              v-if="
                this.sessionRequest.subscriptionData.state === 'Active' &&
                this.sessionRequest.subscriptionData.expiresAfterDate
              "
            >
              <td class="table-header">Expiration</td>
              <td>
                <span
                  v-bind:inner-html.prop="
                    this.sessionRequest.subscriptionData.expiresAfterDate
                      | formatDate
                  "
                ></span>
              </td>
            </tr>

            <!-- Subscription status -->
            <tr v-if="this.sessionRequest.subscriptionData">
              <td class="table-header">Subscription</td>
              <td
                v-if="this.sessionRequest.subscriptionData.state === 'Active'"
              >
                <span class="green"> Active </span>
              </td>
              <td
                v-else-if="this.sessionRequest.subscriptionData.holdDescription"
              >
                <span class="yellow">
                  On hold:
                  {{ this.sessionRequest.subscriptionData.holdDescription }}
                </span>
              </td>
              <td
                v-else-if="
                  this.sessionRequest.subscriptionData.cancelDescription
                "
              >
                <span class="red">
                  {{ this.sessionRequest.subscriptionData.cancelDescription }}
                </span>
              </td>
              <td v-else>
                <span class="red"> Inactive </span>
              </td>
            </tr>

            <!-- PaymentMethod status -->
            <tr v-if="this.sessionRequest.paymentMethodData.paymentMethodType">
              <td class="table-header">Payment method</td>
              <td
                v-if="this.sessionRequest.paymentMethodData.state === 'Active'"
              >
                <span class="green">
                  {{
                    this.sessionRequest.paymentMethodData.paymentMethodType
                      | humanReadablePaymentMethodType
                  }}
                  is active
                </span>
              </td>
              <td v-else>
                <span class="red">
                  {{
                    this.sessionRequest.paymentMethodData.paymentMethodType
                      | humanReadablePaymentMethodType
                  }}
                  is inactive
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-sm-6">
        <!-- Review AddOns -->
        <template v-if="this.sessionRequest.addOnsData.length > 0">
          <h2>Review add-ons</h2>
          <table class="product-info">
            <tbody>
              <tr
                v-for="addOnData in this.sessionRequest.addOnsData"
                v-bind:key="addOnData.addOnGuid"
              >
                <td
                  class="table-header"
                  v-bind:class="
                    addOnData.state === 'Inactive' ? 'strike-through' : ''
                  "
                >
                  <span v-bind:title="'Created ' + addOnData.createdTs">{{
                    addOnData.name
                  }}</span>
                </td>
                <td
                  class="text-align-right"
                  v-bind:class="
                    addOnData.state === 'Inactive' ? 'strike-through' : ''
                  "
                >
                  {{ addOnData.amountTotal | formatAmount }}
                  {{ sessionRequest.agreementData.currencyCode }}
                </td>
              </tr>
              <tr>
                <td>Total add-ons</td>
                <td class="text-align-right">
                  <u
                    >{{ addOnTotal | formatAmount }}
                    {{ sessionRequest.agreementData.currencyCode }}</u
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Total charge</strong>
                </td>
                <td class="text-align-right">
                  <strong
                    ><u
                      >{{ subTotal | formatAmount }}
                      {{ sessionRequest.agreementData.currencyCode }}</u
                    ></strong
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { formats } from "../../mixins/formats.js";

export default Vue.extend({
  name: "OnboardingUpgradeReviewTable",
  mixins: [formats],
  props: {
    sessionRequest: {
      type: Object,
      required: true,
    },
  },
  computed: {
    addOnTotal() {
      let amount = 0;

      for (var i = 0; i < this.sessionRequest.addOnsData.length; i++) {
        if (this.sessionRequest.addOnsData[i].state !== "Inactive") {
          amount += parseInt(this.sessionRequest.addOnsData[i].amountTotal);
        }
      }

      return amount;
    },
    subTotal() {
      let amount = parseInt(this.addOnTotal);

      amount += parseInt(this.sessionRequest.agreementData.amountTotal);

      return amount;
    },
  },
});
</script>
