var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wrap sessions"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"view-header-wrap uik-entity-list-header"},[_c('h2',{staticClass:"title"},[_vm._v("Sessions")]),_c('tool-tip',{attrs:{"tooltip":'A session represents a single submission of a workflow. <a href="https://support.onlinefundraising.dk/hc/da/articles/360013134140" target="_blank">See our guide.</a>'}}),_c('div',{staticClass:"search-wrapper"},[_c('form',{staticClass:"search-form",attrs:{"role":"form","method":"post","action":"","autocomplete":"nope","novalidate":"novalidate"},on:{"submit":function($event){$event.preventDefault();}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"uik-input__input search-input",attrs:{"type":"search","placeholder":"Search by external lead ID","tabindex":"1"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}}),_vm._m(0)])]),_c('list-filter',{attrs:{"entityType":'session'},on:{"appliedFilter":function($event){return _vm.appliedFilter()}}})],1),_c('div',{staticClass:"uik-entity-list session-list"},[_c('table',[_vm._m(1),(this.listErrored === true)?_c('tbody',[_vm._m(2)]):(this.listLoading === true)?_c('tbody',[_vm._m(3)]):(this.$root.store.sessions.length > 0)?_c('tbody',_vm._l((this.$root.store.sessions),function(session){return _c('tr',{key:session.sessionGuid,class:{
              blue: session.status === 'Processing',
              green: session.status === 'Completed',
              red: session.status === 'Failed',
              grey: session.status === 'Expired',
            },attrs:{"tabindex":"0"},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return $event.target.previousElementSibling.focus()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return $event.target.nextElementSibling.focus()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.viewSession(session)}],"click":[function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.viewSession(session)},function($event){if(!$event.ctrlKey)return null;return _vm.viewSessionNewTab(session)}]}},[_c('td',[_vm._v(_vm._s(session.status))]),_c('td',[_c('span',{domProps:{"innerHTML":_vm._f("formatTime")(session.createdTs)}})]),_c('td',[_vm._v(_vm._s(_vm._f("humanReadableServiceType")(session.serviceType)))]),_c('td',[_vm._v(_vm._s(session.campaignName))]),_c('td',[_vm._v(_vm._s(session.workflowName))]),_c('td',[_vm._v(_vm._s(session.agencyId))])])}),0):_c('tbody',[_vm._m(4)])]),(
          this.listErrored != true &&
          this.listLoading != true &&
          this.$root.store.sessions.length > 0
        )?_c('pagination',{attrs:{"pageNumber":this.$root.store.sessionPageNumber,"pageSize":this.$root.store.sessionPageSize,"currentPageSize":this.$root.store.sessions.length},on:{"setPage":_vm.setPage}}):_vm._e()],1)]),(this.currentSession)?_c('div',[_c('session-modal',{attrs:{"session":this.currentSession},on:{"closeSessionModal":function($event){return _vm.closeSessionModal()}}})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{attrs:{"type":"button","tabindex":"-1"}},[_c('i',{staticClass:"dashboard-icon icon-search"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('thead',[_c('tr',[_c('th',{staticClass:"manage-column column-status"},[_vm._v("Status")]),_c('th',{staticClass:"manage-column column-created"},[_vm._v("Created")]),_c('th',{staticClass:"manage-column column-service-type"},[_vm._v("Intention")]),_c('th',{staticClass:"manage-column column-campaign"},[_vm._v("Campaign")]),_c('th',{staticClass:"manage-column column-workflow"},[_vm._v("Workflow")]),_c('th',{staticClass:"manage-column column-agency"},[_vm._v("Agency")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"6"}},[_vm._v(" We are sorry. We were not able to retrieve sessions. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"6"}},[_vm._v("Loading sessions ...")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"6"}},[_vm._v("No sessions found ...")])])
}]

export { render, staticRenderFns }