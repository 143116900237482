<template>
  <div class="upgrade-by-subscription-actions">
    <!-- Hold or Cancel string -->
    <div v-if="this.sessionRequest.subscriptionData.state === 'Active' &&
      (this.checkWorkflowSubscriptionPermission('pauseSubscription') ||
        this.checkWorkflowSubscriptionPermission('cancelSubscription'))
      " class="control-input">
      <label for="subscriptionActionDescription">Describe reason</label>
      <input type="text" id="subscriptionActionDescription" class="uik-input__input" autocomplete="nope"
        placeholder="Optional" v-model.trim="product.subscriptionActionDescription" />
    </div>

    <br />

    <div v-if="this.sessionRequest.subscriptionData.state === 'Active' &&
      (this.checkWorkflowSubscriptionPermission('setExpiration'))
      " class="control-input">
      <label>Enter an expiration date (relevant only for setting expiration)</label>
      <div class="input-wrap">
        <date-range-picker ref="datePicker" v-bind:singleDatePicker="true" v-bind:timePicker="false"
          v-bind:timePicker24Hour="false" v-bind:showWeekNumbers="false" v-bind:showDropdowns="true"
          v-bind:autoApply="true" v-bind:ranges="false" v-bind:minDate="getMinScheduleStartCustomDate(product.paymentMethodType)
            " v-bind:linkedCalendars="false" v-bind:always-show-calendars="true" v-bind:opens="'left'"
          v-bind:locale-data="{
            firstDay: 1,
            format: 'YYYY/MM/DD',
            separator: '-',
          }" v-model="product.expiresAfterDate" v-on:update="whenDatePicked()">
          <template v-slot:input="datePicker">
            <span v-if="product.expiresAfterDate.startDate" v-bind:inner-html.prop="datePicker | formatDatePicker"></span>
            <span v-if="!product.expiresAfterDate.startDate" style="opacity: .5; ">
              Leave empty to remove expiration date
            </span>
            <button v-else type="button" class="btn uik-btn__link btn" style="right: 50px;"
              v-on:click.stop="product.expiresAfterDate = {}">
              Clear
            </button>
            <button type="button" class="btn btn-primary icon-btn">
              <i class="dashboard-icon icon-calendar"></i>
            </button>
          </template>
        </date-range-picker>
      </div>
    </div>
    <!-- Hold, Cancel or Restart button -->
    <div class="footer-action center-aligned">
      <!-- Hold -->
      <button v-if="this.sessionRequest.subscriptionData.state === 'Active' &&
        this.checkWorkflowSubscriptionPermission('pauseSubscription')
        " type="button" class="uik-btn__base btn btn-secondary"
        v-on:click="initialiseRequest(product, 'SubscriptionHold')">
        Hold
      </button>

      <!-- Spacer -->
      <span v-if="this.sessionRequest.subscriptionData.state === 'Active' &&
        this.checkWorkflowSubscriptionPermission('pauseSubscription') &&
        this.checkWorkflowSubscriptionPermission('cancelSubscription')
        ">
        or
      </span>

      <!-- Cancel -->
      <button v-if="this.sessionRequest.subscriptionData.state === 'Active' &&
        this.checkWorkflowSubscriptionPermission('cancelSubscription')
        " type="button" class="uik-btn__base btn btn-primary"
        v-on:click="initialiseRequest(product, 'SubscriptionCancel')">
        Cancel
      </button>

      <!-- Spacer -->
      <span v-if="this.sessionRequest.subscriptionData.state === 'Active' &&
        (this.checkWorkflowSubscriptionPermission('pauseSubscription') ||
          this.checkWorkflowSubscriptionPermission('cancelSubscription')) &&
        this.checkWorkflowSubscriptionPermission('setExpiration')
        ">
        or
      </span>

      <!-- Set expiration -->
      <button v-if="this.sessionRequest.subscriptionData.state === 'Active' &&
        this.checkWorkflowSubscriptionPermission('setExpiration')
        " type="button" class="uik-btn__base btn btn-clear" v-on:click="setExpiration()">
        Set expiration
      </button>

      <!-- Currently inactive -->
      <button v-if="this.sessionRequest.subscriptionData.state !== 'Active' &&
        this.checkWorkflowSubscriptionPermission('restartSubscription')
        " type="button" class="uik-btn__base btn btn-primary"
        v-on:click="initialiseRequest(product, 'SubscriptionRestart')">
        Restart
      </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { changeEvents } from "../../mixins/changeEvents.js";
import { sessionRequestHandler } from "../../mixins/sessionRequestHandler.js";
import DateRangePicker from "vue2-daterange-picker";

export default Vue.extend({
  name: "OnboardingUpgradeSubscriptionActions",
  mixins: [changeEvents, sessionRequestHandler],
  components: { DateRangePicker },
  props: {
    product: {
      type: Object,
      required: true,
    },
    staticWorkflowProduct: {
      type: Object,
      required: true,
    },
    sessionRequest: {
      type: Object,
      required: true,
    },
  },
  methods: {
    /**
     * Handle subscription permission
     */
    checkWorkflowSubscriptionPermission: function (permissionType) {
      for (
        var a = 0;
        a < this.sessionRequest.workflow.permissions.length;
        a++
      ) {
        if (this.sessionRequest.workflow.permissions[a] === permissionType) {
          return true;
        }
      }

      return false;
    },

    /**
     * Define custom expiration date
     */
    whenDatePicked: function () {
      if (this.expirationDate) {
        //  TODO Overvej constraints på valg af dato
      }
    },

    /**
     * Set expiration
     */
    setExpiration: function () {

      //  Ensure session delay counter is enforced
      if (this.sessionDelayCounter > 0) {

        this.$root.errorResponse("Please wait one moment and try again.");
        return;

      } else {

        //  Set on hold. When complete, then restart // SubscriptionHold -> SubscriptionRestart
        this.initialiseRequest(this.product, 'SubscriptionExpirationHold');

        let expirationTimeout = false;
        this.skipToaster = true;

        setTimeout(() => {
          expirationTimeout = true;
        }, 5000);

        const holdInterval = setInterval(() => {
          if (expirationTimeout) {

            //  TODO timeout handling
            this.$root.errorResponse("Hold request timeout. Could not succesfully set expiration date");
            clearInterval(holdInterval);

          } else if (this.sessionStatus == 'Completed') {

            //  Clear and proceed to next action
            clearInterval(holdInterval);
            this.sessionStatus = null;

            //  Set to restart
            this.initialiseRequest(this.product, 'SubscriptionExpirationRestart', true)

            const restartInterval = setInterval(() => {
              if (expirationTimeout) {
                clearInterval(restartInterval);

                //  TODO timeout handling
                this.$root.errorResponse("Could not succesfully set expiration date");

              } else if (this.sessionStatus == 'Completed') {
                clearInterval(restartInterval);

                this.$root.successResponse("New expiration date set");
                this.skipToaster = false;
              }
            }, 50)

          }
        }, 50)
      }
    }
  },
});
</script>
