<template>
  <div class="control-input">
    <label>Please select months to charge</label>
    <ul class="checkbox-group vertical">
      <li>
        <label v-bind:for="'scheduleSelectSet_1_' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'scheduleSelectSet_1_' + product.uuid"
            v-model="product.scheduleSelectSet_1"
          />January
          <div class="check"></div>
        </label>
      </li>
      <li>
        <label v-bind:for="'scheduleSelectSet_2_' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'scheduleSelectSet_2_' + product.uuid"
            v-model="product.scheduleSelectSet_2"
          />February
          <div class="check"></div>
        </label>
      </li>
      <li>
        <label v-bind:for="'scheduleSelectSet_3_' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'scheduleSelectSet_3_' + product.uuid"
            v-model="product.scheduleSelectSet_3"
          />March
          <div class="check"></div>
        </label>
      </li>
      <li>
        <label v-bind:for="'scheduleSelectSet_4_' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'scheduleSelectSet_4_' + product.uuid"
            v-model="product.scheduleSelectSet_4"
          />April
          <div class="check"></div>
        </label>
      </li>
      <li>
        <label v-bind:for="'scheduleSelectSet_5_' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'scheduleSelectSet_5_' + product.uuid"
            v-model="product.scheduleSelectSet_5"
          />May
          <div class="check"></div>
        </label>
      </li>
      <li>
        <label v-bind:for="'scheduleSelectSet_6_' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'scheduleSelectSet_6_' + product.uuid"
            v-model="product.scheduleSelectSet_6"
          />June
          <div class="check"></div>
        </label>
      </li>
      <li>
        <label v-bind:for="'scheduleSelectSet_7_' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'scheduleSelectSet_7_' + product.uuid"
            v-model="product.scheduleSelectSet_7"
          />July
          <div class="check"></div>
        </label>
      </li>
      <li>
        <label v-bind:for="'scheduleSelectSet_8_' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'scheduleSelectSet_8_' + product.uuid"
            v-model="product.scheduleSelectSet_8"
          />August
          <div class="check"></div>
        </label>
      </li>
      <li>
        <label v-bind:for="'scheduleSelectSet_9_' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'scheduleSelectSet_9_' + product.uuid"
            v-model="product.scheduleSelectSet_9"
          />September
          <div class="check"></div>
        </label>
      </li>
      <li>
        <label v-bind:for="'scheduleSelectSet_10_' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'scheduleSelectSet_10_' + product.uuid"
            v-model="product.scheduleSelectSet_10"
          />October
          <div class="check"></div>
        </label>
      </li>
      <li>
        <label v-bind:for="'scheduleSelectSet_11_' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'scheduleSelectSet_11_' + product.uuid"
            v-model="product.scheduleSelectSet_11"
          />November
          <div class="check"></div>
        </label>
      </li>
      <li>
        <label v-bind:for="'scheduleSelectSet_12_' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'scheduleSelectSet_12_' + product.uuid"
            v-model="product.scheduleSelectSet_12"
          />December
          <div class="check"></div>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "WorkflowModalScheduleSelectedSet",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
});
</script>
