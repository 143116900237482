<template>
  <div class="block-wrap meta-data-fields">
    <div
      v-bind:class="[
        this.$root.user.role === 'MerchantAdmin'
          ? 'block'
          : 'block no-pointer-events',
      ]"
    >
      <!-- Header -->
      <div class="block-header">
        <h3>Metadata</h3>
        <p>
          Other information that should be gathered in the session. This
          information will be stored in the dataset.
        </p>
      </div>
      <div class="field-group-wrapper">
        <!-- Repeatable heading -->
        <div class="row field-group-header">
          <div class="col-sm-10">
            <div class="row">
              <div class="col-sm-3">Label</div>
              <div class="col-sm-3">Save as</div>
              <div class="col-sm-3">Input type</div>
              <div class="col-sm-3">Attributes</div>
            </div>
          </div>
        </div>

        <!-- Repeatable fields -->
        <draggable
          v-bind:list="this.metaDataFields"
          v-on:end="updateDataFields()"
          ghost-class="being-dragged"
        >
          <div
            v-for="dataField in this.metaDataFields"
            v-bind:key="dataField.uuid"
          >
            <!-- Field open -->
            <div class="row field-group inline-add-remove">
              <i class="icon-draggable"></i>
              <div class="col-sm-10">
                <div class="row">
                  <!-- Label -->
                  <div class="col-sm-3 control-input">
                    <input
                      type="text"
                      class="uik-input__input"
                      placeholder="Label"
                      v-model="dataField.label"
                      required
                    />
                  </div>
                  <!-- Name -->
                  <div class="col-sm-3 control-input">
                    <input
                      type="text"
                      class="uik-input__input"
                      placeholder="Save as"
                      v-model="dataField.name"
                      required
                    />
                  </div>
                  <!-- Type -->
                  <div class="col-sm-3 control-input">
                    <select
                      class="uik-input__select"
                      v-model="dataField.inputType"
                      required
                    >
                      <option
                        v-for="metaDataFieldType in metaDataFieldTypes"
                        v-bind:value="metaDataFieldType.inputType"
                        v-bind:key="metaDataFieldType.inputType"
                        v-bind:disabled="
                          metaDataFieldType.inputType === '' ? true : false
                        "
                      >
                        {{ metaDataFieldType.label }}
                      </option>
                    </select>
                  </div>
                  <!-- Attributes -->
                  <div class="col-sm-3 control-input">
                    <ul class="checkbox-group horizontal">
                      <li>
                        <div class>
                          <label>
                            <input
                              type="checkbox"
                              v-model="dataField.required"
                            />
                            Require
                            <div class="check"></div>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class>
                          <label>
                            <input
                              type="checkbox"
                              v-model="dataField.readonly"
                            />
                            Read only
                            <div class="check"></div>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- Value -->
                <div
                  v-if="
                    dataField.inputType === 'text' ||
                    dataField.inputType === 'number' ||
                    dataField.inputType === 'hidden'
                  "
                  class="row"
                >
                  <div class="col-sm-9 control-input">
                    <input
                      type="text"
                      class="uik-input__input field-options"
                      placeholder="Add a default value"
                      v-model="dataField.value"
                    />
                  </div>
                </div>

                <!-- Options -->
                <div
                  v-else-if="
                    dataField.inputType === 'radio' ||
                    dataField.inputType === 'select'
                  "
                  class="row"
                >
                  <div class="col-sm-9 control-input">
                    <input
                      type="text"
                      class="uik-input__input field-options"
                      placeholder="Add options split by comma, such as: 1,2,3,4,5"
                      v-model="dataField.options"
                    />
                  </div>
                </div>
              </div>

              <!-- Remove field -->
              <div class="col-sm-2 control-input">
                <button
                  v-if="$root.user.role == 'MerchantAdmin'"
                  type="button"
                  class="uik-btn__base btn btn-remove"
                  v-on:click="removeDataField(dataField.uuid)"
                >
                  Remove
                </button>
              </div>
            </div>

            <!-- Field end -->
          </div>
        </draggable>

        <!-- Add field open -->
        <div class="row field-group inline-add-remove disabled">
          <i class="icon-draggable"></i>
          <div class="col-sm-10">
            <div class="row">
              <div class="col-sm-3 control-input">
                <input type="text" class="uik-input__input" disabled />
              </div>
              <div class="col-sm-3 control-input">
                <input type="text" class="uik-input__input" disabled />
              </div>
              <div class="col-sm-3 control-input">
                <input type="text" class="uik-input__input" disabled />
              </div>
              <div class="col-sm-3 control-input">
                <ul class="checkbox-group horizontal">
                  <li>
                    <div class>
                      <label>
                        <input type="checkbox" disabled />
                        Required
                        <div class="check"></div>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class>
                      <label>
                        <input type="checkbox" disabled />
                        Read only
                        <div class="check"></div>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-2 control-input">
            <button
              v-if="this.$root.user.role === 'MerchantAdmin'"
              type="button"
              class="uik-btn__base btn-primary btn btn-add"
              v-on:click="addDataField()"
            >
              Add
            </button>
          </div>
        </div>
      </div>
      <!-- Add field end -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Draggable from "vuedraggable";

let uuid = 0;

export default Vue.extend({
  name: "MetaDataFields",
  components: {
    Draggable,
  },
  props: {
    element: {
      type: Object,
      required: false,
    },
  },
  data: function () {
    return {
      metaDataFields: null,
      metaDataFieldTypes: [
        {
          label: "Input type",
          inputType: "",
        },
        {
          label: "Text", // string
          inputType: "text",
          options: false,
        },
        {
          label: "Number", // int
          inputType: "number",
          options: false,
        },
        {
          label: "Hidden", // string
          inputType: "hidden",
          options: false,
        },
        {
          label: "Checkbox", // boolean
          inputType: "checkbox",
          options: false,
        },
        {
          label: "Radio",
          inputType: "radio",
          options: true,
        },
        {
          label: "Select",
          inputType: "select",
          options: true,
        },
      ],
    };
  },
  created() {
    //  Populate
    this.metaDataFields = this.element.metaDataFields;

    //  Update uuid
    if (this.metaDataFields) {
      var i;
      for (i = uuid; i < this.metaDataFields.length; i++) {
        //  Bump by greater uuid
        if (this.metaDataFields[i].uuid > uuid) {
          uuid = this.metaDataFields[i].uuid;
        }
        uuid++;
      }
    }
  },
  methods: {
    /**
     * Append field
     */
    addDataField: function () {
      var metaDataField = {
        uuid: uuid++,
        name: "",
        label: "",
        inputType: "",
        value: "",
        options: "",
        required: false,
        readonly: false,
        hiddenToEndUser: false,
      };

      if (!this.metaDataFields) {
        this.metaDataFields = [metaDataField];
      } else {
        this.metaDataFields.push(metaDataField);
      }

      this.updateDataFields();
    },

    /**
     * Update list of fields
     */
    updateDataFields: function () {
      this.element.metaDataFields = this.metaDataFields;
    },

    /**
     * Remove field
     * @param {int} uuid
     */
    removeDataField: function (uuid) {
      if (this.metaDataFields) {
        var i;
        for (i = 0; i < this.metaDataFields.length; i++) {
          if (this.metaDataFields[i].uuid === uuid) {
            this.metaDataFields.splice(i, 1);
          }
        }
      }

      this.updateDataFields();
    },
  },
});
</script>
