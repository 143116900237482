var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"signup"},[_c('h2',{class:{
      hidden: _vm.product.onlyButton,
    },attrs:{"aria-hidden":_vm.product.onlyButton}},[_vm._v(" Select product ")]),_c('div',{staticClass:"nav",class:{
      'no-pointer-events':
        _vm.$route.query.product || _vm.sessionStatus === 'Completed',
      hidden: _vm.product.onlyButton,
    },attrs:{"aria-hidden":this.$route.query.product || _vm.product.onlyButton ? true : false}},[_c('ul',{staticClass:"nav-tabs",attrs:{"role":"tablist","aria-label":"Vælg produkt med piletast."}},_vm._l((_vm.signUpProducts),function(signUpProduct){return _c('li',{key:signUpProduct.uuid,staticClass:"nav-item"},[_c('button',{staticClass:"nav-link",class:_vm.product.uuid === signUpProduct.uuid ? 'active' : null,attrs:{"type":"button","tabindex":_vm.$route.query.product || _vm.sessionStatus === 'Completed' ? '-1' : '0',"role":"tab"},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;return _vm.prevProduct($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return _vm.prevProduct($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 2)return null;return _vm.nextProduct($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return _vm.nextProduct($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.selectProduct(signUpProduct)}],"click":function($event){return _vm.onSignUpProductChange(signUpProduct)}}},[_vm._v(" "+_vm._s(signUpProduct.productName)+" ")])])}),0)]),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-pane active",attrs:{"role":"tabpanel"}},[_c('div',{staticClass:"row",class:{
          hidden: _vm.product.onlyButton,
        },attrs:{"aria-hidden":_vm.product.onlyButton}},[_c('div',{staticClass:"col-sm-6 tab-right"},[(Object.keys(_vm.product).length > 0)?_c('onboarding-sign-up-review-table',{ref:"OnboardingSignUpReviewTable",attrs:{"product":this.product,"staticWorkflowProduct":this.staticWorkflowProduct}}):_vm._e()],1),_c('div',{staticClass:"col-sm-6 tab-left"},[(Object.keys(_vm.product).length > 0)?_c('div',{staticClass:"control-input"},[_c('label',{attrs:{"for":"amountTotal"}},[_vm._v("Amount in "+_vm._s(_vm.product.currencyCode)+", incl. VAT")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.product.amountTotal),expression:"product.amountTotal",modifiers:{"trim":true}}],staticClass:"uik-input__input",class:[
                (_vm.product.amountTotal && _vm.product.amountTotal.length > 0) ||
                _vm.product.agreementType
                  ? 'not-empty'
                  : 'empty',
              ],attrs:{"type":"number","id":"amountTotal","autocomplete":"nope","disabled":_vm.staticWorkflowProduct.amountTotal ||
                _vm.product.agreementType === 'Shared'
                  ? true
                  : false,"aria-label":"Beløb","required":""},domProps:{"value":(_vm.product.amountTotal)},on:{"change":function($event){return _vm.onAmountTotalChange()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.product, "amountTotal", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]):_vm._e(),(
              Object.keys(_vm.product).length > 0 &&
              _vm.product.paymentType === 'Recurring'
            )?_c('onboarding-schedule-type',{ref:"OnboardingScheduleType",attrs:{"product":this.product,"staticWorkflowProduct":this.staticWorkflowProduct}}):_vm._e(),(
              Object.keys(_vm.product).length > 0 &&
              _vm.product.paymentType === 'Recurring'
            )?_c('onboarding-schedule-expiration',{ref:"OnboardingScheduleExpiration",attrs:{"product":this.product,"staticWorkflowProduct":this.staticWorkflowProduct}}):_vm._e()],1)]),(_vm.sessionStatus === 'Completed')?[_c('div',{ref:"successMessage",staticClass:"alert alert-success center-aligned",attrs:{"tabindex":"0","role":"alert","aria-label":"Sessionen er nu gennemført."}},[_vm._v(" Session complete, please proceed to the next lead ")]),_c('hr',{class:{
            hidden: _vm.product.onlyButton,
          },attrs:{"aria-hidden":_vm.product.onlyButton}}),_c('button',{staticClass:"uik-btn__base btn btn-secondary",class:{
            hidden: _vm.product.onlyButton,
          },staticStyle:{"display":"block","margin":"0 auto"},attrs:{"type":"button","tabindex":"-1","aria-hidden":_vm.product.onlyButton},on:{"click":function($event){return _vm.refreshPage()}}},[_vm._v(" Start again ")])]:(_vm.sessionStatus === 'Rejected')?[_c('div',{ref:"errorMessage",staticClass:"alert alert-danger center-aligned",attrs:{"tabindex":"0","role":"alert","aria-label":"Afvist"}},[_vm._v(" Session rejected by lead ")]),_c('div',{staticClass:"footer-action center-aligned"},[_c('button',{staticClass:"uik-btn__base btn btn-primary",attrs:{"type":"button","tabindex":"1","autofocus":_vm.product.onlyButton,"aria-label":"Start session igen."},on:{"click":function($event){return _vm.initialiseRequest(_vm.product, 'SignUp')}}},[_c('span',[_vm._v("Restart session")])])])]:(_vm.sessionStatus === 'Failed')?[_c('div',{ref:"errorMessage",staticClass:"alert alert-danger center-aligned",attrs:{"tabindex":"0","role":"alert","aria-label":"En fejl opstod"}},[_vm._v(" Session failed by gateway ")]),_c('div',{staticClass:"footer-action center-aligned"},[_c('button',{staticClass:"uik-btn__base btn btn-primary",attrs:{"type":"button","tabindex":"1","aria-label":"Start session igen."},on:{"click":function($event){return _vm.initialiseRequest(_vm.product, 'SignUp')}}},[_c('span',[_vm._v("Restart session")])])])]:[(!_vm.iframeGatewayUrl && Object.keys(_vm.product).length > 0)?_c('onboarding-payment-method-type-selection',{ref:"OnboardingPaymentMethodTypeSelection",class:{
            hidden: _vm.product.onlyButton,
          },attrs:{"product":this.product,"staticWorkflowProduct":this.staticWorkflowProduct,"sessionRequest":this.sessionRequest,"aria-hidden":_vm.product.onlyButton},on:{"whenPaymentMethodTypeChanged":function($event){return _vm.updateSignUp()},"whenBsAppVerificationChange":function($event){return _vm.whenBsAppVerificationChange()}}}):_vm._e(),(
            !_vm.iframeGatewayUrl &&
            Object.keys(_vm.product).length > 0 &&
            _vm.product.paymentType === 'Recurring'
          )?_c('onboarding-schedule-start-type',{ref:"OnboardingScheduleStartType",class:{
            hidden: _vm.product.onlyButton,
          },attrs:{"product":this.product,"staticWorkflowProduct":this.staticWorkflowProduct,"sessionRequest":this.sessionRequest,"aria-hidden":_vm.product.onlyButton},on:{"whenScheduleStartTypeChanged":function($event){return _vm.updateSignUp()},"whenStartWithOneOffChanged":function($event){return _vm.updateSignUp()},"whenOneOffAmountChanged":function($event){return _vm.updateSignUp()},"whenBsAppVerificationChange":function($event){return _vm.whenBsAppVerificationChange()}}}):_vm._e(),(!_vm.iframeGatewayUrl && Object.keys(_vm.product).length > 0)?_c('onboarding-send-sms',{ref:"OnboardingSendSms",class:{
            hidden: _vm.product.onlyButton,
          },attrs:{"product":this.product,"sessionRequest":this.sessionRequest,"getContactPhone":this.getContactPhone,"aria-hidden":_vm.product.onlyButton},on:{"whenBsAppVerificationChange":function($event){return _vm.whenBsAppVerificationChange()}}}):_vm._e(),(_vm.iframeGatewayUrl)?_c('div',{staticClass:"iframe-holder",attrs:{"aria-live":"polite"}},[_c('h3',[_vm._v("Enter gateway details")]),_c('p',[_vm._v("Abort session to change i.e. payment method")]),(_vm.product.paymentMethodType == 'MobilePay' && _vm.product.paymentType == 'Single')?_c('a',{staticClass:"uik-btn__base btn-small",attrs:{"href":_vm.iframeGatewayUrl,"target":"_blank"}},[_vm._v("Open in new tab, if window fails")]):_vm._e(),_c('iframe',{attrs:{"type":"text/html","src":_vm.iframeGatewayUrl,"width":"100%","height":_vm.iframeGatewayHeight + 'px',"allowtransparency":"true","scrolling":"yes","seamless":"","webkitAllowFullScreen":"","mozallowfullscreen":"","allowFullScreen":""}})]):_vm._e(),(Object.keys(_vm.product).length > 0)?_c('div',{staticClass:"footer-action center-aligned"},[(_vm.sessionAttempts > 1)?_c('div',{staticClass:"alert alert-info"},[_vm._v(" Beware! You have now started more than one session. Previous sessions may still be active, so please ensure that the lead only completes once. ")]):_vm._e(),(_vm.sessionStatus === 'Committed')?[(!_vm.product.sendSms)?_c('div',{staticClass:"sr-only",attrs:{"role":"status","aria-label":"Vent venligst."}}):_vm._e(),_c('p',[_vm._v("Please wait while we connect to the gateway ...")]),_vm._m(0)]:(_vm.sessionStatus === 'SentToGateway' && _vm.product.sendSms)?[_c('div',{staticClass:"sr-only",attrs:{"role":"status","aria-label":"En SMS er nu afsendt til vedkommende. Vent venligst på deres bekræftelse."}}),_c('p',[_vm._v("An SMS has been sent. Please ask for confirmation ...")]),_vm._m(1)]:_vm._e(),_c('button',{staticClass:"uik-btn__base btn btn-primary",attrs:{"type":"button","disabled":this.sessionDelayCounter < 1 &&
              (_vm.sessionStatus === null || _vm.sessionStatus === 'Aborted')
                ? false
                : true,"tabindex":this.sessionDelayCounter < 1 &&
              (_vm.sessionStatus === null || _vm.sessionStatus === 'Aborted')
                ? '1'
                : '-1',"autofocus":_vm.product.onlyButton,"aria-hidden":this.sessionDelayCounter < 1 &&
              (_vm.sessionStatus === null || _vm.sessionStatus === 'Aborted')
                ? false
                : true,"aria-label":_vm.sessionStatus === 'Aborted'
                ? 'Start session igen.'
                : 'Start session.'},on:{"click":function($event){return _vm.initialiseRequest(_vm.product, 'SignUp')}}},[(_vm.sessionStatus === 'Aborted')?_c('span',[_vm._v("Restart session "),(this.sessionDelayCounter > 0)?[_vm._v("("+_vm._s(this.sessionDelayCounter)+")")]:_vm._e()],2):_c('span',[_vm._v("Start session "),(this.sessionDelayCounter > 0)?[_vm._v("("+_vm._s(this.sessionDelayCounter)+")")]:_vm._e()],2)]),_c('button',{staticClass:"uik-btn__base btn btn-secondary",class:[
              _vm.sessionStatus !== null && _vm.sessionStatus !== 'Aborted'
                ? 'uik-btn__base btn btn-secondary'
                : 'uik-btn__base btn btn-secondary no-visibility',
            ],attrs:{"type":"button","aria-label":"Afbryd sessionen."},on:{"click":function($event){return _vm.abortSession()}}},[_vm._v(" Abort session ")])],2):_vm._e()]],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wave wave-loading"},[_c('div',{staticClass:"ver1"}),_c('div',{staticClass:"ver2"}),_c('div',{staticClass:"ver3"}),_c('div',{staticClass:"ver4"}),_c('div',{staticClass:"ver5"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wave wave-loading"},[_c('div',{staticClass:"ver1"}),_c('div',{staticClass:"ver2"}),_c('div',{staticClass:"ver3"}),_c('div',{staticClass:"ver4"}),_c('div',{staticClass:"ver5"})])
}]

export { render, staticRenderFns }