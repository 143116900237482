var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-left",attrs:{"role":"menu"}},[_c('div',{staticClass:"nav-wrap"},[_c('div',{staticClass:"brand-wrap"}),_c('nav',{staticClass:"primary-menu"},[_c('ul',[_c('li',{class:[_vm.currentPage === '/' ? 'active' : '']},[_c('router-link',{attrs:{"to":{ name: 'agency-dashboard' }}},[_vm._v(" Dashboard ")])],1),_c('li',[(this.listErrored === true)?_c('span',[_vm._v(" Clients - error ")]):(this.listLoading === true)?_c('span',[_vm._v(" Clients - loading ")]):(this.$root.store.merchants.length > 0)?_c('span',{on:{"click":function($event){return _vm.resetMerchant()}}},[_vm._v(" Clients ")]):_vm._e(),(this.$root.store.merchants.length > 0)?_c('ul',{staticClass:"merchants-list sub-menu"},_vm._l((this.$root.store.merchants),function(merchant){return _c('li',{key:merchant.merchantId},[_c('button',{class:[
                  Object.keys(_vm.$root.merchant).length > 0 &&
                  _vm.$root.merchant.merchantId === merchant.merchantId
                    ? 'active'
                    : '',
                ],attrs:{"type":"button"},on:{"click":function($event){return _vm.openMerchantModal(merchant)}}},[_vm._v(" "+_vm._s(merchant.name)+" ")]),_c('ul',{staticClass:"merchants-entities sub-menu"},[_c('li',{class:[
                    _vm.currentPage.includes('leads') ? 'active' : '',
                  ]},[_c('router-link',{attrs:{"to":{
                      name: 'agency-leads',
                      query: { merchantId: merchant.merchantId },
                    }}},[_vm._v(" Leads ")])],1),_c('li',{class:[
                    _vm.currentPage.includes('sessions') ? 'active' : '',
                  ]},[_c('router-link',{attrs:{"to":{
                      name: 'agency-sessions',
                      query: { merchantId: merchant.merchantId },
                    }}},[_vm._v(" Sessions ")])],1),_c('li',{class:[
                    _vm.currentPage.includes('campaigns') ? 'active' : '',
                  ]},[_c('router-link',{attrs:{"to":{
                      name: 'agency-campaigns',
                      query: { merchantId: merchant.merchantId },
                    }}},[_vm._v(" Campaigns ")])],1),(_vm.$root.user.role === 'AgencyManager')?_c('li',{class:[
                    _vm.currentPage.includes('workflows') ? 'active' : '',
                  ]},[_c('router-link',{attrs:{"to":{
                      name: 'agency-workflows',
                      query: { merchantId: merchant.merchantId },
                    }}},[_vm._v(" Workflows ")])],1):_vm._e()])])}),0):_vm._e()]),(this.$root.user.role === 'AgencyManager')?_c('li',{class:[_vm.currentPage.includes('users') ? 'active' : '']},[_c('router-link',{attrs:{"to":{ name: 'agency-users' }}},[_vm._v(" Users ")])],1):_vm._e()])]),_c('nav',{staticClass:"support-menu"},[_vm._m(0),_c('ul',{staticClass:"logout"},[_c('li',[_c('logout')],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"https://support.onlinefundraising.dk","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Support")])])])
}]

export { render, staticRenderFns }