<template>
  <div v-if="product.paymentType === 'Recurring'" class="block">
    <!-- Tooltip -->
    <div class="control-input">
      <label>Scheduling starts</label>
      <tool-tip
        v-bind:tooltip="
          'Please note the chosen payment method type may affect the possible start date'
        "
      />
    </div>
    <ul class="checkbox-group vertical">
      <!-- now -->
      <li>
        <label v-bind:for="'scheduleStartTypeNow' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'scheduleStartTypeNow' + product.uuid"
            v-model="product.scheduleStartTypeNow"
            v-bind:value="'now'"
          />As soon as possible
          <div class="check"></div>
        </label>
      </li>

      <!-- first -->
      <li>
        <label v-bind:for="'scheduleStartTypeFirst' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'scheduleStartTypeFirst' + product.uuid"
            v-model="product.scheduleStartTypeFirst"
            v-bind:value="'first'"
          />On the first of next possible month
          <div class="check"></div>
        </label>
      </li>

      <!-- custom -->
      <li>
        <label v-bind:for="'scheduleStartTypeCustom' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'scheduleStartTypeCustom' + product.uuid"
            v-model="product.scheduleStartTypeCustom"
            v-bind:value="'custom'"
          />On a custom start date
          <div class="check"></div>
        </label>
      </li>

      <!-- scheduleStartWithOneOff -->
      <li>
        <label v-bind:for="'scheduleStartWithOneOff' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'scheduleStartWithOneOff' + product.uuid"
            v-model="product.scheduleStartWithOneOff"
            v-bind:value="'oneoff'"
          />Ask for an instant payment
          <div class="check"></div>
        </label>
      </li>

      <!-- scheduleExpires -->
      <li>
        <label v-bind:for="'scheduleExpires' + product.uuid">
          <input
            type="checkbox"
            v-bind:id="'scheduleExpires' + product.uuid"
            v-model="product.scheduleExpires"
            v-bind:value="'oneoff'"
          />Allow expiration date
          <div class="check"></div>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue";
import ToolTip from "../ToolTip";

export default Vue.extend({
  name: "WorkflowModalScheduleStartTypes",
  components: {
    ToolTip,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
});
</script>
