var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-group-date"},[_c('label',{staticClass:"control-label col-sm-3",attrs:{"for":_vm.contactData.name}},[_vm._v(" "+_vm._s(_vm.contactData.label)+" ")]),_c('div',{staticClass:"control-input col-sm-9"},[_c('div',{staticClass:"input-wrap"},[_c('date-range-picker',{ref:"datePicker",class:[
          _vm.contactData.value && _vm.contactData.value.length > 0
            ? 'not-empty'
            : 'empty',
          _vm.contactData.validity ? _vm.contactData.validity : '',
        ],attrs:{"required":_vm.contactData.required ? true : false,"singleDatePicker":true,"timePicker":false,"timePicker24Hour":false,"showWeekNumbers":false,"showDropdowns":true,"autoApply":true,"ranges":false,"linkedCalendars":false,"always-show-calendars":true,"opens":'left',"locale-data":{
          firstDay: 1,
          format: 'YYYY/MM/DD',
          separator: '-',
        }},on:{"update":function($event){return _vm.whenDatePicked()}},scopedSlots:_vm._u([{key:"input",fn:function(datePicker){return [_c('span',{domProps:{"innerHTML":_vm._f("formatBirthDatePicker")(datePicker)}}),_c('button',{staticClass:"btn btn-primary icon-btn",attrs:{"type":"button"}},[_c('i',{staticClass:"dashboard-icon icon-calendar"})])]}}]),model:{value:(_vm.datePicked),callback:function ($$v) {_vm.datePicked=$$v},expression:"datePicked"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }