var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('main',{staticClass:"container frontend"},[(!this.$route.query.campaignGuid)?[_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" Campaign Guid not defined. ")])]:(this.instanceErrored)?[_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" An error occured. Please refresh and try again. ")])]:(this.instanceVerified)?[(this.$root.user.role === 'MerchantAdmin')?_c('div',{staticClass:"alert alert-info",attrs:{"role":"alert"}},[_vm._v(" Please note! As a merchant you can only review the campaign and the workflow. Not start sessions. ")]):_vm._e(),(this.campaignErrored)?[(this.campaignErrorDescription)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(this.campaignErrorDescription)+" ")]):_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" The campaign is not available. ")])]:(this.workflowErrored)?[_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" The workflow is not available. ")])]:(this.campaignLoading)?[_vm._v("Loading campaign ...")]:(this.workflowLoading)?[_vm._v("Loading workflow ...")]:[(
          this.$root.user.role === 'AgencyManager' ||
          this.$root.user.role === 'SeniorAgent' ||
          this.$root.user.role === 'JuniorAgent'
        )?_c('section',{staticClass:"session-summary",attrs:{"aria-hidden":"true"}},[_c('ul',[(this.$root.merchant.name)?_c('li',[_c('span',[_vm._v("Client")]),_vm._v(" "+_vm._s(this.$root.merchant.name)+" ")]):(this.sessionRequest.merchantId)?_c('li',[_c('span',[_vm._v("Client")]),_vm._v(" "+_vm._s(this.sessionRequest.merchantId)+" ")]):_vm._e(),(this.$root.user.name)?_c('li',[_c('span',[_vm._v("Agent:")]),_vm._v(" "+_vm._s(this.$root.user.name)+" ")]):(this.sessionRequest.userGuid)?_c('li',[_c('span',[_vm._v("Agent:")]),_vm._v(" "+_vm._s(this.sessionRequest.userGuid)+" ")]):_vm._e(),(this.$route.query.externalLeadId)?_c('li',[_c('span',[_vm._v("Lead:")]),_vm._v(" "+_vm._s(this.$route.query.externalLeadId)+" ")]):_vm._e(),(this.sessionRequest.campaign.name)?_c('li',[_c('span',[_vm._v("Campaign:")]),_vm._v(" "+_vm._s(this.sessionRequest.campaign.name)+" ")]):_vm._e(),(this.sessionRequest.workflow.name)?_c('li',[_c('span',[_vm._v("Workflow:")]),_vm._v(" "+_vm._s(this.sessionRequest.workflow.name)+" ")]):_vm._e(),(this.sessionRequest.workflow.serviceType)?_c('li',[_c('span',[_vm._v("Type:")]),_vm._v(" "+_vm._s(this.sessionRequest.workflow.serviceType)+" ")]):_vm._e()]),(this.sessionRequest.campaign.description)?_c('p',[_vm._v(" "+_vm._s(this.sessionRequest.campaign.description)+" ")]):_vm._e(),_c('hr')]):_vm._e(),(
          this.sessionRequest.workflow.serviceType === 'SignUp' &&
          !this.$route.query.externalLeadId
        )?_c('div',[_c('div',{staticClass:"alert alert-warning",attrs:{"role":"alert"}},[_vm._v(" External lead ID is not defined. ")]),_c('p',{attrs:{"aria-hidden":"true"}},[_vm._v(" Please prepend a unique external ID per Lead to your URL like so:"),_c('br'),_vm._v(" "+_vm._s(this.getAppUrl())+"/onboard?campaignGuid="+_vm._s(this.$route.query.campaignGuid)+"&"),_c('u',[_vm._v("externalLeadId={ ID }")])]),_c('hr'),_c('p',{attrs:{"aria-hidden":"true"}},[_vm._v(" If the lead is not registered elsewhere, you can proceed with a random external lead ID. ")]),_c('button',{staticClass:"uik-btn__base btn btn-primary",attrs:{"type":"button","aria-label":"Klik for at definere et tilfældig ID."},on:{"click":function($event){return _vm.defineExternalLeadId()}}},[_vm._v(" Define a random external lead ID ")])]):(
          this.sessionRequest.workflow.serviceType === 'Upgrade' &&
          !this.$route.query.subscriptionGuid
        )?_c('div',[_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" Subscription Guid is not defined. ")]),_c('p',[_vm._v(" Please prepend the correct subscriptionGuid to your URL like so:"),_c('br'),_vm._v(" "+_vm._s(this.getAppUrl())+"/onboard?campaignGuid="+_vm._s(this.$route.query.campaignGuid)+"&"),_c('u',[_vm._v("subscriptionGuid={ guid }")])])]):_c('div',{staticClass:"session-specification",class:{
          'only-button': this.$route.query.onlyButton === 'true',
        }},[_c('form',{staticClass:"form-horizontal",attrs:{"name":"onboarding","method":"post","action":"","autocomplete":"nope","novalidate":"novalidate"},on:{"submit":function($event){$event.preventDefault();}}},[_c('section',{staticClass:"information group-wrap",class:{
              collapsed: _vm.informationCollapsed,
              hidden: this.$route.query.onlyButton === 'true',
            },attrs:{"role":"region","aria-label":"Kontaktinformationer","aria-hidden":this.$route.query.onlyButton === 'true'}},[_c('div',{staticClass:"group-header",attrs:{"role":"expand"}},[_c('h3',[_vm._v("Information")]),_c('button',{attrs:{"type":"button","aria-label":_vm.informationCollapsed
                    ? 'Tryk Enter for at åbne området og ændre på kontaktinformationer.'
                    : 'Tryk Enter for at lukke området.'},on:{"click":function($event){return _vm.toggleInformationCollapsed()}}},[_c('i',{staticClass:"expand-icon"})]),_c('div',{staticClass:"sr-only",attrs:{"role":"status","aria-label":_vm.informationCollapsed ? 'Lukket' : 'Åbnet'}})]),_c('div',{staticClass:"group-content clearfix",attrs:{"aria-hidden":_vm.informationCollapsed ? true : false}},[_c('onboarding-contact-data',{ref:"OnboardingContactData",attrs:{"sessionRequest":this.sessionRequest,"contactDataApplied":this.contactDataApplied}}),_c('onboarding-meta-data',{ref:"OnboardingMetaData",attrs:{"sessionRequest":this.sessionRequest}})],1)]),(this.sessionRequest.workflow.serviceType === 'SignUp')?_c('onboarding-sign-up',{ref:"OnboardingSignUp",attrs:{"sessionRequest":this.sessionRequest},on:{"newSessionStatus":_vm.setSessionStatus,"onSignUpProductChanged":_vm.updateOnboarding}}):(this.sessionRequest.workflow.serviceType === 'Upgrade')?_c('onboarding-upgrade',{ref:"OnboardingUpgrade",attrs:{"sessionRequest":this.sessionRequest},on:{"newSessionStatus":_vm.setSessionStatus}}):_vm._e()],1)])]]:[_c('div',{attrs:{"role":"alert","aria-hidden":"true"}},[_vm._v("Loading ...")])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }