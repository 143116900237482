<template>
  <div
    v-if="product.scheduleExpires"
    class="schedule-selection"
    aria-hidden="true"
  >
    <div class="control-input">
      <label> Expiration date </label>
      <div class="row">
        <div class="col-sm-6">
          <div class="input-wrap">
            <date-range-picker
              ref="datePicker"
              v-bind:singleDatePicker="true"
              v-bind:timePicker="false"
              v-bind:timePicker24Hour="false"
              v-bind:showWeekNumbers="false"
              v-bind:showDropdowns="true"
              v-bind:autoApply="true"
              v-bind:ranges="false"
              v-bind:linkedCalendars="false"
              v-bind:always-show-calendars="true"
              v-bind:opens="'left'"
              v-bind:locale-data="{
                firstDay: 1,
                format: 'YYYY-MM-DD',
                separator: ' - ',
              }"
              v-model="product.expiresAfterDate"
            >
              <template v-slot:input="datePicker">
                <span
                  v-bind:inner-html.prop="datePicker | formatDatePicker"
                ></span>
                <button
                  type="button"
                  class="btn btn-primary icon-btn"
                  tabindex="-1"
                >
                  <i class="dashboard-icon icon-calendar"></i>
                </button>
              </template>
            </date-range-picker>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="control-input">
            <button
              type="button"
              class="uik-btn__base btn"
              v-on:click="clearDateRange(product)"
              tabindex="-1"
            >
              Clear date
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { formats } from "../../mixins/formats.js";

export default Vue.extend({
  name: "OnboardingScheduleExpiration",
  mixins: [formats],
  components: {
    DateRangePicker,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    /**
     * Clear date definition
     * @param {object} product
     */
    clearDateRange: function (product) {
      product.expiresAfterDate = {
        startDate: null,
        endDate: null,
      };
    },
  },
});
</script>
