<template>
  <div class="upgrade-by-add-on">
    <!-- AddOn selection -->
    <div class="control-input">
      <label>
        Select
        {{ sessionRequest.agreementData.scheduleType | formatScheduleType }}
        add-on
      </label>
      <ul class="radio-group horizontal product-select">
        <li
          v-for="upgradeProduct in addOnProducts"
          v-bind:key="upgradeProduct.uuid"
        >
          <label v-bind:for="'product' + upgradeProduct.uuid">
            <input
              type="radio"
              v-bind:id="'product' + upgradeProduct.uuid"
              v-bind:value="upgradeProduct"
              v-model="staticWorkflowProduct"
              v-on:click="onUpgradeProductChange(upgradeProduct)"
            />
            {{ upgradeProduct.productName }}
            <div class="check"></div>
          </label>
        </li>
      </ul>
    </div>

    <div v-if="product" class="row">
      <div class="col-sm-6">
        <!-- AmountTotal -->
        <div v-if="Object.keys(product).length > 0" class="control-input">
          <label v-if="product.taxDeductable === true" for="amountTotal"
            >Amount in {{ sessionRequest.agreementData.currencyCode }}, incl.
            VAT. <em>Applicable to tax deduction.</em></label
          >
          <label v-else for="amountTotal"
            >Amount in {{ sessionRequest.agreementData.currencyCode }}, incl.
            VAT</label
          >
          <input
            type="number"
            id="amountTotal"
            class="uik-input__input"
            autocomplete="nope"
            v-model.trim="product.amountTotal"
            v-bind:disabled="staticWorkflowProduct.amountTotal ? true : false"
            required
            v-bind:class="[
              product.amountTotal && product.amountTotal.length > 0
                ? 'not-empty'
                : 'empty',
            ]"
          />
        </div>

        <!-- Start date -->
        <onboarding-schedule-start-date
          ref="OnboardingScheduleStartDate"
          v-bind:product="product"
          v-bind:staticWorkflowProduct="staticWorkflowProduct"
          v-bind:sessionRequest="sessionRequest"
        />

        <!-- Expires after date -->
        <onboarding-schedule-expiration
          ref="OnboardingScheduleExpiration"
          v-bind:product="product"
          v-bind:staticWorkflowProduct="staticWorkflowProduct"
          v-bind:sessionRequest="sessionRequest"
        />
      </div>
    </div>

    <!-- Submit -->
    <div class="footer-action center-aligned">
      <!-- Please beware of multiple sessions -->
      <div
        v-if="sessionAttempts > 0"
        v-bind:class="
          this.sessionStatus === 'Completed'
            ? 'alert alert-success'
            : this.sessionStatus === 'Rejected'
            ? 'alert alert-danger'
            : 'alert alert-info'
        "
      >
        <span
          v-bind:inner-html.prop="
            this.sessionStatus === 'Completed'
              ? 'The add-on was registered.'
              : this.sessionStatus === 'Rejected'
              ? 'An error occurred. Please review.'
              : 'Processing'
          "
          role="alert"
          v-bind:aria-label="
            this.sessionStatus === 'Completed'
              ? 'Opgraderingen blev gennemført.'
              : this.sessionStatus === 'Rejected'
              ? 'Opgraderingen blev afvist på grund af teknisk fejl.'
              : null
          "
        ></span>
      </div>

      <button
        v-if="Object.keys(product).length > 0"
        type="button"
        class="uik-btn__base btn btn-primary"
        v-on:click="initialiseRequest(product, 'CreateAddOn')"
        v-bind:disabled="this.sessionDelayCounter < 1 ? false : true"
      >
        Create
        <template v-if="this.sessionDelayCounter > 0"
          >({{ this.sessionDelayCounter }})</template
        >
      </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import OnboardingScheduleExpiration from "./OnboardingScheduleExpiration";
import OnboardingScheduleStartDate from ".//OnboardingScheduleStartDate.vue";
import { formats } from "../../mixins/formats.js";
import { changeEvents } from "../../mixins/changeEvents.js";
import { sessionRequestHandler } from "../../mixins/sessionRequestHandler.js";

export default Vue.extend({
  name: "OnboardingUpgradeAddOn",
  mixins: [formats, changeEvents, sessionRequestHandler],
  components: {
    OnboardingScheduleExpiration,
    OnboardingScheduleStartDate,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    staticWorkflowProduct: {
      type: Object,
      required: true,
    },
    sessionRequest: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      sessionStatus: null,
      sessionAttempts: 0,
      sessionDelayCounter: 0,
    };
  },
  computed: {
    addOnProducts: function () {
      return this.sessionRequest.workflow.upgradeProducts.filter(function (p) {
        return p.productName && p.upgradeType === "AddOn";
      });
    },
  },
  created() {
    //  Initialise session delay counter
    setInterval(
      function () {
        this.sessionDelayCounter = this.sessionDelayCounter - 1;
      }.bind(this),
      1000
    );
  },
});
</script>
