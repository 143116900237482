var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"schedule-selection",class:{
    hidden: this.staticWorkflowProduct.hasScheduleSelectedSet,
  }},[(this.staticWorkflowProduct.hasScheduleSelectedSet)?_c('div',{staticClass:"row"}):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"control-input"},[_c('label',{attrs:{"for":"scheduleType"}},[_vm._v("Schedule")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.product.scheduleType),expression:"product.scheduleType"}],staticClass:"uik-input__select",class:[
            _vm.staticWorkflowProduct.scheduleType ||
            _vm.product.agreementType === 'Shared'
              ? 'disabled'
              : null,
            _vm.product.scheduleType && _vm.product.scheduleType.length > 0
              ? 'not-empty'
              : 'empty',
          ],attrs:{"id":"scheduleType","disabled":_vm.staticWorkflowProduct.scheduleType ||
            _vm.product.agreementType === 'Shared'
              ? true
              : false,"required":"","aria-label":"Vælg frekvens"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.product, "scheduleType", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.whenScheduleTypeChange()}]}},[(_vm.product.scheduleType === undefined)?_c('option',{attrs:{"disabled":_vm.staticWorkflowProduct.scheduleType ? true : false},domProps:{"value":undefined}},[_vm._v(" Select schedule ")]):_c('option',{attrs:{"disabled":_vm.staticWorkflowProduct.scheduleType ? true : false},domProps:{"value":null}},[_vm._v(" Select schedule ")]),_vm._l((this.$root.helpers.scheduleTypes),function(scheduleType){return _c('option',{key:scheduleType.value,attrs:{"disabled":_vm.staticWorkflowProduct.scheduleType || scheduleType.disabled
                ? true
                : false},domProps:{"value":scheduleType.value}},[_vm._v(" "+_vm._s(scheduleType.label)+" ")])})],2)])]),_c('div',{staticClass:"col-sm-6",attrs:{"aria-hidden":"true"}},[_c('div',{staticClass:"control-input"},[_c('label',[_vm._v("Repeat every")]),_c('div',{staticClass:"input-wrap"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.product.scheduleEveryOther),expression:"product.scheduleEveryOther"}],staticClass:"uik-input__select",class:[
              _vm.staticWorkflowProduct.scheduleType === 'Manual' ||
              _vm.staticWorkflowProduct.scheduleEveryOther ||
              _vm.product.agreementType === 'Shared'
                ? 'disabled'
                : null,
            ],attrs:{"id":"scheduleEveryOther","required":"","tabindex":"-1"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.product, "scheduleEveryOther", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.whenScheduleEveryOtherChange()}]}},[(_vm.product.scheduleEveryOther === undefined)?_c('option',{attrs:{"disabled":_vm.staticWorkflowProduct.scheduleEveryOther ? true : false},domProps:{"value":undefined}},[_vm._v(" Repeat ")]):_c('option',{attrs:{"disabled":_vm.staticWorkflowProduct.scheduleEveryOther ? true : false},domProps:{"value":null}},[_vm._v(" Repeat ")]),_vm._l((this.$root.helpers
                .scheduleEveryOthers),function(scheduleEveryOther){return _c('option',{key:scheduleEveryOther.value,attrs:{"disabled":_vm.staticWorkflowProduct.scheduleEveryOther ? true : false},domProps:{"value":scheduleEveryOther.value}},[_vm._v(" "+_vm._s(scheduleEveryOther.label)+" ")])})],2),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.product.scheduleCalendarUnit),expression:"product.scheduleCalendarUnit"}],staticClass:"uik-input__select",class:[
              _vm.staticWorkflowProduct.scheduleType === 'Manual' ||
              _vm.staticWorkflowProduct.scheduleCalendarUnit ||
              _vm.product.agreementType === 'Shared'
                ? 'disabled'
                : null,
            ],attrs:{"id":"scheduleCalendarUnit","required":"","tabindex":"-1"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.product, "scheduleCalendarUnit", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.whenScheduleCalendarUnitChange()}]}},[(_vm.product.scheduleCalendarUnit === undefined)?_c('option',{attrs:{"disabled":_vm.staticWorkflowProduct.scheduleCalendarUnit ? true : false},domProps:{"value":undefined}},[_vm._v(" Unit ")]):_c('option',{attrs:{"disabled":_vm.staticWorkflowProduct.scheduleCalendarUnit ? true : false},domProps:{"value":null}},[_vm._v(" Unit ")]),_vm._l((this.$root.helpers
                .scheduleCalendarUnits),function(scheduleCalendarUnit){return _c('option',{key:scheduleCalendarUnit.value,attrs:{"disabled":_vm.staticWorkflowProduct.scheduleCalendarUnit ? true : false},domProps:{"value":scheduleCalendarUnit.value}},[_vm._v(" "+_vm._s(scheduleCalendarUnit.label)+" ")])})],2)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }