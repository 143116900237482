import axios from "axios";

export const userHandler = {
    data: function() {
        return {
            user: {}
        };
    },
    methods: {

        /**
         * Get user by guid
         * @param {string} guid 
         */
        getUser: function(guid) {

            //  Get individually as merchant
            if (this.$root.user.role === "MerchantAdmin") {

                //  Check storage
                if (this.user && this.user.userGuid === guid) {

                    //  Skip
                    // window.console.log("users get user from storage");
                }

                //  Check storage
                else if (this.$root.store.users.length > 0) {

                    // window.console.log("users get user from storage");

                    let foundUser = this.returnUser(guid);
                    if (foundUser) {
                        this.user = foundUser;
                        return;
                    }
                }

                //  Extract from API
                else {

                    this.fetchUser(guid).then(response => {
                        if (response.userErrored) {
                            this.user = {};
                        } else {
                            this.user = response.user;

                            //  Add to storage
                            this.$root.store.users.push(this.user);
                        }
                    });
                }
            }

            //  Get all as agency
            else {

                //  Check storage
                let userPromise = this.loadUsers();
                if (typeof userPromise.then !== "undefined") {
                    userPromise.then(response => {
                        if (response.usersErrored) {
                            this.user = {};
                        } else {
                            this.user = this.returnUser(guid);
                        }
                    });
                } else {

                    //  Extract from storage
                    this.user = this.returnUser(guid);
                }
            }
        },

        /**
         * Get users from storage
         */
        loadUsers: function() {

            //  Check storage
            if (this.$root.store.users.length > 0) {
                // window.console.log("users get list from storage");

                return {
                    usersErrored: false,
                    users: this.$root.store.users,
                };
            }

            //  Fetch to storage
            else {
                return this.fetchUsers();
            }
        },

        /**
         * Get user by guid
         * @param {string} guid 
         */
        fetchUser: function(guid) {

            // window.console.log("users get user from API");

            //  Request
            return axios
                .get(this.$root.apiUrl + "/user/" + guid, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.$root.user.authToken
                    }
                })
                .then(response => {

                    return {
                        userErrored: false,
                        user: response.data
                    };
                })
                .catch(error => {

                    this.$root.handleErrorResponse(error);

                    return {
                        userErrored: true,
                        user: {}
                    };
                });
        },

        /**
         * Get users from API
         */
        fetchUsers: function() {

            // window.console.log("users get list from API");

            //  Request
            return axios
                .get(this.$root.apiUrl + "/users", {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.$root.user.authToken
                    }
                })
                .then(response => {
                    //  Build storage
                    this.$root.store.users = response.data;

                    return {
                        usersErrored: false,
                        users: this.$root.store.users
                    };
                })
                .catch(error => {

                    this.$root.handleErrorResponse(error);

                    return {
                        usersErrored: true,
                        users: []
                    };

                });
        },

        /**
         * Return user by guid
         * @param {string} guid 
         */
        returnUser: function(guid) {

            //  Check storage
            if (this.$root.store.users.length > 0) {

                for (var i = 0; i < this.$root.store.users.length; i++) {

                    //  Extract from storage
                    if (guid === this.$root.store.users[i].userGuid) {
                        return this.$root.store.users[i];
                    }
                }
            }

            return null;
        }
    }
};